export default {
  'common.all': 'All',
  'common.are_you_sure': 'Are you sure?',
  'common.language.da': 'Danish',
  'common.language.en': 'English',
  'common.search': 'Search...',
  'common.unknown': 'Unknown',

  'core.possessive_title': 'Salary’s',
  'core.title': 'Salary',

  'date.date_format': 'd MMMM yyyy',
  'date.date_interval': '{start} – {end}',
  'date.date_short_format': 'd MMM yyyy',
  'date.date_time_format': 'd MMMM yyyy HH:mm',
  'date.date_time_short_format': 'd MMM yyyy HH:mm',
  'date.day_month': 'd MMMM',
  'date.day_of_month': 'd',
  'date.month_of_year': 'MMMM yyyy',

  'number.currency_suffix': 'kr.',
  'number.decimal_separator': '.',
  'number.thousand_separator': ',',

  'unit.day_count.many': '{count} days',
  'unit.day_count.one': '{count} day',
  'unit.day.many': 'days',
  'unit.day.one': 'day',
  'unit.employee_count.many': '{count} employees',
  'unit.employee_count.one': '{count} employee',
  'unit.employee.many': 'employees',
  'unit.employee.one': 'employee',
  'unit.hour_count.many': '{count} hours',
  'unit.hour_count.one': '{count} hour',
  'unit.hour.many': 'hours',
  'unit.hour.one': 'hour',
  'unit.minute_count.many': '{count} minutes',
  'unit.minute_count.one': '{count} minute',
  'unit.minute.many': 'minutes',
  'unit.minute.one': 'minute',

  'accept_user_invite.button.no': 'No',
  'accept_user_invite.button.switch_user': 'Switch user',
  'accept_user_invite.button.yes': 'Yes',
  'accept_user_invite.company_group.intro.line_1':
    'Do you want to attach your user ({email}) to the company group {companyName}?',
  'accept_user_invite.company_group.intro.line_2':
    'If this is not the right user, you should switch user (log out) and log in again with the right user for this company group.  You will also have the opportunity to create a new user, should that be necessary.',
  'accept_user_invite.company_group.title': 'Attach user to company group',
  'accept_user_invite.company.intro.line_1':
    'Do you want to attach your user ({email}) to the company {companyName} ({companyCVR})?',
  'accept_user_invite.company.intro.line_2':
    'If this is not the right user, you should switch user (log out) and log in again with the right user for this company.  You will also have the opportunity to create a new user, should that be necessary.',
  'accept_user_invite.company.title': 'Attach user to company',
  'accept_user_invite.warning.broken_token': 'The invitation token in the link is not valid, try again.',

  'account.add_new_company': 'Add new company',
  'account.email': 'Email',
  'account.error.mfa_input_code': 'Insert code',
  'account.error.mfa_select_method': 'Select method',
  'account.message.email_change_help_text':
    'After you save, a message will be sent to the provided e-mail with a link to confirm the change.',
  'account.message.email_change_success_text': 'You will receive an e-mail with a link to confirm the change.',
  'account.message.mfa_was_saved': 'Set up for two factor authorisation has been saved',
  'account.mfa': 'Two factor authorisation',
  'account.mfa.code': 'Code',
  'account.mfa.confirm': 'Confirmation',
  'account.mfa.continue_to_confirmation': 'Continue to confirmation',
  'account.mfa.disable': 'Disable two factor authorisation',
  'account.mfa.enabled_via': 'Enabled via',
  'account.mfa.message.description': 'Select two factor authorisation via {sms} or {totp}.',
  'account.mfa.message.mfa_not_enabled': 'Two factor authorisation is not enabled',
  'account.mfa.message.recovery_codes_explanation':
    "Below you'll find the recovery codes, you can use, if you have problems using your two factor authorisation.",
  'account.mfa.message.sms_confirmation':
    'Shortly you will receive a coda via text message. Enter the code in the field below.',
  'account.mfa.message.totp_confirmation':
    'Scan the QR code or type in the key in your Authenticator-app. Afterwards, enter the code in the field below.',
  'account.mfa.method': 'Method',
  'account.mfa.none': 'None',
  'account.mfa.recovery_codes': 'Recovery codes',
  'account.mfa.select_method': 'Select method',
  'account.mfa.setup': 'Set up two factor authorisation',
  'account.mfa.sms': 'Text message',
  'account.mfa.totp': 'Authenticator app',
  'account.mitid': 'Confirm identity with MitID',
  'account.mitid.confirm': 'Confirm with MitID',
  'account.mitid.error.unable_to_find_url': 'Unexpected error, contact Salary.',
  'account.mitid.identity_already_verified': 'Your identity has already been verified',
  'account.mitid.intro': 'To look up CPR numbers, you will need to confirm your identity with MitID',
  'account.mitid.logging_note': 'Please note that all CPR requests are logged.',
  'account.mitid.title': 'Confirm your identity with MitID',
  'account.mitid.verified_intro':
    'Since you have already confirmed your identity, you are ready to look up CPR numbers.',
  'account.password': 'Password',
  'account.profile': 'Profile',
  'account.profile.form.language': 'Language',
  'account.profile.form.language.required': 'Language is required',
  'account.profile.form.name': 'Name',
  'account.profile.form.name.required': 'Name is required',
  'account.profile.form.show_company_list_on_log_in': 'Always load the company overview, when logging in',
  'account.profile.form.show_company_list_on_log_in.help.line_1':
    'With this function disabled, Salary will return to the company you were last viewing.',
  'account.profile.form.show_company_list_on_log_in.help.line_2':
    'With this function enabled, Salary will always go to the company after you have logged in.',
  'account.profile.form.side_menu': 'Move the menu to the left',
  'account.profile.form.theme': 'Theme',
  'account.profile.form.theme.classic': 'Classic Salary colours',
  'account.profile.form.theme.dark': 'Dark',
  'account.profile.form.theme.default': 'No preference',
  'account.profile.form.theme.light': 'Light',
  'account.terminate': 'Delete account',
  'account.terminate.message.multiple_companies':
    'If you wish to terminate your agreement and delete your companies, then first select the company to be deleted, navigate to Company - Support, and fill in the deletion request.',
  'account.terminate.message.single_company':
    'If you wish to terminate your agreement and delete your company, then {link} and fill in the deletion request.',
  'account.terminate.message.single_company.click_here': 'click here',
  'account.terminate.title': 'Termination of Salary account',

  'accounting_integration.alert.success': 'Your accounting integration has been saved',
  'accounting_integration.connection.back': 'Back',
  'accounting_integration.connection.form.parameter.placeholder': 'Enter {parameter} from {integration}',
  'accounting_integration.connection.form.parameter.required': '{parameter} is required',
  'accounting_integration.connection.next_step': 'Continue to next step',
  'accounting_integration.connection.title': 'Establish connection',
  'accounting_integration.daybooks.form.daybook_id': 'Select journal',
  'accounting_integration.daybooks.form.daybook_id.allow_no_daybook_note':
    'If you select no journal, Salary will create a new every time we book.',
  'accounting_integration.daybooks.form.daybook_id.allow_no_daybook_note.business_central':
    'If there can be more than 100 transactions at a time, we recommend you chose no daybook, when using Business Central.',
  'accounting_integration.daybooks.form.daybook_id.no_daybook': 'No journal',
  'accounting_integration.daybooks.form.daybook_id.required': 'Journal must be selected',
  'accounting_integration.daybooks.form.submit.editing': 'Save modification',
  'accounting_integration.daybooks.form.submit.next_step': 'Continue to next step',
  'accounting_integration.error_return': 'Return',
  'accounting_integration.integration_type.form.integration_type': 'Select accounting software',
  'accounting_integration.integration_type.form.integration_type.placeholder': 'Select program',
  'accounting_integration.integration_type.form.integration_type.required': 'Accounting software must be selected',
  'accounting_integration.integration_type.intro':
    'We strive to integrate to as many accounting programs as possible. If you cannot find yours on the list, you or your accountant can transfer manually via the accounting report.',
  'accounting_integration.integration_type.next': 'Continue to connection',
  'accounting_integration.integration_type.next.lock': 'Upgrade to Basic to book automatically',
  'accounting_integration.integration_type.title': 'Select your accounting software',
  'accounting_integration.mapping.back': 'Back',
  'accounting_integration.mapping.form.external_id.no_account': 'No account',
  'accounting_integration.mapping.form.external_id.no_integration': 'Account number',
  'accounting_integration.mapping.form.external_id.not_active': 'The account is no longer available',
  'accounting_integration.mapping.form.external_id.not_active.number': 'The account {number} is no longer available',
  'accounting_integration.mapping.form.external_id.option_not_active': '(no longer available)',
  'accounting_integration.mapping.form.header.account': 'Account',
  'accounting_integration.mapping.form.header.text': 'Accounting text',
  'accounting_integration.mapping.form.header.type': 'Accounting type',
  'accounting_integration.mapping.form.text': 'Accounting text',
  'accounting_integration.mapping.intro':
    'Here you configure which accounts in your accounting software, where Salary shall book. Check all the accounting types use the correct accounts from your account plan. You can also define which accounting text to be used when booking.',
  'accounting_integration.mapping.next': 'Connect to {integration}',
  'accounting_integration.mapping.next.no_integration': 'Save account plan',
  'accounting_integration.mapping.title': 'Approve or modify account plan',
  'accounting_integration.organization.next_step': 'Continue to next step',
  'accounting_integration.organization.organization_id': 'Select organisation',
  'accounting_integration.organization.organization_id.required': 'Organisation must be selected',
  'accounting_integration.organization.save_change': 'Save change',
  'accounting_integration.organization.title': 'Select organisation',
  'accounting_integration.steps.choose_daybook': 'Select journal',
  'accounting_integration.steps.choose_organization': 'Select organisation',
  'accounting_integration.steps.intro':
    'Everything accounting related will automatically be transferred to your accounting program.',
  'accounting_integration.steps.map_accounts': 'Account plan',
  'accounting_integration.steps.need_key': 'Establish connection',
  'accounting_integration.steps.note':
    'If you experience problems establishing connection to your accounting software, you are always welcome to contact {link}.',
  'accounting_integration.steps.select_integration': 'Select accounting software',
  'accounting_integration.steps.title': 'Connect your accounting software',

  'accounting_tab.accounting_accounting': 'Accounting is booked',
  'accounting_tab.accounting_accounting.automatic': 'Automatic',
  'accounting_tab.accounting_accounting.manual': 'Manual',
  'accounting_tab.accounting_integration.intro': 'Booking using',
  'accounting_tab.active_information.back': 'Back',
  'accounting_tab.active_information.field.company': 'Company',
  'accounting_tab.active_information.field.company.national_id': 'CVR: {national_id}',
  'accounting_tab.active_information.field.external_id': 'Agreement number',
  'accounting_tab.active_information.intro': 'Information regarding your integration to your accounting software',
  'accounting_tab.active_information.title': 'Active integration details',
  'accounting_tab.add_accounting_integration': 'Integrate to accounting software',
  'accounting_tab.alert.success': 'Changes to {name} have been saved',
  'accounting_tab.broken_integration.button': 'Re-establish connection',
  'accounting_tab.broken_integration.message':
    'Your accounting integration has failed. You will need to re-establish your integration. It failed with the following error message:',
  'accounting_tab.daybooks.alert.success': 'Accounting integration updated',
  'accounting_tab.daybooks.back': 'Back',
  'accounting_tab.daybooks.no_daybooks': 'Your accounting program does not use journals.',
  'accounting_tab.daybooks.no_integration': 'You have no accounting integration, go back to create one.',
  'accounting_tab.daybooks.title': 'Change journal',
  'accounting_tab.dimensions.back': 'Back',
  'accounting_tab.dimensions.departments.intro':
    "Normally Salary compiles all transactions for the entire company, e.g. we only transfer one single pay transaction. But it's possible to have the transactions split by department instead.",
  'accounting_tab.dimensions.departments.title': 'Bookkeeping by department',
  'accounting_tab.dimensions.title.employee_dimensions': 'Bookkeeping by department and employee dimensions',
  'accounting_tab.dimensions.title.no_dimensions': 'Bookkeeping by department',
  'accounting_tab.dimensions.title.with_dimensions': 'Bookkeeping by department and dimensions',
  'accounting_tab.form.booking_rule': 'How will you book in {system}?',
  'accounting_tab.form.booking_rule.placeholder': 'Booking method',
  'accounting_tab.form.booking_rule.required': 'Booking method is required',
  'accounting_tab.form.refresh_account_plan': 'Update account plan',
  'accounting_tab.form.select_cost_center.company': 'Company',
  'accounting_tab.form.select_cost_center.cost_centers': 'Select cost centre',
  'accounting_tab.form.select_cost_center.departments': 'Select department',
  'accounting_tab.header.active_information': 'Integration details',
  'accounting_tab.header.add_integration': 'Change accounting integration',
  'accounting_tab.header.daybook': 'Change journal',
  'accounting_tab.header.dimension.employee_dimensions': 'Bookkeeping by department and employee dimensions',
  'accounting_tab.header.dimension.has_dimensions': 'Bookkeeping by department and dimensions',
  'accounting_tab.header.dimension.no_dimensions': 'Bookkeeping by department',
  'accounting_tab.header.settings': 'Advanced',
  'accounting_tab.header.sync': 'Synchronise',
  'accounting_tab.header.vouchers': 'View booking',
  'accounting_tab.old_dinero_token.message':
    'Your Dinero API key is being phased out. If you do not renew your Dinero API key, your accounting may start to fail. It is Dinero who has decided to phase out the old keys. To change to the new key, select "{link}", select Dinero as accounting software and follow the instructions.',
  'accounting_tab.old_dinero_token.message.link': 'change accounting software',
  'accounting_tab.organizations.alert.success': 'Accounting integration has been updated',
  'accounting_tab.organizations.back': 'Back',
  'accounting_tab.organizations.has_organization':
    'Access to your accounting integration may give you access to more than one company. If you have more than one company in your accounting program, you will need to select which to match {name} in Salary.',
  'accounting_tab.organizations.no_integration': 'You have no accounting integration, go back to create one.',
  'accounting_tab.organizations.no_organization':
    'Your accounting software does not allow access to multiple companies, so you cannot change company.',
  'accounting_tab.organizations.title': 'Change company',
  'accounting_tab.settings.alert.success': 'Accounting settings for {name} have been saved',
  'accounting_tab.settings.back': 'Back',
  'accounting_tab.settings.form.accounting_manual_payment_always_asset':
    'Book manual transferred pay as received on the disp. date',
  'accounting_tab.settings.form.accounting_manual_payment_always_asset.help.line_1':
    "When the pay is transmitted automatically (via MasterCard Payment Services), the system will credit the pay directly on the bank.  If it's a manual transfer, it will be credited to remuneration liability account, since we cannot know when it will actually be transferred.  When it is transferred, you will have to manual debet the liability account and credit the bank.",
  'accounting_tab.settings.form.accounting_manual_payment_always_asset.help.line_2':
    'By enabling this setting, we pretend the manual transfers are automatic transfers, and we thus credit the bank rather then the liability account for manual transfers.',
  'accounting_tab.settings.form.accounting_vacation_liability': 'Book holiday allowance duties',
  'accounting_tab.settings.form.add_consolidation_report_to_voucher':
    'Attach remuneration receipt to the payroll bookings',
  'accounting_tab.settings.form.allow_debit_company_accounting_account':
    'Permit other debit account on freelancer compensations',
  'accounting_tab.settings.form.book_transfers_on_payment_date': 'Book transfers on their intended payment date',
  'accounting_tab.settings.form.book_transfers_on_payment_date.help.line_1':
    'Without this setting, transfers are booked for the date they are actually transferred.',
  'accounting_tab.settings.form.book_transfers_on_payment_date.help.line_2':
    'When it is set, transfers are booked the payment date for the transfer.  This would usually be the disposition date, therefore often the following bank day.',
  'accounting_tab.settings.form.disable_bank_accounting': 'Do not book payments',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_1':
    'Normally Salary book payments of pay, taxes, etc., just as payrolls are booked. If you do not wish for payments to be booked automatically, you can turn it off here.',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_2':
    'Be aware, when you turn off automatic booking of payments, will {none} of your payments be booked, and there will be no booking receipt for them. This includes {all} payments, including pay and tax payments.',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_2.all': 'all',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_2.none': 'none',
  'accounting_tab.settings.form.enable_otp_accounting_text': 'Enable separate accounting lines',
  'accounting_tab.settings.form.enable_otp_accounting_text.help.line_1':
    'Normally bonuses, outlays, etc. are combined into single transactions when booked, so that for instance, all bonus in a given period appears as a single transaction.',
  'accounting_tab.settings.form.enable_otp_accounting_text.help.line_2':
    'However, if you wish to be able to separate individual payment registrations (such as bonus, outlay, advances, etc.) as their own transactions, you can enable that here.',
  'accounting_tab.settings.form.enable_otp_accounting_text.help.line_3':
    'Next time you for instance give someone a bonus, will you have the opportunity to write a separate accounting text.',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts': 'Skip non-mapped accounts',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_1':
    'Normally an account on the account plan that is not mapped to an account in the accounting system will cause our accounting to fail.',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_2':
    'Should you wish the opportunity to skip accounts to be booked, you can enable this setting to skip non-mapped accounts.',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_3':
    'Be aware that this increases the risk of the accounting not balancing!  The attached voucher will still include the accounts that have not been mapped.',
  'accounting_tab.settings.title': 'Advanced accounting settings',
  'accounting_tab.title': 'Accounting',

  'accounting.booking_rule.book': 'Book the vouchers for me',
  'accounting.booking_rule.daybook': 'Transfer to the daybook, I will book the vouchers myself',
  'accounting.no_integration': 'No accounting integration',

  'administration_layout.menu.assets': 'Assets',
  'administration_layout.menu.documents': 'Documents',
  'administration_layout.menu.projects': 'Projects',

  'advanced_setting.alert.success': 'Changes to {name} have been saved',
  'advanced_setting.company_group.retroactive.description':
    'Per default will the settings only affect future companies added to the group, but with this tick box you can ensure all companies already in the group will receive the same settings.',
  'advanced_setting.company_group.retroactive.title': 'Retroactive',
  'advanced_setting.header.accounting': 'Accounting',
  'advanced_setting.header.additional_features': 'Additional functions',
  'advanced_setting.header.back': 'Back',
  'advanced_setting.header.benefits': 'Benefits',
  'advanced_setting.header.car_allowance': 'Transport allowance',
  'advanced_setting.header.company': 'Company administration',
  'advanced_setting.header.employee_app': 'Employee app',
  'advanced_setting.header.employees': 'Employees',
  'advanced_setting.header.leave': 'Holiday & absence',
  'advanced_setting.header.pay': 'Pay',
  'advanced_setting.header.pension': 'Pension',
  'advanced_setting.header.time_registrations': 'Time registration',
  'advanced_setting.header.title': 'Advanced settings',
  'advanced_setting.introduction.paragraph_1':
    'Payroll is complicated, and Salary make it simple. However should still need some more advanced functions for payroll, you can enable them here.',
  'advanced_setting.introduction.paragraph_2':
    'If you are in doubt whether these functions are something you need, then {link}.',
  'advanced_setting.introduction.paragraph_2_link': 'write our support team',
  'advanced_setting.setting.allow_app_employee_update_banking.description':
    'An employee cannot update their own bank information per default. With this setting, an employee can edit their bank information. Both employer and employee will receive an email, when the bank information has been changed.',
  'advanced_setting.setting.allow_app_employee_update_banking.title':
    'Allow employees to update their bank information',
  'advanced_setting.setting.allow_approve_only_user.description':
    'The user type "only approver" is a simple user type, that can only approve payroll through a mobile phone. The user will receive a link via text message, which will take them to the payroll for approval.',
  'advanced_setting.setting.allow_approve_only_user.title': 'Allow the user type "only approver"',
  'advanced_setting.setting.allow_day_laborer.description':
    'Normally holiday is calculated to 2.08 holidays a month. This function is for when you have an employee whose holiday are calculated per day or per hour.',
  'advanced_setting.setting.allow_day_laborer.title': 'Allow holiday calculation per day/hour',
  'advanced_setting.setting.allow_employee_to_upload_documents.description':
    'Normally only the employer can attach documents to an employee. This function allows the employees to do the same. You will see their documents as normal.',
  'advanced_setting.setting.allow_employee_to_upload_documents.title': 'Allow employees to save documents in the app',
  'advanced_setting.setting.allow_freelancer_cvr.description':
    'Normally you use a CPR number for your freelancers. This function is for when your freelancers have a CVR number.',
  'advanced_setting.setting.allow_freelancer_cvr.title': 'Allow CVR numbers for freelancere',
  'advanced_setting.setting.allow_leave_type_employee_selectable.description':
    'Normally, an employee can select days off, sick days and unpaid absence in the app. With this function you can disable specific absence types. When this function is enabled, you can access the settings page here.',
  'advanced_setting.setting.allow_leave_type_employee_selectable.link': 'You can adjust the absence types here',
  'advanced_setting.setting.allow_leave_type_employee_selectable.title':
    'Allow disabling of specific absence types in app',
  'advanced_setting.setting.allow_negation_payroll.description':
    'If a payslip mistakenly has been completed, it can be reversed. When reversing a payslip, it will create a negative payroll that needs approval.',
  'advanced_setting.setting.allow_negation_payroll.title': 'Rolling back payrolls',
  'advanced_setting.setting.allow_net_pension.description':
    'Pension is usually paid based on the gross pay. This function allows 53A pension of the net pay.',
  'advanced_setting.setting.allow_net_pension.title': 'Allow net pay pension',
  'advanced_setting.setting.allow_no_atp.description':
    'All employees in Denmark has to pay ATP. There are exceptions, if one has executive pay. This function allows disabling ATP for the executive paid.',
  'advanced_setting.setting.allow_no_atp.title': 'Allow disabling ATP',
  'advanced_setting.setting.allow_no_cpr.description':
    'An employee is normally required to have a CPR number. This function is for employees without a Danish CPR number, e.g. foreigners working abroad.',
  'advanced_setting.setting.allow_no_cpr.title': 'Allow no CPR number on employees',
  'advanced_setting.setting.allow_non_salaried_paid_vacation.description':
    'Waged and commissioned normally do not received paid holidays. This function allows paid holiday for waged and commissioned employees, including the option to manually pay out their holiday value.  Be aware that with this setting alone, the value of the paid holidays will not be adjusted automatically according to earned/spent holidays; e.g. at termination, the system will pay out the value in full (save that which have already been paid out).',
  'advanced_setting.setting.allow_non_salaried_paid_vacation.title': 'Allow paid holiday for waged and commissioned',
  'advanced_setting.setting.allow_rate_per_car_allowance.description':
    'Transport allowance registrations normally uses the standard rates set by SKAT.  One can also override this rate on employee level.  That might be fine-tuned enough, so with this setting, one can register a rate per registration.  If the field is level blank, it will default to the normal rates.',
  'advanced_setting.setting.allow_rate_per_car_allowance.title': 'Allow rate per transport allowance registration',
  'advanced_setting.setting.allow_salary_reduction_b_income.description':
    'Normally gross pay reductions are done on A income. If you have salaried with B income, it can be useful to perform B income gross pay reductions now and then.',
  'advanced_setting.setting.allow_salary_reduction_b_income.title': 'Allow B income for gross pay reduction',
  'advanced_setting.setting.allow_salary_type_supplement.description':
    'This function allows time based supplements on wage types.',
  'advanced_setting.setting.allow_salary_type_supplement.title': 'Time based supplements on wage types',
  'advanced_setting.setting.allow_sh_fritvalg_direct_pay.description':
    'Normally you will have to manually pay out the earned SH days and/or Fritvalg account. With this function, you can enable recurring pay out for each period, where the accounts will also be emptied.',
  'advanced_setting.setting.allow_sh_fritvalg_direct_pay.title': 'Allow recurring payment of SH days and Fritvalg',
  'advanced_setting.setting.allow_tp_units_and_rate.description':
    'For pay registrations, like travel allowances and bonuses, you normally enter a total amount. This function allows to enter a rate and quantity instead, from which the total amount is calculated. The rate and quantity will appear on the payslip.',
  'advanced_setting.setting.allow_tp_units_and_rate.title': 'Allow rate and quantity on pay registrations',
  'advanced_setting.setting.allow_vacation_fund.description':
    'Normally holiday is reported to Feriekonto. This function allows using a different holiday fund than Feriekonto.',
  'advanced_setting.setting.allow_vacation_fund.title': 'Allow other holiday fund',
  'advanced_setting.setting.allow_vacation_rate.description':
    'Normally one earns 12.5% in holiday allowance. This function allows between 12%, 12.5% and a higher value.',
  'advanced_setting.setting.allow_vacation_rate.title': 'Allow other than 12.5% holiday allowance',
  'advanced_setting.setting.ask_for_otp_draft_state.description':
    'Pay registrations, like travel allowances and bonuses, are normally approved upon creation. With this function you can create them in a draft state instead.',
  'advanced_setting.setting.ask_for_otp_draft_state.title': 'Allow draft state on pay registrations',
  'advanced_setting.setting.contract_templates_enabled.description':
    'This function allows saving templates for your employees, so the fields are prefilled at next creation.',
  'advanced_setting.setting.contract_templates_enabled.title': 'Employee templates',
  'advanced_setting.setting.disable_app_flex_overtime_registration.description':
    'Employees normally register flex and overtime in the app, if you have it enabled. However, that might conflict with a flex and/or overtime registration integration. This function removes flex and overtime registrations in the app.',
  'advanced_setting.setting.disable_app_flex_overtime_registration.title':
    'Remove registration of flex and overtime in the app',
  'advanced_setting.setting.disable_app_leave_registration.description':
    'Employees normally register absence in the app, but this can conflict with a absence registration integration. This function removes absence registration in the app.',
  'advanced_setting.setting.disable_app_leave_registration.title': 'Remove registration of absence in the app',
  'advanced_setting.setting.disable_app_project_registration.description':
    'When you use project management, your employees can register project time themselves in the app. This function removes project time registration in the app.',
  'advanced_setting.setting.disable_app_project_registration.title': 'Remove registration of project time in the app',
  'advanced_setting.setting.disable_app_salary_registration.description':
    'Employees normally register pay supplements in the app, if they have it on their contracts.  This function removes pay supplement registrations in the app.',
  'advanced_setting.setting.disable_app_salary_registration.title': 'Remove registration of pay supplements in the app',
  'advanced_setting.setting.disable_app_time_registration.description':
    'Waged employees registers normally hours in the app, but this can be in conflict with a time registration integration. This function removes time registration in the app.',
  'advanced_setting.setting.disable_app_time_registration.title': 'Remove registration of hours in the app',
  'advanced_setting.setting.disable_app_upcoming_pay_slip.description':
    "Normally an employee can see their future payslips in the app (unless it's a termination payslip). With this function, they can never see their future payslip.",
  'advanced_setting.setting.disable_app_upcoming_pay_slip.title': 'Remove upcoming payslips in the app',
  'advanced_setting.setting.disable_car_allowance_registration_method_detailed_on_invite.description':
    "To register transport allowance in the app, the employee's transport registration need to be detailed. When the employee is invited to the app, they will automatically be set to detailed. This function disables that feature. You can always later make them detailed.",
  'advanced_setting.setting.disable_car_allowance_registration_method_detailed_on_invite.title':
    'Do not set transport registration to detailed when invited to app',
  'advanced_setting.setting.disable_salary_registration_method_detailed_on_invite.description':
    "To register pay supplements in the app, the employee's pay supplement registration need to be detailed. When the employee is invited to the app, they will automatically be set to detailed. This function disables that feature. You can always later make them detailed.",
  'advanced_setting.setting.disable_salary_registration_method_detailed_on_invite.title':
    'Do not set pay supplement registration to detailed when invited to app',
  'advanced_setting.setting.disable_time_registration_method_detailed_on_invite.description':
    "To register time registrations in the app, the employee's time registration need to be detailed. When the employee is invited to the app, they will automatically be set to detailed. This function disables that feature. You can always later make them detailed.",
  'advanced_setting.setting.disable_time_registration_method_detailed_on_invite.title':
    'Do not set time registration to detailed when invited to app',
  'advanced_setting.setting.enable_app_fee_registration.description':
    'With this function, freelancers invited to the app can create compensations that will afterwards have to be approved.',
  'advanced_setting.setting.enable_app_fee_registration.title': 'Allow compensation creation in the app',
  'advanced_setting.setting.enable_app_hourly_leave_registration.description':
    'Normally, waged employees cannot register absence in the app. This function allows that.',
  'advanced_setting.setting.enable_app_hourly_leave_registration.title':
    'Allow absence registration for waged employees in the app',
  'advanced_setting.setting.enable_e_income_texts.description':
    'This function allows sending text to E-income in field 68',
  'advanced_setting.setting.enable_e_income_texts.title': 'Text to E-income',
  'advanced_setting.setting.enable_employee_dimensions.description':
    'This function allows the creation of dimensions directly on the employee, either based on dimensions from your accounting integration or user created ones, which will thus not be booked in the accounting system, but will create a separate line.',
  'advanced_setting.setting.enable_employee_dimensions.title': 'Allow employee dimensions',
  'advanced_setting.setting.enable_flex_and_overtime.description':
    'This function is for when you wish to register flex or overtime.',
  'advanced_setting.setting.enable_flex_and_overtime.title': 'Allow flex and overtime',
  'advanced_setting.setting.enable_multiple_work_weeks.description':
    'Normally an employee have one work week, which repeats over and over; however some times, it may be necessary to have a cycle of 2 or more work weeks.',
  'advanced_setting.setting.enable_multiple_work_weeks.title': 'Work cycle with multiple weeks',
  'advanced_setting.setting.enable_projects.description':
    'Projects allows registrering project hours for salaried, waged, commissioned and freelancers. Projects is part of the HR package.',
  'advanced_setting.setting.enable_projects.title': 'Enable projects',
  'advanced_setting.setting.enable_proportional_leave_type_vesting.description':
    'Generally speaking we assign the full contractual number of days from the beginning. With this function, only the days according to when in the earning period the employee started are assigned.',
  'advanced_setting.setting.enable_proportional_leave_type_vesting.title':
    'Proportional assignment of extra holidays and personal days',
  'advanced_setting.setting.enable_ready_employee_for_payroll.description':
    'Using this function, you can mark an employee ready for payroll. Should an employee not be marked ready, a notification will appear on the payroll. Any changes to an employee - including a completed payroll - will automatically remove the marking again.',
  'advanced_setting.setting.enable_ready_employee_for_payroll.title': 'Function to mark employees ready for payroll',
  'advanced_setting.setting.enable_work_hours_feature.description':
    'From 1 July 2024, it is legally required to report working hours for salaried employees.  In Salary you and your employees can register working hours.',
  'advanced_setting.setting.enable_work_hours_feature.title': 'Enable registration of working hours',
  'advanced_setting.setting.great_prayer_day_enable_default.description':
    'When this setting is enabled, the Great Prayer Day Supplement will be selected by default during employee creation.  You can always change whether the employee should receive the supplement.',
  'advanced_setting.setting.great_prayer_day_enable_default.title': 'Select Great Prayer Day Supplement per default',
  'advanced_setting.setting.great_prayer_day_for_all.description':
    'By default, the Great Prayer Day supplement is for employees with fixed salary. By enabling this setting, it is possible give this supplement to waged employees.',
  'advanced_setting.setting.great_prayer_day_for_all.title': 'Allow Great Prayer Day supplement for waged employees',
  'advanced_setting.setting.great_prayer_day_periodically.description':
    'By default, the Great Prayer Day supplement will be paid out with the vacation supplement (May and August), with this setting set it is paid out each payroll period',
  'advanced_setting.setting.great_prayer_day_periodically.title': 'Pay out Great Prayer Day supplement periodically',
  'advanced_setting.setting.include_benefits_in_pension_basis.description':
    'Values for employee benefits — like paid phone — is normally not included in the pension basis. This function will include them in the pension basis.',
  'advanced_setting.setting.include_benefits_in_pension_basis.title':
    'Include value of employee benefits in pension basis',
  'advanced_setting.setting.include_gpd_in_pension.description':
    'Normally, the great prayer day supplement is not included in the pension basis calculations, but with this option enabled, the part of the supplement that is not dependent on the employer based pension calculations, can be included.',
  'advanced_setting.setting.include_gpd_in_pension.title':
    'Include the great prayer day supplement in the pension basis calculations',
  'advanced_setting.setting.include_vacation_supplements_in_pension_basis.description':
    'With this enabled, you can include holiday supplements in the pension basis.',
  'advanced_setting.setting.include_vacation_supplements_in_pension_basis.title':
    'Allow holiday supplements to be included in pension basis',
  'advanced_setting.setting.more_bonus_options.description':
    'When you create a bonus for an employee, you can normally only give normal and labour market compensation exempt income. This function add more bonus types, such as anniversary bonus or pay in fields 36, 38 or 39.',
  'advanced_setting.setting.more_bonus_options.title': 'More bonus types',
  'advanced_setting.setting.more_income_types.description':
    'Most companies only use A and B income. This function is for when you need tax free income or researcher agreement.',
  'advanced_setting.setting.more_income_types.title': 'More income types',
  'advanced_setting.setting.register_time_registration_start_end.description':
    "Hours are normally registered in number of hours, but that might not fit with one's general process. With this function, you can register hours in from/to fields, as well as entering breaks in minutes.",
  'advanced_setting.setting.register_time_registration_start_end.title':
    'Register hours with from/to rather than amount',
  'advanced_setting.setting.sh_fritvalg_increase_types.description':
    'SH days/Fritvalg is normally earned per payroll. This function allows giving SH days/Fritvalg as a bonus, even when the employee does not have either in their contract.',
  'advanced_setting.setting.sh_fritvalg_increase_types.title': 'Increase of SH days/Fritvalg as a bonus type',
  'advanced_setting.setting.treat_hourly_vacation_as_salaried.description':
    "Normally balance between earned and spent holidays for waged/commissioned employees is ignored for the value of the earned holidays.  That can be a problem for those with paid holidays.  With this function, Salary will treat waged/commissioned employees' holiday as if they were salaried.  Salary will automatically reduce the value of their spent holiday in relation to their earned.",
  'advanced_setting.setting.treat_hourly_vacation_as_salaried.title':
    "Treat waged/commissioned employees' holiday as salaried",
  'advanced_setting.setting.use_adjusted_work_week_leave_rate.description':
    "Normally 1 holiday counts as 1 holiday. However, if one has a work week of 4 days, 1 holiday is worth 1.25 holidays. This function will automatically adjust the value of holidays based on the employee's work week, including allowing registration during weekends, if the work week allows that.",
  'advanced_setting.setting.use_adjusted_work_week_leave_rate.title': 'Adjust holiday values after work week',
  'advanced_setting.setting.use_end_for_time_registration_supplement_overlap.description':
    'If you use from/to on time registrations, it is possible to enter a break, and if you also use time based supplements on waged types, it is important when during the registration the break happens. Normally Salary assumes the break happens at the end of the time registrations (e.g. a time registration from 12 to 18 with a break of 30 minutes, is interpreted as 12:00 to 17:30). With this function is this reversed (so in the previous example, the result would be 12:30 to 18:00).',
  'advanced_setting.setting.use_end_for_time_registration_supplement_overlap.title':
    'Place breaks in time registration at the beginning',
  'advanced_setting.setting.use_standard_rates_for_unknown_atp_hours.description':
    'The ATP contribution rate is based on work hours during the payroll period. This function can be used in cases where the actual amount of work hours are unknown.',
  'advanced_setting.setting.use_standard_rates_for_unknown_atp_hours.title':
    'Use standard ATP rate for unknown work hours',
  'advanced_setting.setting.work_hour_lunch_limit.description':
    'If an employee has a lunch scheme, they will always have to pay for lunch. This function allows adjusting a minimum number of work hours per time registration required before one needs to pay for lunch. Since it is per time registration, it can trigger multiple lunch payments per day.',
  'advanced_setting.setting.work_hour_lunch_limit.title': 'Minimum work hours per time registration to trigger lunch',
  'advanced_setting.setting.payslip_import.title': 'Payslip import (Beta)',
  'advanced_setting.setting.payslip_import.description':
    'If you activate this setting you will be able to import your payslips on your own from the employees tab, on the button "import employees".',

  'api_client.scope.read_all': 'Full read access',
  'api_client.scope.read_base_data': 'Read access to base data',
  'api_client.scope.read_employments': 'Read access to employment data',
  'api_client.scope.read_leave': 'Read access to absence data',
  'api_client.scope.read_personal_data': 'Read access to personal data',
  'api_client.scope.write_all': 'Full write access',

  'api_key.edit.form.description': 'Description',
  'api_key.edit.form.validation.description_required': 'Description is required',
  'api_key.edit.title.create': 'Create API key',
  'api_key.edit.title.edit': 'Edit API key',

  'api_keys.description': 'Read API documentation {link}.',
  'api_keys.description_link': 'here',
  'api_keys.header.add_api_key': 'Add API key',
  'api_keys.header.add_api_key_lock': 'Upgrade to Basic to create API keys',
  'api_keys.table.actions.delete': 'Delete API key',
  'api_keys.table.header.created_at': 'Created',
  'api_keys.table.header.description': 'Description',
  'api_keys.table.header.key': 'API key',
  'api_keys.table.header.scopes': 'Permissions',
  'api_keys.title': 'API keys',

  'approve_tab.car_allowances.actions.approve': 'Approve',
  'approve_tab.car_allowances.actions.approve_all': 'Approve all transport allowance',
  'approve_tab.car_allowances.header.date': 'Date',
  'approve_tab.car_allowances.header.employee': 'Employee',
  'approve_tab.car_allowances.header.kilometers': 'Kilometres',
  'approve_tab.car_allowances.header.reason': 'Reason',
  'approve_tab.car_allowances.header.trip': 'Journey',
  'approve_tab.car_allowances.title': 'Transport allowance',
  'approve_tab.employee_name.freelancer': 'freelancer',
  'approve_tab.employee_name.leader': 'Leader',
  'approve_tab.empty': 'If there is nothing above, then everything is approved.',
  'approve_tab.leave_registrations.actions.approve': 'Approve',
  'approve_tab.leave_registrations.actions.approve_all': 'Approve all absence registrations',
  'approve_tab.leave_registrations.header.date': 'Date',
  'approve_tab.leave_registrations.header.days': 'Value',
  'approve_tab.leave_registrations.header.employee': 'Employee',
  'approve_tab.leave_registrations.header.leave_type': 'Absence type',
  'approve_tab.leave_registrations.title': 'Absence registrations',
  'approve_tab.notification_toggle': 'Notifications',
  'approve_tab.one_time_pays.actions.approve': 'Approve',
  'approve_tab.one_time_pays.actions.approve_all': 'Approve all one time registrations',
  'approve_tab.one_time_pays.header.amount': 'Amount',
  'approve_tab.one_time_pays.header.date': 'Date',
  'approve_tab.one_time_pays.header.employee': 'Employee',
  'approve_tab.one_time_pays.header.title': 'Text',
  'approve_tab.one_time_pays.header.type': 'Type',
  'approve_tab.one_time_pays.table.amount_unit_rate': '{unit} of {rate}',
  'approve_tab.one_time_pays.title': 'One time registrations (supplements, deductions, fees, etc.)',
  'approve_tab.reimbursements_vouchers.actions.approve': 'Approve',
  'approve_tab.reimbursements_vouchers.actions.approve_all': 'Approve all outlays',
  'approve_tab.reimbursements_vouchers.header.amount': 'Amount',
  'approve_tab.reimbursements_vouchers.header.date': 'Receipt date',
  'approve_tab.reimbursements_vouchers.header.employee': 'Employee',
  'approve_tab.reimbursements_vouchers.header.expense_category': 'Expense category',
  'approve_tab.reimbursements_vouchers.table.missing_category': 'Missing category',
  'approve_tab.reimbursements_vouchers.table.received': 'Received',
  'approve_tab.reimbursements_vouchers.title': 'Outlays',
  'approve_tab.salary_registrations.actions.approve': 'Approve',
  'approve_tab.salary_registrations.actions.approve_all': 'Approve all variable supplements',
  'approve_tab.salary_registrations.header.date': 'Date',
  'approve_tab.salary_registrations.header.employee': 'Employee',
  'approve_tab.salary_registrations.header.quantity': 'Quantity',
  'approve_tab.salary_registrations.header.rate': 'Rate',
  'approve_tab.salary_registrations.table.rate_display': '{name} of {rate}',
  'approve_tab.salary_registrations.title': 'Variable supplements',
  'approve_tab.swipes.actions.approve': 'Approve',
  'approve_tab.swipes.actions.approve_all': 'Approve all Swipes',
  'approve_tab.swipes.header.amount': 'Amount',
  'approve_tab.swipes.header.date': 'Date',
  'approve_tab.swipes.header.employee': 'Employee',
  'approve_tab.swipes.table.missing_category': 'Missing category',
  'approve_tab.swipes.table.received': 'Received',
  'approve_tab.swipes.title': 'Swipes',
  'approve_tab.time_registrations.actions.approve': 'Approve',
  'approve_tab.time_registrations.actions.approve_all': 'Approve all time registrations',
  'approve_tab.time_registrations.header.date': 'Date',
  'approve_tab.time_registrations.header.employee': 'Employee',
  'approve_tab.time_registrations.header.hours': 'Hours',
  'approve_tab.time_registrations.header.rate': 'Rate',
  'approve_tab.time_registrations.table.rate_display': '{name} of {rate}',
  'approve_tab.time_registrations.title': 'Time reigstrations',
  'approve_tab.title': 'Awaiting approval',

  'asset_category.edit.form.submit.create': 'Add asset category',
  'asset_category.edit.form.submit.edit': 'Edit asset category',
  'asset_category.edit.form.title': 'Title',
  'asset_category.edit.form.validation.title_required': 'Title is required',
  'asset_category.edit.title.create': 'Create asset category',
  'asset_category.edit.title.edit': 'Edit asset category',
  'asset_category.header.add_asset_category': 'Add asset category',
  'asset_category.header.assets': 'Assets',
  'asset_category.header.title': 'Asset categories',
  'asset_category.table.action.delete': 'Delete asset category',
  'asset_category.table.action.edit': 'Edit asset category',
  'asset_category.table.title': 'Title',

  'asset.display.received.future': 'To be distributed: {date}',
  'asset.display.received.past': 'Distributed: {date}',
  'asset.display.returned.future': 'To be returned: {date}',
  'asset.display.returned.past': 'Returned: {date}',
  'asset.edit.form.category_id': 'Category',
  'asset.edit.form.category_id.required': 'Category must be selected',
  'asset.edit.form.description': 'Description',
  'asset.edit.form.note': 'Note',
  'asset.edit.form.received_date': 'Distribute date',
  'asset.edit.form.received_date.not_future': 'Distribute date cannot be in the future',
  'asset.edit.form.return_date': 'Return date',
  'asset.edit.form.return_date.before_received': 'Return date cannot be before the receive date',
  'asset.edit.form.submit.create': 'Create asset',
  'asset.edit.form.submit.edit': 'Edit asset',
  'asset.edit.form.title': 'Asset',
  'asset.edit.form.title.required': 'Asset is required',
  'asset.edit.title.create': 'Create asset',
  'asset.edit.title.edit': 'Edit asset',
  'asset.tab.confirm.delete': 'Delete. Are you sure?',
  'asset.tab.confirm.hide': 'Remove. Are you sure?',
  'asset.tab.confirm.receive': 'Re-distribute. Are you sure?',
  'asset.tab.confirm.return': 'Return. Are you sure?',
  'asset.tab.confirm.returning': 'Return asset',
  'asset.tab.header.add_asset': 'Register asset',
  'asset.tab.outstanding.table.actions.delete': 'Delete asset',
  'asset.tab.outstanding.table.actions.edit': 'Edit asset',
  'asset.tab.outstanding.table.actions.return': 'Return asset',
  'asset.tab.outstanding.table.header.category': 'Category',
  'asset.tab.outstanding.table.header.description': 'Description',
  'asset.tab.outstanding.table.header.received': 'Distributed',
  'asset.tab.outstanding.table.header.title': 'Asset',
  'asset.tab.outstanding.table.not_received': 'Not distributed',
  'asset.tab.returned.table.actions.hide': 'Remove asset',
  'asset.tab.returned.table.actions.receive': 'Return asset',
  'asset.tab.returned.table.header.category': 'Category',
  'asset.tab.returned.table.header.description': 'Description',
  'asset.tab.returned.table.header.returned': 'Returned',
  'asset.tab.returned.table.header.title': 'Asset',
  'asset.tab.title.outstanding': 'Assets',
  'asset.tab.title.returned': 'Returned assets',

  'assets.header.asset_categories': 'Asset categories',
  'assets.standard.title': 'Assets for employees',
  'assets.table.header.employee': 'Employees',
  'assets.table.header.received': 'Distributed',
  'assets.table.header.returned': 'Returned',
  'assets.terminated.title': 'Assets for terminated employees',

  'asynchronous_task.schedule_type.leave_registration_export': 'absence export',
  'asynchronous_task.status.executing': 'Executing',
  'asynchronous_task.status.failed': 'Failed',
  'asynchronous_task.status.pending': 'Awaiting',
  'asynchronous_task.status.success': 'Success',

  'balance_adjustment_tab.leave.edit.form.add_valuation': 'Include value of holidays',
  'balance_adjustment_tab.leave.edit.form.disposition_date': 'Adjustment date',
  'balance_adjustment_tab.leave.edit.form.disposition_date.required': 'Adjustment date is required',
  'balance_adjustment_tab.leave.edit.form.earned': 'Earned',
  'balance_adjustment_tab.leave.edit.form.leave_type_name': 'Absence type',
  'balance_adjustment_tab.leave.edit.form.leave_type_name.required': 'Absence type is required',
  'balance_adjustment_tab.leave.edit.form.liability': 'Value',
  'balance_adjustment_tab.leave.edit.form.liability.suffix': 'kr.',
  'balance_adjustment_tab.leave.edit.form.operation': 'Operation',
  'balance_adjustment_tab.leave.edit.form.operation.increase': 'Increase',
  'balance_adjustment_tab.leave.edit.form.operation.override': 'Override',
  'balance_adjustment_tab.leave.edit.form.operation.reduce': 'Decrease',
  'balance_adjustment_tab.leave.edit.form.operation.required': 'Operation is required',
  'balance_adjustment_tab.leave.edit.form.used': 'Spent',
  'balance_adjustment_tab.leave.edit.form.valuation.add': 'Valuation of holidays',
  'balance_adjustment_tab.leave.edit.form.valuation.format':
    '({rate} × {multiplier} %) / {divider} days × {earned} {suffix}',
  'balance_adjustment_tab.leave.edit.form.valuation.required': 'Valuation of holidays is required',
  'balance_adjustment_tab.leave.edit.form.valuation.suffix': 'kr.',
  'balance_adjustment_tab.leave.edit.form.value.balance': 'Balance',
  'balance_adjustment_tab.leave.edit.form.value.need_one': 'One must be adjusted',
  'balance_adjustment_tab.leave.edit.intro':
    'An adjustment of the absence balance can be changed by increasing (addition), decreasing (subtraction) or overriding the values.',
  'balance_adjustment_tab.leave.edit.title.create': 'Create absence adjustment',
  'balance_adjustment_tab.leave.edit.title.edit': 'Edit absence adjustment',
  'balance_adjustment_tab.leave.header.balances': 'Balances',
  'balance_adjustment_tab.leave.header.new_adjustment': 'Create absence balance adjustment',
  'balance_adjustment_tab.leave.header.title': 'Absence balance adjustment',
  'balance_adjustment_tab.supplements.edit.form.disposition_date': 'Adjustment date',
  'balance_adjustment_tab.supplements.edit.form.disposition_date.required': 'Adjustment date is required',
  'balance_adjustment_tab.supplements.edit.form.earned': 'Earned',
  'balance_adjustment_tab.supplements.edit.form.operation': 'Operation',
  'balance_adjustment_tab.supplements.edit.form.operation.increase': 'Increase',
  'balance_adjustment_tab.supplements.edit.form.operation.override': 'Override',
  'balance_adjustment_tab.supplements.edit.form.operation.reduce': 'Decrease',
  'balance_adjustment_tab.supplements.edit.form.operation.required': 'Operation is required',
  'balance_adjustment_tab.supplements.edit.form.supplement_type_name': 'Supplement',
  'balance_adjustment_tab.supplements.edit.form.supplement_type_name.required': 'Supplement is required',
  'balance_adjustment_tab.supplements.edit.form.value.need_one': 'One must be adjusted',
  'balance_adjustment_tab.supplements.edit.intro':
    'An adjustment of the supplement balance can be changed by increasing (addition), decreasing (subtraction) or overriding the values.',
  'balance_adjustment_tab.supplements.edit.title.create': 'Create supplement adjustment',
  'balance_adjustment_tab.supplements.edit.title.edit': 'Edit supplement adjustment',
  'balance_adjustment_tab.supplements.header.add_adjustment': 'Create supplement balance adjustment',
  'balance_adjustment_tab.supplements.header.balances': 'Balances',
  'balance_adjustment_tab.supplements.header.title': 'Supplement balance adjustments',
  'balance_adjustment_tab.table.actions.delete': 'Delete adjustment',
  'balance_adjustment_tab.table.actions.edit': 'Edit adjustment',
  'balance_adjustment_tab.table.header.adjustment': 'Adjustment',
  'balance_adjustment_tab.table.header.date': 'Date',
  'balance_adjustment_tab.table.header.type_name': 'Type',
  'balance_adjustment_tab.table.operation.increase': 'Increase',
  'balance_adjustment_tab.table.operation.override': 'Override',
  'balance_adjustment_tab.table.operation.reduce': 'Decrease',
  'balance_adjustment_tab.table.type.complementary': 'Complementary',
  'balance_adjustment_tab.table.type.earned': 'Earned',
  'balance_adjustment_tab.table.type.liability': 'Liability',
  'balance_adjustment_tab.table.type.paid_out': 'Paid out',
  'balance_adjustment_tab.table.type.used': 'Earned',
  'balance_adjustment_tab.table.type.valuation': 'Valuation',
  'balance_adjustment_tab.table.type.written_off': 'Written off',

  'basic_integrations.advisor_service.buttons.offer': 'Find revisor',
  'basic_integrations.advisor_service.buttons.questions': 'Spørgsmål? Skriv til os her',
  'basic_integrations.advisor_service.intro':
    'Salary samarbejder med Ageras, så du nemt kan finde den rette bogholder eller revisor til at hjælpe med din lønadministration. Sammenlign tilbud og vælg selv den ekspert, du gerne vil arbejde sammen med.',
  'basic_integrations.advisor_service.points.0': 'Få tilbud fra revisorer og bogholdere indenfor 24 timer.',
  'basic_integrations.advisor_service.points.1':
    'Let overblik over dine tilbud. Sammenlign priser, kompetencer, anmeldelser m.m.',
  'basic_integrations.advisor_service.points.2':
    'Frihed til at vælge selv, hvilken ekspert, du ønsker at samarbejde med.',
  'basic_integrations.advisor_service.points.3': '100% risikofrit, uforpligtende og gratis.',
  'basic_integrations.advisor_service.points.4': 'Nemt, hurtigt og sikkert.',
  'basic_integrations.advisor_service.points.5': 'Passioneret team, der altid står klar til at hjælpe dig.',
  'basic_integrations.advisor_service.points.6': 'Ageras er din garanti for at finde den rette ekspert.',
  'basic_integrations.advisor_service.quote.line_1':
    '"[...] Jeg opslog mine ønsker søndag aften, blev kontaktet telefonisk dagen efter af Ageras med henblik på at præcisere mine behov. Senere mandag formiddag blev jeg kontaktet af en udbyder med nøjagtig det speciale jeg skulle have hjælp til.',
  'basic_integrations.advisor_service.quote.line_2': 'Venlig og fin service hele vejen i processen hos Ageras"',
  'basic_integrations.advisor_service.title': 'Find din revisor eller bogholder med Ageras',
  'basic_integrations.codan.advise_link': '15 råd der forebygger risikoen for indbrud og skader',
  'basic_integrations.codan.alert.success': 'Ændringer til {name} er gemt',
  'basic_integrations.codan.has_integration.line_1': 'Integration med Codan er kørende.',
  'basic_integrations.codan.has_integration.line_2':
    'Vi sender automatisk jeres til- og afmeldinger af medarbejdere til Codan, som samtidig vil sikre en løbende kontakt til jer for at holde jeres police opdateret.',
  'basic_integrations.codan.no_integration.line_1':
    'Salary samarbejder med Codan om den lovpligtige arbejdsskadeforsikring.',
  'basic_integrations.codan.no_integration.line_2':
    'Hvis I ønsker et uforpligtende tilbud fra Codan, eller hvis I allerede er kunde i Codan, så klik på den grønne knap: Bliv kontaktet af Codan',
  'basic_integrations.codan.no_integration.line_3':
    'Codan ringer jer herefter op for et uforpligtende tilbud. Og er I allerede kunde, så adviserer Salary automatisk Codan hver gang en medarbejder tilføjes eller fjernes. Denne automatik sker straks efter, vi har godkendt jeres kundeforhold i Codan. Herved har I altid det rette antal medarbejdere forsikret på den lovpligtige arbejdsskade.',
  'basic_integrations.codan.pending_integration.line_1':
    'Vi har endnu ikke fået besked fra Codan, om I er kunde. Så snart vi gør, vil til- og afmeldinger af medarbejdere på arbejdsskadeforsikringen automatisk blive opdateret på forsikringspolicen.',
  'basic_integrations.codan.privacy_policy_link': 'Læs Codans persondata- og privatlivspolitik',
  'basic_integrations.codan.start_integration': 'Bliv kontaktet af Codan',
  'basic_integrations.codan.stop_integration.button': 'Stop integration',
  'basic_integrations.codan.stop_integration.title': 'Stop integration',
  'basic_integrations.grandhood.not_completed.buttons.book': 'Spørgsmål? Book møde',
  'basic_integrations.grandhood.not_completed.buttons.sign': 'Underskriv aftale',
  'basic_integrations.grandhood.not_completed.intro':
    'Vi har allerede oprettet en firmaaftale til dig - du mangler bare at underskrive med NemID, og så får du adgang til alle fordelene ved en moderne firmapension:',
  'basic_integrations.grandhood.not_completed.points.0':
    'Fuldt integreret - Slip for at indtaste information i både Salary og dit pensionprogram.',
  'basic_integrations.grandhood.not_completed.points.1':
    'Vi ved, du er digital. Det er vi også. Gratis pensionsrådgivning i vores prisvindende app.',
  'basic_integrations.grandhood.not_completed.points.2':
    'Vi sparer penge ved digital pensionsrådgivning, du sparer penge på opsparing og forsikringer.',
  'basic_integrations.grandhood.not_completed.points.3':
    '12 sekunder fra du ringer, til vi tager telefonen. Hurtigere end den gennemsnitlige teenager.',
  'basic_integrations.grandhood.not_completed.points.4':
    'Grandhood er Danmarks bedst ratede pensionsudbyder på Trustpilot.',
  'basic_integrations.grandhood.not_completed.quote':
    '"Jeg tror, det i høj grad handler om, at vores mål er at skabe en arbejdsplads, hvor folk glæder sig til at gå på arbejde. Sådan har vi det selv, og sådan synes vi alle skulle have det."',
  'basic_integrations.grandhood.not_completed.title': 'Du er kun én underskrift fra at have en firmapension',
  'basic_integrations.grandhood.not_connected.buttons.connect': 'Forbind til Grandhood',
  'basic_integrations.grandhood.not_connected.intro':
    'Vidste du, at {strong}? Det betyder, at du slipper du for at indtaste de samme medarbejderoplysninger i to forskellige systemer. Alt du skal gøre er at trykke "Forbind til Grandhood" og følge guiden. Det tager ikke mere end et par minutter.',
  'basic_integrations.grandhood.not_connected.intro.strong': 'Salary er fuldt integreret med {link}',
  'basic_integrations.grandhood.not_connected.title': 'Undgå dobbeltarbejde',
  'basic_integrations.grandhood.not_registered.buttons.read_more': 'Læs mere',
  'basic_integrations.grandhood.not_registered.buttons.start': 'Opret firmapension',
  'basic_integrations.grandhood.not_registered.intro':
    'Salary er {strong}. Bruger du Grandhood som pensionsudbyder, slipper du for at indtaste samme information i både Salary og dit pensionsprogram, da Grandhood automatisk synkroniserer dine data.',
  'basic_integrations.grandhood.not_registered.intro.strong': 'fuldt integreret med {link}',
  'basic_integrations.grandhood.not_registered.points.0':
    'Fuldt integreret - Slip for at indtaste information i både Salary og dit pensionprogram.',
  'basic_integrations.grandhood.not_registered.points.1':
    'Vi ved, du er digital. Det er vi også. Gratis pensionsrådgivning i vores prisvindende app.',
  'basic_integrations.grandhood.not_registered.points.2':
    'Vi sparer penge ved digital pensionsrådgivning, du sparer penge på opsparing og forsikringer.',
  'basic_integrations.grandhood.not_registered.points.3':
    '12 sekunder fra du ringer, til vi tager telefonen. Hurtigere end den gennemsnitlige teenager.',
  'basic_integrations.grandhood.not_registered.points.4':
    'Grandhood er Danmarks bedst ratede pensionsudbyder på Trustpilot.',
  'basic_integrations.grandhood.not_registered.quote':
    '"Jeg synes, det er fantastisk, at vi som virksomhed kan gå sammen og tilbyde medarbejderne en lang række forsikringer, som ville være dyre og besværlige at få adgang til som enkeltperson"',
  'basic_integrations.grandhood.not_registered.title': 'Tænk pension, bare integreret med dit lønsystem.',
  'basic_integrations.grandhood.registered.buttons.book': 'Spørgsmål? Book møde',
  'basic_integrations.grandhood.registered.buttons.go_to': 'Til Grandhood',
  'basic_integrations.grandhood.registered.intro':
    'Fordi du har integreret Salary med Grandhood, slipper du for at indtaste de samme medarbejderoplysninger i to forskellige systemer. Og så har du selvfølgelig også adgang til alle fordelene ved en moderne firmapension:',
  'basic_integrations.grandhood.registered.points.0':
    'Fuldt integreret - Slip for at indtaste information i både Salary og dit pensionprogram.',
  'basic_integrations.grandhood.registered.points.1':
    'Vi ved, du er digital. Det er vi også. Gratis pensionsrådgivning i vores prisvindende app.',
  'basic_integrations.grandhood.registered.points.2':
    'Vi sparer penge ved digital pensionsrådgivning, du sparer penge på opsparing og forsikringer.',
  'basic_integrations.grandhood.registered.points.3':
    '12 sekunder fra du ringer, til vi tager telefonen. Hurtigere end den gennemsnitlige teenager.',
  'basic_integrations.grandhood.registered.points.4':
    'Grandhood er Danmarks bedst ratede pensionsudbyder på Trustpilot.',
  'basic_integrations.grandhood.registered.title': 'Du er allerede integreret med Grandhood',
  'basic_integrations.min_refusion.buttons.install': 'Connect to MinRefusion',
  'basic_integrations.min_refusion.buttons.read_more': 'Read more',
  'basic_integrations.min_refusion.buttons.api_key': 'API keys',
  'basic_integrations.min_refusion.text.line_1':
    'MinRefusion ensures your payments during illness and maternity/paternity leave. MinRefusion is the future of refund portals, which reports absence due to illness and maternity/paternity leave 100% automatically and flawlessly on behalf of your company. With the integration to Salary, MinRefusion automatically receives absence data when you create absence for an employee during absence registration.',
  'basic_integrations.min_refusion.text.line_2':
    'When the absence meets the right conditions, MinRefusion applies for a refund from the public sector. You save time, avoid hassle and get the payment directly into your account.',
  'basic_integrations.min_refusion.text.line_3':
    'MinRefusion is an approved supplier to NemRefusion, and our expertise therefore ensures flawless reporting and payments to your company.',
  'basic_integrations.min_refusion.text.guide_title': 'How to connect to MinRefusion',
  'basic_integrations.min_refusion.text.guide_1':
    '1. Click the "API Keys" button below, and in the new window that opens, add a new API key. The “Full read access” button must be enabled when you create the key.',
  'basic_integrations.min_refusion.text.guide_2': '2. Copy your new API key.',
  'basic_integrations.min_refusion.text.guide_3': '3. Click "Connect to MinRefusion" and follow their process.',
  'basic_integrations.min_refusion.title': 'Connect MinRefusion to Salary and receive refunds automatically',
  'basic_integrations.personale_zonen.line_1':
    'Med Medarbejderfordele kan du forkæle dine medarbejdere med adgang til en verden af fordele, hvor de får store besparelser, hos nogle af landets førende leverandører af dagligvarer, benzin, personlig pleje, rejser og meget, meget mere.',
  'basic_integrations.personale_zonen.line_2':
    'Medarbejderfordele er en effektiv måde at belønne jeres ansatte for den gode indsats, og der er noget for enhver smag.',
  'basic_integrations.personale_zonen.sign_up': 'Opret virksomhed hos PersonaleZonen',
  'basic_integrations.rmindr.line_1':
    'Som selvstændig i Danmark kan det være svært at hente information om dine virksomhedsfrister. Det betyder, at der er en risiko for at du som virksomhedsejer kan misse vigtige frister for din virksomhed. Frister, som kan have alvorlige konsekvenser for dig.',
  'basic_integrations.rmindr.line_2': 'Det betyder, at du løbende er ajour med din virksomhedsøkonomi.',
  'basic_integrations.rmindr.line_3': 'Få overblik over følgende i appen:',
  'basic_integrations.rmindr.line_3.points.0': 'Skattekonto',
  'basic_integrations.rmindr.line_3.points.1': 'Årsrapport',
  'basic_integrations.rmindr.line_3.points.2': 'Selvangivelse',
  'basic_integrations.rmindr.line_3.points.3': 'Moms',
  'basic_integrations.rmindr.line_3.points.4': 'A-skat',
  'basic_integrations.rmindr.line_3.points.5': 'Lønsumsafgift',
  'basic_integrations.rmindr.line_4':
    'RMINDR sender dig notifikationer forud for fristens udløb, så du undgår bøder og gebyrer fra de offentlige myndigheder.',
  'basic_integrations.rmindr.line_5':
    'Som Salary.dk-bruger får du 20 % rabat på et pro abonnement hos RMINDR ved at anvende rabatkoden {code}.',
  'basic_integrations.rmindr.sign_up': 'Glem intet med Rmindr i dag!',
  'basic_integrations.rmindr.title': 'Undgå bøder, få overblik over dine frister.',

  'benefit.card.board_lodging.value': '{amount} tax value',
  'benefit.card.edit.title': 'Edit employee benefits',
  'benefit.card.employee_association.value': '{amount} net reduction per periode',
  'benefit.card.employee_increase.value': '{amount} outlay per periode',
  'benefit.card.generic.false': 'No',
  'benefit.card.generic.true': 'Yes',
  'benefit.card.items.board_lodging': 'Paid accommodation',
  'benefit.card.items.employee_association': 'Employee association',
  'benefit.card.items.employee_increase': 'Fixed outlay',
  'benefit.card.items.employee_stock': 'Employee stock',
  'benefit.card.items.forenede_gruppeliv': 'Forenede Gruppeliv',
  'benefit.card.items.free_transport': 'Paid transport between home and work',
  'benefit.card.items.health': 'Health insurance',
  'benefit.card.items.paid_car': 'Paid car',
  'benefit.card.items.paid_internet': 'Paid internet',
  'benefit.card.items.paid_lunch': 'Lunch scheme',
  'benefit.card.items.paid_telephone': 'Paid phone',
  'benefit.card.items.permanent_residence': 'Paid permanent residence',
  'benefit.card.paid_car.managed.acquired': '{prefix} (acquired {date})',
  'benefit.card.paid_car.managed.value': '{value} value',
  'benefit.card.paid_car.managed.value_own': '{value} value with {own} payment',
  'benefit.card.paid_car.simple': '{amount} taxed',
  'benefit.card.paid_health.value': '{amount} tax value',
  'benefit.card.paid_lunch.daily': '{amount} net reduction per day',
  'benefit.card.paid_lunch.regular': '{amount} net reduction per period',
  'benefit.card.permanent_residence.value': '{amount} reported',
  'benefit.card.table.header.reduction': 'Reduction per period',
  'benefit.card.table.header.reporting': 'Amount to be reported',
  'benefit.card.table.header.title': 'Description',
  'benefit.card.table.reduction.gross.pension.false': 'Does {not} affect the pension basis',
  'benefit.card.table.reduction.gross.pension.false.not': 'not',
  'benefit.card.table.reduction.gross.pension.true': 'Affects the pension basis',
  'benefit.card.table.reduction.gross.vacation.false': 'Does {not} affect the holiday entitlement basis',
  'benefit.card.table.reduction.gross.vacation.false.not': 'not',
  'benefit.card.table.reduction.gross.vacation.true': 'Affects the holiday entitlement basis',
  'benefit.card.table.reduction.number_format': '{amount} {suffix}',
  'benefit.card.table.reduction.number_format.suffix_gross': 'gross kr. per period',
  'benefit.card.table.reduction.number_format.suffix_net': 'net kr. per period',
  'benefit.card.table.reporting.number_format': '{amount} {suffix}',
  'benefit.card.table.reporting.number_format.suffix': 'kr.',
  'benefit.card.title': 'Employee benefits',
  'benefit.form.board_lodging.intro.line_1':
    'When you pay accommodation for your employees, they will have to pay taxes for it, unless it is in combination with a trip or a temporary place of employment.',
  'benefit.form.board_lodging.intro.line_2':
    "Salary's current solution covers the following areas: agriculture etc., household help, health clinic personnel, kitchen and service personnel including private nurses who receives paid meals at their place of employment.",
  'benefit.form.board_lodging.intro.line_3': 'The rates can be found {link}.',
  'benefit.form.board_lodging.intro.line_3.link': 'here',
  'benefit.form.board_lodging.intro.line_4':
    'You need to fill in the total amount in the field they will be taxed of this month.',
  'benefit.form.board_lodging.lodging_tax_value': 'Tax value',
  'benefit.form.board_lodging.lodging_tax_value.invalid': 'Tax value is invalid',
  'benefit.form.board_lodging.lodging_tax_value.required': 'Tax value is required',
  'benefit.form.board_lodging.lodging_tax_value.suffix': 'kr. per month',
  'benefit.form.board_lodging.title': 'Paid accommodation',
  'benefit.form.car.car_acquisition_date': 'Acquisition date',
  'benefit.form.car.car_acquisition_date.required': 'Acquisition date is required',
  'benefit.form.car.car_first_license_plate_date': 'First registration date',
  'benefit.form.car.car_first_license_plate_date.required': 'First registration date is required',
  'benefit.form.car.car_majority_shareholder': 'Majority shareholder',
  'benefit.form.car.car_majority_shareholder.help_text':
    'If the employee is a majority shareholder, this field must be ticked, so it will correctly reported to SKAT in income subtype 61.',
  'benefit.form.car.car_netto_amount': 'Own payment',
  'benefit.form.car.car_netto_amount.invalid': 'Own payment is invalid',
  'benefit.form.car.car_netto_amount.suffix': 'kr. per period',
  'benefit.form.car.car_tax_value': 'Tax value per period',
  'benefit.form.car.car_tax_value.invalid': 'Tax value per period is invalid',
  'benefit.form.car.car_tax_value.required': 'Tax value per period is required',
  'benefit.form.car.car_tax_value.suffix': 'kr.',
  'benefit.form.car.car_tax_value.warning': 'Calculation of the value is your own responsibility.',
  'benefit.form.car.car_valuation': 'Value',
  'benefit.form.car.car_valuation.invalid': 'Value is invalid',
  'benefit.form.car.car_valuation.required': 'Value is required',
  'benefit.form.car.car_valuation.suffix': 'kr.',
  'benefit.form.car.car_yearly_environmental_tax': 'Annual periodic tax',
  'benefit.form.car.car_yearly_environmental_tax.invalid': 'Annual periodic tax is invalid',
  'benefit.form.car.car_yearly_environmental_tax.required': 'Annual periodic tax is required',
  'benefit.form.car.car_yearly_environmental_tax.suffix': 'kr. per year',
  'benefit.form.car.has_easy_car': 'Easy handling',
  'benefit.form.car.title': 'Paid car',
  'benefit.form.employee_association.amount': 'Net reduction',
  'benefit.form.employee_association.amount.invalid': 'Net reduction is invalid',
  'benefit.form.employee_association.amount.required': 'Net reduction is required',
  'benefit.form.employee_association.amount.suffix': 'kr. per period',
  'benefit.form.employee_stock.stock_text': 'Text for employee stock §7P (income subtype 0101) to be reported',
  'benefit.form.employee_stock.stock_text.max_58_characters':
    'Text for employee stock §7P can at most be 58 characters',
  'benefit.form.employee_stock.stock_text.required': 'Text for employee stock §7P to be reported is required',
  'benefit.form.fixed_disbursement.add_row.empty': 'Add outlay',
  'benefit.form.fixed_disbursement.add_row.not_empty': 'Add new row',
  'benefit.form.fixed_disbursement.header.increase': 'Outlay per period',
  'benefit.form.fixed_disbursement.header.title': 'Description',
  'benefit.form.fixed_disbursement.increase': 'Amount',
  'benefit.form.fixed_disbursement.increase.required': 'Amount is required',
  'benefit.form.fixed_disbursement.increase.suffix': 'kr. per period',
  'benefit.form.fixed_disbursement.intro.title': 'Fixed outlay',
  'benefit.form.fixed_disbursement.title': 'Description',
  'benefit.form.fixed_disbursement.title.required': 'Description is required',
  'benefit.form.forenede_gruppeliv.code': 'Membership code',
  'benefit.form.forenede_gruppeliv.code.required': 'Membership code is required',
  'benefit.form.forenede_gruppeliv.header.code': 'Membership code',
  'benefit.form.forenede_gruppeliv.header.tax_value': 'Amount',
  'benefit.form.forenede_gruppeliv.tax_value': 'Tax value',
  'benefit.form.forenede_gruppeliv.tax_value.invalid': 'Tax value is invalid',
  'benefit.form.forenede_gruppeliv.tax_value.required': 'Tax value is required',
  'benefit.form.forenede_gruppeliv.tax_value.suffix': 'kr. per month',
  'benefit.form.free_text.add_row.empty': 'Add free text',
  'benefit.form.free_text.add_row.not_empty': 'Add new row',
  'benefit.form.free_text.header.title': 'Text',
  'benefit.form.free_text.intro.title': 'Free text',
  'benefit.form.free_text.title': 'Text',
  'benefit.form.free_text.title.required': 'Text is required',
  'benefit.form.has_board_lodging': 'Paid accommodation',
  'benefit.form.has_car': 'Paid car',
  'benefit.form.has_employee_association': 'Employee association',
  'benefit.form.has_employee_stock': 'Employee stock §7P',
  'benefit.form.has_fixed_disbursement': 'Fixed outlay',
  'benefit.form.has_forenede_gruppeliv': 'Forenede Gruppeliv',
  'benefit.form.has_free_text': 'Free text',
  'benefit.form.has_free_transport': 'Paid commute',
  'benefit.form.has_health': 'Health insurance',
  'benefit.form.has_internet': 'Paid internet',
  'benefit.form.has_lunch': 'Lunch scheme',
  'benefit.form.has_pay_reductions': 'Fixed outlay',
  'benefit.form.has_permanent_residence': 'Paid permanent residence',
  'benefit.form.has_tax_reporting_no_threshold': 'Other benefits without threshold',
  'benefit.form.has_tax_reportings': 'Other benefits',
  'benefit.form.has_telephone': 'Paid phone',
  'benefit.form.health.header.health': 'Health insurance',
  'benefit.form.health.tax_value': 'Tax value',
  'benefit.form.health.tax_value.invalid': 'Tax value is invalid',
  'benefit.form.health.tax_value.required': 'Tax value is required',
  'benefit.form.health.tax_value.suffix': 'kr. per month',
  'benefit.form.lunch.has_lunch_daily.false': 'Fixed amount',
  'benefit.form.lunch.has_lunch_daily.true': 'Per day',
  'benefit.form.lunch.header.lunch': 'Lunch scheme',
  'benefit.form.lunch.netto_amount': 'Net reduction',
  'benefit.form.lunch.netto_amount.invalid': 'Net reduction is invalid',
  'benefit.form.lunch.netto_amount.required': 'Net reduction is required',
  'benefit.form.lunch.netto_amount.suffix.daily': 'kr. per day',
  'benefit.form.lunch.netto_amount.suffix.standard': 'kr. per period',
  'benefit.form.pay_reduction.add_row.empty': 'Add reduction',
  'benefit.form.pay_reduction.add_row.not_empty': 'Add new row',
  'benefit.form.pay_reduction.header.reduction': 'Reduction per period',
  'benefit.form.pay_reduction.header.title': 'Description',
  'benefit.form.pay_reduction.intro.title': 'Fixed gross or net reduction',
  'benefit.form.pay_reduction.is_gross.basis_note': 'Gross reduction will also affect the following basises',
  'benefit.form.pay_reduction.is_gross.false': 'Net reduction',
  'benefit.form.pay_reduction.is_gross.true': 'Gross reduction',
  'benefit.form.pay_reduction.reduction.gross': 'Gross reduction',
  'benefit.form.pay_reduction.reduction.net': 'Net reduction',
  'benefit.form.pay_reduction.reduction.required': '{title} is required',
  'benefit.form.pay_reduction.reduction.suffix': 'kr. per period',
  'benefit.form.pay_reduction.title': 'Description',
  'benefit.form.pay_reduction.title.required': 'Description is required',
  'benefit.form.pay_reduction.with_pension': 'Pension',
  'benefit.form.pay_reduction.with_vacation': 'Holiday',
  'benefit.form.permanent_residence.intro.line_1':
    'If you pay for a permanent residence either wholy or partly for an employee, you will need to fill in the difference between the amount the employee pays to have received the residence and the marked rate. If you in addition to the paid residence, also pay for expenses usually paid for by the tenant, such as electricity and heating, you will also need to fill that value in the field. The value is the actual expense.',
  'benefit.form.permanent_residence.intro.line_2':
    'The more precise rules are covered by Ligningsloven § 16, stk. 3, cf. stk. 7 - 9.',
  'benefit.form.permanent_residence.reporting_amount': 'Amount to be reported',
  'benefit.form.permanent_residence.reporting_amount.invalid': 'Amount to be reported is invalid',
  'benefit.form.permanent_residence.reporting_amount.required': 'Amount to be reported is required',
  'benefit.form.permanent_residence.reporting_amount.suffix': 'kr. per month',
  'benefit.form.permanent_residence.title': 'Paid permanent residence',
  'benefit.form.tax_reporting_no_threshold.add_row.empty': 'Add benefit',
  'benefit.form.tax_reporting_no_threshold.add_row.not_empty': 'Add new row',
  'benefit.form.tax_reporting_no_threshold.header.reporting_amount': 'Amount to be reported',
  'benefit.form.tax_reporting_no_threshold.header.title': 'Description',
  'benefit.form.tax_reporting_no_threshold.intro.line_1':
    'Value of other benefits that are labour marked contribution exempt B income. Benefits, which is not enumerate invidually somewhere else, and not covered by thresholds. That is, in this field, you will fill in the calculated value of benefits, where there is no threshold for when it is taxed.',
  'benefit.form.tax_reporting_no_threshold.intro.line_2': 'You can read more {link}.',
  'benefit.form.tax_reporting_no_threshold.intro.line_2.link': 'here',
  'benefit.form.tax_reporting_no_threshold.intro.title': 'Other benefits without threshold',
  'benefit.form.tax_reporting_no_threshold.reporting_amount': 'Amount to be reported',
  'benefit.form.tax_reporting_no_threshold.reporting_amount.required': 'Amount to be reported is required',
  'benefit.form.tax_reporting_no_threshold.reporting_amount.suffix': 'kr.',
  'benefit.form.tax_reporting_no_threshold.title': 'Description',
  'benefit.form.tax_reporting_no_threshold.title.required': 'Description is required',
  'benefit.form.tax_reporting.add_row.empty': 'Add benefit',
  'benefit.form.tax_reporting.add_row.not_empty': 'Add new row',
  'benefit.form.tax_reporting.header.reporting_amount': 'Amount to be reported',
  'benefit.form.tax_reporting.header.title': 'Description',
  'benefit.form.tax_reporting.intro.line_1':
    'If you have provided a non-employment related expense to an employee, where the benefit is covered by the threshold in Ligningsloven § 16, stk. 3, 3. pkt and the value of the value the individual benefits exceeds the provided threshold - which is 1,200 kr. in 2020 - you must enter it here.',
  'benefit.form.tax_reporting.intro.line_2': 'Examples of employee benefits, where the value is reported here:',
  'benefit.form.tax_reporting.intro.line_2.items.1': 'paid motorcycle',
  'benefit.form.tax_reporting.intro.line_2.items.2': 'paid fitness centre',
  'benefit.form.tax_reporting.intro.line_2.items.3': 'paid hunting and value of hunting',
  'benefit.form.tax_reporting.intro.line_2.items.4':
    "paid child care, which is not in the form a company child care 'administered by the employer'",
  'benefit.form.tax_reporting.intro.line_2.items.5':
    'paid multimedias, which is not covered by the conditions for A income in field 20, and also has to be taxed of the value according to the marked rate - that is there is not a pay reduction to have received the benefit. It will be a monthly reporting',
  'benefit.form.tax_reporting.intro.line_2.items.6':
    'paid computer (including accessories), provided without any work relation, without pay reduction, has to be taxed of the value according to the marked rate of a similar computer (including accessories). It has to be at least a monthly reporting of proportional part',
  'benefit.form.tax_reporting.intro.line_3': 'You can read more {link}.',
  'benefit.form.tax_reporting.intro.line_3.link': 'here',
  'benefit.form.tax_reporting.intro.title': 'Other benefits',
  'benefit.form.tax_reporting.reporting_amount': 'Amount to be reported',
  'benefit.form.tax_reporting.reporting_amount.required': 'Amount to be reported is required',
  'benefit.form.tax_reporting.reporting_amount.suffix': 'kr.',
  'benefit.form.tax_reporting.title': 'Description',
  'benefit.form.tax_reporting.title.required': 'Description is required',
  'benefit.form.valid_from.at_date': 'Changes take effect at {date}.',
  'benefit.form.valid_from.now': 'Changes take effect immediately.',
  'benefit.type.board_and_lodging': 'Free board and lodging',
  'benefit.type.car': 'Free car',
  'benefit.type.fixed_disbursement': 'Fixed disbursement',
  'benefit.type.gross_pay_reduction': 'Gross pay reduction',
  'benefit.type.gross_pay_reduction_no_pension': 'Gross pay reduction (without pension basis)',
  'benefit.type.gross_pay_reduction_no_vacation': 'Gross pay reduction (without holiday basis)',
  'benefit.type.gross_pay_reduction_no_vacation_and_pension': 'Gross pay reduction (without holiday and pension basis)',
  'benefit.type.health': 'Health insurance',
  'benefit.type.internet': 'Free internet',
  'benefit.type.lunch': 'Lunch',
  'benefit.type.net_pay_reduction': 'Net pay reduction',
  'benefit.type.other': 'Other employee benefit',
  'benefit.type.permanent_residence': 'Free permanent residence',
  'benefit.type.tax_reporting': 'Other employee benefit',
  'benefit.type.tax_reporting_no_threshold': 'Other employee benefit without threshold',
  'benefit.type.telephone': 'Free phone',

  'bonus.edit.form.amount': 'bonus',
  'bonus.edit.form.amount.anniversary_bonus':
    'The first 8,000 kr. are tax free (the whole amount is reported in field 69 in E-income).',
  'bonus.edit.form.amount.empty_balance': 'Empty account',
  'bonus.edit.form.amount.free_phone': 'paid telephone',
  'bonus.edit.form.amount.paid_vacation': 'Total value: {total}, {part}/day',
  'bonus.edit.form.amount.sh_fritvalg': 'Balance: {amount}',
  'bonus.edit.form.approved': 'Approved?',
  'bonus.edit.form.approved.checked': 'Approved',
  'bonus.edit.form.approved.unchecked': 'Draft',
  'bonus.edit.form.as_in_kind': 'As in kind',
  'bonus.edit.form.as_in_kind.no_title': 'The text on the payslip will also be reported to SKAT.',
  'bonus.edit.form.atp_hours': 'ATP hours',
  'bonus.edit.form.atp_hours.help.line_1': 'Registration for ATP follows the default rates.',
  'bonus.edit.form.atp_hours.help.line_2':
    'Enter a custom number of hours here, for which the ATP rate will be calculated.  If the number is 0, the ATP rate will be calculated based on the number of hours appearing on the same payslip the ATP is attached.',
  'bonus.edit.form.atp_hours.help.line_3': 'The ATP hours have no other impact on pay or reporting.',
  'bonus.edit.form.atp_hours.invalid': 'ATP hours are invalid',
  'bonus.edit.form.atp_hours.suffix': 'hours',
  'bonus.edit.form.category': 'Subtype',
  'bonus.edit.form.disposition_date': 'Disposition date',
  'bonus.edit.form.no_amount.free_phone': "Registration for free phone follows SKAT's rates.",
  'bonus.edit.form.overtime_payout_units': 'Number of hours',
  'bonus.edit.form.overtime_payout_units.invalid': 'Number of hours is invalid',
  'bonus.edit.form.overtime_payout_units.less_than_available': 'Number of hours cannot be more than what is left',
  'bonus.edit.form.overtime_payout_units.not_negative': 'Number of hours cannot be negative',
  'bonus.edit.form.overtime_payout_units.note.line_1': 'Rate: {rate}/hour; hours left: {hours_left}',
  'bonus.edit.form.overtime_payout_units.note.line_2': 'Value: {rate}',
  'bonus.edit.form.overtime_payout_units.required': 'Number of hours is required',
  'bonus.edit.form.overtime_payout_units.suffix': 'hours',
  'bonus.edit.form.type': 'Type',
  'bonus.edit.form.type.required': 'Type is required',
  'bonus.edit.form.with_pension': 'With pension',
  'bonus.edit.form.with_vacation': 'With holiday entitled pay',
  'bonus.edit.form.work_hours_units': 'Number of hours',
  'bonus.edit.form.work_hours_units.invalid': 'Number of hours is invalid',
  'bonus.edit.form.work_hours_units.required': 'Number of hours is required',
  'bonus.edit.form.work_hours_units.suffix': 'hours',
  'bonus.edit.intro': 'Give your employee a bonus for a future payslip.',
  'bonus.edit.title.create': 'Create bonus',
  'bonus.edit.title.edit': 'Edit bonus',
  'bonus.edit.title.view': 'View bonus',
  'bonus.tab.header.add_bonus': 'Create bonus',
  'bonus.tab.header.approve_all': 'Approve all',
  'bonus.tab.header.hide_history': 'Back',
  'bonus.tab.header.show_history': 'Show archived',
  'bonus.tab.table.action.approve': 'Approve',
  'bonus.tab.table.action.delete': 'Delete bonus',
  'bonus.tab.table.action.edit': 'Edit bonus',
  'bonus.tab.table.action.remove_approve': 'Remove approval',
  'bonus.tab.table.action.view': 'View bonus',
  'bonus.tab.table.approved': 'Yes',
  'bonus.tab.table.header.amount': 'Amount',
  'bonus.tab.table.header.approved': 'Approved',
  'bonus.tab.table.header.date': 'Date',
  'bonus.tab.table.header.title': 'Description',
  'bonus.tab.table.header.type': 'Type',
  'bonus.tab.table.not_approved': 'No',
  'bonus.tab.table.saving': 'Saving...',
  'bonus.tab.title.history': 'Bonus archive',
  'bonus.tab.title.standard': 'Bonus',

  'calendar.approval_all_button_text': 'Approve all for {month}',
  'calendar.legend.employee_holiday': 'Weekend/bank holiday',
  'calendar.legend.need_approval': 'Missing approval',
  'calendar.named_holiday.christ_ascension': 'Christ Ascension',
  'calendar.named_holiday.day_after_christ_ascension': 'Day after Christ Ascension',
  'calendar.named_holiday.easter_monday': 'Easter Monday',
  'calendar.named_holiday.easter_sunday': 'Easter Sunday',
  'calendar.named_holiday.first_day_of_pentecost': 'Whit Sunday',
  'calendar.named_holiday.good_friday': 'Good Friday',
  'calendar.named_holiday.great_prayer_day': 'Great Prayer Day',
  'calendar.named_holiday.maundy_thursday': 'Maundy Thursday',
  'calendar.named_holiday.palm_sunday': 'Palm Sunday',
  'calendar.named_holiday.whit_monday': 'White Monday',
  'calendar.navigation.next_month': 'Next month',
  'calendar.navigation.previous_month': 'Previous month',
  'calendar.navigation.today': 'Today',
  'calendar.single.edit_registration': 'Edit registration',
  'calendar.single.new_registration': 'New registration',
  'calendar.table.approve': 'Approve',
  'calendar.table.cell.click_mode': 'Click to create {new_type} for {name}',
  'calendar.table.cell.context_menu.new': 'Create {new_type} for {name}',
  'calendar.table.cell.drag_mode': 'Click and drag from here to create {new_type} for {name}',
  'calendar.table.click_to_approve': '{title} – click to approve',
  'calendar.table.click_to_edit': '{title} – click to edit',
  'calendar.table.header.date': 'Date',
  'calendar.table.header.day': 'Day',
  'calendar.table.header.week': 'Week',
  'calendar.week.short.friday': 'F',
  'calendar.week.short.monday': 'M',
  'calendar.week.short.saturday': 'S',
  'calendar.week.short.sunday': 'S',
  'calendar.week.short.thursday': 'T',
  'calendar.week.short.tuesday': 'T',
  'calendar.week.short.wednesday': 'W',

  'car_allowance_rate.edit.form.car_allowance_rate': 'Rate',
  'car_allowance_rate.edit.form.car_allowance_rate.above_limit': 'Rate cannot be higher than {limit}',
  'car_allowance_rate.edit.form.car_allowance_rate.invalid': 'Rate is invalid',
  'car_allowance_rate.edit.form.car_allowance_rate.suffix': 'kr./km',
  'car_allowance_rate.edit.intro.line_1':
    'Use a different rate than the standard for transport allowance for your employees.',
  'car_allowance_rate.edit.intro.line_2': 'Leave the field blank to use the default rate.',
  'car_allowance_rate.edit.title': 'Edit rate',

  'car_allowance.car_allowance.many': 'transport allowance',
  'car_allowance.car_allowance.one': 'transport allowance',
  'car_allowance.coarse_car_allowance.many': 'coarse transport allowance',
  'car_allowance.coarse_car_allowance.one': 'coarse transport allowance',
  'car_allowance.detailed_car_allowance.many': 'detailed transport allowance',
  'car_allowance.detailed_car_allowance.one': 'detailed transport allowance',
  'car_allowance.trip': '{from} – {to}',
  'car_allowance.trip_length': '{amount} km',

  'car_allowances_tab.coarse.alert.success': "{name}'s transport allowance have been saved",
  'car_allowances_tab.coarse.form.kilometers': 'Distance',
  'car_allowances_tab.coarse.form.kilometers.invalid': 'Distance is invalid',
  'car_allowances_tab.coarse.form.kilometers.required': 'Distance is required',
  'car_allowances_tab.coarse.form.kilometers.suffix': 'km',
  'car_allowances_tab.coarse.period.no_change':
    'Transport allowance for the whole period {from} - {to} is registered below.',
  'car_allowances_tab.coarse.period.period_range': '{from} - {to}',
  'car_allowances_tab.coarse.period.select_period': 'Select period',
  'car_allowances_tab.coarse.sub_select.help.line_1':
    'Normally when you register coarse transport allowance, there will only be one registration per payroll period.',
  'car_allowances_tab.coarse.sub_select.help.line_2':
    'When a payroll is completed, it locks the registration, and if you afterwards modify the registration, it creates a new one instead.',
  'car_allowances_tab.coarse.sub_select.help.line_3':
    'If you wish to retroactively register more kilometres than originally registered, then you should create another registration with the difference.  For example, if the correct total was 1,000 km, but only 800 km had been registered, you should add another with 200 km.',
  'car_allowances_tab.coarse.sub_select.help.title':
    'Why are there more than one coarse transport allowance for the same period?',
  'car_allowances_tab.coarse.sub_select.immutable': 'Completed',
  'car_allowances_tab.coarse.sub_select.intro': 'There more than one registration for period',
  'car_allowances_tab.coarse.sub_select.line': '{number}{ordinal_suffix} registration',
  'car_allowances_tab.coarse.warning':
    'Note: If you use this solution, you will need to document each journey somewhere else. {link}.',
  'car_allowances_tab.coarse.warning.link': 'Read more at SKAT',
  'car_allowances_tab.detailed.edit.form.add_via_point': 'Add waypoint',
  'car_allowances_tab.detailed.edit.form.create_return_trip': 'Also add return journey',
  'car_allowances_tab.detailed.edit.form.create_return_trip.false': 'No',
  'car_allowances_tab.detailed.edit.form.create_return_trip.true': 'Yes',
  'car_allowances_tab.detailed.edit.form.date': 'Date',
  'car_allowances_tab.detailed.edit.form.date.required': 'Date is required',
  'car_allowances_tab.detailed.edit.form.kilometer_format': '{kilometers} km',
  'car_allowances_tab.detailed.edit.form.kilometers': 'Distance',
  'car_allowances_tab.detailed.edit.form.kilometers.required': 'Distance is required',
  'car_allowances_tab.detailed.edit.form.kilometers.suffix': 'km',
  'car_allowances_tab.detailed.edit.form.license_plate': 'Number plate',
  'car_allowances_tab.detailed.edit.form.license_plate.required': 'Number plate is required',
  'car_allowances_tab.detailed.edit.form.location_from': 'From',
  'car_allowances_tab.detailed.edit.form.location_from.required': 'From is required',
  'car_allowances_tab.detailed.edit.form.location_to': 'To',
  'car_allowances_tab.detailed.edit.form.location_to.required': 'To is required',
  'car_allowances_tab.detailed.edit.form.location_via': 'Waypoint',
  'car_allowances_tab.detailed.edit.form.location_via.required': 'Waypoint is required',
  'car_allowances_tab.detailed.edit.form.rate': 'Rate',
  'car_allowances_tab.detailed.edit.form.rate.above_limit': 'Rate cannot be more than {limit}',
  'car_allowances_tab.detailed.edit.form.rate.help':
    'It is possible to register a specific rate per transport allowance registration.  If the field is empty, a potential rate on the contract will be used; and if that is not configured, the default rates will be used.',
  'car_allowances_tab.detailed.edit.form.rate.invalid': 'Rate is invalid',
  'car_allowances_tab.detailed.edit.form.rate.suffix': 'kr.',
  'car_allowances_tab.detailed.edit.form.reason': 'Reason',
  'car_allowances_tab.detailed.edit.form.reason.required': 'Reason is required',
  'car_allowances_tab.detailed.edit.form.remove_via_point': 'Remove waypoint',
  'car_allowances_tab.detailed.edit.form.suggested.route': 'Suggested route',
  'car_allowances_tab.detailed.edit.form.suggested.route.need_input': 'Suggested route (enter from/to)',
  'car_allowances_tab.detailed.edit.form.via.distance_to_next': 'Distance to next waypoint:',
  'car_allowances_tab.detailed.edit.form.via.help.line_1':
    'Waypoints are created as individual registrations.  If one enters a route of Copenhagen to Odense to Aalborg, two registrations will be created, respectively Copenhagen to Odense and Odense to Aalborg.',
  'car_allowances_tab.detailed.edit.form.via.help.line_2':
    'Therefore you cannot enter the distance manually per waypoint during creation.  However you can edit them afterwards, since they are created as individual registrations.',
  'car_allowances_tab.detailed.edit.form.via.help.line_3':
    'Do note that if you create a return trip, it will be the whole way back.  From the example above, it would be another two trips of Aalborg to Odense and then Odense to Copenhagen.  If your journey can better be described as a closed ring, you should end where you started.',
  'car_allowances_tab.detailed.edit.title.create': 'Create transport allowance',
  'car_allowances_tab.detailed.edit.title.edit': 'Edit transport allowance',
  'car_allowances_tab.detailed.header.add_registration': 'Register transport allowance',
  'car_allowances_tab.detailed.header.approve_all': 'Approve all',
  'car_allowances_tab.detailed.header.title': 'Number of kilometres, awaiting payment.',
  'car_allowances_tab.detailed.header.unsettled_suffix': 'Kilometres',
  'car_allowances_tab.detailed.history.table.header.date': 'Date',
  'car_allowances_tab.detailed.history.table.header.kilometers': 'Distance',
  'car_allowances_tab.detailed.history.table.header.reason': 'Reason',
  'car_allowances_tab.detailed.table.actions.copy': 'Copy transport allowance',
  'car_allowances_tab.detailed.table.actions.delete': 'Delete transport allowance',
  'car_allowances_tab.detailed.table.actions.edit': 'Edit transport allowance',
  'car_allowances_tab.detailed.table.approve': 'Approve',
  'car_allowances_tab.detailed.table.approved.false': 'No',
  'car_allowances_tab.detailed.table.approved.true': 'Yes',
  'car_allowances_tab.detailed.table.header.approved': 'Approved',
  'car_allowances_tab.detailed.table.header.date': 'Date',
  'car_allowances_tab.detailed.table.header.kilometers': 'Distance',
  'car_allowances_tab.detailed.table.header.reason': 'Reason',
  'car_allowances_tab.detailed.table.kilometer_format': '{amount} km',
  'car_allowances_tab.detailed.table.kilometers_format': '{from} to {to}',
  'car_allowances_tab.detailed.table.remove_approval': 'Remove approval',
  'car_allowances_tab.detailed.table.saving': 'Saving...',
  'car_allowances_tab.detailed.unsettled_kilometers_format.many': '{count} kilometres',
  'car_allowances_tab.detailed.unsettled_kilometers_format.one': '{count} kilometre',
  'car_allowances_tab.header.edit_rate': 'Edit rate',
  'car_allowances_tab.header.hide_history': 'Back',
  'car_allowances_tab.header.show_history': 'Show archive',
  'car_allowances_tab.header.switch_detailed': 'Detailed',
  'car_allowances_tab.map.avoid_ferries': 'Avoid ferries',
  'car_allowances_tab.map.no_return_route': 'Could not create a return journey between the two points.',
  'car_allowances_tab.map.no_route': 'Could not create a journey between the two points.',
  'car_allowances_tab.title.history': 'Archived transport allowance',
  'car_allowances_tab.title.standard': 'Transport allowance',

  'car_allowances_table.detailed.table.rate_format': '(rate: {rate})',

  'car_allowances.coarse': 'Combined transport allowance for {name}',
  'car_allowances.coarse.title': 'Coarse transport allowance',
  'car_allowances.detailed.title': 'Detailed transport allowance',
  'car_allowances.table.actions.edit': 'Edit',
  'car_allowances.table.actions.register': 'Register',
  'car_allowances.table.all_approved': 'All transport allowance approved',
  'car_allowances.table.approve_all': 'Approve transport allowance',
  'car_allowances.table.header.distance': 'Distance',
  'car_allowances.table.header.employee': 'Employee',

  'choice.card.table.direct_pay.false': 'No',
  'choice.card.table.direct_pay.true': 'Yes',
  'choice.card.table.fixed_suffix': 'kr.',
  'choice.card.table.header.direct_pay': 'Recurring payment',
  'choice.card.table.header.rate': 'Rate',
  'choice.card.table.header.type': 'Type',
  'choice.card.table.percentage_suffix': '%',
  'choice.card.table.rate_format': '{rate} {suffix}',
  'choice.card.title': 'SH days and Fritvalg account',
  'choice.edit.form.add_row': 'Add type',
  'choice.edit.form.compensation_rate.fixed': 'Amount',
  'choice.edit.form.compensation_rate.invalid_percentage': 'Procent is invalid',
  'choice.edit.form.compensation_rate.percentage': 'Procent',
  'choice.edit.form.compensation_rate.required': '{title} is required',
  'choice.edit.form.direct_pay': 'With recurring payment ',
  'choice.edit.form.has_choice.false': 'No SH days and Fritvalg account for this employee',
  'choice.edit.form.has_choice.true': 'SH days and Fritvalg account for this employee',
  'choice.edit.form.header.direct_pay': 'Recurring payment',
  'choice.edit.form.header.rate': 'Rate',
  'choice.edit.form.header.type': 'Type',
  'choice.edit.form.suffix.fixed_amount': 'kr.',
  'choice.edit.form.suffix.percentage': '%',
  'choice.edit.form.type': 'Type',
  'choice.edit.form.type.required': 'Type is required',
  'choice.edit.form.valid_from.at_date': 'Changes take effect at {date}.',
  'choice.edit.form.valid_from.now': 'Changes take effect immediately.',
  'choice.edit.title': 'Edit SH days and Fritvalg account',

  'companies_add.alert.success': '{name} is now created as a company',
  'companies_add.edit.title': 'Fill in the details for the company',
  'companies_add.employee_user_notice.line_1':
    'You are already attached to a company as an employee, if you wish to log into the employee app, then {link}.',
  'companies_add.employee_user_notice.line_1.link': 'go to the employee site',
  'companies_add.employee_user_notice.line_2': 'Otherwise create a new company below.',
  'companies_add.form.accounting_software': 'Accounting system',
  'companies_add.form.accounting_software.manual': 'Manual',
  'companies_add.form.accounting_software.other': 'Other',
  'companies_add.form.accounting_software.required': 'Accounting system is required',
  'companies_add.form.address': 'Address',
  'companies_add.form.address.required': 'Address is required',
  'companies_add.form.allow_automatic_zero_tax_report': 'Automatic zero tax report',
  'companies_add.form.allow_automatic_zero_tax_report.false': 'No, do not report zero tax automatically',
  'companies_add.form.allow_automatic_zero_tax_report.required': 'Automatic zero tax report is required',
  'companies_add.form.allow_automatic_zero_tax_report.true': 'Yes, automatically report when there is no payroll',
  'companies_add.form.city': 'City',
  'companies_add.form.city.required': 'City is required',
  'companies_add.form.company_role': 'Your role in connection with payroll administration',
  'companies_add.form.company_role.option.accountant': 'I am an accountant/bookkeeper, and I do payroll for the client',
  'companies_add.form.company_role.option.company_owner_accountant_runs_pay_roll':
    'My accountant/bookkeeper do my payroll',
  'companies_add.form.company_role.option.company_owner_runs_pay_roll': 'I do the payroll myself',
  'companies_add.form.company_role.required': 'Your role in connection with payroll administration is required',
  'companies_add.form.company_size': 'Company size',
  'companies_add.form.company_size.option.1_2': '1 - 2 employees',
  'companies_add.form.company_size.option.100_plus': 'Over 100 employees',
  'companies_add.form.company_size.option.11_20': '11 - 20 employees',
  'companies_add.form.company_size.option.21_50': '21 - 50 employees',
  'companies_add.form.company_size.option.3_5': '3 - 5 employees',
  'companies_add.form.company_size.option.51_100': '51 - 100 employees',
  'companies_add.form.company_size.option.6_10': '6 - 10 employees',
  'companies_add.form.company_size.required': 'Company size is required',
  'companies_add.form.default_hourly_monthly_cycle_id': 'Payroll period for waged employees',
  'companies_add.form.default_hourly_monthly_cycle_id.no_offset': 'From the first to the last day of the month',
  'companies_add.form.default_hourly_monthly_cycle_id.placeholder': 'Select cycle',
  'companies_add.form.default_hourly_monthly_cycle_id.required': 'Payroll period for waged employees is required',
  'companies_add.form.default_hourly_monthly_cycle_id.with_offset': 'From the {from} to the {to} of the month',
  'companies_add.form.name': 'Name',
  'companies_add.form.name.required': 'Name is required',
  'companies_add.form.postal_code': 'Postcode',
  'companies_add.form.postal_code.greenland_not_allowed': 'Postcode cannot be a Greenlandic postcode',
  'companies_add.form.postal_code.invalid': 'Postcode is invalid',
  'companies_add.form.postal_code.required': 'Postcode is required',
  'companies_add.form.referred_by': 'Where have you heard of Salary?',
  'companies_add.form.skat_size': "Company's size",
  'companies_add.form.skat_size.placeholder': 'Select size',
  'companies_add.form.skat_size.required': "Company's size is required",
  'companies_add.form.submit': 'Create company',
  'companies_add.search.placeholder': 'Search for CVR number or company name',
  'companies_add.search.title': 'Collect information directly from the Central Business Register (CVR)',

  'companies.add_company': 'Add new company',
  'companies.add_company_outside_group': 'Add new company outside the group',
  'companies.add_company_to_group': 'Add new company to the group',
  'companies.group.accounting_integration.note':
    "Right now it's only possible to synchronise your integrations across the group, but cross group features are planned.",
  'companies.group.accounting_integration.synchronise': 'Synchronoise all integrations',
  'companies.group.accounting_integration.title': 'Accounting integrations',
  'companies.group.reports.form.from': 'From',
  'companies.group.reports.form.from.required': 'From date is required',
  'companies.group.reports.form.not_while_group': 'Select the companies to be included in the report.',
  'companies.group.reports.form.report': 'Select report',
  'companies.group.reports.form.to': 'To',
  'companies.group.reports.form.to.required': 'To date is required',
  'companies.group.reports.form.while_group': 'Create the report for the entire group',
  'companies.group.reports.title': 'Reports',
  'companies.group.users.action.delete': 'Remove user',
  'companies.group.users.action.edit_permissions': 'Edit permissions',
  'companies.group.users.add.form.email': 'Email',
  'companies.group.users.add.form.email.invalid': 'Email is invalid',
  'companies.group.users.add.form.email.not_same_domain':
    "You are about to add a user, whose email address has a different domain than your email address. Remember you are about to add a user to {group}, and not just {company}. If that's the intention, you can ignore this message.",
  'companies.group.users.add.form.email.not_same_domain.company': 'a company',
  'companies.group.users.add.form.email.not_same_domain.group': 'a company group',
  'companies.group.users.add.form.email.required': 'Email is required',
  'companies.group.users.add.form.group_user_type': 'Role',
  'companies.group.users.add.form.group_user_type.placeholder': 'Select role',
  'companies.group.users.add.form.group_user_type.required': 'Role is required',
  'companies.group.users.add.form.permissions.intro':
    'The permissions to automatically attach to those companies which are a part of the group.',
  'companies.group.users.add.form.permissions.title': 'Automatic permissions for companies',
  'companies.group.users.add.intro':
    'You can invite people to your company group here. Remember that they get access to all companies in the group, and companies later added to the group.',
  'companies.group.users.add.submit': 'Send invitation now',
  'companies.group.users.add.title': 'Invite new user to company group',
  'companies.group.users.alert.success': 'Permisions for {name} have been saved',
  'companies.group.users.edit.title': 'Edit company user permissions for {name}',
  'companies.group.users.header.add': 'Add user',
  'companies.group.users.invitations.title.many': '{count} invitations',
  'companies.group.users.invitations.title.one': '{count} invitation',
  'companies.group.users.invites.table.action.delete': 'Delete invitation',
  'companies.group.users.invites.table.header.created_at': 'Sent',
  'companies.group.users.invites.table.header.email': 'Email',
  'companies.group.users.table.header.permissions': 'Automatic permissions for new companies',
  'companies.group.users.table.header.type': 'Type',
  'companies.group.users.table.header.user_name': 'User',
  'companies.group.users.title': 'Users',
  'companies.header.group': 'Company group',
  'companies.menu.accounting': 'Accounting integrations',
  'companies.menu.companies': 'Companies',
  'companies.menu.reports': 'Reports',
  'companies.menu.settings': 'Settings',
  'companies.menu.users': 'Users',
  'companies.search.companies.many': '{count} companies',
  'companies.search.companies.one': '{count} company',
  'companies.search.searching_for': '(searching for "{query}")',
  'companies.settings.advanced.intro':
    "If you're advanced settings for your companies, you will not need to adjust the settings for each company you add to the group. Here you can set the default advanced settings for new companies in the group.",
  'companies.settings.advanced.title': 'Advanced company settings',
  'companies.settings.alert.success': 'Changes to {name} have been saved',
  'companies.settings.form.default_pricing_package_id': 'Price plan for new companies in the group',
  'companies.settings.form.default_pricing_package_id.note':
    "When a new company is added to the group, it will automatically get this price plan. You can always adjust a company's price plan afterwards.",
  'companies.settings.form.name': 'Name',
  'companies.settings.form.name.required': 'Name is required',
  'companies.settings.title': 'Company group settings',
  'companies.table.add_company_to_group': 'Add to group',
  'companies.table.company': 'Company',
  'companies.table.employees': 'Employees',
  'companies.table.go_to_company': 'Go to company',
  'companies.table.in_group': 'In group?',
  'companies.table.in_group.false': 'No',
  'companies.table.in_group.true': 'Yes',
  'companies.table.national_id': 'CVR',
  'companies.table.remove_company_from_group': 'Remove company from the group',
  'companies.table.see_user_list': 'See user list',
  'companies.table.users': 'Users',

  'company_bank_account.alert.success': 'Changes to {name} have been saved',
  'company_bank_account.description.link': 'MasterCard Payment Service page',
  'company_bank_account.description.text':
    "The fastest way is to go to {link} and create a Payment Service agreement. Follow the instructions and select Salary's CVR number ({cvr}) as Data Provider.",
  'company_bank_account.header.back': 'Back',
  'company_bank_account.header.title': 'Create MasterCard Payment Service agreement',
  'company_bank_account.request.form.bank_account_number': "The company's bank account number",
  'company_bank_account.request.form.bank_registration_number': "The company's bank registration number",
  'company_bank_account.request.form.contact_email': "Signer's email",
  'company_bank_account.request.form.contact_name': "Signer's name",
  'company_bank_account.request.form.contact_phone': "Signer's phone number",
  'company_bank_account.request.form.submit': 'Create agreement',
  'company_bank_account.request.form.validation.bank_account_number.invalid':
    "The company's bank account number is invalid",
  'company_bank_account.request.form.validation.bank_account_number.required':
    "The company's bank account number is required",
  'company_bank_account.request.form.validation.bank_registration_number.invalid':
    "The company's bank registration number is invalid",
  'company_bank_account.request.form.validation.bank_registration_number.required':
    "The company's bank registration number is required",
  'company_bank_account.request.form.validation.contact_email.invalid': "Signer's email is invalid",
  'company_bank_account.request.form.validation.contact_email.required': "Signer's email is required",
  'company_bank_account.request.form.validation.contact_name.required': "Signer's name is required",
  'company_bank_account.request.form.validation.contact_phone.invalid': "Signer's phone number is invalid",
  'company_bank_account.request.form.validation.contact_phone.required': "Signer's phone number is required",
  'company_bank_account.request.line_1':
    'Alternatively you can use this form to create a Payment Service agreement, this is particularly useful if you are creating the agreement on behalf of someone else.',
  'company_bank_account.request.line_2': 'The signer will receiver an email with instructions.',
  'company_bank_account.request.title': 'Request Payment Service agreement',

  'company_dashboards.header.add_company': 'Add company',
  'company_dashboards.header.search': 'Search...',
  'company_dashboards.table.company_info.employees.many': '{count} employees',
  'company_dashboards.table.company_info.employees.one': '{count} employee',
  'company_dashboards.table.company_info.name': 'Name',
  'company_dashboards.table.company_info.national_id': 'CVR',
  'company_dashboards.table.company_info.users.many': '{count} users',
  'company_dashboards.table.company_info.users.one': '{count} user',
  'company_dashboards.table.header.company_info': 'Company info',
  'company_dashboards.table.header.next_pay_roll': 'Next payroll',
  'company_dashboards.table.header.pay_roll_settings': 'Payroll settings',
  'company_dashboards.table.header.registrations': 'Pay registrations',
  'company_dashboards.table.next_pay_roll.none': 'There are no active payrolls',
  'company_dashboards.table.pay_roll_settings.accounting_integration': 'Accounting',
  'company_dashboards.table.pay_roll_settings.approval': 'Approval',
  'company_dashboards.table.pay_roll_settings.payment_integration': 'Transfers',
  'company_dashboards.table.registrations.car_allowance': 'Transport allowance',
  'company_dashboards.table.registrations.car_allowance.format': '{number} km need approval',
  'company_dashboards.table.registrations.hours': 'Hours',
  'company_dashboards.table.registrations.hours.format': '{number} need approval',
  'company_dashboards.table.registrations.leave': 'Holidays',
  'company_dashboards.table.registrations.leave.format': '{number} need approval',
  'company_dashboards.table.registrations.notifications': 'Notifications',
  'company_dashboards.table.registrations.reimbursements': 'Outlays',
  'company_dashboards.table.registrations.reimbursements.format': '{number} need approval',
  'company_dashboards.table.registrations.swipe': 'Swipe',
  'company_dashboards.table.registrations.swipe.format': '{number} need approval',
  'company_dashboards.table.status.awaiting_approval': 'Awaiting approval',
  'company_dashboards.table.status.deadline_format': 'before {time}',
  'company_dashboards.title.companies.many': '{count} companies',
  'company_dashboards.title.companies.one': '{count} company',
  'company_dashboards.title.search_format': " (searching for '{query}')",

  'company_data_integration.alert.success': 'Your integration has been saved',
  'company_data_integration.connection_form.form.back': 'Back',
  'company_data_integration.connection_form.form.parameter.placeholder': 'Enter {parameter} from {integration}',
  'company_data_integration.connection_form.form.parameter.required': '{parameter} is required',
  'company_data_integration.connection_form.form.submit': 'Continue to next step',
  'company_data_integration.connection_form.title': 'Establish connection',
  'company_data_integration.integration_type_form.features.export.leave_registrations': 'absence registrations',
  'company_data_integration.integration_type_form.features.format.both':
    'Import of {import_features} and export of {export_features}',
  'company_data_integration.integration_type_form.features.format.export': 'Export of {export_features}',
  'company_data_integration.integration_type_form.features.format.import': 'Import of {import_features}',
  'company_data_integration.integration_type_form.features.import.car_allowances': 'transport allowance',
  'company_data_integration.integration_type_form.features.import.employees': 'employees',
  'company_data_integration.integration_type_form.features.import.leave_registrations': 'absence',
  'company_data_integration.integration_type_form.features.import.pay_roll': 'payroll data',
  'company_data_integration.integration_type_form.features.import.reimbursements': 'outlays',
  'company_data_integration.integration_type_form.form.integration_type': 'Select data provider',
  'company_data_integration.integration_type_form.form.integration_type.required': 'Data provider is required',
  'company_data_integration.integration_type_form.intro':
    'We strive to integrate to as many data providers as possible. If you cannot find yours on the list, you can contact support at {email}.',
  'company_data_integration.integration_type_form.submit': 'Continue to connection',
  'company_data_integration.integration_type_form.submit.lock': 'Upgrade to Basic to integrate to a data provider',
  'company_data_integration.integration_type_form.title': 'Select data provider',
  'company_data_integration.steps.help_note':
    "If you're having problems connecting to your data provider, you can always contact {email}.",
  'company_data_integration.steps.intro':
    'Employees, time registrations and/or absence registrations can be imported from a data provider, like a time management or employee management system.',
  'company_data_integration.steps.point.0': 'Select data provider',
  'company_data_integration.steps.point.1': 'Establish connection',
  'company_data_integration.steps.title': 'Connect to your data provider',

  'company_group.user_type.admin': 'Administrator',
  'company_group.user_type.regular': 'Regular',

  'company_pay_roll.alert.success': 'Changes to {name} have been saved',
  'company_pay_roll.approval_code.approval_code': 'Approval code',
  'company_pay_roll.approval_code.blocked':
    'You cannot enable payroll approval code, while you have automatic payroll approval enabled.',
  'company_pay_roll.approval_code.button.blocked': 'Close',
  'company_pay_roll.approval_code.button.cancel': 'Cancel',
  'company_pay_roll.approval_code.button.submit': 'Save',
  'company_pay_roll.approval_code.intro.line_1':
    'A payroll approval code is a code that is required to approve a payroll.  When the code is set, all payroll approvals will require the correct code.  The code is at company level, and all users will have to use the same code.',
  'company_pay_roll.approval_code.intro.line_2':
    'Be aware that once you have set the code, it cannot be changed or removed.  Should it be necessary, you will have to contact support to have it removed.',
  'company_pay_roll.approval_code.title': 'Payroll approval code',
  'company_pay_roll.form.allow_automatic_zero_tax_report': 'Automatically perform zero tax reporting to SKAT',
  'company_pay_roll.form.allow_automatic_zero_tax_report.lock': 'Update to Basic to perform zero tax reporting',
  'company_pay_roll.form.allow_automatic_zero_tax_report.note':
    "Note: Salary performs zero tax reporting for you, even if you don't do payroll through us.",
  'company_pay_roll.form.allow_empty_tax_card_mail':
    'Inform the employee, if they have received an empty tax card from SKAT.',
  'company_pay_roll.form.approval_notifications_note':
    'You can adjust when to receive notifications when something needs approval. The users can receive up to two notifications, you choose yourself how many days before the approval deadline the notification will be received. Are the values 0, will there be no notifications.',
  'company_pay_roll.form.approvers_reviewers.auto_approve_note':
    'Automatic approval of pay is turned on. If you wish to use mulitple payroll approvers, you will have to switch to manual approval.',
  'company_pay_roll.form.approvers_reviewers.note.line_1':
    "When you have multiple users attached to a company, you enable rules for how many approvers you'll need for a payroll. Normally only one approval is necessary for a payroll.",
  'company_pay_roll.form.approvers_reviewers.note.line_2':
    "If you for instance require two approvals for a payroll, will it first be executed when two of the users attached to the company have approved it. After the first approval, it will be in a 'partial approved' state and will be locked. When the payroll is re-opened, it will need to be approved twice once more.",
  'company_pay_roll.form.approvers_reviewers.note.line_3':
    "If one for instance wishes to have one's accountant to review one's payroll, you can enable that payrolls can be reviewed before approval. When a payroll have been reviewed, it will be locked. Even if a payroll has not been reviewed, it can still be approved.",
  'company_pay_roll.form.approvers_reviewers.note.line_4':
    'Remember that a payroll that is locked can still be unlocked when before the final deadline.',
  'company_pay_roll.form.department_approver_notification_days_before_1': 'First notification',
  'company_pay_roll.form.department_approver_notification_days_before_1.invalid': 'First notification is invalid',
  'company_pay_roll.form.department_approver_notification_days_before_1.suffix': 'bank days',
  'company_pay_roll.form.department_approver_notification_days_before_2': 'Second notification',
  'company_pay_roll.form.department_approver_notification_days_before_2.invalid': 'Second notification is invalid',
  'company_pay_roll.form.department_approver_notification_days_before_2.suffix': 'bank days',
  'company_pay_roll.form.number_of_pay_roll_approvers':
    'Number of people needed to approve a payroll before it can be executed',
  'company_pay_roll.form.number_of_pay_roll_approvers.not_enough':
    'There needs to be at least two attached to the company that can approve payrolls before you can enable multiple approvers.',
  'company_pay_roll.form.number_of_pay_roll_reviewers': 'Allow payroll review for those with that permission.',
  'company_pay_roll.form.number_of_pay_roll_reviewers.not_enough':
    'There needs to be at least one attached to the company that can review payrolls before you can enable payroll review.',
  'company_pay_roll.form.rule_pay_roll_run_approval': 'How shall the payrolls be approved?',
  'company_pay_roll.form.rule_pay_roll_run_approval.lock': 'Upgrade to Basic to enable automatic payroll',
  'company_pay_roll.form.rule_pay_roll_run_approval.payment_card_note':
    'You cannot select automatic payroll approval without a payment card agreement. Go to {link}.',
  'company_pay_roll.form.rule_pay_roll_run_approval.payment_card_note.link':
    'invoices to create a payment card agreement',
  'company_pay_roll.form.rule_pay_roll_run_approval.payroll_approval_code_lock':
    'You cannot switch to automatic payroll approval, when you payroll approval code enabled.  You will need to contact support to get the code removed.',
  'company_pay_roll.form.rule_pay_roll_run_approval.required': 'Approval type must be selected',
  'company_pay_roll.form.section.approval_notifications': 'Approval notifications',
  'company_pay_roll.form.section.approvers_reviewers': 'Multiple approvers & payroll review',
  'company_pay_roll.form.section.income_reports': 'Income reports',
  'company_pay_roll.form.section.notifications': 'Notifications',
  'company_pay_roll.header.approval_code.button': 'Approval code',
  'company_pay_roll.header.approval_code.enabled': 'Approval code is enabled',
  'company_pay_roll.header.deviations': 'Deviations',
  'company_pay_roll.header.nets_messages': 'See messages from MasterCard Payment Services',
  'company_pay_roll.header.salary_types': 'Modify wage types',
  'company_pay_roll.header.user_notifications': 'Notifications',
  'company_pay_roll.title': 'Payroll settings',

  'company_tax_cards.table.header.deduction_monthly': 'Mthly deduct.',
  'company_tax_cards.table.header.employee_number': 'Employee no.',
  'company_tax_cards.table.header.max_aincome_free_card': 'Tax free amount',
  'company_tax_cards.table.header.name': 'Name',
  'company_tax_cards.table.header.national_id': 'CPR number',
  'company_tax_cards.table.header.tax_card_type': 'Type',
  'company_tax_cards.table.header.tax_percentage': 'Percentage',
  'company_tax_cards.title': 'Active tax cards',

  'company_users.add.error.no_departments': 'Select at least one department to invite a department manager.',
  'company_users.add.form.departments.intro':
    "Access to a department gives access to view the department's employees and approve their hours, transport allowance and outlays.",
  'company_users.add.form.departments.title': 'Departments',
  'company_users.add.form.email': 'Email',
  'company_users.add.form.email.invalid': 'Email is invalid',
  'company_users.add.form.email.required': 'Email is required',
  'company_users.add.form.name': 'Name',
  'company_users.add.form.name.required': 'Name is required',
  'company_users.add.form.permissions.intro': 'With no permissions, the user cannot approve payrolls.',
  'company_users.add.form.permissions.title': 'Extra permissions',
  'company_users.add.form.read_only_note':
    'A user with read only access, can see all parts of Salary, but not make any changes.',
  'company_users.add.form.submit.approve_only': 'Create user',
  'company_users.add.form.submit.regular': 'Send invitation now',
  'company_users.add.form.user_type': 'Role',
  'company_users.add.form.user_type.placeholder': 'Select role',
  'company_users.add.form.user_type.required': 'Role is required',
  'company_users.add.intro':
    'If you wish to provide an accountant, bookkeeper or similar access to your company, you can invite them through their email here.',
  'company_users.add.title': 'Invite new user',
  'company_users.edit_departments.alert.success': 'Changes to {name} have been saved',
  'company_users.edit_departments.form.can_write.with_see_employment': 'Create/edit hours, pay, etc.',
  'company_users.edit_departments.form.can_write.without_see_employment': 'Create/edit hours, etc.',
  'company_users.edit_departments.form.departments': 'Departments',
  'company_users.edit_departments.form.has_access': '(approve hours, absence & outlay)',
  'company_users.edit_departments.form.see_employment': 'See employment contracts and remuneration',
  'company_users.edit_departments.title': 'Department access for {name}',
  'company_users.edit.title': 'Permissions for {name}',
  'company_users.header.add_user': 'Add user',
  'company_users.invite_table.actions.delete': 'Delete invitation',
  'company_users.invite_table.header.created_at': 'Sent',
  'company_users.invite_table.header.email': 'Email',
  'company_users.invite_table.title.many': '{count} invitations',
  'company_users.invite_table.title.one': '{count} invitation',
  'company_users.table.actions.delete_access': 'Delete access',
  'company_users.table.actions.edit_departments': 'Edit departments',
  'company_users.table.actions.edit_permissions': 'Edit permissions',
  'company_users.table.department_manager': 'Department manager',
  'company_users.table.departments.many': '{count} departments',
  'company_users.table.departments.one': '{count} department',
  'company_users.table.header.created_at': 'User since',
  'company_users.table.header.email': 'Email',
  'company_users.table.header.name': 'Name',
  'company_users.table.header.permissions': 'Permissions',
  'company_users.table.title.many': '{count} users',
  'company_users.table.title.one': '{count} user',

  'company.company_size.large': 'Large (A-tax over 1,000,000 kr or AM contribution over 250,000 kr annually)',
  'company.company_size.small': 'Small (A-tax under 1,000,000 kr and AM contribution under 250,000 kr annually)',
  'company.disposition_date_rule.next_friday': 'Friday after the payroll period',
  'company.disposition_date_rule.next_monday': 'Monday after the payroll period',
  'company.disposition_date_rule.next_thursday': 'Thursday after the payroll period',
  'company.disposition_date_rule.next_tuesday': 'Tuesday after the payroll period',
  'company.disposition_date_rule.next_wednesday': 'Wednesday after the payroll period',
  'company.disposition_date_rule.period_end': 'Last bank day in the payroll period',
  'company.edit.alert.success': 'Changes to {name} have been saved',
  'company.edit.form.address': 'Address',
  'company.edit.form.auto_approve_car_allowances': 'Auto approve all transport allowance registered by employees',
  'company.edit.form.auto_approve_compensations': 'Auto approve all compensations registered by freelancers',
  'company.edit.form.auto_approve_description':
    'Note: You can still delete registrations you do not believe should be paid.',
  'company.edit.form.auto_approve_reimbursement_vouchers': 'Auto approve all outlays registered by employees',
  'company.edit.form.auto_approve_salary_registrations': 'Auto approve all pay registrations registered by employees',
  'company.edit.form.auto_approve_time_registration_hours': 'Auto approve all time registrations by employees',
  'company.edit.form.auto_approve_time_registration_leave': 'Auto approve all absence registrations by employees',
  'company.edit.form.city': 'City',
  'company.edit.form.default_biweekly_cycle_id': 'Payroll period for fortnightly (14 days)',
  'company.edit.form.default_biweekly_cycle_id.placeholder': 'Select cycle',
  'company.edit.form.default_hourly_monthly_cycle_id': 'Payroll period for waged employees',
  'company.edit.form.default_hourly_monthly_cycle_id.option.no_offset': 'From the first to the last day of the month',
  'company.edit.form.default_hourly_monthly_cycle_id.option.with_offset':
    'From the {from} to the {to} day of the month',
  'company.edit.form.default_hourly_monthly_cycle_id.placeholder': 'Select cycle',
  'company.edit.form.family_leave_fund': 'Family leave',
  'company.edit.form.name': 'Name',
  'company.edit.form.national_id': 'CVR number',
  'company.edit.form.offset_disposition_biweekly': 'Disposition date for fortnightly (14 days) payrolls',
  'company.edit.form.offset_disposition_biweekly.select': 'Select',
  'company.edit.form.offset_disposition_monthly': 'Disposition date for monthly payrolls',
  'company.edit.form.offset_disposition_monthly.rule.0': 'Last bank day of the month',
  'company.edit.form.offset_disposition_monthly.rule.after.many':
    '{count} bank days after the last bank day of the month',
  'company.edit.form.offset_disposition_monthly.rule.after.one':
    '{count} bank day after the last bank day of the month',
  'company.edit.form.offset_disposition_monthly.rule.before.many':
    '{count} bank days before the last bank day of the month',
  'company.edit.form.offset_disposition_monthly.rule.before.one':
    '{count} bank day before the last bank day of the month',
  'company.edit.form.offset_disposition_monthly.select': 'Select',
  'company.edit.form.offset_disposition_weekly': 'Disposition date for weekly payrolls',
  'company.edit.form.offset_disposition_weekly.select': 'Select',
  'company.edit.form.offset_disposition.description':
    'When changing the disposition dates settings, it will take effect by the next payroll. For ongoing payrolls, the disposition date will not be altered and should be edited manually on the specific payroll.',
  'company.edit.form.pay_slip_transport_default': 'Standard payslip delivery method',
  'company.edit.form.pay_slip_transport_default.batch_note':
    'You can use {link} to move all your employees to another Digital Post inbox.',
  'company.edit.form.pay_slip_transport_default.batch_note_link': 'employee batch',
  'company.edit.form.pay_slip_transport_default.eboks': 'e-Boks',
  'company.edit.form.pay_slip_transport_default.email': 'Email',
  'company.edit.form.pay_slip_transport_default.mit_dk': 'mit.dk',
  'company.edit.form.pay_slip_transport_default.none': 'None',
  'company.edit.form.postal_code': 'Postcode',
  'company.edit.form.tax_size': 'Company size',
  'company.edit.form.tax_size.select': 'Select size',
  'company.edit.form.validation.address.required': 'Address is required',
  'company.edit.form.validation.city.required': 'City is required',
  'company.edit.form.validation.default_biweekly_cycle_id.required':
    'Payroll period for fortnightly (14 days) is required',
  'company.edit.form.validation.default_hourly_monthly_cycle_id.required':
    'Payroll period for waged employees is required',
  'company.edit.form.validation.name.required': 'Name is required',
  'company.edit.form.validation.national_id.in_use': 'CVR number is already in use',
  'company.edit.form.validation.national_id.invalid': 'CVR number is invalid',
  'company.edit.form.validation.national_id.required': 'CVR number is required',
  'company.edit.form.validation.offset_disposition_biweekly.required':
    'Disposition date for fortnightly (14 days) payrolls is required',
  'company.edit.form.validation.offset_disposition_monthly.invalid':
    'Disposition date for monthly payrolls is required',
  'company.edit.form.validation.offset_disposition_weekly.required': 'Disposition date for weekly payrolls is required',
  'company.edit.form.validation.postal_code.invalid': 'Postcode is invalid',
  'company.edit.form.validation.postal_code.required': 'Postcode is required',
  'company.edit.form.validation.tax_size.required': 'Company size is required',
  'company.edit.header.advanced_settings': 'Advanced',
  'company.edit.header.pay_slip_fields': 'Change payslip appearance',
  'company.edit.header.production_units': 'Production units',
  'company.edit.header.title': 'Company',
  'company.edit.header.users': 'User administration',
  'company.edit.header.vacation_settings': 'Holiday settings',
  'company.menu.api_keys': 'API keys',
  'company.menu.club': 'Benefit club',
  'company.menu.company': 'Company',
  'company.menu.departments': 'Departments',
  'company.menu.invoices': 'Invoices',
  'company.menu.pay': 'Payroll settings',
  'company.menu.pricing': 'Price plan',
  'company.menu.reports': 'Reports',
  'company.menu.support': 'Support',
  'company.menu.transfers': 'Transfers',

  'contract_book.alert.success': 'Changes to {name} have been saved',
  'contract_book.form.add_integration': 'Add ContractBook integration',
  'contract_book.form.api_key': 'API key',
  'contract_book.form.callback_url': 'Callback URL',
  'contract_book.form.delete': 'Delete',
  'contract_book.form.header.api_key': 'API key',
  'contract_book.form.header.callback_url': 'Callback URL',
  'contract_book.form.header.delete': 'Delete?',
  'contract_book.table.actions.delete': 'Hide contract',
  'contract_book.table.header.contract_name': 'Contract',
  'contract_book.table.header.signee_name': 'Employee',
  'contract_book.table.header.updated_at': 'Updated at',
  'contract_book.unsigned_contracts': 'Signed contracts',

  'contract_delete.introduction.end_date': 'Are you sure you want to delete the contract valid from {from} to {to}?',
  'contract_delete.introduction.no_end_date': 'Are you sure you want to delete the contract valid from {from}?',
  'contract_delete.submit': 'Delete contract',
  'contract_delete.title.future': 'Delete this future contract?',
  'contract_delete.title.ordinary': 'Delete this contract?',
  'contract_delete.warning': 'The contract will be deleted immediately.',
  'contract_delete.warning.no_previous_contract':
    'Since there is no contract immediately before this contract, its deletion will have no influence on other contracts.',
  'contract_delete.warning.previous_contract.end_date_changed':
    'The contract which is valid to the day before this one begins, which is valid from {from} to {to}, will have its valid to date changed to {to_new}.',
  'contract_delete.warning.previous_contract.end_date_removed':
    'The contract which is valid to the day before this one begins, which is valid from {from} to {to}, will have its valid to date removed.',

  'contract_delta.field.car_allowance_rate': 'Transport allowance rate',
  'contract_delta.field.car_allowance_rate.format': '{amount}/km',
  'contract_delta.field.car_allowance_registration_method': 'Transport registration method',
  'contract_delta.field.car_allowance_registration_method.coarse': 'Coarse',
  'contract_delta.field.car_allowance_registration_method.detailed': 'Detailed',
  'contract_delta.field.extra_tax_percentage': 'Extra tax percantage',
  'contract_delta.field.family_leave_fund': 'Family leave',
  'contract_delta.field.period_work_hours': 'Hours registered',
  'contract_delta.field.position': 'Position',
  'contract_delta.field.salary_cycle_id': 'Payroll period',
  'contract_delta.field.time_registration_method_type': 'Time registration method',
  'contract_delta.field.time_registration_method_type.coarse': 'Coarse',
  'contract_delta.field.time_registration_method_type.detailed': 'Detailed',
  'contract_delta.field.type': 'Employment',
  'contract_delta.field.type.ordinary': 'Salaried',
  'contract_delta.field.vacation_type_change': 'Changed to new Holiday Act',
  'contract_delta.field.weekly_hours': 'Weekly work hours',
  'contract_delta.field.work_cycle': 'Work week',
  'contract_delta.field.work_cycle_anchor_date': 'Work week anchor date',

  'contract_history.actions.new_future_contract': 'Create future contract',
  'contract_history.actions.remove_leave': 'Remove',
  'contract_history.history.change.no_change': 'No change',
  'contract_history.history.leave': 'Leave',
  'contract_history.history.range_format': '{from} - {to}',
  'contract_history.history.state.active': 'Active',
  'contract_history.history.state.expired': 'Expired',
  'contract_history.history.state.future': 'Future',
  'contract_history.history.state.initial': 'first contract',
  'contract_history.history.state.last_edit': 'last change by {name}',
  'contract_history.title': 'Employment timeline',

  'contract_template.add.select.form.contract_id': 'Start contract',
  'contract_template.add.select.form.contract_id.option.active': '{from} - {to} (active)',
  'contract_template.add.select.form.contract_id.option.other': '{from} - {to}',
  'contract_template.add.select.form.contract_id.placeholder': 'Select start contract',
  'contract_template.add.select.form.contract_id.required': 'Start contract is required',
  'contract_template.add.select.form.submit': 'Select contract',
  'contract_template.add.step.0.buttons.back': 'Back',
  'contract_template.add.step.0.employee_select': 'Select',
  'contract_template.add.step.0.intro':
    'To create a template, you first pick an employee to base the template on.  Later you will be able to review the template before you create it.',
  'contract_template.add.step.0.title': 'Step 1',
  'contract_template.add.step.1.intro':
    'The employee has a contract timeline, so you will need to select a start contract on which to base their template.  You will afterwards be able to select whether the succeeding contracts shall be included in the template as scheduled changes.',
  'contract_template.add.step.1.title': 'Step 2',
  'contract_template.add.step.2.intro.line_1':
    'Review the template and make sure it is as you expect. If not, then select a different employee, or {link}, and then return here to attempt creating the template again.',
  'contract_template.add.step.2.intro.line_1.link': 'edit this employee',
  'contract_template.add.step.2.intro.line_2':
    'All the fields described below are those that will be included on the template, but after the employee has been created, you can always adjust the fields as normal.',
  'contract_template.add.step.2.title': 'Step 3',
  'contract_template.add.steps.0': 'Select employee',
  'contract_template.add.steps.1': 'Select start contract',
  'contract_template.add.steps.2': 'Review template',
  'contract_template.add.steps.intro': 'After only three steps, you will have created an employee template',
  'contract_template.add.steps.title': 'New employee template',
  'contract_template.alert.success': 'The employee template have been saved',
  'contract_template.back': 'Back',
  'contract_template.edit.back': 'Back',
  'contract_template.edit.delete': 'Delete template',
  'contract_template.edit.intro':
    'Here you can review the existing template. There is no option to modify the template. If you wish to modify it, delete it and create a new one based on an employee with the correct values.',
  'contract_template.edit.title': 'Employee template',
  'contract_template.form.back': 'Back',
  'contract_template.form.description': 'Description',
  'contract_template.form.description.required': 'Description is required',
  'contract_template.form.false': 'No',
  'contract_template.form.fields.benefits.none': 'None',
  'contract_template.form.fields.benefits.title': 'Employee benefits',
  'contract_template.form.fields.benefits.value': 'Value',
  'contract_template.form.fields.contract.car_allowance_registration_method_type': 'Transport registration method',
  'contract_template.form.fields.contract.car_allowance_registration_method_type.coarse': 'Coarse',
  'contract_template.form.fields.contract.car_allowance_registration_method_type.detailed': 'Detailed',
  'contract_template.form.fields.contract.day_laborer': 'Other holiday calculation',
  'contract_template.form.fields.contract.day_laborer.days': 'Per day',
  'contract_template.form.fields.contract.day_laborer.hours': 'Per hour',
  'contract_template.form.fields.contract.position': 'Position',
  'contract_template.form.fields.contract.salary_cycle': 'Payroll cycle',
  'contract_template.form.fields.contract.time_registration_method_type': 'Time registration',
  'contract_template.form.fields.contract.time_registration_method_type.coarse': 'Coarse',
  'contract_template.form.fields.contract.time_registration_method_type.detailed': 'Detailed',
  'contract_template.form.fields.contract.title': 'Employment',
  'contract_template.form.fields.contract.work_week': 'Work week',
  'contract_template.form.fields.employee.department': 'Department',
  'contract_template.form.fields.employee.department.none': 'None',
  'contract_template.form.fields.employee.director': 'Executive pay?',
  'contract_template.form.fields.employee.employee_type': 'Employee type',
  'contract_template.form.fields.employee.income_type': 'Income type',
  'contract_template.form.fields.employee.language': 'Language',
  'contract_template.form.fields.employee.pay_slip_transport': 'Payslip delivery method',
  'contract_template.form.fields.employee.pay_slip_transport.e_boks': 'e-Boks',
  'contract_template.form.fields.employee.pay_slip_transport.email': 'Email',
  'contract_template.form.fields.employee.pay_slip_transport.mit_dk': 'mit.dk',
  'contract_template.form.fields.employee.pay_slip_transport.sms': 'Text message',
  'contract_template.form.fields.employee.preferred_tax_card_type': 'Primary tax card',
  'contract_template.form.fields.employee.title': 'Employee level',
  'contract_template.form.fields.employee.transport_destination_type': 'Account transfer',
  'contract_template.form.fields.leave.none': 'None',
  'contract_template.form.fields.leave.title': 'Absence',
  'contract_template.form.fields.pension.none': 'None',
  'contract_template.form.fields.pension.title': 'Pension',
  'contract_template.form.fields.remuneration.header.include': 'Include',
  'contract_template.form.fields.remuneration.header.rate': 'Rate',
  'contract_template.form.fields.remuneration.header.type': 'Remuneration',
  'contract_template.form.fields.remuneration.intro':
    'Per default, remuneration is {not} included on a template, but you can include them, by ticking "include".',
  'contract_template.form.fields.remuneration.intro.not': 'not',
  'contract_template.form.fields.remuneration.title': 'Remuneration',
  'contract_template.form.fields.supplements.none': 'None',
  'contract_template.form.fields.supplements.title': 'Supplements',
  'contract_template.form.frequency.bi_weekly': 'fortnights (14 days)',
  'contract_template.form.frequency.default': 'payroll periods',
  'contract_template.form.frequency.monthly': 'months',
  'contract_template.form.frequency.weekly': 'weeks',
  'contract_template.form.future.change.include': 'Include',
  'contract_template.form.future.change.title': '{number} change',
  'contract_template.form.future.change.type.base': 'Changed',
  'contract_template.form.future.change.type.decrease': 'Decrease',
  'contract_template.form.future.change.type.increase': 'Increase',
  'contract_template.form.future.change.type.remove': 'Remove',
  'contract_template.form.future.change.valid_from': 'Valid from',
  'contract_template.form.future.change.valid_from.invalid': 'Valid from is invalid',
  'contract_template.form.future.change.valid_from.note.from_employment':
    'After {number} {period} from the employment date.',
  'contract_template.form.future.change.valid_from.note.from_last_change':
    'After {number} {period} from the last change.',
  'contract_template.form.future.change.valid_from.required': 'valid after is required',
  'contract_template.form.future.header.changes': 'Changes',
  'contract_template.form.future.header.due_date': 'Due date',
  'contract_template.form.future.intro':
    "The employee has at least one employment changes, which you can include as part of the template's planned changes. Select which changes you wish to include, and when they should activate.",
  'contract_template.form.future.title': 'Planned changes',
  'contract_template.form.submit.create': 'Create',
  'contract_template.form.submit.update': 'Update',
  'contract_template.form.title': 'Title',
  'contract_template.form.title.required': 'Title is required',
  'contract_template.form.true': 'Yes',
  'contract_template.header.new_template': 'New employee template',
  'contract_template.intro':
    'With employee templates you can save previously filled in information for an employee and their employment, so next time you create an employee you can select a template to prefill the fields. You can always adjust the fields individually afterwards. All the fields that are common for an employment - i.e. not personal - can be saved.',
  'contract_template.table.actions.delete': 'Delete',
  'contract_template.table.actions.details': 'Show details',
  'contract_template.table.header.description': 'Description',
  'contract_template.table.header.title': 'Title',
  'contract_template.title': 'Employee templates',

  'contract_valid_from_form.valid_from': 'Valid from',
  'contract_valid_from_form.valid_from.invalid': 'Valid from is invalid',
  'contract_valid_from_form.valid_from.not_after_termination_date':
    'Valid from cannot be after the termination date ({date})',
  'contract_valid_from_form.valid_from.not_before_date': 'Valid from cannot be before {date}',
  'contract_valid_from_form.valid_from.not_before_employment_date': 'Valid from cannot be before the employment date',
  'contract_valid_from_form.valid_from.not_before_start_of_last_year':
    'Valid from cannot be before the beginning of last year',
  'contract_valid_from_form.valid_from.not_same_or_after_valid_to':
    "Valid from cannot be the same or after the contract's valid to ({date})",
  'contract_valid_from_form.valid_from.required': 'Valid from is required',

  'contract.first_salary_period.valid_from': 'First payroll period',
  'contract.first_salary_period.valid_from.not_after_end_date': 'First period cannot be after the termination date',
  'contract.first_salary_period.valid_from.placeholder': 'Select period',
  'contract.first_salary_period.valid_from.required': 'First period is required',
  'contract.position.employment_position_id': 'Position (DISCO-08)',
  'contract.position.position': 'Position description',
  'contract.position.position.required': 'Position description is required',
  'contract.workplace.production_unit_id': 'Production unit',
  'contract.workplace.production_unit_id.placeholder': 'Select production unit',
  'contract.workplace.production_unit_id.required': 'Production unit is required',

  'contracts_add.alert.success': '{name} now has an employment',
  'contracts_add.benefits_step.back': 'Back',
  'contracts_add.benefits_step.submit': 'Continue to holiday',
  'contracts_add.benefits_step.title': 'Employee benefits',
  'contracts_add.employment_step.intro':
    'Note that this is not the valid contract for the employee. You need to fill in the details from the active contract with the employee. You can always edit the information later, or create a new contract for the employee.',
  'contracts_add.employment_step.submit': 'Continue to remuneration',
  'contracts_add.employment_step.title': 'Fill in the details for the contract',
  'contracts_add.future_contract_step.back': 'Back',
  'contracts_add.future_contract_step.form.include': 'Include',
  'contracts_add.future_contract_step.intro':
    'There are future contracts on this template. Remember that you can always edit the future changes manually.',
  'contracts_add.future_contract_step.submit': 'Save employment',
  'contracts_add.future_contract_step.title': 'Future changes',
  'contracts_add.pay_step.back': 'Back',
  'contracts_add.pay_step.submit': 'Continue to employee benefits',
  'contracts_add.pay_step.title': 'How much will {name} be paid?',
  'contracts_add.pension_step.back': 'Back',
  'contracts_add.pension_step.choice.title': 'SH days and Fritvalg account',
  'contracts_add.pension_step.pension.title': 'Pension',
  'contracts_add.pension_step.submit.final': 'Save employment',
  'contracts_add.pension_step.submit.to_future': 'Continue to future changes',
  'contracts_add.pension_step.title': 'Add pension',
  'contracts_add.step_0.title': 'Step 1',
  'contracts_add.step_1.title': 'Step 2',
  'contracts_add.step_2.title': 'Step 3',
  'contracts_add.step_3.title': 'Step 4',
  'contracts_add.step_4.title': 'Step 5',
  'contracts_add.step_5.title': 'Step 6',
  'contracts_add.steps.0': 'Employment',
  'contracts_add.steps.1': 'Remuneration',
  'contracts_add.steps.2': 'Benefits',
  'contracts_add.steps.3': 'Holiday',
  'contracts_add.steps.4': 'Pension',
  'contracts_add.steps.5': 'Future changes',
  'contracts_add.steps.contract_book.intro': 'Below you will find the fields, we cannot automatically fill.',
  'contracts_add.steps.contract_book.missing': '[not filled]',
  'contracts_add.steps.contract_book.title': 'ContractBook fields',
  'contracts_add.steps.help_note':
    'If you have any issues creating the employment, you can always contact {email}. We look forward to helping you!',
  'contracts_add.steps.intro': 'After these {steps} steps, you will have created an employment for {name}',
  'contracts_add.steps.title': 'Employment creation',
  'contracts_add.vacation_step.back': 'Back',
  'contracts_add.vacation_step.submit': 'Continue to pension',
  'contracts_add.vacation_step.title': 'Holiday',

  'country_code.ad': 'Andorra',
  'country_code.ae': 'United Arab Emirates',
  'country_code.af': 'Afghanistan',
  'country_code.ag': 'Antigua and Barbuda',
  'country_code.ai': 'Anguilla',
  'country_code.al': 'Albania',
  'country_code.am': 'Armenia',
  'country_code.ao': 'Angola',
  'country_code.aq': 'Antarctica',
  'country_code.ar': 'Argentina',
  'country_code.as': 'American Samoa',
  'country_code.at': 'Austria',
  'country_code.au': 'Australia',
  'country_code.aw': 'Aruba',
  'country_code.ax': 'Åland Islands',
  'country_code.az': 'Azerbaijan',
  'country_code.ba': 'Bosnia and Herzegovina',
  'country_code.bb': 'Barbados',
  'country_code.bd': 'Bangladesh',
  'country_code.be': 'Belgium',
  'country_code.bf': 'Burkina Faso',
  'country_code.bg': 'Bulgaria',
  'country_code.bh': 'Bahrain',
  'country_code.bi': 'Burundi',
  'country_code.bj': 'Benin',
  'country_code.bl': 'Saint Barthélemy',
  'country_code.bm': 'Bermuda',
  'country_code.bn': 'Brunei',
  'country_code.bo': 'Bolivia',
  'country_code.bq': 'Bonaire, Sint Eustatius and Saba',
  'country_code.br': 'Brazil',
  'country_code.bs': 'Bahamas',
  'country_code.bt': 'Bhutan',
  'country_code.bv': 'Bouvet Island',
  'country_code.bw': 'Botswana',
  'country_code.by': 'Belarus',
  'country_code.bz': 'Belize',
  'country_code.ca': 'Canada',
  'country_code.cc': 'Cocos (Keeling) Islands',
  'country_code.cd': 'Congo (Democratic Republic of the)',
  'country_code.cf': 'Central African Republic',
  'country_code.cg': 'Congo',
  'country_code.ch': 'Switzerland',
  'country_code.ci': "Côte d'Ivoire",
  'country_code.ck': 'Cook Islands',
  'country_code.cl': 'Chile',
  'country_code.cm': 'Cameroon',
  'country_code.cn': 'China',
  'country_code.co': 'Colombia',
  'country_code.cr': 'Costa Rica',
  'country_code.cu': 'Cuba',
  'country_code.cv': 'Cabo Verde',
  'country_code.cw': 'Curaçao',
  'country_code.cx': 'Christmas Island',
  'country_code.cy': 'Cyprus',
  'country_code.cz': 'Czechia',
  'country_code.de': 'Germany',
  'country_code.dj': 'Djibouti',
  'country_code.dk': 'Denmark',
  'country_code.dm': 'Dominica',
  'country_code.do': 'Dominican Republic',
  'country_code.dz': 'Algeria',
  'country_code.ec': 'Ecuador',
  'country_code.ee': 'Estonia',
  'country_code.eg': 'Egypt',
  'country_code.eh': 'Western Sahara',
  'country_code.er': 'Eritrea',
  'country_code.es': 'Spain',
  'country_code.et': 'Ethiopia',
  'country_code.fi': 'Finland',
  'country_code.fj': 'Fiji',
  'country_code.fk': 'Falkland Islands (Malvinas)',
  'country_code.fm': 'Micronesia',
  'country_code.fo': 'Faroe Islands',
  'country_code.fr': 'France',
  'country_code.ga': 'Gabon',
  'country_code.gb': 'United Kingdom of Great Britain and Northern Ireland',
  'country_code.gd': 'Grenada',
  'country_code.ge': 'Georgia',
  'country_code.gf': 'French Guiana',
  'country_code.gg': 'Guernsey',
  'country_code.gh': 'Ghana',
  'country_code.gi': 'Gibraltar',
  'country_code.gl': 'Greenland',
  'country_code.gm': 'Gambia',
  'country_code.gn': 'Guinea',
  'country_code.gp': 'Guadeloupe',
  'country_code.gq': 'Equatorial Guinea',
  'country_code.gr': 'Greece',
  'country_code.gs': 'South Georgia and the South Sandwich Islands',
  'country_code.gt': 'Guatemala',
  'country_code.gu': 'Guam',
  'country_code.gw': 'Guinea-Bissau',
  'country_code.gy': 'Guyana',
  'country_code.hk': 'Hong Kong',
  'country_code.hm': 'Heard Island and McDonald Islands',
  'country_code.hn': 'Honduras',
  'country_code.hr': 'Croatia',
  'country_code.ht': 'Haiti',
  'country_code.hu': 'Hungary',
  'country_code.id': 'Indonesia',
  'country_code.ie': 'Ireland',
  'country_code.il': 'Israel',
  'country_code.im': 'Isle of Man',
  'country_code.in': 'India',
  'country_code.io': 'British Indian Ocean Territory',
  'country_code.iq': 'Iraq',
  'country_code.ir': 'Iran',
  'country_code.is': 'Iceland',
  'country_code.it': 'Italy',
  'country_code.je': 'Jersey',
  'country_code.jm': 'Jamaica',
  'country_code.jo': 'Jordan',
  'country_code.jp': 'Japan',
  'country_code.ke': 'Kenya',
  'country_code.kg': 'Kyrgyzstan',
  'country_code.kh': 'Cambodia',
  'country_code.ki': 'Kiribati',
  'country_code.km': 'Comoros',
  'country_code.kn': 'Saint Kitts and Nevis',
  'country_code.kp': 'Korea, North',
  'country_code.kr': 'Korea, South',
  'country_code.kw': 'Kuwait',
  'country_code.ky': 'Cayman Islands',
  'country_code.kz': 'Kazakhstan',
  'country_code.la': 'Laos',
  'country_code.lb': 'Lebanon',
  'country_code.lc': 'Saint Lucia',
  'country_code.li': 'Liechtenstein',
  'country_code.lk': 'Sri Lanka',
  'country_code.lr': 'Liberia',
  'country_code.ls': 'Lesotho',
  'country_code.lt': 'Lithuania',
  'country_code.lu': 'Luxembourg',
  'country_code.lv': 'Latvia',
  'country_code.ly': 'Libya',
  'country_code.ma': 'Morocco',
  'country_code.mc': 'Monaco',
  'country_code.md': 'Moldova',
  'country_code.me': 'Montenegro',
  'country_code.mf': 'Saint Martin (French part)',
  'country_code.mg': 'Madagascar',
  'country_code.mh': 'Marshall Islands',
  'country_code.mk': 'North Macedonia',
  'country_code.ml': 'Mali',
  'country_code.mm': 'Myanmar',
  'country_code.mn': 'Mongolia',
  'country_code.mo': 'Macao',
  'country_code.mp': 'Northern Mariana Islands',
  'country_code.mq': 'Martinique',
  'country_code.mr': 'Mauritania',
  'country_code.ms': 'Montserrat',
  'country_code.mt': 'Malta',
  'country_code.mu': 'Mauritius',
  'country_code.mv': 'Maldives',
  'country_code.mw': 'Malawi',
  'country_code.mx': 'Mexico',
  'country_code.my': 'Malaysia',
  'country_code.mz': 'Mozambique',
  'country_code.na': 'Namibia',
  'country_code.nc': 'New Caledonia',
  'country_code.ne': 'Niger',
  'country_code.nf': 'Norfolk Island',
  'country_code.ng': 'Nigeria',
  'country_code.ni': 'Nicaragua',
  'country_code.nl': 'Netherlands',
  'country_code.no': 'Norway',
  'country_code.np': 'Nepal',
  'country_code.nr': 'Nauru',
  'country_code.nu': 'Niue',
  'country_code.nz': 'New Zealand',
  'country_code.om': 'Oman',
  'country_code.pa': 'Panama',
  'country_code.pe': 'Peru',
  'country_code.pf': 'French Polynesia',
  'country_code.pg': 'Papua New Guinea',
  'country_code.ph': 'Philippines',
  'country_code.pk': 'Pakistan',
  'country_code.pl': 'Poland',
  'country_code.pm': 'Saint Pierre and Miquelon',
  'country_code.pn': 'Pitcairn',
  'country_code.pr': 'Puerto Rico',
  'country_code.ps': 'Palestine',
  'country_code.pt': 'Portugal',
  'country_code.pw': 'Palau',
  'country_code.py': 'Paraguay',
  'country_code.qa': 'Qatar',
  'country_code.re': 'Réunion',
  'country_code.ro': 'Romania',
  'country_code.rs': 'Serbia',
  'country_code.ru': 'Russia',
  'country_code.rw': 'Rwanda',
  'country_code.sa': 'Saudi Arabia',
  'country_code.sb': 'Solomon Islands',
  'country_code.sc': 'Seychelles',
  'country_code.sd': 'Sudan',
  'country_code.se': 'Sweden',
  'country_code.sg': 'Singapore',
  'country_code.sh': 'Saint Helena, Ascension and Tristan da Cunha',
  'country_code.si': 'Slovenia',
  'country_code.sj': 'Svalbard and Jan Mayen',
  'country_code.sk': 'Slovakia',
  'country_code.sl': 'Sierra Leone',
  'country_code.sm': 'San Marino',
  'country_code.sn': 'Senegal',
  'country_code.so': 'Somalia',
  'country_code.sr': 'Suriname',
  'country_code.ss': 'South Sudan',
  'country_code.st': 'Sao Tome and Principe',
  'country_code.sv': 'El Salvador',
  'country_code.sx': 'Sint Maarten (Dutch part)',
  'country_code.sy': 'Syria',
  'country_code.sz': 'Eswatini',
  'country_code.tc': 'Turks and Caicos Islands',
  'country_code.td': 'Chad',
  'country_code.tf': 'French Southern Territories',
  'country_code.tg': 'Togo',
  'country_code.th': 'Thailand',
  'country_code.tj': 'Tajikistan',
  'country_code.tk': 'Tokelau',
  'country_code.tl': 'Timor-Leste',
  'country_code.tm': 'Turkmenistan',
  'country_code.tn': 'Tunisia',
  'country_code.to': 'Tonga',
  'country_code.tr': 'Turkey',
  'country_code.tt': 'Trinidad and Tobago',
  'country_code.tv': 'Tuvalu',
  'country_code.tw': 'Taiwan',
  'country_code.tz': 'Tanzania',
  'country_code.ua': 'Ukraine',
  'country_code.ug': 'Uganda',
  'country_code.um': 'United States Minor Outlying Islands',
  'country_code.us': 'United States of America',
  'country_code.uy': 'Uruguay',
  'country_code.uz': 'Uzbekistan',
  'country_code.va': 'Holy See',
  'country_code.vc': 'Saint Vincent and the Grenadines',
  'country_code.ve': 'Venezuela',
  'country_code.vg': 'Virgin Islands (British)',
  'country_code.vi': 'Virgin Islands (U.S.)',
  'country_code.vn': 'Vietnam',
  'country_code.vu': 'Vanuatu',
  'country_code.wf': 'Wallis and Futuna',
  'country_code.ws': 'Samoa',
  'country_code.x1': 'Only Danish contact address',
  'country_code.x2': 'No known address',
  'country_code.xi': 'Northern Ireland',
  'country_code.xu': 'Great Britain (United Kingdom without Northern Ireland)',
  'country_code.ye': 'Yemen',
  'country_code.yt': 'Mayotte',
  'country_code.za': 'South Africa',
  'country_code.zm': 'Zambia',
  'country_code.zw': 'Zimbabwe',

  'create_future_contract.form.contract_id': 'Copy an existing contract to be the basis for the future contract',
  'create_future_contract.form.contract_id.contract_state.active': '(active)',
  'create_future_contract.form.contract_id.contract_state.active_viewing': '(active, selected)',
  'create_future_contract.form.contract_id.contract_state.viewing': '(selected)',
  'create_future_contract.form.contract_id.invalid': 'Contract template is invalid',
  'create_future_contract.form.contract_id.placeholder': 'Contract template',
  'create_future_contract.form.contract_id.required': 'Contract template is required',
  'create_future_contract.form.submit': 'Create future contract',
  'create_future_contract.form.valid_from': 'Date the future contract shall be valid from',
  'create_future_contract.form.valid_from.invalid': 'From date is invalid',
  'create_future_contract.form.valid_from.not_before_or_same_as_active':
    "From date cannot be before or the same as the active contract's valid from",
  'create_future_contract.form.valid_from.placeholder': 'From date',
  'create_future_contract.form.valid_from.required': 'From date is required',
  'create_future_contract.form.warning.line_1': 'The future contract will be saved immediately.',
  'create_future_contract.form.warning.line_2':
    'You can modify the future contract immediately after you\'ve created it, or later by selecting it in the "Select contract" list.',
  'create_future_contract.title': 'Create future contract',

  'dashboard.anniversaries.anniversary': 'anniversary',
  'dashboard.anniversaries.anniversary_wrapper': '{year}{ordinal_suffix} {word}',
  'dashboard.anniversaries.birthday': 'birthday',
  'dashboard.anniversaries.birthday_wrapper': '{year}{ordinal_suffix} {word}',
  'dashboard.anniversaries.empty': 'There are no upcoming birthdays or anniversaries',
  'dashboard.anniversaries.load_more': 'Load {number} more',
  'dashboard.anniversaries.load_more_out_of': 'out of {number} more',
  'dashboard.anniversaries.load_more.five': 'five',
  'dashboard.anniversaries.title': 'Birthdays and anniversaries',
  'dashboard.main_boxes.average_transfers_last_year': 'Monthly pay expense (avg.)',
  'dashboard.main_boxes.employee_awaiting.many': 'Employees awaiting employments',
  'dashboard.main_boxes.employee_awaiting.one': 'Employee awaiting employments',
  'dashboard.main_boxes.employee.many': 'Employees',
  'dashboard.main_boxes.employee.one': 'Employee',
  'dashboard.main_boxes.total_transfers_this_year': 'Annual pay expense',
  'dashboard.notifications.fix_credit_card_integration': 'Card payment failed',
  'dashboard.notifications.invalid_national_id': 'Invalid CPR-number for {name}',
  'dashboard.notifications.missing_contract': 'Missing employment for {name}',
  'dashboard.notifications.nats_onboarding_failed': 'Creation of MasterCard Payment Service agreement failed',
  'dashboard.notifications.not_registered_as_employer': 'Missing registration as employer',
  'dashboard.notifications.title': 'Notifications',
  'dashboard.notifications.voucher_booking_failed': 'Accounting voucher could not be booked',
  'dashboard.onboarding.choose_pay_roll_automation.description':
    'Salary can automatically run your payrolls and information you',
  'dashboard.onboarding.choose_pay_roll_automation.title': 'Automatic payroll',
  'dashboard.onboarding.click_here': 'Click here',
  'dashboard.onboarding.configure_expense_category_accounts.description':
    'When you are using expense management, you should add booking accounts to your expense categories',
  'dashboard.onboarding.configure_expense_category_accounts.title': 'Set up accounts on expense categories',
  'dashboard.onboarding.create_employee.description': 'Start by adding yourself or one of your employees',
  'dashboard.onboarding.create_employee.title': 'Add employee',
  'dashboard.onboarding.employer_registration.description':
    'Otherwise we cannot file your payrolls with SKAT – if you are registered, you can remove this message',
  'dashboard.onboarding.employer_registration.title': 'Register as employer at virk.dk',
  'dashboard.onboarding.grandhood.description': 'Read more about Grandhood',
  'dashboard.onboarding.grandhood.title': 'Make it easy to manage pensions',
  'dashboard.onboarding.missing_accounting_integration.description':
    'For Salary to automatically book in your accounting system, you need an integration',
  'dashboard.onboarding.missing_accounting_integration.title': 'Integrate your accounting system',
  'dashboard.onboarding.payment_flow_not_started.description':
    'To automatically pay your employees, you must give Salary access to MasterCard Payment Services',
  'dashboard.onboarding.payment_flow_not_started.title': 'Create Payment Services agreement',
  'dashboard.pay_rolls.awaiting_approval': 'Awaiting approval',
  'dashboard.pay_rolls.awaiting_approval_deadline': 'Awaiting approval ({date})',
  'dashboard.pay_rolls.awaiting_approval_deadline_tooltip': 'at the latest {date}',
  'dashboard.pay_rolls.empty': 'No payroll has been prepared or run',
  'dashboard.pay_rolls.title': 'Calendar',
  'dashboard.registrations.header.need_approval': 'Needs approval',
  'dashboard.registrations.header.sum': 'Total',
  'dashboard.registrations.table.car_allowance': 'Transport allowance',
  'dashboard.registrations.table.hours': 'Hours',
  'dashboard.registrations.table.leave': 'Holidays',
  'dashboard.registrations.table.reimbursement': 'Outlays',
  'dashboard.registrations.table.swipe': 'Swipe',
  'dashboard.registrations.title': 'Registrations',
  'dashboard.reports.description': 'Go to {link} to gather reports.',
  'dashboard.reports.link': 'Reports',
  'dashboard.reports.title': 'Payroll report',
  'dashboard.support.chat_alternative': 'Or write us in the chat as usual.',
  'dashboard.support.phone_introduction': 'You can call support all weekdays between 09 and 15 at',
  'dashboard.support.title': 'Support',
  'dashboard.transfers.empty': 'There are no upcoming transfers',
  'dashboard.transfers.header.amount': 'Amount',
  'dashboard.transfers.header.method': 'Method',
  'dashboard.transfers.header.type': 'Type',
  'dashboard.transfers.title': 'Upcoming transfers',

  'data_integration.async_schedule.edit.delete': 'Delete scheduling of {title}',
  'data_integration.async_schedule.edit.form.day_frequency': 'How often shall {title} be executed?',
  'data_integration.async_schedule.edit.form.day_frequency.most_60_days':
    'Frequency for {title} cannot be more than 60 days',
  'data_integration.async_schedule.edit.form.day_frequency.positive_integer':
    'Frequency for {title} must be a positive integer',
  'data_integration.async_schedule.edit.form.day_frequency.prefix': 'Every',
  'data_integration.async_schedule.edit.form.day_frequency.required': 'Frequency for {title} is required',
  'data_integration.async_schedule.edit.form.day_frequency.suffix': 'day',
  'data_integration.async_schedule.edit.form.hour_stamp': 'When during the day shall {title} be executed?',
  'data_integration.async_schedule.edit.form.hour_stamp.invalid':
    'Time stamp must be between 00 and 23, both inclusive',
  'data_integration.async_schedule.edit.form.hour_stamp.required': 'Time stamp for {title} is required',
  'data_integration.async_schedule.edit.intro':
    'You have an existing scheduling for {title}. The next execution will take place at',
  'data_integration.async_schedule.edit.title': 'Create scheduled {title}',
  'data_integration.async_task_select.format.not_started_at': 'Not started yet',
  'data_integration.async_task_select.format.started_at': 'Started {date}',
  'data_integration.async_task_select.format.with_finished_at': '{started_at}, collected {time}',
  'data_integration.async_task_select.format.without_finished_at': '{started_at}',
  'data_integration.async_task_select.title': 'Select an earlier collection of data',
  'data_integration.async_task_status.started': 'Started',
  'data_integration.async_task_status.started.false': 'Not yet',
  'data_integration.car_allowance_import.actions.save_import': 'Save data',
  'data_integration.car_allowance_import.actions.start_import': 'Collect data',
  'data_integration.car_allowance_import.actions.start_import_again': 'Collect data again',
  'data_integration.car_allowance_import.alert.success': 'The transport allowance have been saved',
  'data_integration.car_allowance_import.data_imported': 'Data collected at {date}',
  'data_integration.car_allowance_import.error.employees_cannot_be_imported.many': '{count} employees had errors',
  'data_integration.car_allowance_import.error.employees_cannot_be_imported.one': '{count} employee had errors',
  'data_integration.car_allowance_import.error.no_period': 'Could not find a payroll period from the payrolls',
  'data_integration.car_allowance_import.importing': 'Importing...',
  'data_integration.car_allowance_import.status.importing.description':
    'Import started. When the import is ready, the result will be displayed here. In the mean time, you can use the rest of Salary, the result will appear here when you return.',
  'data_integration.car_allowance_import.status.importing.title': 'Import status',
  'data_integration.car_allowance_import.status.saving.description':
    'The transport allowance registrations are being saved. In the mean time, you can use the rest of Salary..',
  'data_integration.car_allowance_import.status.saving.title': 'Saving status',
  'data_integration.car_allowance_import.table.external_id': 'External ID',
  'data_integration.car_allowance_import.table.header.amount': 'Total kilometres',
  'data_integration.car_allowance_import.table.header.name': 'Name',
  'data_integration.car_allowance_import.table.header.quantity': 'Quantity',
  'data_integration.car_allowance_import.title': 'Import transport allowance from {displayName}',
  'data_integration.car_allowance_import.warning.already_used': 'These data have already been saved',
  'data_integration.custom_field_mapping.alert.success': 'External fields have been saved.',
  'data_integration.custom_field_mapping.form.field_description.boolean': '{field}.',
  'data_integration.custom_field_mapping.form.field_description.boolean.selector':
    '{field}. Select the value for when the field is true.',
  'data_integration.custom_field_mapping.form.field_description.company_id':
    '{field}. If the field is selected, only employees in the selected company will be included.',
  'data_integration.custom_field_mapping.form.field_description.company_name':
    '{field}. If the field is selected, only employees which value in the field matches will be included.',
  'data_integration.custom_field_mapping.form.field_description.company_national_id':
    "{field}. If the field is selected, only employees which value in the field matches the company's CVR number will be included.",
  'data_integration.custom_field_mapping.form.field_description.date': '{field}. The value must include a date.',
  'data_integration.custom_field_mapping.form.field_description.department_id_limit':
    '{field}. If the field is selected, only employees in the selected department will be included.',
  'data_integration.custom_field_mapping.form.field_description.leave_type': '{field}.',
  'data_integration.custom_field_mapping.form.field_description.multi':
    '{field}. Select the value which decides that this field is valid.',
  'data_integration.custom_field_mapping.form.field_description.number':
    '{field}. The value must only include a number.',
  'data_integration.custom_field_mapping.form.field_description.text': '{field}.',
  'data_integration.custom_field_mapping.form.field_description.text_selector':
    '{field}.  Field is only used with specific value.',
  'data_integration.custom_field_mapping.form.field_required': '(required)',
  'data_integration.custom_field_mapping.form.field_type.boolean': 'Tick box',
  'data_integration.custom_field_mapping.form.field_type.date': 'Date field',
  'data_integration.custom_field_mapping.form.field_type.leave_type': 'Absence type',
  'data_integration.custom_field_mapping.form.field_type.multi': 'Multiple choice',
  'data_integration.custom_field_mapping.form.field_type.number': 'Number field',
  'data_integration.custom_field_mapping.form.field_type.text': 'Text field',
  'data_integration.custom_field_mapping.form.field_type.text_selector': 'Field with specific value',
  'data_integration.custom_field_mapping.form.field.address': 'Address',
  'data_integration.custom_field_mapping.form.field.bank_account_number': 'Account number',
  'data_integration.custom_field_mapping.form.field.bank_registration_number': 'Registration number',
  'data_integration.custom_field_mapping.form.field.benefit_free_phone': 'Free phone',
  'data_integration.custom_field_mapping.form.field.benefit_health': 'Value of health insurance',
  'data_integration.custom_field_mapping.form.field.benefit_lunch': 'Value of lunch scheme',
  'data_integration.custom_field_mapping.form.field.city': 'City',
  'data_integration.custom_field_mapping.form.field.company_id': 'Company',
  'data_integration.custom_field_mapping.form.field.company_name': 'Company name',
  'data_integration.custom_field_mapping.form.field.company_national_id': 'CVR number',
  'data_integration.custom_field_mapping.form.field.department_id_limit': 'Limit to department',
  'data_integration.custom_field_mapping.form.field.email': 'Email',
  'data_integration.custom_field_mapping.form.field.employee_number': 'Employee number',
  'data_integration.custom_field_mapping.form.field.leave_day_off': 'Day off',
  'data_integration.custom_field_mapping.form.field.leave_extra_day': 'Extra holiday',
  'data_integration.custom_field_mapping.form.field.leave_personal_day': 'Personal day',
  'data_integration.custom_field_mapping.form.field.leave_sick': 'Sick day',
  'data_integration.custom_field_mapping.form.field.leave_unpaid': 'Unpaid day off',
  'data_integration.custom_field_mapping.form.field.leave_vacation_accrual': 'Paid holiday',
  'data_integration.custom_field_mapping.form.field.leave_vacation_fund': 'Unpaid holiday',
  'data_integration.custom_field_mapping.form.field.national_id': 'CPR number',
  'data_integration.custom_field_mapping.form.field.postal_code': 'Postcode',
  'data_integration.custom_field_mapping.form.field.salary_fixed': 'Monthly salary',
  'data_integration.custom_field_mapping.form.field.salary_fixed.multi': 'Determines whether they are salaried',
  'data_integration.custom_field_mapping.form.field.salary_hourly': 'Wage',
  'data_integration.custom_field_mapping.form.field.salary_hourly.multi': 'Determines whether they are waged',
  'data_integration.custom_field_mapping.form.field.salary_rate': 'Pay rate',
  'data_integration.custom_field_mapping.form.field.start_date': 'Employment date',
  'data_integration.custom_field_mapping.form.field.tax_card_primary': 'Use primary tax card',
  'data_integration.custom_field_mapping.form.field.tax_card_secondary': 'Use secondary tax card',
  'data_integration.custom_field_mapping.form.header.external_field': '{title} field',
  'data_integration.custom_field_mapping.form.header.salary_field': 'Salary field',
  'data_integration.custom_field_mapping.form.selector.none': 'No field',
  'data_integration.custom_field_mapping.form.selector.required': '{title} is required',
  'data_integration.custom_field_mapping.form.value': 'Value for {title}',
  'data_integration.custom_field_mapping.form.value.note': 'Write the value precisely as it appears in {title}.',
  'data_integration.custom_field_mapping.form.value.required': 'Value for {title} is required',
  'data_integration.custom_field_mapping.intro':
    '{title} has custom fields. This means that you will manually need to match these fields with their counterparts in Salary. Base data fields are handled, and will not need manual matching.',
  'data_integration.custom_field_mapping.title': "Match {title}'s fields to Salary's fields",
  'data_integration.employees.actions.save_import': 'Save data',
  'data_integration.employees.actions.start_import': 'Collect employees',
  'data_integration.employees.actions.start_import_again': 'Collect data again',
  'data_integration.employees.actions.start_new_import': 'Collect a new portion of data',
  'data_integration.employees.alert.success': 'The employees are saved',
  'data_integration.employees.button.uncheck_all': 'Unselect all',
  'data_integration.employees.checkbox.check_all': 'Select all that can be imported',
  'data_integration.employees.checkbox.check_only_final': 'Select only those without missing fields',
  'data_integration.employees.header.address': 'Address',
  'data_integration.employees.header.bank_info': 'Account number',
  'data_integration.employees.header.benefits': 'Benefits',
  'data_integration.employees.header.email': 'Email',
  'data_integration.employees.header.name': 'Name',
  'data_integration.employees.header.national_id': 'CPR number',
  'data_integration.employees.header.phone_number': 'Phone number',
  'data_integration.employees.header.remuneration': 'Remuneration',
  'data_integration.employees.importing': 'Loading...',
  'data_integration.employees.intro.line_1':
    'Employees marked with red will not be created.  Those marked with yellow will be created in a draft state, meaning you will have to fill in the missing fields before they are ready for payroll.  Existing employees will only be updated with the fields that are not missing.',
  'data_integration.employees.intro.line_2.many': 'You are about to import {amount} employees.',
  'data_integration.employees.intro.line_2.one': 'You are about to import {amount} employee.',
  'data_integration.employees.mapping_required':
    'Your integration requires you match certain {link} to fields in Salary. You must match these fields before you can import from the external system.',
  'data_integration.employees.mapping_required.link': 'external fields',
  'data_integration.employees.status.importing.description':
    'Import started. When the import is ready, the result will be displayed here. In the mean time, you can use the rest of Salary, the result will appear here when you return.',
  'data_integration.employees.status.importing.title': 'Import status',
  'data_integration.employees.status.saving.description':
    'The employees are being saved. In the mean time, you can use the rest of Salary.',
  'data_integration.employees.status.saving.title': 'Saving status',
  'data_integration.employees.tax_card_selector': 'Preferred tax card:',
  'data_integration.employees.title': 'Import employees from {name}',
  'data_integration.employees.warning.already_used': 'These data have already been saved',
  'data_integration.employees.warning.errors_on_employees.many': '{count} employees had errors.',
  'data_integration.employees.warning.errors_on_employees.one': '{count} employee had errors.',
  'data_integration.employees.warning.more_than_24_hours':
    'Data is more than 24 hours old, you must collect new data before you save.',
  'data_integration.integration.header.change': 'Change data provider',
  'data_integration.integration.header.delete': 'Delete integration',
  'data_integration.integration.saving': 'Saving...',
  'data_integration.integration.sheet.car_allowances_import': 'Transport',
  'data_integration.integration.sheet.employees_import': 'Employees',
  'data_integration.integration.sheet.field_mapping': 'External fields',
  'data_integration.integration.sheet.leave_registrations_export': 'Absence export',
  'data_integration.integration.sheet.leave_registrations_import': 'Absence registrations',
  'data_integration.integration.sheet.pay_roll_import': 'Pay import',
  'data_integration.integration.sheet.reimbursements_import': 'Outlays',
  'data_integration.integration.summary.name': 'Integrated with',
  'data_integration.integration.title': 'Data provider',
  'data_integration.leave_export.actions.collect': 'Collect data',
  'data_integration.leave_export.actions.collect_again': 'Collect data again',
  'data_integration.leave_export.actions.save_export': 'Export',
  'data_integration.leave_export.actions.schedule': 'Schedule',
  'data_integration.leave_export.alert.success': 'Absence registrations have been exported',
  'data_integration.leave_export.collecting': 'Collecting...',
  'data_integration.leave_export.data_collected': 'Data collected at {data}',
  'data_integration.leave_export.header.date': 'Date',
  'data_integration.leave_export.header.leave_type': 'Absence type',
  'data_integration.leave_export.header.name': 'Name',
  'data_integration.leave_export.header.rate': 'Rate',
  'data_integration.leave_export.status.gathering.description':
    'Gathering commenced. When the gathering is done, the result will be displayed here. In the mean time, you can use the rest of Salary, the result will be here when you return.',
  'data_integration.leave_export.status.gathering.title': 'Gathering status',
  'data_integration.leave_export.status.saving.description': 'Absence registrations have been exported.',
  'data_integration.leave_export.status.saving.title': 'Export status',
  'data_integration.leave_export.title': 'Export absence to {name}',
  'data_integration.leave_import.actions.save_import': 'Save data',
  'data_integration.leave_import.actions.start_import': 'Collect data',
  'data_integration.leave_import.actions.start_import_again': 'Collect data again',
  'data_integration.leave_import.alert.success': 'Absence registrations have been saved',
  'data_integration.leave_import.data_imported': 'Data collected at {date}',
  'data_integration.leave_import.error.employees_cannot_be_imported.many': '{count} employees could not be imported',
  'data_integration.leave_import.error.employees_cannot_be_imported.one': '{count} employee could not be imported',
  'data_integration.leave_import.error.no_period': 'Could not find a payroll period from the payrolls',
  'data_integration.leave_import.ignore_unknowns': 'Ignore unknown absence types',
  'data_integration.leave_import.ignore_unknowns.help.line_1':
    'Absence registrations with types not mapped to one in Salary will be marked as a registration with an unknown absence type.  Normally, these will be marked as errors, and they cannot be imported.',
  'data_integration.leave_import.ignore_unknowns.help.line_2':
    'However one may use absence types in the external system that make no sense to map to a type in Salary, and in such a case, one should ignore them with this setting.',
  'data_integration.leave_import.ignore_unknowns.help.line_3':
    'Be advised that it will also ignore absence types you simply have not mapped yet.  So check the result first without this setting to ensure that the remaining unknowns are the ones you do not wish to include.',
  'data_integration.leave_import.importing': 'Loading...',
  'data_integration.leave_import.status.importing.description':
    'Import commenced. When the import is done, the result will be shown here. In the mean time, you can use the rest of Salary, the result will be here when you return.',
  'data_integration.leave_import.status.importing.title': 'Import status',
  'data_integration.leave_import.status.saving.description':
    'Absence registrations are being saved. You will be redirected to the absence registrations page, when the registrations have been saved. In the mean time, you can use the rest of Salary. If you do that, you will not be redirected.',
  'data_integration.leave_import.status.saving.title': 'Saving status',
  'data_integration.leave_import.table.external_id': 'External ID',
  'data_integration.leave_import.table.header.days': 'Days',
  'data_integration.leave_import.table.header.leave_type': 'Absence type',
  'data_integration.leave_import.table.header.name': 'Name',
  'data_integration.leave_import.title': 'Import absence from {displayName}',
  'data_integration.leave_import.warning.already_used': 'This gathering of data has already been saved',
  'data_integration.no_integration.button.create': 'Establish connection to data provider',
  'data_integration.no_integration.intro.line_1':
    'You do not have an active data provider integration, such as a time registration, absence registration and/or employee import. Create an integration by pushing the button below.',
  'data_integration.no_integration.intro.line_2':
    'If your integration is missing, write our support at {link}, and will determine whether it can be added.',
  'data_integration.no_integration.intro.title': 'Data provider',
  'data_integration.no_integration.title': 'Data provider',
  'data_integration.pay_roll.actions.save_import': 'Save data',
  'data_integration.pay_roll.actions.start_import': 'Collect data',
  'data_integration.pay_roll.actions.start_import_again': 'Collect data again',
  'data_integration.pay_roll.actions.start_new_import': 'Collect a new portion of data',
  'data_integration.pay_roll.alert.success.hourly': 'Time registrations have been saved',
  'data_integration.pay_roll.alert.success.salaried': 'Pay registrations have been saved',
  'data_integration.pay_roll.data_finished_at': 'Data collected at {data}',
  'data_integration.pay_roll.error.employees_cannot_import.many': '{count} employees cannot be imported',
  'data_integration.pay_roll.error.employees_cannot_import.one': '{count} employee cannot be imported',
  'data_integration.pay_roll.error.no_pay_roll_period': 'Could not find a payroll period from the payrolls',
  'data_integration.pay_roll.importing': 'Loading...',
  'data_integration.pay_roll.mode.hourly': 'Waged',
  'data_integration.pay_roll.mode.salaried': 'Salaried',
  'data_integration.pay_roll.status.importing.description':
    'Import commenced. When the import is ready, the result will be displayed here. In the mean time, you can use the rest of Salary.  The result will be here when you return.',
  'data_integration.pay_roll.status.importing.title': 'Import status',
  'data_integration.pay_roll.status.saving.description.hourly':
    'Payroll data is being saved. When it is saved, you will be redirected to the time registration page. In the mean time, you can use the rest of Salary. If you do, you will not be redirected.',
  'data_integration.pay_roll.status.saving.description.salaried':
    'Payroll data is being saved. When it is saved, you will be redirected to the pay registration page. In the mean time, you can use the rest of Salary. If you do, you will not be redirected.',
  'data_integration.pay_roll.status.saving.title': 'Saving status',
  'data_integration.pay_roll.summary.total_gross': 'Total gross pay',
  'data_integration.pay_roll.summary.total_gross.can_be_imported': ' (of which {amount} can be imported)',
  'data_integration.pay_roll.summary.total_hours': 'Total hours',
  'data_integration.pay_roll.summary.total_hours.can_be_imported': ' (of which {amount} can be imported)',
  'data_integration.pay_roll.table.external_id': 'External ID',
  'data_integration.pay_roll.table.header.hours': 'Hours',
  'data_integration.pay_roll.table.header.name': 'Name',
  'data_integration.pay_roll.table.header.rate': 'Rate',
  'data_integration.pay_roll.table.header.remuneration': 'Remuneration',
  'data_integration.pay_roll.table.note.remuneration_added': 'Remuneration to be added to employment',
  'data_integration.pay_roll.table.note.remuneration_changed': 'Remuneration on contract to be changed',
  'data_integration.pay_roll.table.note.remuneration_created': 'Wage type to be created',
  'data_integration.pay_roll.table.quantity_format.many': '{count} pieces',
  'data_integration.pay_roll.table.quantity_format.one': '{count} piece',
  'data_integration.pay_roll.warning.already_used': 'This collection of data has already been saved',
  'data_integration.pay_roll.warning.error_on_employees.many': '{count} employees had errors.',
  'data_integration.pay_roll.warning.error_on_employees.one': '{count} employee had errors.',
  'data_integration.period_selector.no_payrolls':
    'There are no open payrolls, so you cannot select a period to import for.',
  'data_integration.reimbursement_import.actions.save_import': 'Save data',
  'data_integration.reimbursement_import.actions.start_import': 'Collect data',
  'data_integration.reimbursement_import.actions.start_import_again': 'Collect data again',
  'data_integration.reimbursement_import.alert.success': 'The outlays have been saved',
  'data_integration.reimbursement_import.data_imported': 'Data collected at {date}',
  'data_integration.reimbursement_import.error.employees_cannot_be_imported.many': '{count} employees had errors.',
  'data_integration.reimbursement_import.error.employees_cannot_be_imported.one': '{count} employee had errors.',
  'data_integration.reimbursement_import.error.no_period': 'Could not find a payroll period from the payrolls',
  'data_integration.reimbursement_import.importing': 'Importing...',
  'data_integration.reimbursement_import.status.importing.description':
    'Import started. When the import is ready, the result will be displayed here. In the mean time, you can use the rest of Salary, the result will appear here when you return.',
  'data_integration.reimbursement_import.status.importing.title': 'Import status',
  'data_integration.reimbursement_import.status.saving.description':
    'The outlays are being saved. In the mean time, you can use the rest of Salary..',
  'data_integration.reimbursement_import.status.saving.title': 'Saving status',
  'data_integration.reimbursement_import.table.external_id': 'External ID',
  'data_integration.reimbursement_import.table.header.amount': 'Total amount',
  'data_integration.reimbursement_import.table.header.name': 'Name',
  'data_integration.reimbursement_import.table.header.quantity': 'Quantity',
  'data_integration.reimbursement_import.title': 'Import outlays from {displayName}',
  'data_integration.reimbursement_import.warning.already_used': 'These data have already been saved',

  'data_provider_terms.download': 'Download Data Processing Agreement as PDF',
  'data_provider_terms.not_signed': 'Data Processing Agreement has not been accepted.',
  'data_provider_terms.signed_at': 'Data Processing Agreement was accepted at {time}.',
  'data_provider_terms.title': 'Data Processing Agreement',

  'date_picker.range.select_end_date': 'Pick end date',
  'date_picker.range.select_start_date': 'Pick start date',
  'date_picker.today': 'Today',

  'day.friday': 'Friday',
  'day.holiday': 'Holiday',
  'day.monday': 'Monday',
  'day.saturday': 'Saturday',
  'day.sunday': 'Sunday',
  'day.thursday': 'Thursday',
  'day.tuesday': 'Tuesday',
  'day.wednesday': 'Wednesday',

  'department.edit.form.name': 'Description',
  'department.edit.form.validation.name.required': 'Description is required',
  'department.edit.title.create': 'Create department',
  'department.edit.title.edit': 'Edit department',

  'departments.header.add_department': 'Add department',
  'departments.header.title': 'Departments',
  'departments.table.actions.delete': 'Delete department',
  'departments.table.actions.edit': 'Edit department',
  'departments.table.header.dimension': 'Dimension',
  'departments.table.header.employee_count': 'Size',
  'departments.table.header.name': 'Description',

  'deviation.alert.success': 'Changes to {name} have been saved',
  'deviation.edit.form.gross_pay_reduction.intro':
    'When the basis for the gross pay changes (e.g. if someone is hired or terminated during the period).',
  'deviation.edit.form.gross_pay.description':
    'Select a threshold in percentage for how large the deviation has to be, before it is highlighted. Afterwards you can select the number of past payrolls to check. An average of the gross pay per employee for these past payrolls will be compared to the current payroll; if the deviation exceeds the threshold it will be highlighted on the payroll.',
  'deviation.edit.form.gross_pay.intro': 'When the gross pay on an employee changes significantly.',
  'deviation.edit.form.gross_pay.periods': 'Number of past payrolls',
  'deviation.edit.form.gross_pay.periods.suffix': 'payrolls',
  'deviation.edit.form.gross_pay.threshold': 'Threshold for deviation',
  'deviation.edit.form.header.enable': 'Enable',
  'deviation.edit.form.header.settings': 'Settings',
  'deviation.edit.form.header.type': 'Deviation type',
  'deviation.edit.form.hours.description':
    'Select a threshold in percentage for how large the deviation has to be, before it is highlighted. Afterwards you can select the number of past payrolls to check. An average of the registered hours per employee for these past payrolls will be compared to the current payroll; if the deviation exceeds the threshold it will be highlighted on the payroll.',
  'deviation.edit.form.hours.intro': 'When the number of registered hours per employee changes significantly.',
  'deviation.edit.form.hours.periods': 'Number of past payrolls',
  'deviation.edit.form.hours.periods.suffix': 'payrolls',
  'deviation.edit.form.hours.threshold': 'Threshold for deviation',
  'deviation.edit.form.pay_check.description':
    'Select a threshold in percentage for how large the deviation has to be, before it is highlighted. Afterwards you can select the number of past payrolls to check. An average of the net pay per employee for these past payrolls will be compared to the current payroll; if the deviation exceeds the threshold it will be highlighted on the payroll.',
  'deviation.edit.form.pay_check.intro': 'When the net pay on an employee changes significantly.',
  'deviation.edit.form.pay_check.periods': 'Number of past payrolls',
  'deviation.edit.form.pay_check.periods.suffix': 'payrolls',
  'deviation.edit.form.pay_check.threshold': 'Threshold for deviation',
  'deviation.edit.form.submit': 'Save deviation settings',
  'deviation.edit.form.tax_card.intro': 'When an employee changes tax card type (e.g. primary to secondary)',
  'deviation.header.back': 'Back',
  'deviation.header.title': 'Payroll deviation settings',
  'deviation.introduction':
    'When a significant change from one payroll to another occurs, you will get a notification on the payroll that a deviation has happened. Here you chose which types of deviations you wish to be notified about, including additional settings for them.',
  'deviation.type.gross_pay': 'Gross pay',
  'deviation.type.gross_pay_reduction': 'Gross pay reduction',
  'deviation.type.hours': 'Hours',
  'deviation.type.pay_check': 'Net pay',
  'deviation.type.tax_card': 'Tax card',

  'dimensions_tab.card.form.dimension_id': 'Select dimension',
  'dimensions_tab.card.form.intro.line_1.cost_centers':
    'Your accounting software uses dimensions. You can match your cost centres to these external dimensions.',
  'dimensions_tab.card.form.intro.line_1.departments':
    'Your accounting software uses dimensions. You can match your departments to these external dimensions.',
  'dimensions_tab.card.form.intro.line_2.cost_centers':
    'Select the dimension you wish to use, to match the dimension values to cost centres. You can afterwards match from other dimensions by selecting another dimension.',
  'dimensions_tab.card.form.intro.line_2.departments':
    'Select the dimension you wish to use, to match the dimension values to departments. You can afterwards match from other dimensions by selecting another dimension.',
  'dimensions_tab.card.form.row.cost_center_id.cost_center': 'Cost centre',
  'dimensions_tab.card.form.row.cost_center_id.deactivated_note': '{name} (deactivated)',
  'dimensions_tab.card.form.row.cost_center_id.deactivated.cost_center': 'Cost centre is deactivated',
  'dimensions_tab.card.form.row.cost_center_id.deactivated.department': 'Department is deactivated',
  'dimensions_tab.card.form.row.cost_center_id.department': 'Department',
  'dimensions_tab.card.form.row.cost_center_id.new.cost_center': 'Create new cost centre',
  'dimensions_tab.card.form.row.cost_center_id.new.department': 'Create new department',
  'dimensions_tab.card.form.row.cost_center_id.required.cost_center': 'Cost centre is required',
  'dimensions_tab.card.form.row.cost_center_id.required.department': 'Department is required',
  'dimensions_tab.card.form.table.header.active': 'Active',
  'dimensions_tab.card.form.table.header.cost_center': 'Cost centre',
  'dimensions_tab.card.form.table.header.department': 'Department',
  'dimensions_tab.card.form.table.header.dimension': 'Dimension',
  'dimensions_tab.card.form.table.header.dimension_value': 'Dimension value',
  'dimensions_tab.card.form.table.show_with_cost_center.cost_centers.many':
    'Show only the {count} dimensions with cost centres',
  'dimensions_tab.card.form.table.show_with_cost_center.cost_centers.one':
    'Show only the {count} dimension with cost centre',
  'dimensions_tab.card.form.table.show_with_cost_center.departments.many':
    'Show only the {count} dimensions with departments',
  'dimensions_tab.card.form.table.show_with_cost_center.departments.one':
    'Show only the {count} dimension with department',
  'dimensions_tab.card.form.table.with_cost_center.intro.cost_centers': 'Dimensions with cost centres',
  'dimensions_tab.card.form.table.with_cost_center.intro.departments': 'Dimensions with departments',
  'dimensions_tab.card.form.table.without_cost_center.button.cost_centers':
    "Create a new cost centre for all the dimensions, which don't already have one",
  'dimensions_tab.card.form.table.without_cost_center.button.departments':
    "Create a new department for all the dimensions, which don't already have one",
  'dimensions_tab.card.form.table.without_cost_center.intro.cost_centers': 'Dimensions without cost centres',
  'dimensions_tab.card.form.table.without_cost_center.intro.departments': 'Dimensions without departments',
  'dimensions_tab.card.title': 'Dimensions',
  'dimensions_tab.departments.form.cost_center_accounting': 'Book pay in departments',
  'dimensions_tab.departments.form.cost_center_accounting.cost_centers': 'By cost centres',
  'dimensions_tab.departments.form.cost_center_accounting.departments': 'Yes',
  'dimensions_tab.departments.form.cost_center_accounting.departments.has_dimensions': 'By departments',
  'dimensions_tab.departments.form.cost_center_accounting.lock': 'Upgrade to Basic to allow department accounting',
  'dimensions_tab.departments.form.cost_center_accounting.off': 'No',
  'dimensions_tab.departments.form.time_registration_department.cost_centers':
    'Allow cost centres on time, pay and transport registrations',
  'dimensions_tab.departments.form.time_registration_department.departments':
    'Allow department on time, pay and transport registrations',
  'dimensions_tab.departments.form.time_registration_department.note.cost_centers':
    'When you have cost centre accounting, you can allow time, pay and transport registrations to be attached to a cost centre.',
  'dimensions_tab.departments.form.time_registration_department.note.departments':
    'When you have department accounting, you can allow time, pay and transport registrations to be attached to a different department than the department the employee is in.',
  'dimensions_tab.employee.form.active': 'Active',
  'dimensions_tab.employee.form.add_dimension': 'Add employee dimension',
  'dimensions_tab.employee.form.dimension_code': 'Dimension code',
  'dimensions_tab.employee.form.dimension_code.required': 'Dimension code is required',
  'dimensions_tab.employee.form.external_id.false': 'No',
  'dimensions_tab.employee.form.external_id.true': 'Yes',
  'dimensions_tab.employee.form.table.header.accounting_integration': 'To be booked in accounting software?',
  'dimensions_tab.employee.form.table.header.active': 'Active',
  'dimensions_tab.employee.form.table.header.dimension': 'Dimension',
  'dimensions_tab.employee.form.table.header.dimension_value': 'Dimension values',
  'dimensions_tab.employee.form.value.add': 'Add value',
  'dimensions_tab.employee.form.value.first': 'Start value',
  'dimensions_tab.employee.form.value.later': 'Additional value',
  'dimensions_tab.employee.intro':
    'Employee dimensions allows differentiating employees by additional fields. An additional field will appear on the employee for each employee dimension.',
  'dimensions_tab.employee.title': 'Employee dimensions',
  'dimensions_tab.employee.with_integration.cost_centers':
    'You cannot use dimensions you already use for cost centres. You can add additional dimensions here, but they will not be booked, since they have no connection to your accounting software.',
  'dimensions_tab.employee.with_integration.departments':
    'You cannot use dimensions you already use for departments. You can add additional dimensions here, but they will not be booked, since they have no connection to your accounting software.',

  'document_category.edit.form.name': 'Description',
  'document_category.edit.form.name.required': 'Description is required',
  'document_category.edit.title.create': 'Create category',
  'document_category.edit.title.edit': 'Edit category',
  'document_category.header.add_category': 'Add category',
  'document_category.header.documents': 'To document overview',
  'document_category.header.title': 'Document categories',
  'document_category.table.actions.delete': 'Delete category',
  'document_category.table.actions.edit': 'Edit category',
  'document_category.table.actions.reactivate': 'Re-activate category',
  'document_category.table.active.false': 'No',
  'document_category.table.active.true': 'Yes',
  'document_category.table.header.active': 'Active',
  'document_category.table.header.name': 'Description',

  'document_tab.confirm.archive': 'Are you sure you wish to archive the document?',
  'document_tab.confirm.remove': 'Are you sure you wish to delete the document?',
  'document_tab.edit.form.document_category_id': 'Category',
  'document_tab.edit.form.document_category_id.required': 'Category is required',
  'document_tab.edit.form.employee.placeholder': 'Employee',
  'document_tab.edit.form.employee.required': 'Employee is required',
  'document_tab.edit.form.employee.terminated': '{name} (terminated)',
  'document_tab.edit.form.name': 'Description',
  'document_tab.edit.form.name.required': 'Description is required',
  'document_tab.edit.form.submit.create': 'Create document',
  'document_tab.edit.form.visible_for_employee': 'Visible for employee',
  'document_tab.edit.form.visible_for_employee.false': 'No',
  'document_tab.edit.form.visible_for_employee.true': 'Yes',
  'document_tab.edit.title.create': 'Add document',
  'document_tab.edit.title.edit': 'Edit document',
  'document_tab.header.add_document': 'Add document',
  'document_tab.header.document_categories': 'Document categories',
  'document_tab.header.title': 'Documents',
  'document_tab.signer.table.header.language': 'Language',
  'document_tab.signer.table.header.name': 'Name',
  'document_tab.signer.table.header.phone_number': 'Phone number',
  'document_tab.signer.table.header.signed': 'Signed?',
  'document_tab.signer.table.signed.false': 'No',
  'document_tab.signer.table.signed.true': 'Yes',
  'document_tab.signer.table.signed.true_with_date': 'Yes, at {date}',
  'document_tab.signer.table.title': 'Signers',
  'document_tab.table.actions.archive': 'Activate document',
  'document_tab.table.actions.delete': 'Delete document',
  'document_tab.table.actions.download': 'Download document',
  'document_tab.table.actions.edit': 'Edit document',
  'document_tab.table.actions.refresh': 'Refresh document',
  'document_tab.table.archived.false': 'No',
  'document_tab.table.archived.true': 'Yes',
  'document_tab.table.created_at': 'Created {date}',
  'document_tab.table.created_by_system': 'The system',
  'document_tab.table.document_signed': 'This document has been signed',
  'document_tab.table.document_signed_at': 'This document has been signed at {date}',
  'document_tab.table.header.archived': 'Archived',
  'document_tab.table.header.category': 'Category',
  'document_tab.table.header.created_by': 'Created by',
  'document_tab.table.header.filename': 'File name',
  'document_tab.table.header.name': 'Description',
  'document_tab.table.header.visible_for_employee': 'Visible',
  'document_tab.table.visible_for_employee.false': 'No',
  'document_tab.table.visible_for_employee.true': 'Yes',

  'documents.attach.form.employee_id': 'Employee',
  'documents.attach.form.employee_id.required': 'Employee is required',
  'documents.attach.form.submit': 'Attach employee',
  'documents.attach.title': 'Attach a signed document to an employee',
  'documents.company_document_edit.form.document_category_id': 'Category',
  'documents.company_document_edit.form.document_category_id.required': 'Category is required',
  'documents.company_document_edit.form.name': 'Description',
  'documents.company_document_edit.form.name.required': 'Description is required',
  'documents.company_document_edit.form.submit.create': 'Create document',
  'documents.company_document_edit.title.create': 'Add template',
  'documents.company_document_edit.title.edit': 'Edit template',
  'documents.company.confirm.delete_document': 'Are you sure you wish to delete the document?',
  'documents.company.header.back': 'Back',
  'documents.company.header.new_document': 'New template',
  'documents.company.header.new_document.lock': 'Upgrade to Premium to create document templates',
  'documents.company.header.title': 'Templates',
  'documents.company.table.actions.delete': 'Delete document',
  'documents.company.table.actions.download': 'Download document',
  'documents.company.table.actions.edit': 'Edit document',
  'documents.company.table.header.category': 'Category',
  'documents.company.table.header.filename': 'File name',
  'documents.company.table.header.name': 'Description',
  'documents.confirm.archive': 'Are you sure you wish to archive the document?',
  'documents.confirm.remove': 'Are you sure you wish to delete the document?',
  'documents.employee_table.actions.archive': 'Archive document',
  'documents.employee_table.actions.delete': 'Delete document',
  'documents.employee_table.actions.download': 'Download document',
  'documents.employee_table.actions.edit': 'Edit document',
  'documents.employee_table.actions.un_archive': 'Refresh document',
  'documents.employee_table.created_at': 'Created {date}',
  'documents.employee_table.header.category': 'Category',
  'documents.employee_table.header.employee': 'Employee',
  'documents.employee_table.header.filename': 'File name',
  'documents.employee_table.header.name': 'Description',
  'documents.employee_table.header.visible_for_employee': 'Visible',
  'documents.employee_table.signed': 'This document was signed at {signed_at} with {signed_with}',
  'documents.employee_table.visible_for_employee.false': 'No',
  'documents.employee_table.visible_for_employee.true': 'Yes',
  'documents.error.must_be_pdf': 'The file has to be a PDF',
  'documents.header.categories': 'Document categories',
  'documents.header.category_select.placeholder': 'Category',
  'documents.header.hide_history': 'Back',
  'documents.header.new_document': 'New document',
  'documents.header.new_document.lock': 'Upgrade to Premium to manage documents',
  'documents.header.new_signing_document': 'New document for signing',
  'documents.header.new_signing_document.lock': 'Upgrade to Premium to sign documents',
  'documents.header.search': 'Search...',
  'documents.header.show_history': 'Archive',
  'documents.header.templates': 'Templates',
  'documents.signing_title': 'Documents awaiting signature',
  'documents.signing.edit.form.document_category_id': 'Category',
  'documents.signing.edit.form.document_category_id.required': 'Category is required',
  'documents.signing.edit.form.name': 'Description',
  'documents.signing.edit.form.name.required': 'Description is required',
  'documents.signing.edit.form.signed_with': 'Sign with',
  'documents.signing.edit.form.signed_with.criipto': 'MitID (cost of {price} per document)',
  'documents.signing.edit.form.signed_with.criipto.lock':
    'Contact support to here about the possibility of using MitID to sign documents',
  'documents.signing.edit.form.signed_with.sms': 'Text message',
  'documents.signing.edit.form.signers.add': 'Add signer',
  'documents.signing.edit.form.signers.email': "Signer's email",
  'documents.signing.edit.form.signers.email.invalid': "Signer's email is invalid",
  'documents.signing.edit.form.signers.email.required': "Signer's email is required",
  'documents.signing.edit.form.signers.language': 'Language',
  'documents.signing.edit.form.signers.multiple_signers_note':
    'The document is first sent to the second signer, when the first signer has signed the document.',
  'documents.signing.edit.form.signers.multiple_signers_note.2_plus':
    "The document is first sent to the second signer, when the first signer has signed the document. And so on, until the document has been signed by all in the order you've selected your signers.",
  'documents.signing.edit.form.signers.name': "Signer's name",
  'documents.signing.edit.form.signers.name.required': "Signer's name is required",
  'documents.signing.edit.form.signers.refresh': 'Re-send email for signature',
  'documents.signing.edit.form.signers.remove': 'Remove signer',
  'documents.signing.edit.form.signers.title': '{number}{ordinal_suffix} signer',
  'documents.signing.edit.form.submit.create': 'Create document',
  'documents.signing.edit.title.create': 'Add document for signature',
  'documents.signing.edit.title.edit': 'Edit document for signature',
  'documents.table.actions.attach': 'Attach employee',
  'documents.table.actions.attach_create': 'Create employee',
  'documents.table.actions.delete': 'Delete document',
  'documents.table.actions.download': 'Download document',
  'documents.table.actions.edit': 'Edit document',
  'documents.table.header.category': 'Category',
  'documents.table.header.name': 'Description',
  'documents.table.header.signed': 'Signed',
  'documents.table.header.signer': 'Signer',
  'documents.table.signed.false': 'No',
  'documents.table.signed.true': 'Yes',
  'documents.title.history': 'Archived documents',
  'documents.title.standard': 'Documents',

  'early_payments.edit.description':
    'Normally, tax, ATP, holiday allowances, etc. are paid according to a fix date for a period. But you can also chose to pay these expenses as soon as possible.',
  'early_payments.edit.form.enable_early_payments': 'Early payments of taxes, etc.',
  'early_payments.edit.note':
    '* Remember to withdraw the amount on your tax account to avoid SKAT transferring the money back.',
  'early_payments.edit.title': 'Early payments of taxes, etc.',

  'employee_batch.message.failure.company_feature_missing': 'Function not available for company',
  'employee_batch.message.failure.company_not_found': 'Unknown company',
  'employee_batch.message.failure.email_not_found': 'Employee has no email',
  'employee_batch.message.failure.employee_contract_invalid': "Employee's employment is invalid",
  'employee_batch.message.failure.employee_contract_invalid.create_employee_contract':
    'Employment change would be invalid',
  'employee_batch.message.failure.employee_contract_invalid.update_employee_contract':
    'Employment change would be invalid',
  'employee_batch.message.failure.employee_contract_invalid.validate_create_contract':
    'An employment change could not be performed',
  'employee_batch.message.failure.employee_contract_not_found': 'Employee has no employment',
  'employee_batch.message.failure.employee_is_freelancer': 'Employee is freelancer',
  'employee_batch.message.failure.generic': 'An unknown error occurred during employee update',
  'employee_batch.message.failure.invalid_national_id': 'Employee has an invalid CPR/CVR number',
  'employee_batch.message.failure.invalid_national_id.dk_no_cpr': 'Employee has no CPR/CVR number',
  'employee_batch.message.failure.invalid_national_id.must_have_cpr': 'Employee must have a CPR number',
  'employee_batch.message.failure.leave_type_not_found': 'Absence type not found',
  'employee_batch.message.failure.pension_definition_invalid': 'Pension information is invalid',
  'employee_batch.message.failure.phone_number_not_set': 'Employee has no phone number',
  'employee_batch.message.failure.salary_definition_not_found': 'Employee missing remuneration on their employment',
  'employee_batch.message.great_prayer_day_not_available_yet': 'Great Prayer Day supplement is not available yet',
  'employee_batch.message.great_prayer_day_not_enabled_for_all_employees':
    'Great Prayer Day supplement cannot be changed',
  'employee_batch.message.no_change.already_did_not_have_pay_slip_transport_type':
    'Employee already did not receive their payslips using this method',
  'employee_batch.message.no_change.already_had_pay_slip_transport_type':
    'Employee already receives their payslips using this method',
  'employee_batch.message.no_change.employee_already_had_preferred_tax_card': "Employee's tax card already set",
  'employee_batch.message.no_change.employee_already_in_department': 'Employee was already in department',
  'employee_batch.message.no_change.employee_already_invited': 'Employee already invited',
  'employee_batch.message.no_change.employee_already_not_in_department': 'Employee was already not in a department',
  'employee_batch.message.no_change.employee_already_set_to_language': "Employee's language was already set",
  'employee_batch.message.no_change.employee_contract_needed_no_update': "Employee's employment required no update",
  'employee_batch.message.no_change.generic': 'Employee required no changes',
  'employee_batch.message.success.employee_contract_updated': "Employee's employment has been updated",
  'employee_batch.message.success.employee_invited': 'Employee has been invited',
  'employee_batch.message.success.employee_terminated': 'Employee terminated',
  'employee_batch.message.success.employee_termination_scheduled': "Employee's termination has been scheduled",
  'employee_batch.message.success.employee_updated': 'Employee has been updated',
  'employee_batch.message.success.generic': 'Employee updated',
  'employee_batch.search_form.continue': 'Continue to action',
  'employee_batch.search_form.title': 'Find employees',
  'employee_batch.update.form.accrual': 'Earning period',
  'employee_batch.update.form.accrual.false': 'Annual',
  'employee_batch.update.form.accrual.true': 'Monthly',
  'employee_batch.update.form.action': 'Action',
  'employee_batch.update.form.action.app_invite': 'Invite to employee app',
  'employee_batch.update.form.action.asset': 'Add/remove asset',
  'employee_batch.update.form.action.convert_employment': 'Convert freelancers to employees',
  'employee_batch.update.form.action.delete': 'Delete employees',
  'employee_batch.update.form.action.department': 'Add to/remove from department',
  'employee_batch.update.form.action.employee_association_benefit': 'Add employee association',
  'employee_batch.update.form.action.great_prayer_day_supplement': 'Add/remove Great Prayer Day supplement',
  'employee_batch.update.form.action.health_benefit': 'Add/remove health insurance',
  'employee_batch.update.form.action.language': 'Change language on payslip',
  'employee_batch.update.form.action.lunch_benefit': 'Add/remove lunch scheme',
  'employee_batch.update.form.action.net_pay_reduction_benefit': 'Add/remove net pay reduction',
  'employee_batch.update.form.action.one_time_pay': 'Give bonus',
  'employee_batch.update.form.action.optional_days': 'Change number of extra holidays',
  'employee_batch.update.form.action.pay_slip_transport': 'Change payslip transport',
  'employee_batch.update.form.action.payslip_text': 'Text on payslip',
  'employee_batch.update.form.action.pension': 'Add/remove pension',
  'employee_batch.update.form.action.personal_days': 'Change number of personal day',
  'employee_batch.update.form.action.placeholder': 'What do you want to do?',
  'employee_batch.update.form.action.position': 'Change position',
  'employee_batch.update.form.action.preferred_tax_card': 'Change tax card',
  'employee_batch.update.form.action.production_unit': 'Change production unit',
  'employee_batch.update.form.action.salary': 'Add/remove/update pay (wage, supplement, etc.)',
  'employee_batch.update.form.action.salary_cycle': 'Change payroll cycle',
  'employee_batch.update.form.action.supplement': 'Add/remove SH-days/Fritvalg',
  'employee_batch.update.form.action.telephone_benefit': 'Add/remove paid phone',
  'employee_batch.update.form.action.terminate': 'Terminate employees',
  'employee_batch.update.form.amount.bonus': 'Amount',
  'employee_batch.update.form.amount.health_benefit': 'Amount for health insurance',
  'employee_batch.update.form.amount.lunch_benefit': 'Amount for lunch scheme',
  'employee_batch.update.form.amount.net_pay_reduction_benefit': 'Net pay reduction',
  'employee_batch.update.form.amount.optional_days': 'Number of extra holidays',
  'employee_batch.update.form.amount.personal_days': 'Number of personal days',
  'employee_batch.update.form.amount.placeholder': 'Amount',
  'employee_batch.update.form.amount.required': 'Amount is required',
  'employee_batch.update.form.amount.required.optional_days': 'Number of extra holidays is required',
  'employee_batch.update.form.amount.required.personal_days': 'Number of personal days is required',
  'employee_batch.update.form.amount.salary.fixed': 'Salary',
  'employee_batch.update.form.amount.salary.hourly': 'Wage',
  'employee_batch.update.form.amount.salary.required.fixed': 'Salary is required',
  'employee_batch.update.form.amount.salary.required.hourly': 'Wage is required',
  'employee_batch.update.form.amount.salary.required.supplement': 'Pay supplement is required',
  'employee_batch.update.form.amount.salary.required.supplement_varied': 'Variable supplement is required',
  'employee_batch.update.form.amount.salary.supplement': 'Pay supplement',
  'employee_batch.update.form.amount.salary.supplement_varied': 'Variable supplement',
  'employee_batch.update.form.amount.suffix': 'kr.',
  'employee_batch.update.form.amount.suffix.lunch.daily': 'kr. per day',
  'employee_batch.update.form.amount.suffix.lunch.standard': 'kr. per period',
  'employee_batch.update.form.asset_category_id': 'Asset category',
  'employee_batch.update.form.asset_category_id.required': 'Asset category is required',
  'employee_batch.update.form.assets_to_return.intro': 'Select the assets to be returned from the employees.',
  'employee_batch.update.form.assets_to_return.none': 'There are no assets to return...',
  'employee_batch.update.form.back': 'Back',
  'employee_batch.update.form.delete_note.line_1.many': 'You are about to delete {count} employees!',
  'employee_batch.update.form.delete_note.line_1.one': 'You are about to delete {count} employee!',
  'employee_batch.update.form.delete_note.line_2':
    'When the employees have been deleted, it {cannot} be undone. Employees can only be manually recreated afterwards.',
  'employee_batch.update.form.delete_note.line_2.not': 'cannot',
  'employee_batch.update.form.delete_note.line_3':
    'Employees with completed payslips cannot be deleted, and an error message will appear below.',
  'employee_batch.update.form.department_id': 'Which department',
  'employee_batch.update.form.department_id.placeholder': 'Department',
  'employee_batch.update.form.department_id.required': 'Department is required',
  'employee_batch.update.form.description.asset': 'Description for asset',
  'employee_batch.update.form.description.required': 'Description is required',
  'employee_batch.update.form.employee_list.note.after.many': 'Action completed for the following {count} employees',
  'employee_batch.update.form.employee_list.note.after.one': 'Action completed for the following employee',
  'employee_batch.update.form.employee_list.note.before.many': 'Action will affect the following {count} employees',
  'employee_batch.update.form.employee_list.note.before.one': 'Action will affect the following employee',
  'employee_batch.update.form.finish': 'Done',
  'employee_batch.update.form.has_lunch_daily.false': 'Fixed amount',
  'employee_batch.update.form.has_lunch_daily.true': 'Per day',
  'employee_batch.update.form.language': 'Language',
  'employee_batch.update.form.one_time_pay_type': 'Bonus type',
  'employee_batch.update.form.one_time_pay_type.required': 'Bonus type is required',
  'employee_batch.update.form.operation': 'Add/remove?',
  'employee_batch.update.form.operation.checked': 'Add',
  'employee_batch.update.form.operation.unchecked': 'Remove',
  'employee_batch.update.form.pay_slip_transport_setting': 'Which transport method to enable/disable?',
  'employee_batch.update.form.pay_slip_transport_setting.e_boks': 'e-Boks',
  'employee_batch.update.form.pay_slip_transport_setting.e_boks.lock': 'Upgrade to Basic to use e-Boks',
  'employee_batch.update.form.pay_slip_transport_setting.email': 'Email',
  'employee_batch.update.form.pay_slip_transport_setting.mit_dk': 'mit.dk',
  'employee_batch.update.form.pay_slip_transport_setting.mit_dk.lock': 'Upgrade to Basic to use mit.dk',
  'employee_batch.update.form.pay_slip_transport_setting.national_inbox_note':
    'We will disable the other national digital inbox automatically, when you enable mit.dk or e-Boks for your employees.',
  'employee_batch.update.form.pay_slip_transport_setting.sms': 'SMS',
  'employee_batch.update.form.pay_slip_transport_setting.sms.lock': 'Upgrade to Basic to use text message',
  'employee_batch.update.form.pension_company_id': 'Pension company',
  'employee_batch.update.form.pension_company_id.required': 'Pension company is required',
  'employee_batch.update.form.pension_customer_id': 'Account number',
  'employee_batch.update.form.pension_customer_id.max_15_characters': 'Account number can at most be 15 characters',
  'employee_batch.update.form.pension_customer_id.required': 'Account number is required',
  'employee_batch.update.form.pension_employee_amount': 'Employee paid pension',
  'employee_batch.update.form.pension_employee_amount.percentage_invalid': 'Percent is invalid',
  'employee_batch.update.form.pension_employee_amount.required.amount': 'Amount is required',
  'employee_batch.update.form.pension_employee_amount.required.percentage': 'Percent is required',
  'employee_batch.update.form.pension_employee_amount.suffix.amount': 'kr.',
  'employee_batch.update.form.pension_employee_amount.suffix.percentage': '%',
  'employee_batch.update.form.pension_employee_percentage.false': 'Fixed amount',
  'employee_batch.update.form.pension_employee_percentage.true': 'Percent',
  'employee_batch.update.form.pension_employer_amount': 'Employer paid pension',
  'employee_batch.update.form.pension_employer_amount.percentage_invalid': 'Percent is invalid',
  'employee_batch.update.form.pension_employer_amount.required.amount': 'Amount is required',
  'employee_batch.update.form.pension_employer_amount.required.percentage': 'Percent is required',
  'employee_batch.update.form.pension_employer_amount.suffix.amount': 'kr.',
  'employee_batch.update.form.pension_employer_amount.suffix.percentage': '%',
  'employee_batch.update.form.pension_employer_percentage.false': 'Fixed amount',
  'employee_batch.update.form.pension_employer_percentage.true': 'Percent',
  'employee_batch.update.form.pension_operation': 'Add/remove pension?',
  'employee_batch.update.form.pension_operation.checked': 'Add',
  'employee_batch.update.form.pension_operation.note':
    'If an employee already has pension, and you leave a field empty, the pension will be removed from the field you left blank.',
  'employee_batch.update.form.pension_operation.unchecked': 'Remove',
  'employee_batch.update.form.pension_union_agreement_number': 'Union agreement number',
  'employee_batch.update.form.pension_union_agreement_number.5_characters':
    'Union agreement number must be 5 characters',
  'employee_batch.update.form.pension_union_agreement_number.tooltip': 'Union agreement number is not required',
  'employee_batch.update.form.preferred_tax_card': 'Preferred tax card',
  'employee_batch.update.form.preferred_tax_card.placeholder': 'Select tax card',
  'employee_batch.update.form.production_unit_id': 'Production unit',
  'employee_batch.update.form.production_unit_id.required': 'Production unit is required',
  'employee_batch.update.form.quantity': 'Quantity',
  'employee_batch.update.form.quantity.required': 'Quantity is required',
  'employee_batch.update.form.rate': 'Rate',
  'employee_batch.update.form.rate.required': 'Rate is required',
  'employee_batch.update.form.rate.suffix': 'kr.',
  'employee_batch.update.form.salary_class': 'Pay type?',
  'employee_batch.update.form.salary_class.fixed': 'Salary',
  'employee_batch.update.form.salary_class.hourly': 'Wage',
  'employee_batch.update.form.salary_class.required': 'Pay type is required',
  'employee_batch.update.form.salary_class.supplement': 'Pay supplement',
  'employee_batch.update.form.salary_class.supplement_varied': 'Variable supplement',
  'employee_batch.update.form.salary_cycle_id': 'Payroll cycle',
  'employee_batch.update.form.salary_cycle_id.placeholder': 'Select payroll cycle',
  'employee_batch.update.form.salary_cycle_id.required': 'Payroll cycle is required',
  'employee_batch.update.form.salary_type_id.hourly': 'Wage type',
  'employee_batch.update.form.salary_type_id.required.hourly': 'Wage type is required',
  'employee_batch.update.form.salary_type_id.required.supplement': 'Pay supplement type is required',
  'employee_batch.update.form.salary_type_id.required.supplement_varied': 'Variable supplement type is required',
  'employee_batch.update.form.salary_type_id.supplement': 'Pay supplement type',
  'employee_batch.update.form.salary_type_id.supplement_varied': 'Variable supplement type',
  'employee_batch.update.form.submit': 'Run',
  'employee_batch.update.form.supplement_compensation_rate': 'Payment',
  'employee_batch.update.form.supplement_compensation_rate.percentage_invalid': 'Percent is invalid',
  'employee_batch.update.form.supplement_compensation_rate.required.amount': 'Amount is required',
  'employee_batch.update.form.supplement_compensation_rate.required.percentage': 'Percent is required',
  'employee_batch.update.form.supplement_compensation_rate.suffix.amount': 'kr.',
  'employee_batch.update.form.supplement_compensation_rate.suffix.percentage': '%',
  'employee_batch.update.form.supplement_percentage.false': 'Fixed amount ',
  'employee_batch.update.form.supplement_percentage.true': 'Percent',
  'employee_batch.update.form.supplement_type_title': 'SH-days/Fritvalg',
  'employee_batch.update.form.supplement_type_title.placeholder': 'Select SH-days/Fritvalg type',
  'employee_batch.update.form.terminate_note.line_1.many': 'You are about to terminate {count} employees!',
  'employee_batch.update.form.terminate_note.line_1.one': 'You are about to terminate {count} employee!',
  'employee_batch.update.form.terminate_note.line_2':
    'When the employees have been terminated, they can only be rehired manually.',
  'employee_batch.update.form.title.asset': 'Title for asset',
  'employee_batch.update.form.title.net_pay_reduction_benefit': 'Title for net pay reduction',
  'employee_batch.update.form.title.one_time_pay': 'Title for bonus',
  'employee_batch.update.form.title.payslip_text': 'Text on payslip',
  'employee_batch.update.form.title.required': 'Title is required',
  'employee_batch.update.form.title.required.payslip_text': 'Text on payslip is required',
  'employee_batch.update.form.title.salary.fixed': 'Text for salary',
  'employee_batch.update.form.title.salary.hourly': 'Text for wage',
  'employee_batch.update.form.title.salary.required.supplement': 'Text for pay supplement is required',
  'employee_batch.update.form.title.salary.supplement': 'Text for pay supplement',
  'employee_batch.update.form.title.salary.supplement_varied': 'Text for variable supplement',
  'employee_batch.update.form.valid_from': 'With effect from',
  'employee_batch.update.form.valid_from_picker.required.terminate': 'Last workday is required',
  'employee_batch.update.form.valid_from_picker.terminate': 'Last workday',
  'employee_batch.update.form.valid_from.now': 'Immediately',
  'employee_batch.update.loading.no_task': 'Bulk action initiated',
  'employee_batch.update.loading.task':
    'Bulk action initiated and running in the background.  If you navigate away from this page, the bulk action will still be completed, however you will not be able to see the result.',
  'employee_batch.update.title': 'Bulk action',
  'employee_batch.wizard.step.0.title': 'Step 1',
  'employee_batch.wizard.step.1.title': 'Step 2',
  'employee_batch.wizard.steps.0': 'Find employees',
  'employee_batch.wizard.steps.1': 'Bulk action',
  'employee_batch.wizard.steps.intro':
    'After just two steps, you will have performed a bulk action on a group of employees',
  'employee_batch.wizard.steps.title': 'Employee bulk action',

  'employee_delete.alert.success': '{name} has been deleted',
  'employee_delete.button.employee': 'Delete the employee {name}',
  'employee_delete.button.freelancer': 'Delete the freelancer {name}',
  'employee_delete.intro.employee.line_1': 'Delete the employee permanently, and stop all future payments, etc.',
  'employee_delete.intro.employee.line_2': 'The employee has never been paid through Salary, so can be deleted.',
  'employee_delete.intro.freelancer.line_1': 'Delete the freelancer permanently, and stop all future payments, etc.',
  'employee_delete.intro.freelancer.line_2': 'The freelancer has never been paid through Salary, so can be deleted.',
  'employee_delete.title.employee': 'Delete employee',
  'employee_delete.title.freelancer': 'Delete freelancer',

  'employee_filter.department': 'Department',
  'employee_filter.department.none': 'No department',
  'employee_filter.search': 'Search...',
  'employee_filter.searching_for': 'searching for "{query}"',
  'employee_filter.searching_in_department': 'in department {department}',
  'employee_filter.searching_in_no_department': 'without department',

  'employee_leave.form.end_date': 'Leave to',
  'employee_leave.form.end_date.required': 'Leave to is required',
  'employee_leave.form.invalid_period': 'Leave period is not valid',
  'employee_leave.form.start_date': 'Leave from',
  'employee_leave.form.start_date.required': 'Leave from is required',
  'employee_leave.form.submit': 'Create leave for employee',
  'employee_leave.title.create': 'Create leave for employee',
  'employee_leave.title.delete': "Cancel employee's leave",
  'employee_leave.title.delete.button': 'Cancel leave',

  'employee_no_contract.button': 'Add employment',
  'employee_no_contract.text': 'Add an employment to pay the employee.',
  'employee_no_contract.title': 'This employee has no employment.',

  'employee_no_employment.link': 'Write support',
  'employee_no_employment.text': "Contact support to recreate the employee's employment.",
  'employee_no_employment.title': 'This employee has been terminated.',

  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id': 'Booking account',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_account': 'No account',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.generic':
    'The account is no longer available',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.option_note':
    '(no longer available)',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.specific':
    'The account {account} is no longer available',
  'employee_nondisburseable_account.edit.title': 'Edit the company card account for {name}',

  'employee_search.form.created_before': 'Only employees created before date',
  'employee_search.form.department_id': 'Department',
  'employee_search.form.department_id.all': 'All departments',
  'employee_search.form.department_id.placeholder': 'All departments',
  'employee_search.form.employment_type': 'Employment type',
  'employee_search.form.employment_type.all': 'All employment types',
  'employee_search.form.employment_type.placeholder': 'All employment types',
  'employee_search.form.employment_types.commissioned': 'Commissioned',
  'employee_search.form.employment_types.freelancer': 'Freelancers',
  'employee_search.form.employment_types.full_time': 'Full time',
  'employee_search.form.employment_types.hourly': 'Waged',
  'employee_search.form.employment_types.part_time': 'Part time',
  'employee_search.form.not_selected': 'No selected employees',
  'employee_search.form.not_selected.footer.many': '{count} employees.',
  'employee_search.form.not_selected.footer.one': '{count} employee.',
  'employee_search.form.not_selected.select': 'Select employee',
  'employee_search.form.not_selected.select_all': 'Select all',
  'employee_search.form.only_draft': 'Only employees in draft state',
  'employee_search.form.only_mutable': 'Only newly hired (for whom there is {not} finished payroll yet)',
  'employee_search.form.only_mutable.not': 'no',
  'employee_search.form.reset_fields': 'Reset filters',
  'employee_search.form.salary_cycle_id': 'Period',
  'employee_search.form.salary_cycle_id.all': 'All periods',
  'employee_search.form.salary_cycle_id.placeholder': 'All periods',
  'employee_search.form.search_query': 'Search',
  'employee_search.form.search_query.placeholder': 'Free text',
  'employee_search.form.selected': 'Selected employees',
  'employee_search.form.selected.footer.many': '{count} employees.',
  'employee_search.form.selected.footer.one': '{count} employee.',
  'employee_search.form.selected.select': 'De-select employee',
  'employee_search.form.selected.select_all': 'De-select all',

  'employee_settings.alert.success': 'Settings for {name} have been saved.',
  'employee_settings.form.employee_automatic_optional_vacation_transfer_limit':
    'Employee may transfer extra holidays/personal days',
  'employee_settings.form.employee_automatic_vacation_transfer_limit': 'Employee may transfer holidays',
  'employee_settings.form.employee_enable_reimbursement_voucher': 'Employee can register outlays',
  'employee_settings.form.employee_enable_work_hours': 'Employee can register working hours',
  'employee_settings.form.employee_registrations_never_approved_from_employee_app':
    'Registrations created in employee app are never automatically approved',
  'employee_settings.form.employee_vacation_excess_limit': 'Employee may borrow holidays',
  'employee_settings.intro.line_1': 'From here you can alter in some general settings for the employee.',
  'employee_settings.intro.line_2':
    'If there are no settings on this page, you are not using the features they require.',
  'employee_settings.title': 'Employee settings',

  'employee_single.alert.leave.success': '{name} has received leave',
  'employee_single.alert.rehire.success': '{name} has been rehired',
  'employee_single.menu.car_allowance': 'Transport allowance',
  'employee_single.menu.contract_history': 'Employment timeline',
  'employee_single.menu.employment': 'Employment',
  'employee_single.menu.leave': 'Absence',
  'employee_single.menu.pay_slips': 'Payslips',
  'employee_single.menu.profile': 'Profile',
  'employee_single.menu.project_registration': 'Project',
  'employee_single.menu.salary_registration': 'Pay supplements',
  'employee_single.menu.settings': 'Settings',
  'employee_single.menu.start_balances': 'Start balances',
  'employee_single.menu.tax_cards': 'Tax cards',
  'employee_single.menu.time_box_registration.flex': 'Flex',
  'employee_single.menu.time_box_registration.overtime': 'Overtime',
  'employee_single.menu.time_registration': 'Time registrations',
  'employee_single.menu.work_hours_registration': 'Working time',
  'employee_single.profile.alert.success': "The employee's profile has been saved",
  'employee_single.profile.form.address': 'Address',
  'employee_single.profile.form.address.required': 'Address is required',
  'employee_single.profile.form.bank_account_number': 'Account number',
  'employee_single.profile.form.bank_account_number.invalid': 'Account number is invalid',
  'employee_single.profile.form.bank_account_number.required': 'Account number is required',
  'employee_single.profile.form.bank_info_title': 'Bank information',
  'employee_single.profile.form.bank_registration_number': 'Registration number',
  'employee_single.profile.form.bank_registration_number.invalid': 'Registration number is invalid',
  'employee_single.profile.form.bank_registration_number.required': 'Registration number is required',
  'employee_single.profile.form.birth_date': 'Birth date',
  'employee_single.profile.form.birth_date.invalid': 'Birth date is invalid',
  'employee_single.profile.form.birth_date.required': 'Birth date is required',
  'employee_single.profile.form.city': 'City',
  'employee_single.profile.form.city.required': 'City is required',
  'employee_single.profile.form.country': 'Country',
  'employee_single.profile.form.country.not_gb':
    'For the United Kingdom, select either Great Britain or Northern Ireland',
  'employee_single.profile.form.country.placeholder': 'Select country',
  'employee_single.profile.form.country.required': 'Country is required',
  'employee_single.profile.form.email': 'Email',
  'employee_single.profile.form.email.invalid': 'Email is invalid',
  'employee_single.profile.form.emergency_contact_title': 'Next of kin',
  'employee_single.profile.form.emergency_contact.country_code': 'Country code',
  'employee_single.profile.form.emergency_contact.country_code.invalid': 'Country code is invalid',
  'employee_single.profile.form.emergency_contact.country_code.required': 'Country code is required',
  'employee_single.profile.form.emergency_contact.email': 'Email',
  'employee_single.profile.form.emergency_contact.email.invalid': 'Email is invalid',
  'employee_single.profile.form.emergency_contact.name': 'Name',
  'employee_single.profile.form.emergency_contact.phone_number': 'Phone number',
  'employee_single.profile.form.emergency_contact.phone_number.invalid': 'Phone number is invalid',
  'employee_single.profile.form.emergency_contact.relation': 'Relation',
  'employee_single.profile.form.employee_number': 'Employee number',
  'employee_single.profile.form.employee_number.in_use': 'Employee number is in use',
  'employee_single.profile.form.employee_number.invalid': 'Employee number is invalid',
  'employee_single.profile.form.employee_number.required': 'Employee number is required',
  'employee_single.profile.form.gender': 'Gender',
  'employee_single.profile.form.gender.placeholder': 'Select gender',
  'employee_single.profile.form.gender.required': 'Gender is required',
  'employee_single.profile.form.language': 'Language',
  'employee_single.profile.form.language.placeholder': 'Select language',
  'employee_single.profile.form.language.required': 'Language is required',
  'employee_single.profile.form.name': 'Name',
  'employee_single.profile.form.name.required': 'Name is required',
  'employee_single.profile.form.national_id_type': 'Type',
  'employee_single.profile.form.national_id_type.placeholder': 'Select type',
  'employee_single.profile.form.national_id_type.required': 'Type is required',
  'employee_single.profile.form.national_id_unlock': 'Unlock the field to edit it',
  'employee_single.profile.form.national_id.look_up': 'Look up the CPR number in CPR',
  'employee_single.profile.form.postal_code': 'Postcode',
  'employee_single.profile.form.postal_code.required': 'Postcode is required',
  'employee_single.profile.form.tin': 'TIN',
  'employee_single.profile.form.tin.intro':
    'TIN (Tax Identification Number) is an international tax identification number, which is required to provide employees in other EU countries per 1 January 2024.',
  'employee_single.profile.form.tin.read_more': 'Read more {link}.',
  'employee_single.profile.form.tin.read_more.link': "at EU's website",
  'employee_single.profile.form.transfer_destination_type': 'Transfer type:',
  'employee_single.profile.form.transfer_destination_type.dk_account': 'Normal bank transfer',
  'employee_single.profile.form.transfer_destination_type.easy_account': 'Easy Account',
  'employee_single.profile.form.transfer_destination_type.none': 'Foreign account - manual transfer',
  'employee_single.profile.form.use_manual_transfer': 'Foreign account - manual transfer',
  'employee_single.profile.title': 'Profile',
  'employee_single.rehire.alert.success.employee': '{name} has been rehired',
  'employee_single.rehire.alert.success.freelancer': '{name} has been reactivated',
  'employee_single.rehire.form.start_date.employee': 'Employment date',
  'employee_single.rehire.form.start_date.freelancer': 'Reactivation date',
  'employee_single.rehire.form.start_date.required.employee': 'Employment date',
  'employee_single.rehire.form.start_date.required.freelancer': 'Reactivation date is required',
  'employee_single.rehire.form.submit.employee': 'Rehired the employee',
  'employee_single.rehire.form.submit.freelancer': 'Reactivate the employee',
  'employee_single.rehire.title.employee': 'Rehired the employee',
  'employee_single.rehire.title.freelancer': 'Reactivate the employee',
  'employee_single.warning.employee_is_foreign_resident':
    "The employee does not live in Denmark, according to Skat.  If they do live abroad, you will need to change their type to 'CPR number residing outside Denmark' under profile before the next payroll.  If they do live in Denmark, your employee must change their address with the national registration office.",
  'employee_single.warning.in_draft_state':
    'The employee is a draft state, its missing fields needs to be filled in before the employee is ready for payroll',
  'employee_single.warning.invalid_birth_date': "The employee's birth date is invalid.",
  'employee_single.warning.start_balances_need_adjustment': "The employee's start balances need adjustments",

  'employee_terminate.alert.success.freelancer': '{name} has been hidden',
  'employee_terminate.alert.success.standard': '{name} has been terminated',
  'employee_terminate.employed.freelancer': 'Hired',
  'employee_terminate.employed.standard': 'Employed',
  'employee_terminate.form.end_date.deadline_format': '{field} (earliest {date})',
  'employee_terminate.form.end_date.freelancer': 'Hide date',
  'employee_terminate.form.end_date.not_before_immutable': '{field} cannot be before the next payroll is over',
  'employee_terminate.form.end_date.required': '{field} is required',
  'employee_terminate.form.end_date.standard': 'Termination date',
  'employee_terminate.form.submit.freelancer': 'Hide the freelancer {name}',
  'employee_terminate.form.submit.standard': 'Terminate the employee {name}',
  'employee_terminate.intro.freelancer': 'Hide the freelancer from the list of freelancers.',
  'employee_terminate.intro.standard.line_1': 'Terminate the employee, and stop all future payments.',
  'employee_terminate.intro.standard.line_2': 'Select the date to which the employee will get paid.',
  'employee_terminate.intro.standard.line_3':
    'The employee will be saved in the system for five years, in accordance with the law.',
  'employee_terminate.title.freelancer': 'Hide freelancer',
  'employee_terminate.title.standard': 'Terminate employee',

  'employee.add.base_data.address': 'Address',
  'employee.add.base_data.address.required': 'Address is required',
  'employee.add.base_data.birth_date': 'Birth date',
  'employee.add.base_data.birth_date.invalid': 'Birth date is invalid',
  'employee.add.base_data.birth_date.required': 'Birth date is required',
  'employee.add.base_data.city': 'City',
  'employee.add.base_data.city.required': 'City is required',
  'employee.add.base_data.contract_template_id': 'Employee template',
  'employee.add.base_data.contract_template_id.none': 'No template',
  'employee.add.base_data.contract_template_id.placeholder': 'Select template',
  'employee.add.base_data.country': 'Country',
  'employee.add.base_data.country.not_gb': 'For the United Kingdom, select either Great Britain or Northern Ireland',
  'employee.add.base_data.country.placeholder': 'Select country',
  'employee.add.base_data.country.required': 'Country is required',
  'employee.add.base_data.department_id': 'Department',
  'employee.add.base_data.department_id.only_accessible': 'You can only select a department you have access to',
  'employee.add.base_data.department_id.placeholder': 'Select department',
  'employee.add.base_data.department_id.required': 'Department is required',
  'employee.add.base_data.email': 'Email',
  'employee.add.base_data.email.invalid': 'Email is invalid',
  'employee.add.base_data.employee_number': 'Employee number',
  'employee.add.base_data.employee_number.in_use': 'Employee number is already in use',
  'employee.add.base_data.employee_number.invalid': 'Employee number is invalid',
  'employee.add.base_data.employee_number.required': 'Employee number is required',
  'employee.add.base_data.employment_start_date': 'Employment date in the company',
  'employee.add.base_data.employment_start_date.required': 'Employment date in the company is required',
  'employee.add.base_data.gender': 'Gender',
  'employee.add.base_data.gender.placeholder': 'Select gender',
  'employee.add.base_data.gender.required': 'Gender is required',
  'employee.add.base_data.name': 'Name',
  'employee.add.base_data.name.required': 'Name is required',
  'employee.add.base_data.national_id_type': 'Create via',
  'employee.add.base_data.national_id_type.placeholder': 'Select type',
  'employee.add.base_data.national_id_type.required': 'Create via is required',
  'employee.add.base_data.national_id.look_up': 'Look up the CPR number in CPR',
  'employee.add.base_data.postal_code': 'Postcode',
  'employee.add.base_data.postal_code.required': 'Postcode is required',
  'employee.add.base_data.submit': 'Continue to communication',
  'employee.add.base_data.tin': 'TIN',
  'employee.add.base_data.tin.intro':
    'TIN (Tax Identification Number) is an international tax identification number, which is required to provide for employees in other countries per 1 January 2024.',
  'employee.add.base_data.tin.read_more': 'Read more {link}.',
  'employee.add.base_data.tin.read_more.link': "on EU's website",
  'employee.add.base_data.title.freelancer': 'Fill in the details for the freelancer',
  'employee.add.base_data.title.standard': 'Fill in the details for the employee',
  'employee.add.communication.back': 'Back',
  'employee.add.communication.language': 'Language',
  'employee.add.communication.language.required': 'Language is required',
  'employee.add.communication.language.select': 'Select language',
  'employee.add.communication.pay_slip_transport': "How to send the employee's payslips?",
  'employee.add.communication.submit': 'Continue to financial information',
  'employee.add.communication.title': 'Communication',
  'employee.add.financial_data.back': 'Back',
  'employee.add.financial_data.bank_account_number': 'Account number',
  'employee.add.financial_data.bank_account_number.invalid': 'Account number is invalid',
  'employee.add.financial_data.bank_account_number.required': 'Account number is required',
  'employee.add.financial_data.bank_registration_number': 'Registration number',
  'employee.add.financial_data.bank_registration_number.invalid': 'Registration number is invalid',
  'employee.add.financial_data.bank_registration_number.required': 'Registration number is required',
  'employee.add.financial_data.income_type': 'Income type',
  'employee.add.financial_data.income_type.lock':
    'Enable more income types under company - advanced to edit this field',
  'employee.add.financial_data.income_type.placeholder': 'Select income type',
  'employee.add.financial_data.income_type.required': 'Income type is required',
  'employee.add.financial_data.preferred_tax_card_type': 'Preferred tax card',
  'employee.add.financial_data.preferred_tax_card_type.placeholder': 'Select tax card',
  'employee.add.financial_data.preferred_tax_card_type.required': 'Preferred tax card is required',
  'employee.add.financial_data.submit': 'Save',
  'employee.add.financial_data.title': 'Financial information',
  'employee.add.financial_data.transfer_destination_type': 'Transfer type:',
  'employee.add.financial_data.transfer_destination_type.dk_account': 'Normal bank transfer',
  'employee.add.financial_data.transfer_destination_type.easy_account': 'Easy Account',
  'employee.add.financial_data.transfer_destination_type.none': 'Foreign account - manual transfer',
  'employee.add.financial_data.use_manual_transfer': 'Foreign account - manual transfer',
  'employee.add.invite.department_id': 'Department',
  'employee.add.invite.department_id.placeholder': 'Select department',
  'employee.add.invite.email': 'Email',
  'employee.add.invite.email.invalid': 'Email is invalid',
  'employee.add.invite.email.required': 'Email is required',
  'employee.add.invite.employee_number': 'Employee number',
  'employee.add.invite.employee_number.in_use': 'Employee number is already in use',
  'employee.add.invite.employee_number.invalid': 'Employee number is invalid',
  'employee.add.invite.employee_number.required': 'Employee number is required',
  'employee.add.invite.employment_start_date': 'Employment date in company',
  'employee.add.invite.employment_start_date.required': 'Employment date in company is required',
  'employee.add.invite.submit': 'Send invitation',
  'employee.add.invite.title': 'Invite employee',
  'employee.add.step_0.title': 'Step 1',
  'employee.add.step_1.title': 'Step 2',
  'employee.add.step_2.title': 'Step 3',
  'employee.add.steps.0': 'Base data',
  'employee.add.steps.1': 'Communication',
  'employee.add.steps.2': 'Financial information',
  'employee.add.steps.contract_book.intro': 'Below are those fields, we could not automatically fill in.',
  'employee.add.steps.contract_book.missing': '[not filled]',
  'employee.add.steps.contract_book.title': 'ContractBook fields',
  'employee.add.steps.intro': 'After these three steps, you will have created an employee',
  'employee.add.steps.support_note':
    'If you have any issues creating an employee, can you always contact {email}. We are looking forward to helping you!',
  'employee.add.steps.title': 'Add employee',
  'employee.employee_pay_type.commissioned': 'Commissioned',
  'employee.employee_pay_type.hourly_paid': 'Waged',
  'employee.employee_pay_type.salaried': 'Salaried',
  'employee.employment_type.commissioned': 'Commissioned',
  'employee.employment_type.fixed': 'Salaried',
  'employee.employment_type.freelancer': 'Freelancer',
  'employee.employment_type.full_time': 'Full time',
  'employee.employment_type.hourly': 'Waged',
  'employee.employment_type.part_time': 'Part time',
  'employee.national_id.cpr': 'CPR number',
  'employee.national_id.cpr_foreign': 'CPR number residing outside Denmark',
  'employee.national_id.cvr': 'CVR number',
  'employee.national_id.no_cpr': 'No CPR number',
  'employee.sidebar.actions.assets': 'Assets',
  'employee.sidebar.actions.assets.lock': 'Upgrade to Premium to manage assets for your employees',
  'employee.sidebar.actions.balance_adjustments': 'Balance adjustments',
  'employee.sidebar.actions.bonus': 'Give bonus',
  'employee.sidebar.actions.delete_leave': 'Delete leave',
  'employee.sidebar.actions.documents': 'Documents',
  'employee.sidebar.actions.documents.lock': 'Upgrade to Premium to manage documents for your employees',
  'employee.sidebar.actions.e_income_text': 'Text on payslip',
  'employee.sidebar.actions.extra_pension': 'Additional pension',
  'employee.sidebar.actions.free_text': 'Text on payslip',
  'employee.sidebar.actions.give_leave': 'Give leave',
  'employee.sidebar.actions.pay_check_advance': 'Net reduction',
  'employee.sidebar.actions.reimbursement': 'Create outlay',
  'employee.sidebar.actions.salary_reduction': 'Gross reduction',
  'employee.sidebar.actions.travel_allowance': 'Travel allowance',
  'employee.sidebar.contract_switcher.active': '{from} - {to} (active)',
  'employee.sidebar.contract_switcher.other': '{from} - {to}',
  'employee.sidebar.contract_switcher.title': 'Select contract',
  'employee.sidebar.current_pay_slip.download': 'Download',
  'employee.sidebar.current_pay_slip.title': 'Ongoing payslip',
  'employee.sidebar.details.address': 'Address',
  'employee.sidebar.details.city': 'City',
  'employee.sidebar.details.department': 'Department',
  'employee.sidebar.details.department.none': 'None',
  'employee.sidebar.details.email': 'Email',
  'employee.sidebar.details.emergency_contact': 'Next of kin',
  'employee.sidebar.details.employee_number': 'Employee number',
  'employee.sidebar.details.phone_number': 'Phone number',
  'employee.sidebar.details.position': 'Position',
  'employee.sidebar.employee_app.feature_lock': 'Upgrade to Basic to use the employee app',
  'employee.sidebar.employee_app.invite': 'Invite to employee app',
  'employee.sidebar.employee_app.invite_sent': 'Invitation to the employee app was sent {at}.',
  'employee.sidebar.employee_app.loading': 'Loading app information...',
  'employee.sidebar.employee_app.lock': 'Enter email address to invite to employee app',
  'employee.sidebar.employee_app.remove_access': 'Remove access to employee app',
  'employee.sidebar.employee_app.resend_invite': 'Resend invitation',
  'employee.sidebar.employee_app.title': 'Employee app',
  'employee.sidebar.employee_invite.success': 'Invitation to employee app has been sent.',
  'employee.sidebar.employee_switcher.title': 'Go to employee',
  'employee.sidebar.loading': 'Loading employee...',
  'employee.sidebar.pay_slips': 'Payslips',
  'employee.sidebar.upload_profile_image.create': 'Upload profile image',
  'employee.sidebar.upload_profile_image.edit': 'Change profile image',
  'employee.sidebar.upload_profile_image.uploading': 'Loading profile image...',

  'employees_excel_import.step_four.all_imported': 'All employees imported.',
  'employees_excel_import.step_four.button.done': 'Done',
  'employees_excel_import.step_four.table.header.name': 'Employee',
  'employees_excel_import.step_four.table.header.national_id': 'CPR number',
  'employees_excel_import.step_four.table.header.state': 'Change status',
  'employees_excel_import.step_four.table.header.state_message': 'Description',
  'employees_excel_import.step_one.button.download': 'Download Excel file',
  'employees_excel_import.step_one.button.skip': 'Continue to upload',
  'employees_excel_import.step_one.intro':
    'Download the Excel file with a template for employees via the button below. If you already have downloaded and filled in the Excel file, you can continue directly to the upload.',
  'employees_excel_import.step_three.button.back': 'Back',
  'employees_excel_import.step_three.button.save': 'Create employees',
  'employees_excel_import.step_three.importing': 'Saving...',
  'employees_excel_import.step_three.no_changes': 'There were no changes',
  'employees_excel_import.step_three.table.employment_format.date': 'Date: {date}',
  'employees_excel_import.step_three.table.employment_format.hourly': '{position} ({pay_type} at {amount}/hour)',
  'employees_excel_import.step_three.table.employment_format.non_hourly': '{position} ({pay_type} at {amount})',
  'employees_excel_import.step_three.table.header.account': 'Bank information',
  'employees_excel_import.step_three.table.header.contact': 'Contact',
  'employees_excel_import.step_three.table.header.employment': 'Employment',
  'employees_excel_import.step_three.table.header.name': 'Employee',
  'employees_excel_import.step_three.table.header.national_id': 'CPR number',
  'employees_excel_import.step_three.table.will_be_updated': 'The employee will be updated',
  'employees_excel_import.step_two.button.back': 'Back',
  'employees_excel_import.step_two.button.upload': 'Upload Excel file',
  'employees_excel_import.step_two.intro': 'Upload the filled in Excel file with your employees via the button below.',
  'employees_excel_import.step_two.loading': 'Loading...',
  'employees_excel_import.steps.1': 'Download Excel file',
  'employees_excel_import.steps.2': 'Upload Excel file',
  'employees_excel_import.steps.3': 'Confirm',
  'employees_excel_import.steps.4': 'Result',
  'employees_excel_import.title': 'Import employees via Excel file',

  'employees.awaiting.title': 'Awaiting employment creation',
  'employees.contract_book.error.creation_error':
    'Something went wrong during the creation of the ContractBook contract, try again or write support.',
  'employees.contract_book.error.no_template':
    'We could not find the ContractBook template, check your ContractBook integration or write support.',
  'employees.contract_book.form.template_id': 'Template',
  'employees.contract_book.form.template_id.placeholder': 'Select template',
  'employees.contract_book.form.template_id.submit': 'Go to ContractBook',
  'employees.contract_book.title': 'Add employee via ContractBook',
  'employees.header.add_by_contract_book': 'New via ContractBook',
  'employees.header.add_employee': 'New employee',
  'employees.header.import_employees': 'Import employees',
  'employees.header.add_employee_lock': 'Upgrade to Basic, to add more employees',
  'employees.header.batch': 'Bulk action',
  'employees.header.employee_templates': 'Employee templates',
  'employees.header.invite_employee': 'Invite employee',
  'employees.header.show_pay': 'Show pay',
  'employees.hide': 'Hide',
  'employees.on_leave.title': 'On leave',
  'employees.show': 'Show',
  'employees.table.action.delete': 'Delete',
  'employees.table.action.details': 'See details',
  'employees.table.action.edit': 'Edit',
  'employees.table.action.give_leave': 'Arrange leave',
  'employees.table.action.terminate': 'Terminate',
  'employees.table.add_employment': 'Add employment',
  'employees.table.fill_in_information': 'Fill in information',
  'employees.table.header.employee': 'Employee',
  'employees.table.header.employment': 'Employment',
  'employees.table.header.end_date': 'Terminated',
  'employees.table.header.gross_pay': 'Gross pay',
  'employees.table.header.start_date': 'Employed',
  'employees.table.hourly_pay_format': '{amount}/hour',
  'employees.table.pay_hidden': 'Hidden',
  'employees.table.termination_at': 'Termination at {date}',
  'employees.terminated.title': 'Terminated',
  'employees.title.employee.many': '{count} employees',
  'employees.title.employee.one': '{count} employee',
  'employees.title.more_loading': 'more loading...',

  'employment_details.card.actions.create_future_contract': 'Create future contract',
  'employment_details.card.actions.delete': 'Delete',
  'employment_details.card.actions.delete_contract': 'Delete contract',
  'employment_details.card.actions.delete_contract.future': 'Delete future contract',
  'employment_details.card.actions.mark_ready': 'Mark ready for payroll',
  'employment_details.card.actions.mark_unready': 'Remove ready mark for payroll',
  'employment_details.card.actions.mark_unready.readied_by': 'Marked ready by {name}.',
  'employment_details.card.actions.rehire': 'Rehire',
  'employment_details.card.actions.terminate': 'Terminate',
  'employment_details.card.field.department': 'Department',
  'employment_details.card.field.department.none': 'None selected',
  'employment_details.card.field.employee_dimension': 'Employee dimension',
  'employment_details.card.field.employment_date': 'Employment date',
  'employment_details.card.field.employment_position': 'Position',
  'employment_details.card.field.family_leave_fund': 'Family leave',
  'employment_details.card.field.income_type': 'Income type',
  'employment_details.card.field.period.future': 'Future contract',
  'employment_details.card.field.period.past': 'Expired contract',
  'employment_details.card.field.period.valid': 'Active contract',
  'employment_details.card.field.position': 'Position description',
  'employment_details.card.field.production_unit': 'Work place',
  'employment_details.card.title': 'Employment',
  'employment_details.card.valid_period.end_date': '{from} - {to}',
  'employment_details.card.valid_period.no_end_date': '{from} - now',
  'employment_details.edit.form.department_id': 'Department',
  'employment_details.edit.form.department_id.none': 'None',
  'employment_details.edit.form.department_id.placeholder': 'Select department',
  'employment_details.edit.form.dimension_id': 'Employee dimension',
  'employment_details.edit.form.dimension_id.none': 'None',
  'employment_details.edit.form.dimension_id.placeholder': 'Select employee dimension',
  'employment_details.edit.form.dimension_value_id.none': 'None',
  'employment_details.edit.form.dimension_value_id.none_or_new': 'None or new',
  'employment_details.edit.form.dimension_value_id.placeholder': 'Select value for {code}',
  'employment_details.edit.form.employment_start_date': 'Employment date',
  'employment_details.edit.form.employment_start_date.not_before_contract':
    "Employment date cannot be before the contract's valid from",
  'employment_details.edit.form.employment_start_date.required': 'Employment date is required',
  'employment_details.edit.form.family_leave_fund': 'Family leave',
  'employment_details.edit.form.family_leave_fund.placeholder': 'Select family leave',
  'employment_details.edit.form.future.valid_from': 'Valid from',
  'employment_details.edit.form.future.valid_from.note':
    'You cannot alter {em} on a future contract, instead you should delete it and create a new with a different date.',
  'employment_details.edit.form.future.valid_from.note.em': 'valid from',
  'employment_details.edit.form.income_type': 'Income type',
  'employment_details.edit.form.income_type.lock':
    'Enable more income types under company - advanced to edit this field',
  'employment_details.edit.form.income_type.placeholder': 'Select income type',
  'employment_details.edit.form.income_type.required': 'Income type is required',
  'employment_details.edit.title': 'Edit employment',
  'employment_details.edit.warning.at_date': 'Changes take effect at {date}.',
  'employment_details.edit.warning.immediate': 'Changes take effect immediately.',

  'employment_pay.card.employment.salaried.full_time': '{title} (full time)',
  'employment_pay.card.employment.salaried.part_time': '{title} (part time)',
  'employment_pay.card.fields.cycle': 'Period',
  'employment_pay.card.fields.employment': 'Employment',
  'employment_pay.card.fields.weekly_hours': 'Work hours',
  'employment_pay.card.fields.weekly_hours.days.many': '{count} days',
  'employment_pay.card.fields.weekly_hours.days.one': '{count} day',
  'employment_pay.card.fields.weekly_hours.hours.many': '{count} hours',
  'employment_pay.card.fields.weekly_hours.hours.one': '{count} hour',
  'employment_pay.card.fields.weekly_hours.weeks.many': '{count} weeks',
  'employment_pay.card.fields.weekly_hours.weeks.one': '{count} week',
  'employment_pay.card.table.frequency_format': 'kr. {frequency}',
  'employment_pay.card.table.frequency.hourly': 'per hour',
  'employment_pay.card.table.frequency.piece': 'per piece',
  'employment_pay.card.table.header.rate': 'Remuneration',
  'employment_pay.card.table.header.title': 'Description',
  'employment_pay.card.table.header.type': 'Remuneration type',
  'employment_pay.card.table.quantity_format': '{quantity} × {rate} ({total}) {frequency}',
  'employment_pay.card.table.rate_format': '{rate} {frequency}',
  'employment_pay.card.table.rate_format.with_supplement': '(with supplements)',
  'employment_pay.card.title': 'Remuneration',
  'employment_pay.edit.contract_payment.pay_type.description':
    'Remove all remuneration lines which are not pay supplement to create a commissioned employee',
  'employment_pay.edit.contract_payment.salary_cycle_id.freelancer': 'Compensation payment',
  'employment_pay.edit.contract_payment.salary_cycle_id.placeholder': 'Select cycle',
  'employment_pay.edit.contract_payment.salary_cycle_id.required': 'Cycle is required',
  'employment_pay.edit.contract_payment.salary_cycle_id.standard': 'Payroll period',
  'employment_pay.edit.contract_period_work_hours.period_work_hours': 'Hours for period',
  'employment_pay.edit.contract_period_work_hours.period_work_hours.invalid': 'Hours is invalid',
  'employment_pay.edit.contract_period_work_hours.period_work_hours.placeholder': 'Number of hours',
  'employment_pay.edit.contract_period_work_hours.period_work_hours.suffix': 'hours per period',
  'employment_pay.edit.contract_worktime.add_week': 'Add week',
  'employment_pay.edit.contract_worktime.period_work_hours': 'Report a different number of work hours than calculated',
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method':
    'Always use simple period hours calculation',
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method.help.line_1':
    "Normally for monthly salaried employees the system users Skat's method for calculating monthly work hours.  That is weekly hours × (4+1/3).  So for 37 weekly hours (normal time) the result is 160.33 hours, regardless of how many hours the employee actually worked.",
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method.help.line_2':
    'For other salaried employees, including those monthly who are only working a partial portion of the month, the system uses a simpler method, which is actual worked days × weekly hours / work days per week.  So for a normal work week with 37 weekly hours (normal time) in January 2025 (where there are 22 work days) the result would be 162.8 hours.',
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method.help.line_3':
    "Normally you should always use Skat's method for calculating monthly work hours, if you are a monthly salaried employee.  There can however be exceptions, and this setting allows you to force the system to always use the simple method, even if the employee fulfils the requirements for Skat's method.",
  'employment_pay.edit.contract_worktime.period_work_hours.invalid': 'Hours is invalid',
  'employment_pay.edit.contract_worktime.period_work_hours.placeholder': 'Number of hours',
  'employment_pay.edit.contract_worktime.period_work_hours.suffix': 'hours per period',
  'employment_pay.edit.contract_worktime.remove_week': 'Remove work week',
  'employment_pay.edit.contract_worktime.weekly_hours': 'Work hours',
  'employment_pay.edit.contract_worktime.weekly_hours.invalid': 'Work hours is invalid',
  'employment_pay.edit.contract_worktime.weekly_hours.required': 'Work hours is required',
  'employment_pay.edit.contract_worktime.weekly_hours.suffix': 'hours per week',
  'employment_pay.edit.contract_worktime.weekly_work_days': 'Work days',
  'employment_pay.edit.contract_worktime.weekly_work_days.suffix': 'days per week',
  'employment_pay.edit.contract_worktime.work_cycle_anchor_date.description':
    'To know exactly which calendar week a work cycle week covers, you will need to provide a date that appears in the first work cycle week.  This date is called the "anchor date".',
  'employment_pay.edit.contract_worktime.work_cycle_anchor_date.required': 'Anchor date is required',
  'employment_pay.edit.contract_worktime.work_cycle.week_no': '{number} week',
  'employment_pay.edit.contract_worktime.work_cycle.which_week':
    'This week would cover - as an example - the calendar week {weekNo} ({start} – {end})',
  'employment_pay.edit.contract_worktime.work_days.note':
    'When another value than 5 weekly work days, you can chose exactly which week days work occurs.',
  'employment_pay.edit.contract_worktime.work_days.note.multiple_weeks':
    'Select the number of weeks the work cycle shall include, and the precise days of the week for each week.  When a cycle ends, it will start over in the order shown here.',
  'employment_pay.edit.form.orphans.remove_orphaned_salary_parts': 'Remove registered remuneration parts',
  'employment_pay.edit.form.valid_from.at_date': 'Changes take effect at {date}.',
  'employment_pay.edit.form.valid_from.now': 'Changes take effect immediately.',
  'employment_pay.edit.pay.add_row': 'Add remuneration line',
  'employment_pay.edit.pay.frequency.hourly': 'per hour',
  'employment_pay.edit.pay.frequency.salary': '{cycle}',
  'employment_pay.edit.pay.frequency.supplement': 'per piece {cycle}',
  'employment_pay.edit.pay.frequency.supplement_varied': 'per piece',
  'employment_pay.edit.pay.header.quantity': 'Quantity',
  'employment_pay.edit.pay.header.rate': 'Remuneration',
  'employment_pay.edit.pay.header.type': 'Remuneration type',
  'employment_pay.edit.pay.quantity': 'Quantity',
  'employment_pay.edit.pay.quantity.invalid': 'Quantity is invalid',
  'employment_pay.edit.pay.rate': 'Rate',
  'employment_pay.edit.pay.rate.required': 'Rate is required',
  'employment_pay.edit.pay.rate.salaried_must_be_more_than_0': 'Salary must be more than 0',
  'employment_pay.edit.pay.rate.suffix': 'kr. {frequency}',
  'employment_pay.edit.pay.row_move_down': 'Move down',
  'employment_pay.edit.pay.row_move_up': 'Move up',
  'employment_pay.edit.pay.salary_0_note':
    'Salary cannot be set to 0 kr.; give the employee leave instead, if your intention is to avoid paying them.',
  'employment_pay.edit.pay.salary_type_id.placeholder': 'Select type',
  'employment_pay.edit.pay.salary_type_id.required': 'Type is required',
  'employment_pay.edit.pay.title': 'Description',
  'employment_pay.edit.title': 'Edit remuneration',

  'employment_vacation.card.edit.title': 'Edit holiday',
  'employment_vacation.card.edit.warning.at_date': 'Changes take effect at {date}.',
  'employment_vacation.card.edit.warning.immediate': 'Changes take effect immediately.',
  'employment_vacation.card.extra_vacation_day.format.annual': 'annually',
  'employment_vacation.card.extra_vacation_day.format.monthly': 'monthly',
  'employment_vacation.card.extra_vacation_day.format.no_suffix': '{days}',
  'employment_vacation.card.extra_vacation_day.format.with_suffix': '{days} ({suffix})',
  'employment_vacation.card.no_vacation_note': 'This employee has executive pay.',
  'employment_vacation.card.personal_days.format.annual': 'annually',
  'employment_vacation.card.personal_days.format.monthly': 'monthly',
  'employment_vacation.card.personal_days.format.no_suffix': '{days}',
  'employment_vacation.card.personal_days.format.with_suffix': '{days} ({suffix})',
  'employment_vacation.card.table.header.extra_vacation_days': 'Extra holidays',
  'employment_vacation.card.table.header.personal_days': 'Personal days',
  'employment_vacation.card.table.header.vacation_amount.paid': 'Holiday supplement',
  'employment_vacation.card.table.header.vacation_amount.unpaid': 'Holiday allowance',
  'employment_vacation.card.table.header.vacation_days': 'Holidays',
  'employment_vacation.card.title.none': 'Holiday',
  'employment_vacation.card.title.with_pay': 'Paid holiday',
  'employment_vacation.card.title.without_pay': 'Unpaid holiday',
  'employment_vacation.card.vacation_amount': '{amount}%',

  'employment.alert.employee_ready': '{name} is now marked as ready.',
  'employment.alert.employee_unready': '{name} is no longer marked ready.',
  'employment.alert.success': "{name}'s employment has been saved",
  'employment.contract_warning.future': 'This is a future contract, which will first activate on {date}.',
  'employment.contract_warning.has_future': 'This employee has at least one future contract after their current.',
  'employment.contract_warning.immutable.has_mutable': 'This is an expired contract, that cannot be altered, {link}.',
  'employment.contract_warning.immutable.has_mutable.link': 'click here to go to the current contract',
  'employment.contract_warning.immutable.no_mutable': 'This is an expired contract, that cannot be altered.',
  'employment.no_atp_warning': 'The employee has {em} ATP.',
  'employment.no_atp_warning.button': 'Click here to give the employee ATP',
  'employment.no_atp_warning.em': 'no',

  'error.base.bad_gateway':
    'The external system did not respond correctly. You can try again, and if the error persists, you can contact support.',
  'error.base.company_already_exists': 'The company already exists',
  'error.base.company_feature_missing':
    'You do not have access to this function. Go to Company > Price Package to change package.',
  'error.base.email_already_exists': 'The email address already exists',
  'error.base.gateway_timeout':
    'The external system did not respond in time. You can try again, and if the error persists, you can contact support.',
  'error.base.internal_server_error': 'An unexpected error occurred. We are working on it.',
  'error.base.pricing_package_locked': 'Price plan could not be added/removed.',
  'error.base.request_too_large': 'The file is too large.',
  'error.base.unknown': 'An unexpected error occurred. Try again, and if the error persists, you can contact support.',
  'error.base.wrong_password': 'Wrong password.',
  'error.details.contract_update.orphaned_salary_parts': 'Contract update will remove registered remuneration parts',
  'error.details.time_registration_invalid.contract_does_not_allow_type':
    'The employee does not have the time registration’s pay type on their employment.',
  'error.details.time_registration_invalid.dk_old_vacation_before_20200831':
    'You cannot register holidays in September before the new Holiday Act has come into force 1/9/2020.',
  'error.details.time_registration_invalid.no_leave_on_vacation_day':
    'You cannot register absence outside the work week.',
  'error.details.time_registration_invalid.no_leave_on_vacation_day.not_use_adjusted_work_week_leave_rate':
    'You cannot register absence outside the regular work week.  Register only absence Monday through Friday.  If you wish to be able to register after the actual work week, you can enable {link}.',
  'error.details.time_registration_invalid.no_leave_on_vacation_day.not_use_adjusted_work_week_leave_rate.link':
    'automatic adjusting of holiday values after work week',
  'error.details.time_registration_invalid.other': 'The time registration is invalid.',
  'error.invalid_employee_data': 'The employee data is invalid. Go back to edit or contract support.',
  'error.invalid_employee_data.account_number_at_most_10_digits':
    'The bank account number must at most be 10 characters.',
  'error.invalid_employee_data.address_is_missing': 'Address is required.',
  'error.invalid_employee_data.bad_formatted_email': 'The employee’s email address is invalid.',
  'error.invalid_employee_data.city_is_missing': 'City is required.',
  'error.invalid_employee_data.dk_foreign_residents_must_have_birth_date':
    'Birthdays must be provided for employees outside Denmark.',
  'error.invalid_employee_data.dk_foreign_residents_must_have_gender':
    'Gender must be provided for employees outside Denmark.',
  'error.invalid_employee_data.dk_foreign_residents_no_address_in_dk':
    'Employees outside Denmark may not have an address in Denmark.',
  'error.invalid_employee_data.dk_without_cpr_must_have_birth_date':
    'Birthday must be provided for employees without CPR number.',
  'error.invalid_employee_data.dk_without_cpr_must_have_gender':
    'Gender must be provided for employees without CPR number.',
  'error.invalid_employee_data.dk_without_cpr_no_address_in_dk':
    'Employees without CPR number may not have an address in Denmark.',
  'error.invalid_employee_data.dk_without_cpr_no_national_id':
    'Employees without CPR number may not have a CPR or CVR number.',
  'error.invalid_employee_data.missing_country': 'Country is required.',
  'error.invalid_employee_data.name_is_missing': 'Name is required.',
  'error.invalid_employee_data.national_id_must_be_10_digits': 'CPR number must be 10 characters.',
  'error.invalid_employee_data.national_id_must_be_8_digits': 'CVR number must be 8 characters.',
  'error.invalid_employee_data.nemkonto_need_cpr': 'NemKonto requires a CPR number.',
  'error.invalid_employee_data.nemkonto_need_cvr': 'NemKonto requires a CVR number.',
  'error.invalid_employee_data.nemkonto_transfers_disabled': 'NemKonto transfer is not enabled for this company.',
  'error.invalid_employee_data.only_freelancers_can_have_cvr': 'Only freelancers may use CVR number.',
  'error.invalid_employee_data.postal_code_is_missing': 'Postcode is required.',
  'error.invalid_employee_data.registration_number_at_most_4_digits':
    'The bank registration number must at most be 4 characters.',
  'error.message.access_token_not_privileged': 'You do not have access to this function. Contact your administrator.',
  'error.message.account_mapping_not_permitted_for_this_accounting_integration':
    'It is not possible to edit the account mapping for this accounting integration.',
  'error.message.accounting_api_auth_invalid':
    'Your accounting integration connection has been rejected, this can be due to changing of password, among other things. Please re-integrate with your the accounting system.',
  'error.message.accounting_api_key_invalid': 'API key is invalid.',
  'error.message.accounting_integration_mapped_account_missing':
    'The account {accountNumber} was not found in the account plan, verify that the account plan does not contain any expired accounts.',
  'error.message.accounting_integration_missing_account_mapping':
    'One or more accounts in Salary are missing a mapping to an account in your accounting system',
  'error.message.accounting_integration_partial_draft':
    "Only a partial draft was transmitted to the accounting integration's daybook, you should clear your daybook before you attempt to book again.",
  'error.message.accounting_integration_re_auth_setup_mismatch':
    'It was not possible to perform a simple re-authorisation of your accounting integration, you need to use the "change accounting integration" button and start over.',
  'error.message.accounting_integration_request_failed_try_again':
    'There were problems with the accounting integration, try again.',
  'error.message.accounting_integration_setup_not_found': 'The accounting integration could not be found.',
  'error.message.an_account_in_e_conomic_used_by_salary_is_barred':
    'The account {accountNumber} is barred in e-conomic, so it is not possible to book on it.',
  'error.message.an_account_in_e_conomic_used_by_salary_is_barred_multiple':
    'The accounts {accountNumbers} are barred in e-conomic, so it is not possible to book on them.',
  'error.message.asynchronous_task_staged_data_already_used':
    'These data have already been used to save, please reload new data.',
  'error.message.car_allowance_immutable':
    'You cannot edit this registration, probably due to an approved payroll. Try to reopen the payroll, otherwise contact support.',
  'error.message.car_allowance_invalid_method': 'A transport registration already exists.',
  'error.message.coarse_car_allowance_already_exists':
    'There is already a coarse transport allowance. Contact support to have it removed.',
  'error.message.coarse_car_allowance_immutable':
    'You cannot edit this registration, probably due to an approved payroll. Try to reopen the payroll, otherwise contact support.',
  'error.message.coarse_time_registration_already_exists':
    'There is already a coarse time registration. Contact support to have it removed.',
  'error.message.coarse_time_registration_immutable':
    'The time registration cannot be edited right now, which is normally dued to an approved payroll. If you wish to make changes, you must re-open the payroll for this period first.',
  'error.message.coarse_time_registration_more_than_one_contract_in_period':
    'The employee has two contracts for this period, so you cannot create coarse time registrations.',
  'error.message.coarse_time_registration_period_is_approved':
    'The time registration cannot be edited right now, which is normally dued to an approved payroll. If you wish to make changes, you must re-open the payroll for this period first.',
  'error.message.company_accounting_integration_not_found': 'The accounting integration could not be found.',
  'error.message.company_feature_missing':
    'You do not have access to this function. Go to Company > Price Package to change package.',
  'error.message.company_feature_not_enabled': 'The company does not have access to this feature.',
  'error.message.company_has_future_contracts':
    'The company has scheduled contracts, which must be deleted before the feature can be disabled.',
  'error.message.company_is_frozen': 'The company is no longer active at Salary. Contact support to re-activate it.',
  'error.message.company_logo_format_invalid': 'The file is not in a supported format.',
  'error.message.company_logo_size_invalid': 'The file is too large, the image must at most be 60 KB.',
  'error.message.company_not_found': 'The company could not be found.',
  'error.message.company_payment_transfer_setting_not_allowed': 'An invalid transfer setting has been selected.',
  'error.message.concurrency_issue_detected':
    'The page was not refreshed correctly – refresh the page. If the error persists, contact support.',
  'error.message.contractbook_api_error':
    'We received an unknown error from ContractBook. Try again, if the error persists, contact support.',
  'error.message.contractbook_internal_api_error':
    'We received an internal error from ContractBook. Try again, if the error persists, contact support.',
  'error.message.contractbook_invalid_api_key':
    'The API key for ContractBook is not valid. Go to Integrations > ContractBook to review the settings.',
  'error.message.data_provider_api_invalid_key': 'The data provider key is invalid.',
  'error.message.department_not_found': 'Department could not be found.',
  'error.message.departmental_distribution_in_e_conomic_used_by_salary_is_barred':
    'The departmental distribution {departmentalDistribution} is barred in e-conomic, so it is not possible to book on it.',
  'error.message.document_unsupported_file_format': 'Unsupported file type.',
  'error.message.email_not_found': 'The email could not be found.',
  'error.message.email_token_not_found': 'The email link is no longer vlaid.',
  'error.message.employee_batch_data_invalid':
    'Some of the bulk action parameters are invalid; for instance, valid from must always be after now.',
  'error.message.employee_cap_exceeded':
    'You have reached the maximum limit of employees on the free package. Go to Company > Price Package to upgrade to the Basic package.',
  'error.message.employee_contract_immutable':
    'The employment cannot be modified currently, which normally is due to an approved payroll. To modify the employment in the period, you must re-open the payroll. Otherwise you can modify the employment for the next period.',
  'error.message.employee_contract_invalid': 'Employment is invalid.',
  'error.message.employee_contract_not_found': 'Employment could not be found.',
  'error.message.employee_contract_salary_period_overlap':
    'Contracts with the same payroll cycle cannot be valid in the same payroll period.  If you have not modified dates, contact support.',
  'error.message.employee_data_too_recent': 'Employee data in Salary has been updated after these data were imported',
  'error.message.employee_employed_in_company': 'Employee already exists.',
  'error.message.employee_image_format_invalid': 'The file type for the employee’s profile picture is invalid.',
  'error.message.employee_immutable': 'The employee cannot be modified.',
  'error.message.employee_is_director': 'Employees with director pay cannot receive holiday allowance.',
  'error.message.employee_leave_invalid': 'The leave period is not valid.',
  'error.message.employee_not_found': 'Employee not found.',
  'error.message.employee_time_registration_already_exists': 'There already exists a registration for this period.',
  'error.message.employee_time_registration_excess_hour_per_day': 'You can register more than 24 hours per day.',
  'error.message.employee_time_registration_immutable':
    'The time registration cannot be edited right now, which is normally dued to an approved payroll. If you wish to make changes, you must re-open the payroll first.',
  'error.message.employee_time_registration_invalid_method':
    'There is a time registration already, contact support if it needs editing.',
  'error.message.employment_already_terminated': 'The employee has already been terminated.',
  'error.message.employment_invalid': 'Employment is invalid.',
  'error.message.employment_not_found': 'Employment could not be found.',
  'error.message.employment_number_invalid': 'Employee number is invalid.',
  'error.message.employment_number_is_not_unique': 'Employee number is already in use.',
  'error.message.employment_terminated_wrong_leave_type': 'You cannot add paid holiday for terminated employees.',
  'error.message.grandhood_api_user_already_exists':
    'Your email address is already associated with a user at Grandhood, you will have to contact Grandhood yourself.',
  'error.message.import_could_not_log_in': 'Username or password is not correct.',
  'error.message.import_failed': 'The import failed.',
  'error.message.incorrect_format_for_staged_document':
    "The format of the file does not match the import type you've selected, please check the file and try again.",
  'error.message.invalid_family_fund_for_company':
    'The selected family leave fund cannot be selected for this company.',
  'error.message.invalid_national_id': 'CPR number is not valid. Go back to edit.',
  'error.message.invalid_transfer_destination': 'The employee has no bank account information.',
  'error.message.leave_type_not_found': 'Unknown absence type, try another or contact support.',
  'error.message.login_throttled': 'You have attempted to log in too many times in a row.  Wait.',
  'error.message.login_throttled.details':
    'You have attempted to log in too many times in a row.  Wait until {time} and try again.',
  'error.message.mfa_wrong_response': 'Wrong code, try again and be sure your code has not expired.',
  'error.message.no_accounting_year_in_dinero_accounting_integration':
    'There was no valid accounting year for the accounting date',
  'error.message.no_endpoint_for_inventio_accounting_integration':
    'Your Inventio integration is not set up correctly. ',
  'error.message.no_environment_found_for_business_central_accounting_integration':
    'We could not find the production environment for your Business Central account.',
  'error.message.one_time_pay_immutable':
    'It is no longer possible to modify this, which is normally due to an approved payroll.',
  'error.message.password_must_be_at_least_8_characters': 'Password must be at least 8 characters',
  'error.message.password_must_not_be_listed_in_public_data_breach':
    'Password was found in a public breach of log in information',
  'error.message.payroll_approve_code_incorrect': 'Approval code is incorrect',
  'error.message.payroll_approve_code_required': 'Approval code is required',
  'error.message.pension_definition_invalid': 'Pension is invalid.',
  'error.message.pension_definition_invalid_destination': 'Unknown pension company, contact support.',
  'error.message.pension_definition_invalid_union_agreement_number':
    'The union agreement code must be at most 5 digits.',
  'error.message.period_in_e_conomic_used_by_salary_is_barred':
    'The period is barred in e-conomic, so it is not possible to book on it.',
  'error.message.phone_number_invalid': 'The phone number is invalid.',
  'error.message.phone_number_is_used_for_mfa':
    'The phone number is used for two factor authorisation. To modify, turn off two factor authorisation first.',
  'error.message.pricing_package_campaign_code_invalid': 'Invalid campaign code.',
  'error.message.pricing_package_not_found': 'Price plan could not be found.',
  'error.message.pro_feature_missing_for_dinero_accounting_integration':
    'You need to have Dinero Pro to integrate with Dinero.',
  'error.message.production_unit_already_exists': 'The production unit already exists.',
  'error.message.reimbursement_voucher_already_approved':
    'You cannot modify an approved outlay. Remove the approval to edit.',
  'error.message.reimbursement_voucher_has_no_disburse_amount':
    'The outlay cannot be approved, when its amount is 0 kr.',
  'error.message.reimbursement_voucher_has_no_expense_category':
    'An outlay must have an expense category to be approved.',
  'error.message.reimbursement_voucher_unsupported_file_format': 'The file type is not allowed.',
  'error.message.salary_definition_invalid':
    'Something is missing in your input, try to fill in all the fields or contact support.',
  'error.message.salary_definition_invalid.hourly_twice':
    'You cannot have the same wage type more than once on a contract; pick another wage type or create more under company.',
  'error.message.salary_definition_invalid.supplement_varied_twice':
    'You cannot have the same variable supplement more than once on a contract; pick another variable supplement or create more under company.',
  'error.message.salary_definition_not_found':
    'Something is missing in your input, try to fill in all the fields or contact support.',
  'error.message.salary_period_invalid': 'We are trying to save on the wrong payroll period, contact support.',
  'error.message.salary_type_is_in_use':
    'The pay rate cannot be modified. If you wish to make changes, you will have to create a new pay rate, and add it to your employees.',
  'error.message.shipment_transfer_row_is_immutable': 'Select a valid date in the future.',
  'error.message.smart_api_not_active_for_inventio_accounting_integration':
    "To use Salary's Inventio integration, you will need to activate SMARTapi in Inventio.",
  'error.message.staged_data_incompatible': 'These data are old and cannot be re-used, collect data again',
  'error.message.support_may_not_perform_this_action': 'Support cannot perform this action.',
  'error.message.tax_card_request_not_found': 'A tax card request could not be found.',
  'error.message.time_registration_api_invalid_key': 'API key is invalid.',
  'error.message.transaction_immutable': 'The calculations have been filed, and therefore cannot be deleted.',
  'error.message.unauthenticated_for_invalid_credentials':
    'You could not be logged out, probably because you are already logged out.',
  'error.message.unauthorized': 'You do not have access to this function.',
  'error.message.user_data_invalid': 'User data is invalid. Edit and try again, or contact support.',
  'error.message.user_not_found': 'User could not be found.',
  'error.message.workflow_cannot_be_cancelled':
    'Changes cannot be performed currently, which is normally due to an approved payroll.',
  'error.message.workflow_not_found':
    'There is no open payroll for the period, contact support to have a payroll created.',
  'error.message.workflow_task_not_in_approval_state':
    'An error occurred, because the payroll was being recalculated. Try refreshing the page and approve again.',

  'expense_category.edit.form.accounting_account_id': 'Booking account',
  'expense_category.edit.form.accounting_account_id.no_account': 'No account',
  'expense_category.edit.form.accounting_account_id.no_longer_available.generic': 'The account is no longer available',
  'expense_category.edit.form.accounting_account_id.no_longer_available.select_note': '(no longer available)',
  'expense_category.edit.form.accounting_account_id.no_longer_available.specific':
    'The account {account} is no longer available',
  'expense_category.edit.form.name': 'Description',
  'expense_category.edit.form.name.required': 'Description is required',
  'expense_category.edit.form.order': 'Order',
  'expense_category.edit.form.order.invalid': 'Order is invalid',
  'expense_category.edit.form.order.required': 'Order is required',
  'expense_category.edit.title.create': 'Create expense category',
  'expense_category.edit.title.edit': 'Edit expense category',
  'expense_category.tab.category.table.actions.delete': 'Delete expense category',
  'expense_category.tab.category.table.actions.edit': 'Edit expense category',
  'expense_category.tab.category.table.header.account': 'Booking account',
  'expense_category.tab.category.table.header.name': 'Description',
  'expense_category.tab.category.table.header.order': 'Order',
  'expense_category.tab.category.table.no_account': 'No account',
  'expense_category.tab.category.title': 'Expense categories',
  'expense_category.tab.employee.description':
    'When we book outlays paid with a company card, we offset the amount on the bank. You can select individual offset accounts for employees, who have their own company card.',
  'expense_category.tab.employee.table.actions.edit': 'Edit company card account',
  'expense_category.tab.employee.table.header.account': 'Booking account',
  'expense_category.tab.employee.table.header.name': 'Description',
  'expense_category.tab.employee.table.standard_account': 'Default bank account',
  'expense_category.tab.employee.title': 'Company card account per employee',
  'expense_category.tab.header.add_category': 'Add expense category',
  'expense_category.tab.header.reimbursement_vouchers': 'Outlay overview',

  'external_salary_file_setting.alert.success': 'Changes to {name} have been saved',
  'external_salary_file_setting.edit.form.header.custom_title': 'Custom title',
  'external_salary_file_setting.edit.form.header.pay_part': 'Pay part',
  'external_salary_file_setting.edit.form.header.standard_title': 'Default title',
  'external_salary_file_setting.edit.form.variable_type_note':
    'This is a variable field, where % is replaced by a number.  The number is inserted at the end of the title you set.',
  'external_salary_file_setting.header.description':
    "The external pay parts from {name} normally uses {name}'s standard titles, but if you wish to use a different title, you can do that here.",
  'external_salary_file_setting.header.file_import': 'To file import',
  'external_salary_file_setting.header.title': "Edit titles for {name}'s pay file format",

  'extra_pension.edit.form.account': 'Accountno.',
  'extra_pension.edit.form.account.invalid': 'Accountno. is invalid',
  'extra_pension.edit.form.account.required': 'Accountno. is required',
  'extra_pension.edit.form.amount': 'Amount',
  'extra_pension.edit.form.amount.cannot_be_negative': 'Amount cannot be negative',
  'extra_pension.edit.form.amount.invalid': 'Amount is invalid',
  'extra_pension.edit.form.amount.required': 'Amount is required',
  'extra_pension.edit.form.amount.suffix': 'kr.',
  'extra_pension.edit.form.disposition_date': 'Payslip date',
  'extra_pension.edit.form.pension_company_id': 'Pension company',
  'extra_pension.edit.form.pension_company_id.none': 'No company',
  'extra_pension.edit.form.pension_company_id.required': 'Pension company is required',
  'extra_pension.edit.form.pension_customer_id.required': '{type} is required',
  'extra_pension.edit.form.pension_list_id': 'Select an existing pension line to prefill the fields with',
  'extra_pension.edit.form.pension_list_id.intro.many':
    'The employee already has pension. You can chose to prefill the fields with one of these lines. You can always edit the fields afterwards.',
  'extra_pension.edit.form.pension_list_id.intro.one':
    'The employee already has pension. You can chose to prefill the fields with this pension. You can always edit the fields afterwards.',
  'extra_pension.edit.form.reporting_base_amount': 'Pension basis',
  'extra_pension.edit.form.reporting_base_amount.invalid': 'Pension basis is invalid',
  'extra_pension.edit.form.reporting_base_amount.max_amount': 'Max 9,999,999,999.99 kr.',
  'extra_pension.edit.form.reporting_base_amount.note':
    'Pension basis and procent are only used for the purpose of reporting to the pension company, and have no effect on the calculation.',
  'extra_pension.edit.form.reporting_base_amount.required': 'Pension basis is required',
  'extra_pension.edit.form.reporting_base_amount.suffix': 'kr.',
  'extra_pension.edit.form.reporting_percentage': 'Procent',
  'extra_pension.edit.form.reporting_percentage.invalid': 'Procent is invalid',
  'extra_pension.edit.form.reporting_percentage.max_amount': 'Max 99.99%',
  'extra_pension.edit.form.reporting_percentage.required': 'Procent is required',
  'extra_pension.edit.form.reporting_percentage.suffix': '%',
  'extra_pension.edit.form.scheme': 'Paid by',
  'extra_pension.edit.form.scheme.required': 'Paid by is required',
  'extra_pension.edit.form.sort_code': 'Reg.no.',
  'extra_pension.edit.form.sort_code.invalid': 'Reg.no. is invalid',
  'extra_pension.edit.form.sort_code.required': 'Reg.no. is required',
  'extra_pension.edit.form.union_agreement_number': 'Union agreement number',
  'extra_pension.edit.form.union_agreement_number.not_five_characters':
    'Union agreement number must be five characters',
  'extra_pension.edit.form.union_agreement_number.tooltip': 'Union agremeent number is not required',
  'extra_pension.edit.intro':
    'In additional the normal pension savings, you can give your employees an additional pension savings here.',
  'extra_pension.edit.title.create': 'Create extra pension payment',
  'extra_pension.edit.title.edit': 'Edit extra pension payment',
  'extra_pension.edit.title.view': 'View extra pension payment',
  'extra_pension.tab.header.add_new': 'Add extra pension payment',
  'extra_pension.tab.header.approve_all': 'Approve all',
  'extra_pension.tab.header.hide_history': 'Back',
  'extra_pension.tab.header.show_history': 'Show archived',
  'extra_pension.tab.table.actions.delete': 'Delete extra pension payment',
  'extra_pension.tab.table.actions.edit': 'Edit extra pension payment',
  'extra_pension.tab.table.actions.view': 'View extra pension payment',
  'extra_pension.tab.table.approved.approve': 'Approve',
  'extra_pension.tab.table.approved.false': 'No',
  'extra_pension.tab.table.approved.remove': 'Remove approval',
  'extra_pension.tab.table.approved.true': 'Yes',
  'extra_pension.tab.table.header.amount': 'Amount',
  'extra_pension.tab.table.header.approved': 'Approved',
  'extra_pension.tab.table.header.date': 'Date',
  'extra_pension.tab.table.header.pension_info': 'Pension info',
  'extra_pension.tab.table.header.title': 'Text',
  'extra_pension.tab.table.no_company': 'No company',
  'extra_pension.tab.table.pension_info.account': 'Accountno.',
  'extra_pension.tab.table.pension_info.company_name': 'Company',
  'extra_pension.tab.table.pension_info.customer_id': 'Number',
  'extra_pension.tab.table.pension_info.scheme': 'Paid by',
  'extra_pension.tab.table.pension_info.sort_code': 'Reg.no.',
  'extra_pension.tab.table.saving': 'Saving...',
  'extra_pension.tab.title.history': 'Extra pension payment archive',
  'extra_pension.tab.title.standard': 'Extra pension payment',

  'family_leave.fund.barsel_dk': 'Barsel.dk',
  'family_leave.fund.barsel_dk_partial': 'Partial Barsel.dk',
  'family_leave.fund.da_barsel': 'DA Barsel',
  'family_leave.fund.other': 'Other',

  'form.button.save_changes': 'Save changes',
  'form.button.save_setup': 'Save set up',
  'form.field.current_password': 'Current password',
  'form.field.email': 'Email',
  'form.field.name': 'Name',
  'form.field.new_password': 'New password',
  'form.field.phone': 'Phone',
  'form.field.phone_number': 'Phone number',
  'form.field.phone_number_country_code': 'Country code',
  'form.field.repeat_password': 'Repeat password',
  'form.field.signing_document.validation_code': 'Text message code',
  'form.message.your_changes_are_saved': 'Your changes have been saved',
  'form.message.your_password_has_been_updated': 'Your password has been updated',
  'form.validation.current_password_is_invalid': 'Current password is invalid',
  'form.validation.current_password_is_required': 'Current password is required',
  'form.validation.email_is_invalid': 'Email is invalid',
  'form.validation.email_is_required': 'Email is required',
  'form.validation.email_is_the_same_as_existing': 'Email is the same as the current one',
  'form.validation.fill_in_fields_correctly': 'Please fill in all the required fields correctly',
  'form.validation.must_be_a_number': 'Must be a number',
  'form.validation.new_password_is_invalid': 'New password is invalid',
  'form.validation.new_password_is_required': 'New password is required',
  'form.validation.new_passwords_doesnt_match': 'The two new passwords are not identical',
  'form.validation.phone_number_country_code_is_invalid': 'Country code is invalid',
  'form.validation.phone_number_country_code_is_required': 'Country code is required',
  'form.validation.phone_number_is_invalid': 'Phone number is invalid',
  'form.validation.phone_number_is_required': 'Phone number is required',
  'form.validation.repeated_password_is_required': 'Repeating the new password is required',
  'form.validation.signing_document.validation_code_is_required': 'Text message code is required',

  'free_text.actions.delete': 'Delete text',
  'free_text.actions.edit': 'Edit text',
  'free_text.actions.view': 'View text',
  'free_text.form.disposition_date': 'Payslip date',
  'free_text.header.approve_all': 'Approve all',
  'free_text.header.create.e_income': 'Create text for E-income',
  'free_text.header.create.standard': 'Create text on the payslip',
  'free_text.header.show_history': 'Show archive',
  'free_text.header.title.history.e_income': 'E-income text archive',
  'free_text.header.title.history.standard': 'Payslip text archive',
  'free_text.header.title.standard.e_income': 'E-income text',
  'free_text.header.title.standard.standard': 'Payslip text',
  'free_text.header.view_history': 'Back',
  'free_text.intro.e_income':
    'Write a text for E-income. The text will also appear on the payslip, the date is valid for.',
  'free_text.intro.standard':
    'Write a text for the payslip. The text will appear on the payslip, the date is valid for.',
  'free_text.table.approved.approve': 'Approve',
  'free_text.table.approved.false': 'No',
  'free_text.table.approved.remove': 'Remove approval',
  'free_text.table.approved.true': 'Yes',
  'free_text.table.header.approved': 'Approved',
  'free_text.table.header.date': 'Date',
  'free_text.table.header.title': 'Text',
  'free_text.table.saving': 'Saving...',
  'free_text.title.e_income.create': 'Create text for E-income',
  'free_text.title.e_income.edit': 'Edit text for E-income',
  'free_text.title.e_income.view': 'See text for E-income',
  'free_text.title.standard.create': 'Create text for payslip',
  'free_text.title.standard.edit': 'Edit text for payslip',
  'free_text.title.standard.view': 'See text for payslip',

  'freelancer.add.alert.success': '{name} is now created as a freelancer',
  'freelancer.add.invite.form.department_id': 'Department',
  'freelancer.add.invite.form.department_id.placeholder': 'Select department',
  'freelancer.add.invite.form.email': 'Email',
  'freelancer.add.invite.form.email.invalid': 'Email is invalid',
  'freelancer.add.invite.form.email.required': 'Email is required',
  'freelancer.add.invite.form.employment_start_date': 'Start date for freelancer',
  'freelancer.add.invite.form.employment_start_date.required': 'Start date for freelancer is required',
  'freelancer.add.invite.form.submit': 'Send invitation',
  'freelancer.add.invite.title': 'Invite freelancer',
  'freelancer.add.step_0.title': 'Step 1',
  'freelancer.add.step_1.title': 'Step 2',
  'freelancer.add.step_2.title': 'Step 3',
  'freelancer.add.steps.0': 'Base data',
  'freelancer.add.steps.1': 'Communication',
  'freelancer.add.steps.2': 'Financial information',
  'freelancer.add.steps.help_note':
    'If you have trouble creating a freelancer, you can always contact {email}. We are looking forward to helping you!',
  'freelancer.add.steps.intro': 'After these three steps, you will have created a freelancer',
  'freelancer.add.steps.title': 'Add freelancer',
  'freelancer.details.card.convert_to_employee': 'Convert to employee',
  'freelancer.details.card.department': 'Department',
  'freelancer.details.card.department.none': 'None selected',
  'freelancer.details.card.title': 'Contract',
  'freelancer.details.edit.department_id': 'Department',
  'freelancer.details.edit.department_id.none_or_new': 'None or new',
  'freelancer.details.edit.department_id.placeholder': 'Select department',
  'freelancer.details.edit.title': 'Edit contract',
  'freelancer.details.edit.valid_from.at_date': 'Changes take effect at {date}.',
  'freelancer.details.edit.valid_from.now': 'Changes take effect immediately.',
  'freelancer.employment.alert.success': "{name}'s contract has been saved",
  'freelancer.fee.edit.amount': 'Amount for compensation',
  'freelancer.fee.edit.amount.placeholder': 'Compensation',
  'freelancer.fee.edit.amount.required': 'Amount for compensation is required',
  'freelancer.fee.edit.amount.suffix': 'kr.',
  'freelancer.fee.edit.category': 'Subtype',
  'freelancer.fee.edit.intro.editing': 'Give your freelancer a compensation to be paid out on a future payslip.',
  'freelancer.fee.edit.intro.not_editing': 'This is an archived compensation and cannot be edited.',
  'freelancer.fee.edit.title.create': 'Create compensation',
  'freelancer.fee.edit.title.edit': 'Edit compensation',
  'freelancer.fee.edit.title.view': 'View compensation',
  'freelancer.fee.edit.type': 'Type',
  'freelancer.fee.edit.type.required': 'Type is required',
  'freelancer.fee.edit.without_payment': 'Only reporting',
  'freelancer.fee.header.approve_all': 'Approve all',
  'freelancer.fee.header.hide_history': 'Back',
  'freelancer.fee.header.new_fee': 'Create compensation',
  'freelancer.fee.header.show_history': 'Show archive',
  'freelancer.fee.header.title': 'Compensation',
  'freelancer.fee.table.actions.delete': 'Delete compensation',
  'freelancer.fee.table.actions.edit': 'Edit compensation',
  'freelancer.fee.table.actions.view': 'View compensation',
  'freelancer.fee.table.approved.approve': 'Approve',
  'freelancer.fee.table.approved.false': 'No',
  'freelancer.fee.table.approved.remove': 'Remove approval',
  'freelancer.fee.table.approved.true': 'Yes',
  'freelancer.fee.table.header.amount': 'Amount',
  'freelancer.fee.table.header.approved': 'Approved',
  'freelancer.fee.table.header.date': 'Date',
  'freelancer.fee.table.header.title': 'Description',
  'freelancer.fee.table.header.with_payment': 'Only reporting',
  'freelancer.fee.table.saving': 'Saving...',
  'freelancer.no_contract_card.button': 'Add contract',
  'freelancer.no_contract_card.error.no_cycle': 'Could not find a payroll cycle',
  'freelancer.no_contract_card.error.no_production_unit': 'No production unit on this company',
  'freelancer.no_contract_card.intro': 'Add a contract to pay compensation to this freelancer.',
  'freelancer.no_contract_card.title': 'This freelancer has no contract',
  'freelancer.pay_invoice.header.download_all': 'Download all completed payments',
  'freelancer.pay_invoice.header.title': 'Payments',
  'freelancer.pay_invoice.table.completed': 'Yes',
  'freelancer.pay_invoice.table.header.completed': 'Completed',
  'freelancer.pay_invoice.table.header.disposition_date': 'Disposition date',
  'freelancer.pay_invoice.table.header.net_pay': 'Payment net',
  'freelancer.pay_invoice.table.header.period': 'Period',
  'freelancer.pay.card.cycle': 'Payment',
  'freelancer.pay.card.title': 'Compensation',
  'freelancer.pay.edit.title': 'Edit compensation',
  'freelancer.pay.edit.valid_from.at_date': 'Changes take effect at {date}.',
  'freelancer.pay.edit.valid_from.now': 'Changes take effect immediately.',
  'freelancer.sidebar.actions.delete': 'Delete freelancer',
  'freelancer.sidebar.actions.rehire': 'Re-activate freelancer',
  'freelancer.sidebar.actions.terminate': 'Hide freelancer',
  'freelancer.sidebar.employee_switcher.title': 'Switch to freelancer',
  'freelancer.single.alert.deleted': '{name} have been deleted',
  'freelancer.single.alert.leave': '{name} has received leave',
  'freelancer.single.alert.terminated': '{name} have been hidden',
  'freelancer.single.menu.employment': 'Contract',
  'freelancer.single.menu.overview': 'Compensations',
  'freelancer.single.menu.payments': 'Payments',
  'freelancer.single.menu.profile': 'Profile',
  'freelancer.single.menu.project_registrations': 'Project registrations',

  'freelancers_overview.header.import_from_file': 'Import from file',
  'freelancers_overview.table.actions.add_fee': 'Add compensation',
  'freelancers_overview.table.actions.details': 'Compensation overview',
  'freelancers_overview.table.header.compensation': 'Combined compensation',
  'freelancers_overview.table.header.freelancer': 'Freelancer',
  'freelancers_overview.title': 'Compensation creation',

  'freelancers.awaiting.title': 'Awaiting creation',
  'freelancers.header.add_freelancer': 'New freelancer',
  'freelancers.header.add_freelancer_lock': 'Upgrade to Basic, to create freelancers',
  'freelancers.header.invite_freelancer': 'Invite freelancer',
  'freelancers.hide': 'Hide',
  'freelancers.show': 'Show',
  'freelancers.table.actions.delete': 'Delete',
  'freelancers.table.actions.details': 'See details',
  'freelancers.table.actions.edit': 'Edit',
  'freelancers.table.actions.terminate': 'Hide',
  'freelancers.table.add_contract': 'Add contract',
  'freelancers.table.fill_in_information': 'Fill in information',
  'freelancers.table.header.freelancer': 'Freelancer',
  'freelancers.table.header.payout_this_year': 'Paid out this year to date',
  'freelancers.terminated.title': 'Hidden',
  'freelancers.title.freelancer.many': '{count} freelancers',
  'freelancers.title.freelancer.one': '{count} freelancer',
  'freelancers.title.searching_for': 'searching for "{query}"',

  'gender.female': 'Woman',
  'gender.male': 'Man',
  'gender.unknown': 'Unknown',

  'global_message.company_scheduled_for_deletion': 'This company will be deleted at {deletionScheduledAt}!',
  'global_message.company_scheduled_for_deletion.button': 'Click here to cancel the deletion',

  'global_search.articles': 'Articles',
  'global_search.employees': 'Employees',

  'header.account': 'Account',
  'header.administration': 'HR',
  'header.all_companies': 'All companies',
  'header.company': 'Company',
  'header.dashboard': 'Overview',
  'header.employees': 'Employees',
  'header.freelancers': 'Freelancers',
  'header.global_search': 'Search',
  'header.integrations': 'Integrations',
  'header.log_out': 'Log off',
  'header.more_menu_items': 'More menu items',
  'header.pay': 'Payroll',
  'header.registrations': 'Registrations',
  'header.settings': 'Settings',
  'header.warning.is_payroll_locked':
    'You cannot approve payrolls!  Your company has been locked, since you have unpaid invoices.  Go to {link} to solve the problem.',
  'header.warning.is_payroll_locked.link': 'the invoice overview',

  'help_modal.toggle_text': 'Click for more information',

  'income_type.denmark.b_income': 'B income',
  'income_type.denmark.contribution_free': '09 – Taxfree income',
  'income_type.denmark.contribution_free_abroad': '09 – Taxfree income (foreign residence)',
  'income_type.denmark.contribution_free_am': '09 – Taxfree income (with AM contribution)',
  'income_type.denmark.research_income': '08 – Researcher arrangement',
  'income_type.denmark.salary_income': '00 – A income/employed',

  'integration_layout.menu.accounting': 'Bookkeeping',
  'integration_layout.menu.advisor': 'Find advisor',
  'integration_layout.menu.codan': 'Insurance',
  'integration_layout.menu.contract_book': 'ContractBook',
  'integration_layout.menu.data_provider': 'Data provider',
  'integration_layout.menu.loan': 'Loan',
  'integration_layout.menu.min_refusion': 'MinRefusion',
  'integration_layout.menu.pension': 'Pension',
  'integration_layout.menu.personalezonen': 'Employee care',
  'integration_layout.menu.rmindr': 'Rmindr',
  'integration_layout.menu.slack': 'Slack',

  'invoice_email.alert.success': 'Changes to {name} have been saved',
  'invoice_email.edit.form.intro.line_1':
    'You can enter an email address which will receive invoices paid through Salary.',
  'invoice_email.edit.form.intro.line_2': 'Leave blank to disable.',
  'invoice_email.edit.form.invoice_email': 'Email for invoice receiver',
  'invoice_email.edit.form.invoice_email.invalid': 'Email for invoice receiver is invalid',
  'invoice_email.edit.title': 'Set invoice receiver',

  'invoices.tab.header.active_card': 'Active card: {brand} ending in {last4} (expiring {expiry})',
  'invoices.tab.header.integration.change': 'Change payment card',
  'invoices.tab.header.integration.create': 'Create payment card agreement',
  'invoices.tab.header.invoice_email': 'Invoice receiver',
  'invoices.tab.table.header.description': 'Description',
  'invoices.tab.table.header.due_date': 'Due date',
  'invoices.tab.table.header.invoice_date': 'Date',
  'invoices.tab.table.header.status': 'Status',
  'invoices.tab.table.header.sub_total': 'Subtotal',
  'invoices.tab.table.header.total': 'Total',
  'invoices.tab.table.status.invoiced': 'Awaiting payment',
  'invoices.tab.table.status.paid': 'Paid',
  'invoices.tab.table.status.pending': 'Draft',
  'invoices.tab.table.status.rejected': 'Rejected',
  'invoices.tab.title': 'Invoices',
  'invoices.tab.warnings.payroll_locked':
    'Since you have unpaid invoices, you cannot approve payrolls.  You can create a payment card agreement to pay your unpaid invoices.  You can always switch back to Payment Services afterwards.',

  'leave_registrations.calendar.approve_all_text.many':
    'Are you sure you wish to approve {count} absence registrations for all of {month}?',
  'leave_registrations.calendar.approve_all_text.one':
    'Are you sure you wish to approve {count} absence registration for all of {month}?',
  'leave_registrations.calendar.context_menu.format.no_note': '{days}',
  'leave_registrations.calendar.context_menu.format.with_note': '{days} ({note})',
  'leave_registrations.calendar.instruction_text':
    'Drag and drop from an open field to create new absence. Click existing absence to approve, edit or delete.',
  'leave_registrations.calendar.new_type': 'new absence',
  'leave_registrations.calendar.tooltip_format': '{type} for {name} on {date}',
  'leave_registrations.header.switch_to_calendar': 'Show as calendar',
  'leave_registrations.header.switch_to_table': 'Show as table',
  'leave_registrations.table.actions.edit': 'Edit',
  'leave_registrations.table.actions.register': 'Register',
  'leave_registrations.table.all_approved': 'All approved',
  'leave_registrations.table.approve_leave': 'Approve absence',
  'leave_registrations.table.descriptor.borrowed': '{amount} borrowed',
  'leave_registrations.table.descriptor.left': '{amount} left',
  'leave_registrations.table.descriptor.used': '{amount} spent',
  'leave_registrations.table.header.employee': 'Employee',
  'leave_registrations.table.header.has_unapproved': 'Approve',
  'leave_registrations.table.other_type': 'Other absence',
  'leave_registrations.table.unapproved_format': '{name}: {days}',
  'leave_registrations.title': 'Absence registration',

  'leave_type_setting.alert.success': 'Changes to absence type {type} have been saved',
  'leave_type_setting.leave_sub_type.table.actions.permit': 'Allow',
  'leave_type_setting.leave_sub_type.table.actions.restrict': 'Remove option',
  'leave_type_setting.leave_sub_type.table.can_select.false': 'No',
  'leave_type_setting.leave_sub_type.table.can_select.true': 'Yes',
  'leave_type_setting.leave_sub_type.table.header.can_select': 'Can be used by employees?',
  'leave_type_setting.leave_sub_type.table.header.configure': 'Set',
  'leave_type_setting.leave_sub_type.table.header.name': 'Absence subtype',
  'leave_type_setting.leave_sub_type.table.header.parent_name': 'Absence type',
  'leave_type_setting.leave_type.table.actions.permit': 'Allow',
  'leave_type_setting.leave_type.table.actions.restrict': 'Remove option',
  'leave_type_setting.leave_type.table.can_select.false': 'No',
  'leave_type_setting.leave_type.table.can_select.true': 'Yes',
  'leave_type_setting.leave_type.table.header.can_select': 'Can be used by employees?',
  'leave_type_setting.leave_type.table.header.configure': 'Set',
  'leave_type_setting.leave_type.table.header.name': 'Absence type',
  'leave_type_setting.tab.header.back': 'Back',
  'leave_type_setting.tab.sub_type_intro':
    'Some absence types have subtypes. You can set which subtypes the employees can select in the app.',
  'leave_type_setting.tab.title': 'Absence type settings',
  'leave_type_setting.tab.type_intro': 'Set which absence types the employees can use in the app.',

  'leave.sub_type.anniversary': 'Anniversary',
  'leave.sub_type.child_s_first_sick_day': 'Child’s first sick day',
  'leave.sub_type.child_s_second_sick_day': 'Child’s second sick day',
  'leave.sub_type.course': 'Course',
  'leave.sub_type.covid_19': 'COVID-19',
  'leave.sub_type.mourning_day': 'Mourning day',
  'leave.sub_type.moving_day': 'Moving day',
  'leave.sub_type.paragraph_56': '§ 56 agreement',
  'leave.sub_type.parental_leave': 'Employer paid parental leave',
  'leave.sub_type.parental_leave_after_2_weeks': 'Parental leave',
  'leave.sub_type.parental_leave_first_2_weeks': 'Paternity leave',
  'leave.sub_type.parental_leave_last_4_weeks_before_term': 'Four weeks before term (normal process)',
  'leave.sub_type.parental_leave_sickness': 'Pregnancy-related sickness',
  'leave.sub_type.time_off_in_lieu': 'Time off in lieu',
  'leave.tab.balances.assignable.standard': '{used} spent, {left} left',
  'leave.tab.balances.assignable.with_borrowed': '{used} spent, {left} left, {borrowed} borrowed',
  'leave.tab.balances.assignable.with_future': '{used} spent ({future} later), {left} left',
  'leave.tab.balances.assignable.with_future_borrowed':
    '{used} spent ({future} later), {left} left, {borrowed} borrowed',
  'leave.tab.balances.not_assignable.standard': '{used} spent',
  'leave.tab.balances.not_assignable.with_future': '{used} spent ({future} later)',
  'leave.tab.balances.unknown': 'Unknown balance',
  'leave.tab.confirm.bulk_delete':
    'Are you sure you wish to delete all the registrations? They cannot be deleted later.',
  'leave.tab.edit.button.approve': 'Approve absence registration',
  'leave.tab.edit.button.delete': 'Delete absence registration',
  'leave.tab.edit.form.created_at': 'Absence registration created {createdAt}.',
  'leave.tab.edit.form.date': 'Date',
  'leave.tab.edit.form.date.required': 'Date is required',
  'leave.tab.edit.form.days': 'Days',
  'leave.tab.edit.form.days.above_1_note':
    'Values higher than 1 day are usually only for employees, whose work week are fewer than 5 days, to match the value of 5 holidays per week. Consider instead to register the absence over several days.',
  'leave.tab.edit.form.days.invalid': 'Days is invalid',
  'leave.tab.edit.form.days.max_5': 'Days can at most be 5',
  'leave.tab.edit.form.days.required': 'Days is required',
  'leave.tab.edit.form.leave_duration': 'Duration',
  'leave.tab.edit.form.leave_duration.required': 'Duration is required',
  'leave.tab.edit.form.leave_sub_type_id.day_off': 'Day off type',
  'leave.tab.edit.form.leave_sub_type_id.none': 'None',
  'leave.tab.edit.form.leave_sub_type_id.other': 'Subtype',
  'leave.tab.edit.form.leave_sub_type_id.sick_day': 'Sick day type',
  'leave.tab.edit.form.leave_type_id': 'Type',
  'leave.tab.edit.form.leave_type_id.required': 'Type is required',
  'leave.tab.edit.form.no_rate_adjustment': 'Do {not} adjust absence value',
  'leave.tab.edit.form.no_rate_adjustment.help.line_1':
    'The company automatically adjusts absence values according to the work week, e.g. 1 holiday on a 4 day work week becomes 1.25 days.  This adjustment also occurs for other values than 1.',
  'leave.tab.edit.form.no_rate_adjustment.help.line_2':
    'If you chose not to adjust, the value will be saved as written.',
  'leave.tab.edit.form.no_rate_adjustment.not': 'not',
  'leave.tab.edit.form.note': 'Note',
  'leave.tab.edit.form.period': 'Period',
  'leave.tab.edit.form.period.required': 'Period is required',
  'leave.tab.edit.title.create': 'Create absence for {name}',
  'leave.tab.edit.title.edit': 'Edit absence for {name}',
  'leave.tab.header.add_new': 'Register absence',
  'leave.tab.header.approve_all': 'Approve all',
  'leave.tab.header.bulk_delete': 'Delete all',
  'leave.tab.header.link_to_calendar': "Go to the company's absence calendar",
  'leave.tab.header.title': 'Holiday and absence',
  'leave.tab.switch_to_calendar': 'Show as calendar',
  'leave.tab.switch_to_table': 'Show as table',
  'leave.tab.table.actions.delete': 'Delete registration',
  'leave.tab.table.actions.edit': 'Edit registration',
  'leave.tab.table.approved.approve': 'Approve',
  'leave.tab.table.approved.false': 'No',
  'leave.tab.table.approved.remove': 'Remove approval',
  'leave.tab.table.approved.true': 'Yes',
  'leave.tab.table.duration.format': '{description} ({extra})',
  'leave.tab.table.header.approved': 'Approved',
  'leave.tab.table.header.date': 'Date',
  'leave.tab.table.header.description': 'Description',
  'leave.tab.table.saving': 'Saving...',
  'leave.type.denmark_day_off_salaried.many': 'Days off (employer paid)',
  'leave.type.denmark_day_off_salaried.one': 'Day off (employer paid)',
  'leave.type.denmark_day_off.many': 'Days off',
  'leave.type.denmark_day_off.one': 'Day off',
  'leave.type.denmark_extra_vacation_accrual.many': 'Extra holidays (monthly)',
  'leave.type.denmark_extra_vacation_accrual.one': 'Extra holiday (monthly)',
  'leave.type.denmark_extra_vacation.many': 'Extra holidays',
  'leave.type.denmark_extra_vacation.one': 'Extra holiday',
  'leave.type.denmark_flex_time.many': 'Flex time',
  'leave.type.denmark_flex_time.one': 'Flex time',
  'leave.type.denmark_leave_day.many': 'Leave',
  'leave.type.denmark_leave_day.one': 'Leave',
  'leave.type.denmark_optional_vacation.many': 'Extra holidays (annual)',
  'leave.type.denmark_optional_vacation.one': 'Extra holiday (annual)',
  'leave.type.denmark_overtime.many': 'Overtime',
  'leave.type.denmark_overtime.one': 'Overtime',
  'leave.type.denmark_personal_day.many': 'Personal days (annual)',
  'leave.type.denmark_personal_day.one': 'Personal day (annual)',
  'leave.type.denmark_personal_time_accrual.many': 'Personal days (monthly)',
  'leave.type.denmark_personal_time_accrual.one': 'Personal day (monthly)',
  'leave.type.denmark_personal.many': 'Personal days',
  'leave.type.denmark_personal.one': 'Personal day',
  'leave.type.denmark_remote_work.many': 'Remote work',
  'leave.type.denmark_remote_work.one': 'Remote work',
  'leave.type.denmark_sick_day_paid_salaried.many': 'Sick days (employer paid)',
  'leave.type.denmark_sick_day_paid_salaried.one': 'Sick day (employer paid)',
  'leave.type.denmark_sick_day_paid.many': 'Sick days',
  'leave.type.denmark_sick_day_paid.one': 'Sick day',
  'leave.type.denmark_unpaid_day_off.many': 'Unpaid absence',
  'leave.type.denmark_unpaid_day_off.one': 'Unpaid absence',
  'leave.type.denmark_vacation_accrual.many': 'Paid holidays',
  'leave.type.denmark_vacation_accrual.one': 'Paid holiday',
  'leave.type.denmark_vacation_fund_with_pension.many': 'Unpaid holidays',
  'leave.type.denmark_vacation_fund_with_pension.one': 'Unpaid holiday',
  'leave.type.denmark_vacation_fund.many': 'Unpaid holidays',
  'leave.type.denmark_vacation_fund.one': 'Unpaid holiday',
  'leave.type.denmark_vacation_no_pay.many': 'Unpaid holidays',
  'leave.type.denmark_vacation_no_pay.one': 'Unpaid holiday',
  'leave.type.denmark_vacation_paid_additional.many': 'Paid holidays',
  'leave.type.denmark_vacation_paid_additional.one': 'Paid holiday',
  'leave.type.denmark_vacation_paid.many': 'Paid holidays',
  'leave.type.denmark_vacation_paid.one': 'Paid holiday',
  'leave.type.denmark_vacation.many': 'Holidays',
  'leave.type.denmark_vacation.one': 'Holiday',

  'loading.and': '{left} and {right}',
  'loading.comma': '{left}, {right}',
  'loading.loading': 'Loading {text}...',
  'loading.reducer.accounting_dimensions': 'accounting dimensions',
  'loading.reducer.asynchronous_tasks': 'asynchronous results',
  'loading.reducer.available_accounting_integrations': 'available accounting integrations',
  'loading.reducer.car_allowances': 'detailed transport registrations',
  'loading.reducer.coarse_car_allowances': 'coarse transport registrations',
  'loading.reducer.coarse_salary_registrations': 'coarse variable supplement registrations',
  'loading.reducer.coarse_time_registrations': 'coarse time registrations',
  'loading.reducer.company_account_plans': 'account plan',
  'loading.reducer.company_accounting_integration': 'accounting integration',
  'loading.reducer.company_payment_integrations': 'payment settings',
  'loading.reducer.company_users': 'company users',
  'loading.reducer.contract_templates': 'employee templates',
  'loading.reducer.contracts': 'contracts',
  'loading.reducer.cost_centers': 'cost centres',
  'loading.reducer.employee_emergency_contacts': 'next of kin',
  'loading.reducer.employees': 'employees',
  'loading.reducer.employment_positions': 'positions',
  'loading.reducer.employments': 'employments',
  'loading.reducer.leave_types': 'absence types',
  'loading.reducer.one_time_pays': 'one time registrations',
  'loading.reducer.pay_slips': 'payslips',
  'loading.reducer.pension_companies': 'pension companies',
  'loading.reducer.projects': 'projects',
  'loading.reducer.reimbursement_vouchers': 'outlays',
  'loading.reducer.remuneration': 'remuneration',
  'loading.reducer.salary_cycles': 'payroll cycles',
  'loading.reducer.salary_registrations': 'detailed variable supplement registrations',
  'loading.reducer.salary_types': 'pay rates',
  'loading.reducer.swipes': 'swipes',
  'loading.reducer.tax_cards': 'tax cards',
  'loading.reducer.time_registrations': 'detailed time registrations',
  'loading.reducer.transfers': 'transfers',
  'loading.reducer.user_invites': 'invitations',
  'loading.saving': 'Saving {text}...',

  'login.channel.form.recovery_code': 'Recovery code',
  'login.channel.form.recovery_code.required': 'Recovery code is required',
  'login.channel.form.response': 'Code',
  'login.channel.form.response.required': 'Code is required',
  'login.channel.form.toggle_recovery_code': 'Use recovery code',
  'login.channel.form.trust_device': 'Remember device for 30 days',
  'login.channel.mfa_channel': '{channel} two factor authentication code',
  'login.channel.recovery_code': 'recovery code',
  'login.channel.title': 'Fill in your {desc} below.',
  'login.form.create_new_user': 'Create new user',
  'login.form.email': 'Email',
  'login.form.email.required': 'Email is required',
  'login.form.employee_user_note': 'Are you an employee? {link}',
  'login.form.employee_user_note.link': 'Go to the employee site',
  'login.form.forgot_password': 'Forgot password?',
  'login.form.password': 'Password',
  'login.form.password.required': 'Password is required',
  'login.form.remember': 'Remember me',
  'login.form.submit': 'Log in',
  'login.splash.subtitle': 'Payroll without the hassle',
  'login.splash.title': 'Welcome to Salary',
  'login.title': 'Log in',

  'modal.confirm.cancel': 'Cancel',
  'modal.confirm.ok': 'OK',

  'modals.basic_upgrade.button': 'Upgrade to Basic',
  'modals.basic_upgrade.new_price_coming': '21 kr./payslip from 1 March 2024',
  'modals.basic_upgrade.price_per': 'Per payslip',
  'modals.basic_upgrade.subtitle': 'Get even more out of Salary and make your workday even easier',
  'modals.basic_upgrade.title': 'Upgrade to Basic',
  'modals.premium_upgrade.button': 'Upgrade to Premium',
  'modals.premium_upgrade.price_per': 'Per payslip',
  'modals.premium_upgrade.subtitle': 'Get even more out of Salary and make your workday even easier',
  'modals.premium_upgrade.title': 'Upgrade to Premium',

  'national_id.invalid': 'CPR number is invalid',
  'national_id.look_up.error': 'Could not look up the CPR number',
  'national_id.look_up.hidden_address': 'The CPR number has name and address protection',
  'national_id.look_up.not_found': 'CPR number not found',

  'nets_tab.disposition_date_change_reason.01': 'Requested change of disposition date',
  'nets_tab.disposition_date_change_reason.02': 'Not completed due to missing agreement or exceeding maximum',
  'nets_tab.disposition_date_change_reason.03': 'Changed disposition date due to negation',
  'nets_tab.disposition_date_change_reason.04': 'Changed disposition date due to late transfer/not bank day',
  'nets_tab.error_code.1': 'No Easy Account found for the transferred identification (e.g. CVR-no, CPR-no)',
  'nets_tab.error_code.2': 'CPR number is invalid',
  'nets_tab.error_code.3': 'CVR number is invalid',
  'nets_tab.error_code.4': 'SE number is invalid',
  'nets_tab.error_code.5': 'Production unit number is invalid',
  'nets_tab.error_code.6': 'Transferred SE number is missing CVR number',
  'nets_tab.error_code.7': 'Disposition date too far in the future (a maximum of 4 days after receiving delivery)',
  'nets_tab.error_code.8': "Key type in OS6 record's field 10A is invalid",
  'nets_tab.error_code.9': 'Section start record is missing before OS6 record',
  'nets_tab.error_code.d':
    'Payment transmitter does not have a valid agreement regarding treatment of Easy Account based payments',
  'nets_tab.error_code.k': 'Account number is invalid',
  'nets_tab.error_code.p': 'PBS number is invalid',
  'nets_tab.error_code.r': 'Registration number is invalid',
  'nets_tab.error_code.s': 'No response received from Easy Account within time limit (5 days after disposition date)',
  'nets_tab.error_code.t': 'CPR number or client number is incorrect',
  'nets_tab.info_type.0': 'Standard',
  'nets_tab.info_type.100': 'Pension and insurance',
  'nets_tab.info_type.150': 'Labour market pension',
  'nets_tab.info_type.200': 'Union dues',
  'nets_tab.info_type.300': 'State payments',
  'nets_tab.info_type.400': 'Holiday allowance',
  'nets_tab.info_type.800': 'ATP / Family leave funds',
  'nets_tab.info_type.900': 'A tax / labour market contribution',
  'nets_tab.limit_type.2.text': 'Limited',
  'nets_tab.limit_type.2.tool_tip':
    "Payment transmitter's bank have decided a maximum amount for transfers per payment day. If the transfers exceeds the maximum amount, the transfers will not be completed.",
  'nets_tab.limit_type.3.text': 'Unlimited',
  'nets_tab.limit_type.3.tool_tip': "Payment transmitter's bank controls the amount in their own system.",
  'nets_tab.limit_type.4.text': 'Limited',
  'nets_tab.limit_type.4.tool_tip':
    "Payment transmitter's bank have decided a maximum amount for transfers. Payments will be completed regardless of whether they exceed the maximum amount, but the bank will receive a notification of the exceedance.",
  'nets_tab.message.comment.amount': 'Amount',
  'nets_tab.message.comment.error_code': 'Error code',
  'nets_tab.message.comment.error_text_1': 'Error information 1',
  'nets_tab.message.comment.error_text_2': 'Error information 2',
  'nets_tab.message.comment.record_count': 'Record count',
  'nets_tab.message.comment.reference': 'Reference',
  'nets_tab.message.comment.retracted_amount': 'Retracted amount',
  'nets_tab.message.comment.transfer_type': 'Transfer type',
  'nets_tab.message.disposition_changed.account_number': 'Accountno.',
  'nets_tab.message.disposition_changed.amount': 'Amount',
  'nets_tab.message.disposition_changed.cvr': 'CVR number',
  'nets_tab.message.disposition_changed.new_disposition_date': 'New disposition date',
  'nets_tab.message.disposition_changed.next_bank_day': 'Next bank day',
  'nets_tab.message.disposition_changed.original_disposition_date': 'Original disposition date',
  'nets_tab.message.disposition_changed.reason': 'Reason',
  'nets_tab.message.disposition_changed.record_count': 'Record count',
  'nets_tab.message.disposition_changed.registration_number': 'Reg.no.',
  'nets_tab.message.disposition_changed.transaction_type': 'Transaction type',
  'nets_tab.message.disposition_changed.transfer_type': 'Transfer type',
  'nets_tab.message.double_delivery.account_number': 'Accountno.',
  'nets_tab.message.double_delivery.amount': 'Amount',
  'nets_tab.message.double_delivery.cvr': 'CVR number',
  'nets_tab.message.double_delivery.cvr_supplier': 'CVR number (data supplier)',
  'nets_tab.message.double_delivery.delivery_number': 'Delivery number',
  'nets_tab.message.double_delivery.disposition_date': 'Disposition date',
  'nets_tab.message.double_delivery.record_count': 'Record count',
  'nets_tab.message.double_delivery.registration_number': 'Reg.no.',
  'nets_tab.message.double_delivery.section_number': 'Section number',
  'nets_tab.message.double_delivery.transfer_type': 'Transfer type',
  'nets_tab.message.info.amount': 'Amount',
  'nets_tab.message.info.cpr': 'CPR number',
  'nets_tab.message.info.customer_number': 'Client number',
  'nets_tab.message.info.error_code': 'Error code',
  'nets_tab.message.info.info_type': 'Info type',
  'nets_tab.message.info.pbs': 'PBS number',
  'nets_tab.message.info.transfer_type': 'Transfer type',
  'nets_tab.message.maximum_amount.limit_type': 'Disposition restriction code',
  'nets_tab.message.maximum_amount.maximum_amount': 'Maximum amount',
  'nets_tab.message.stop.account_number': 'Accountno.',
  'nets_tab.message.stop.amount': 'Amount',
  'nets_tab.message.stop.code': 'Stop/delete code',
  'nets_tab.message.stop.cvr': 'CVR number',
  'nets_tab.message.stop.disposition_date': 'Disposition date',
  'nets_tab.message.stop.name': 'Name',
  'nets_tab.message.stop.record_count': 'Record count',
  'nets_tab.message.stop.registration_number': 'Reg.no.',
  'nets_tab.message.stop.transfer_type': 'Transfer type',
  'nets_tab.message.transaction_receipt.account_number': 'Accountno.',
  'nets_tab.message.transaction_receipt.amount': 'Amount',
  'nets_tab.message.transaction_receipt.bookkeeping_date': 'Bookkeeping date',
  'nets_tab.message.transaction_receipt.info_type': 'Info type',
  'nets_tab.message.transaction_receipt.record_count': 'Record count',
  'nets_tab.message.transaction_receipt.record_type': 'Record type',
  'nets_tab.message.transaction_receipt.registration_number': 'Reg.no.',
  'nets_tab.message.transaction_receipt.rejected_amount': 'Rejected amount',
  'nets_tab.message.transaction_receipt.rejected_record_count': 'Rejected record count',
  'nets_tab.message.transaction_receipt.transaction_type': 'Transaction type',
  'nets_tab.message.transaction_receipt.transfer_type': 'Transfer type',
  'nets_tab.record_type.7': 'Reverse transfered (will be credited)',
  'nets_tab.record_type.8': 'Debited',
  'nets_tab.stop_code.1': 'Stop message',
  'nets_tab.stop_code.3': 'Delete message',
  'nets_tab.table.header.date': 'Date',
  'nets_tab.table.header.info': 'Information',
  'nets_tab.table.header.title': 'Title',
  'nets_tab.title': 'Messages from MasterCard Payment Service',
  'nets_tab.transaction_type.a': 'Normal transfer',
  'nets_tab.transaction_type.h': 'Fast transfer',
  'nets_tab.transfer_type.10': 'Pay',
  'nets_tab.transfer_type.30': 'Transferred',
  'nets_tab.transfer_type.40': 'No text',
  'nets_tab.transfer_type.50': 'Standing order',
  'nets_tab.transfer_type.60': 'Information transfer',
  'nets_tab.transfer_type.70': 'Transferred',
  'nets_tab.transfer_type.80': 'No text',
  'nets_tab.transfer_type.90': 'Pension',
  'nets_tab.transfer_type.91': 'No text',

  'nets.message_type.comment': 'Comment',
  'nets.message_type.disposition_change': 'Disposition date change',
  'nets.message_type.double_delivery': 'Double delivery',
  'nets.message_type.info': 'Information',
  'nets.message_type.maximum_amount': 'Information about maximum amount',
  'nets.message_type.stop': 'Stop message',
  'nets.message_type.transaction_receipt': 'Transaction receipt',

  'notifications.header.delete_all': 'Delete all notifications',
  'notifications.header.title': 'Notification centre',
  'notifications.table.actions.link': 'Go to',
  'notifications.table.actions.remove': 'Remove',
  'notifications.table.header.description': 'Description',
  'notifications.table.header.status': 'Status',
  'notifications.warning.company_not_verified.description':
    'Awaiting approval of company. Write {email} if more than 24 hours have passed since you created your company in Salary.',
  'notifications.warning.company_not_verified.description.email': 'Support',
  'notifications.warning.company_not_verified.status_text': 'No payroll',
  'notifications.warning.company_not_verified.title': 'Company not yet approved',
  'notifications.warning.dinero_old_token.description':
    'Your Dinero key is being phased out. If you do not renew your Dinero key, your booking will start to fail. It is Dinero who has decided to phase out the old keys. To switch to the new key, go to integration - accounting, select "change accounting integration", select Dinero as accounting software and follow the instructions.',
  'notifications.warning.dinero_old_token.status_text': 'Booking probably not possible',
  'notifications.warning.dinero_old_token.title': 'Your Dinero key is being phased out',
  'notifications.warning.employee_contract_family_leave_not_changed.description':
    "During the update of the company's family leave fund, the specific family leave fund could not be changed for {name}.  You will need to check the employee manually.",
  'notifications.warning.employee_contract_family_leave_not_changed.status_text':
    'Employee has wrong family leave fund',
  'notifications.warning.employee_contract_family_leave_not_changed.title':
    'The family leave fund for {name} could not be changed',
  'notifications.warning.employee_empty_tax_card.description':
    'We received an empty tax card from SKAT for your employee. That means we will deduct 55% in A tax without exemptions. If your employee expects something else, they should edit their preliminary income assessment at SKAT. Once completed, we will automatically receive a new tax card from SKAT.',
  'notifications.warning.employee_empty_tax_card.status_text': 'No exemptions and 55% tax',
  'notifications.warning.employee_empty_tax_card.title': 'Empty tax card for {name}',
  'notifications.warning.employee_invalid_national_id.description': 'You can edit it under profile on the employee.',
  'notifications.warning.employee_invalid_national_id.status_text': 'Invalid CPR number',
  'notifications.warning.employee_invalid_national_id.title': 'Invalid CPR number for {name}',
  'notifications.warning.employee_is_foreign_resident.description':
    "SKAT says that the employee {name} is residing outside Denmark. If they do live abroad, you will need to change their type to 'CPR number residing outside Denmark' under profile before the next payroll.  If they do live in Denmark, your employee must change their address with the national registration office.",
  'notifications.warning.employee_is_foreign_resident.status_text': 'Reporting notice',
  'notifications.warning.employee_is_foreign_resident.title': 'Employee residing outside Denmark',
  'notifications.warning.employee_missing_contract.description': 'Click here to create an employment for the employee.',
  'notifications.warning.employee_missing_contract.status_text': 'No pay for employee',
  'notifications.warning.employee_missing_contract.title': 'Missing employment for {name}',
  'notifications.warning.employee_missing_tax_card.description':
    'Salary has not yet received a tax card for {name}, so the employee will be taxed by 55%. Please check that the CPR-number is correct, or attempt to request a new tax card. If you’ve created the employee a moment ago, please wait for SKAT to send the tax card - this can take up to an hour.',
  'notifications.warning.employee_missing_tax_card.status_text': 'No exemptions and 55% tax',
  'notifications.warning.employee_missing_tax_card.title': 'Missing tax card for {name}',
  'notifications.warning.error_in_shipment_transfer.description':
    'A transfer to {name} has failed.  MasterCard Payment Services provides the following reason (in Danish): {response}',
  'notifications.warning.error_in_shipment_transfer.status_text': 'Transfer failed',
  'notifications.warning.error_in_shipment_transfer.title': 'Transfer to employee failed',
  'notifications.warning.fix_credit_card_integration.description.contact_issuer':
    'Salary could not pay the invoice, since there were problems with the payment card. You should contact your card issuer, and try again.',
  'notifications.warning.fix_credit_card_integration.description.default':
    'Salary could not pay the invoice, since there were problems with the payment card.',
  'notifications.warning.fix_credit_card_integration.description.new_card':
    'Salary could not pay the invoice, since there were problems with the payment card. You will need to create a new payment card agreement.',
  'notifications.warning.fix_credit_card_integration.description.try_again':
    'Salary could not pay the invoice, since there were problems with the payment card. Fix the error and try again.',
  'notifications.warning.fix_credit_card_integration.description.with_note_from_issuer':
    '{description} Error from card issuer: {error}',
  'notifications.warning.fix_credit_card_integration.status_text': 'Invoice not paid',
  'notifications.warning.fix_credit_card_integration.title': 'Card payment failed',
  'notifications.warning.invalid_production_unit.description':
    'SKAT says that the production unit number for {name} is not valid. You will need to fix this before your next payroll.',
  'notifications.warning.invalid_production_unit.status_text': 'Reporting notice',
  'notifications.warning.invalid_production_unit.title': 'Invalid production unit number',
  'notifications.warning.nets_onboarding_failed.description':
    'Establishing of MasterCard Payment Service was rejected. Check that you have entered the correct registration and account numbers, and try again. If the error persists, contact support.',
  'notifications.warning.nets_onboarding_failed.status_text': 'No automatic transfers',
  'notifications.warning.nets_onboarding_failed.title': 'PaymentService agreement establishment failed',
  'notifications.warning.not_registered_as_employer.description':
    "After the payroll, we were informed from SKAT, that the company is not registered as an employer. The reporting was thus rejected. You need to register the company with SKAT and AM at virk.dk, afterwards write Salary's support, so that we can re-report for you.",
  'notifications.warning.not_registered_as_employer.status_text': 'No reporting done',
  'notifications.warning.not_registered_as_employer.title': 'Missing registration as employer',
  'notifications.warning.voucher_booking_failed.description':
    'Ensure all the fields are matched correctly in your integration with those in your accounting program, and try again.',
  'notifications.warning.voucher_booking_failed.status_text': 'Booking not possible',
  'notifications.warning.voucher_booking_failed.title': 'Receipt could not be booked',
  'notifications.warning.voucher_missing_account.description':
    'Salary could not book a payroll, since the follow accounts have not been configured for the accounting integration: {accounts}',
  'notifications.warning.voucher_missing_account.status_text': 'Booking not possible',
  'notifications.warning.voucher_missing_account.title': 'Missing booking accounts',

  'one_time_pay.category.type_0000': 'None',
  'one_time_pay.category.type_0050': '50 - Purchase and signing rights to stocks including shares cf. LL § 16',
  'one_time_pay.category.type_0051': '51 - Purchase and signing rights cf. LL § 28',
  'one_time_pay.category.type_0061':
    '61 - Benefits for majority shareholders including distributed paid car as dividend',
  'one_time_pay.category.type_0079': '79 - Self-employment',
  'one_time_pay.category.type_0082': '82 - Remuneration to private day labourers',
  'one_time_pay.category.type_0097': '97 - Tax free distributions and endowment',
  'one_time_pay.category.type_0099':
    '99 - Taxable non-profit distributions from non-profit taxable foundations and associations',
  'one_time_pay.category.type_0100':
    '100 - Taxable FOR-profit distributions from non-profit taxable foundations and associations',
  'one_time_pay.category.type_0103': '103 - Employer paid sick day allowance',
  'one_time_pay.category.type_0104': '104 - Compensation days (G-dage)',
  'one_time_pay.type.anniversary_bonus': 'Anniversary and termination bonus',
  'one_time_pay.type.atp': 'ATP',
  'one_time_pay.type.benefit_in_kind': 'Employee stock § 7P (income category 0101)',
  'one_time_pay.type.bonus': 'Regular',
  'one_time_pay.type.bonus_no_pension': 'Regular (without pension)',
  'one_time_pay.type.bonus_no_vacation': 'Regular (without holiday entitled pay)',
  'one_time_pay.type.bonus_no_vacation_and_pension': 'Regular (without holiday entitled pay or pension)',
  'one_time_pay.type.car_allowance': 'Transport allowance',
  'one_time_pay.type.car_simple': 'Paid car',
  'one_time_pay.type.compensation': 'B income with AM contribution (field 36)',
  'one_time_pay.type.compensation_no_am': 'B income without AM contribution (field 38)',
  'one_time_pay.type.free_phone': 'Free phone',
  'one_time_pay.type.free_text': 'Text on payslip',
  'one_time_pay.type.fritvalg': 'Fritvalg account',
  'one_time_pay.type.fritvalg_increase': 'Increase of Fritvalg account',
  'one_time_pay.type.fritvalg_increase_no_pension': 'Increase of Fritvalg account (without pension)',
  'one_time_pay.type.fritvalg_increase_no_vacation': 'Increase of Fritvalg account (without holiday entitled pay)',
  'one_time_pay.type.fritvalg_increase_no_vacation_and_pension':
    'Increase of Fritvalg account (without holiday entitled pay and pension)',
  'one_time_pay.type.g_dage': 'Income without AM contribution',
  'one_time_pay.type.grant': 'Tax free grant (field 38, category 97)',
  'one_time_pay.type.gross_pay_reduction': 'Gross pay reduction',
  'one_time_pay.type.gross_pay_reduction_no_pension': 'Gross pay reduction (without pension basis)',
  'one_time_pay.type.gross_pay_reduction_no_vacation': 'Gross pay reduction (without holiday basis)',
  'one_time_pay.type.gross_pay_reduction_no_vacation_and_pension':
    'Gross pay reduction (without holiday and pension basis)',
  'one_time_pay.type.gross_vacation_money': 'Gross holiday allowance',
  'one_time_pay.type.honorary_gift': 'Honorary gift (field 39)',
  'one_time_pay.type.honorary_gift_benefit_in_kind': 'Honorary gift (as a benefit in kind)',
  'one_time_pay.type.overtime_payout': 'Overtime payout',
  'one_time_pay.type.paid_vacation': 'Paid holiday',
  'one_time_pay.type.pay_check_advanced': 'Advance/net pay reduction',
  'one_time_pay.type.reimbursement': 'Outlay',
  'one_time_pay.type.sh_dage': 'SH-days',
  'one_time_pay.type.sh_dage_increase': 'Increase of SH-days',
  'one_time_pay.type.sh_dage_increase_no_pension': 'Increase of SH-days (without pension)',
  'one_time_pay.type.sh_dage_increase_no_vacation': 'Increase of SH-days (without holiday entitled pay)',
  'one_time_pay.type.sh_dage_increase_no_vacation_and_pension':
    'Increase of SH-days (without holiday entitled pay and pension)',
  'one_time_pay.type.tax_deduction_modification_decrease': 'Reduction of tax exemption',
  'one_time_pay.type.tax_deduction_modification_increase': 'Increase of tax exemption',
  'one_time_pay.type.tax_free_income': 'Tax free income',
  'one_time_pay.type.tax_free_income_abroad': 'Tax free income for foreign residents',
  'one_time_pay.type.travel_allowance': 'Travel allowance',
  'one_time_pay.type.vacation_fund_payment': 'Sick holiday allowance',
  'one_time_pay.type.vacation_money_amount': 'Holiday funds paid out amount',
  'one_time_pay.type.vacation_money_days': 'Holiday funds paid out days',
  'one_time_pay.type.work_hours': 'Work hours',

  'one_time_pays.header.import_excel': 'Import Excel',
  'one_time_pays.import.alert.success.fees': 'The compensations were saved',
  'one_time_pays.import.alert.success.standard': 'The supplements, etc. were saved',
  'one_time_pays.import.step_0.buttons.continue': 'Continue to upload',
  'one_time_pays.import.step_0.buttons.download': 'Download Excel file',
  'one_time_pays.import.step_0.intro':
    'Download the Excel file with a list of your employees using the button below. If you have already downloaded and filled in the Excel file, you can continue to the file upload.',
  'one_time_pays.import.step_1.buttons.back': 'Back',
  'one_time_pays.import.step_1.buttons.upload': 'Upload Excel file',
  'one_time_pays.import.step_1.importing': 'Loading...',
  'one_time_pays.import.step_1.intro': 'Upload the filled in Excel file with your employees using the button below.',
  'one_time_pays.import.step_2.buttons.back': 'Back',
  'one_time_pays.import.step_2.buttons.save.fee': 'Save the compensations',
  'one_time_pays.import.step_2.buttons.save.standard': 'Save the supplements',
  'one_time_pays.import.step_2.importing': 'Saving...',
  'one_time_pays.import.step_2.result.header.amount': 'Amount',
  'one_time_pays.import.step_2.result.header.category': 'Subtype',
  'one_time_pays.import.step_2.result.header.employee': 'Employee',
  'one_time_pays.import.step_2.result.header.title': 'Text',
  'one_time_pays.import.step_2.result.header.type': 'Type',
  'one_time_pays.import.step_3.buttons.done': 'Done',
  'one_time_pays.import.step_3.result.header.employee': 'Employee',
  'one_time_pays.import.step_3.result.header.state': 'Change status',
  'one_time_pays.import.step_3.result.header.state_message': 'Description',
  'one_time_pays.import.step_3.success.fee': 'All compensations imported.',
  'one_time_pays.import.step_3.success.standard': 'All supplements imported.',
  'one_time_pays.import.steps.0': 'Download Excel file',
  'one_time_pays.import.steps.1': 'Upload Excel file',
  'one_time_pays.import.steps.2': 'Confirm',
  'one_time_pays.import.steps.3': 'Result',
  'one_time_pays.import.title': 'Import Excel file',
  'one_time_pays.table.add': 'Add',
  'one_time_pays.table.approve_amount': 'Approve {amount}',
  'one_time_pays.table.header.advanced': 'Advance',
  'one_time_pays.table.header.bonus': 'Bonus',
  'one_time_pays.table.header.employee': 'Employee',
  'one_time_pays.table.header.reimbursement': 'Outlay',
  'one_time_pays.table.header.travel': 'Travel',
  'one_time_pays.title': 'Supplements/reductions',

  'otp_form.accounting_text': 'Text for separate accounting line (leave blank to not be separate)',
  'otp_form.amount.calculated_amount': 'Calculated amount: {amount}',
  'otp_form.amount.invalid': 'Amount for {description} is invalid',
  'otp_form.amount.not_negative': 'Amount for {description} cannot be negative',
  'otp_form.amount.rate': 'Rate',
  'otp_form.amount.rate.invalid': 'Rate is invalid',
  'otp_form.amount.rate.not_negative': 'Rate cannot be negative',
  'otp_form.amount.rate.required': 'Rate is required',
  'otp_form.amount.rate.suffix': 'kr.',
  'otp_form.amount.required': 'Amount for {description} is required',
  'otp_form.amount.suffix': 'kr.',
  'otp_form.amount.title': 'Amount for {description}',
  'otp_form.amount.units': 'Quantity',
  'otp_form.amount.units.invalid': 'Quantity is invalid',
  'otp_form.amount.units.not_negative': 'Quantity cannot be negative',
  'otp_form.amount.units.required': 'Quantity is required',
  'otp_form.amount.use_rate': 'Use rate and quantity instead',
  'otp_form.approved.approved': 'Approved?',
  'otp_form.approved.checked': 'Approved',
  'otp_form.approved.unchecked': 'Draft',
  'otp_form.cost_center': 'Cost centre',
  'otp_form.debit_company_accounting_account_id': 'Other debit account',
  'otp_form.debit_company_accounting_account_id.no_longer_available.option_note': '(no longer available)',
  'otp_form.disposition_date.default_title': 'Disposition date',
  'otp_form.disposition_date.picker': 'Date picker',
  'otp_form.disposition_date.required': '{title} is required',
  'otp_form.title': 'Text on payslip',
  'otp_form.title.count': '{length}/{max} characters',
  'otp_form.title.required': 'Text on payslip is required',
  'otp_form.title.too_long': 'Text on payslip can at most be {limit} characters',
  'otp_form.title.warning':
    'You are about to use more characters than available space on the payslip; the remaining will be cut off.',

  'password_request.completed': "Check your email's inbox for your new password.",
  'password_request.form.email': 'Email',
  'password_request.form.email.required': 'Email is required',
  'password_request.form.submit': 'Request a new password',
  'password_request.intro': 'Enter your email and receive your new password within 2 minutes.',
  'password_request.title': 'Forgotten password',

  'password_reset.alert.success': 'Your password has been saved. Log in below.',
  'password_reset.form.password': 'Password',
  'password_reset.form.password.at_least_8_characters': 'Password must be at least 8 characters',
  'password_reset.form.password.required': 'Password is required',
  'password_reset.form.submit': 'Approve new password',
  'password_reset.title': 'Create new password',

  'pay_check_advance.edit.form.amount': 'advance/net reduction',
  'pay_check_advance.edit.form.disposition_date': 'When shall the advanced/net reduction be reduced from the pay?',
  'pay_check_advance.edit.form.disposition_date.placeholder': 'Reduction date',
  'pay_check_advance.edit.title.create': 'Register advance/net reduction',
  'pay_check_advance.edit.title.edit': 'Edit advance/net reduction',
  'pay_check_advance.header.add_pay_check_advance': 'Register advance/net reduction',
  'pay_check_advance.header.approve_all': 'Approve all',
  'pay_check_advance.header.hide_history': 'Back',
  'pay_check_advance.header.show_history': 'Show archived',
  'pay_check_advance.table.actions.delete': 'Delete advance/net reduction',
  'pay_check_advance.table.actions.edit': 'Edit advance/net reduction',
  'pay_check_advance.table.actions.view': 'View advance/net reduction',
  'pay_check_advance.table.amount_format': '{units} of {rate}',
  'pay_check_advance.table.approved.approve': 'Approve',
  'pay_check_advance.table.approved.false': 'No',
  'pay_check_advance.table.approved.remove': 'Remove approval',
  'pay_check_advance.table.approved.true': 'Yes',
  'pay_check_advance.table.header.amount': 'Amount',
  'pay_check_advance.table.header.approved': 'Approved',
  'pay_check_advance.table.header.date': 'Date',
  'pay_check_advance.table.header.title': 'Description',
  'pay_check_advance.table.saving': 'Saving...',
  'pay_check_advance.title.history': 'Advance/net reduction archive',
  'pay_check_advance.title.standard': 'Advance/net reduction',

  'pay_roll.approval_type.automatic': 'Automatic',
  'pay_roll.approval_type.manual': 'Manual',
  'pay_roll.approval_type.no_approval': 'Automatic',
  'pay_roll.single.accounting.header.accounting_voucher': 'Download accounting report',
  'pay_roll.single.accounting.header.accounting_voucher.csv': 'Download as CSV',
  'pay_roll.single.accounting.header.accounting_voucher.excel': 'Download as Excel',
  'pay_roll.single.accounting.header.accounting_voucher.pdf': 'Download as PDF',
  'pay_roll.single.accounting.header.automatic': 'Automatic via: {type}',
  'pay_roll.single.accounting.header.employees_report': 'Download payroll report',
  'pay_roll.single.accounting.header.employees_report.excel': 'Download as Excel',
  'pay_roll.single.accounting.header.employees_report.pdf': 'Download as PDF',
  'pay_roll.single.accounting.header.income_tax_report': 'Download E-income report',
  'pay_roll.single.accounting.header.income_tax_report.excel': 'Download as Excel',
  'pay_roll.single.accounting.header.income_tax_report.pdf': 'Download as PDF',
  'pay_roll.single.accounting.header.manual': 'Manual',
  'pay_roll.single.accounting.header.settings': 'Settings',
  'pay_roll.single.accounting.header.title': 'Accounting',
  'pay_roll.single.accounting.table.header.account_number': 'Account',
  'pay_roll.single.accounting.table.header.credit': 'Credit (kr.)',
  'pay_roll.single.accounting.table.header.debit': 'Debit (kr.)',
  'pay_roll.single.accounting.table.header.description': 'Description',
  'pay_roll.single.accounting.table.header.vat': 'Moms (kr.)',
  'pay_roll.single.accounting.table.unknown_account': 'Unknown account number',
  'pay_roll.single.approve.approval_code': 'Approval code',
  'pay_roll.single.approve.create_stripe_integration_button': 'Create payment card agreement',
  'pay_roll.single.approve.intro':
    'Until the payroll is completed, you can reopen it and add new changes. When the payroll is completed, it cannot be modified further.',
  'pay_roll.single.approve.intro.no_deadline': 'This payroll will complete five minutes after approval.',
  'pay_roll.single.approve.no_payment_integration.intro.line_1':
    'A payment card is required to pay invoices to Salary, when there is no Payment Service agreement.',
  'pay_roll.single.approve.no_payment_integration.intro.line_2':
    'Note that pay, taxes and other transfers do not happen via payment card.',
  'pay_roll.single.approve.no_payment_integration.title': 'You cannot approve the payroll without a payment card',
  'pay_roll.single.approve.partial_approval.many':
    'When you have approved the payroll, it still needs {count} to approve the payroll before it is fully approved.',
  'pay_roll.single.approve.partial_approval.one':
    'When you have approved the payroll, it still needs one more to approve the payroll before it is fully approved.',
  'pay_roll.single.approve.payroll_locked.intro.line_1':
    'Since you have unpaid invoices, you cannot approve payrolls.  You will have to pay your invoices before you can approve payrolls.',
  'pay_roll.single.approve.payroll_locked.intro.line_2':
    'You can create a payment card agreement immediately to pay your unpaid invoices.  Check {link} to see your unpaid invoices.',
  'pay_roll.single.approve.payroll_locked.intro.line_2.link': 'the invoice overview',
  'pay_roll.single.approve.payroll_locked.title': 'You cannot approve payrolls due to unpaid invoices',
  'pay_roll.single.approve.select_other_approvers':
    'Select which other users to notify that the payroll is now partially approved:',
  'pay_roll.single.approve.submit': 'Approve payroll',
  'pay_roll.single.approve.title.finally': 'Would you like to fully approve the payroll?',
  'pay_roll.single.approve.title.partial': 'Would you like to partially approve the payroll?',
  'pay_roll.single.delete.intro':
    'When the payroll is deleted, you will need to create a whole new payroll for the same period to run it again.',
  'pay_roll.single.delete.submit': 'Delete payroll',
  'pay_roll.single.delete.title': 'Do you want to delete the payroll?',
  'pay_roll.single.delete.warning_about_other_approved':
    'There is at least one other open approved payroll for this payroll period.  Be aware that when you delete this payroll, approved payrolls will not be recalculated.  You should therefore check that they are still correct; and if not, reopen them.',
  'pay_roll.single.details.approved_by': 'Approved by',
  'pay_roll.single.details.approved_by.at': '{time}',
  'pay_roll.single.details.approved_by.none': 'None',
  'pay_roll.single.details.disposition_date': 'Disposition date',
  'pay_roll.single.details.frequency': 'Frequency',
  'pay_roll.single.details.period': 'Period',
  'pay_roll.single.details.status': 'Status',
  'pay_roll.single.details.status.see_receipt': 'See receipt',
  'pay_roll.single.details.title': 'Overview',
  'pay_roll.single.details.total_transfer': 'Amount',
  'pay_roll.single.details.type': 'Type',
  'pay_roll.single.details.type.manual': 'Extra payroll',
  'pay_roll.single.details.type.negation': 'Negation of payroll',
  'pay_roll.single.details.type.ordinary': 'Ordinary payroll',
  'pay_roll.single.details.type.terminated_employments': 'Termination of salaried employees',
  'pay_roll.single.deviations.currency_format': '{percentage} % ({currency})',
  'pay_roll.single.deviations.header.deviation': 'Deviation',
  'pay_roll.single.deviations.header.name': 'Employee',
  'pay_roll.single.deviations.header.type': 'Type',
  'pay_roll.single.deviations.hours_format': '{percentage} % ({hours} hours)',
  'pay_roll.single.deviations.settings': 'Settings',
  'pay_roll.single.deviations.tax_card_format': 'From {old} to {new}',
  'pay_roll.single.deviations.title': 'Deviations',
  'pay_roll.single.deviations.total': 'In total: {total}',
  'pay_roll.single.edit_disposition_date.disposition_date': 'Disposition date',
  'pay_roll.single.edit_disposition_date.disposition_date.not_before_date': 'Disposition date cannot be before {date}',
  'pay_roll.single.edit_disposition_date.disposition_date.required': 'Disposition date is required',
  'pay_roll.single.edit_disposition_date.intro':
    'You are about to edit the tax disposition date for the payroll. If you change the disposition date to a different month, the payroll will tax wise connect to that new month/year. Contact support if you have questions.',
  'pay_roll.single.edit_disposition_date.submit': 'Change disposition date',
  'pay_roll.single.edit_disposition_date.title': 'Changing the disposition date for the payroll',
  'pay_roll.single.fix_national_id.errors.employee_update_failed': 'A CPR number could not be changed.',
  'pay_roll.single.fix_national_id.errors.extra_payroll_failed': 'The extra payroll could not be created.',
  'pay_roll.single.fix_national_id.errors.negate_payroll_failed': 'The negation could not be completed.',
  'pay_roll.single.fix_national_id.errors.otp_creation_failed': 'A net reduction could not be created.',
  'pay_roll.single.fix_national_id.extra_pay_roll_link': 'Go to the extra payroll',
  'pay_roll.single.fix_national_id.header.name': 'Employee',
  'pay_roll.single.fix_national_id.header.new_national_id': 'Enter new CPR number',
  'pay_roll.single.fix_national_id.header.old_national_id': 'Current CPR number',
  'pay_roll.single.fix_national_id.intro.part_1.many':
    '{count} employees have invalid CPR numbers, which need to be fixed before their pay can be reported to SKAT.',
  'pay_roll.single.fix_national_id.intro.part_1.one':
    'An employee has an invalid CPR number, which need to be fixed before their pay can be reported to SKAT.',
  'pay_roll.single.fix_national_id.intro.part_2.many':
    'Fill in the correct CPR numbers for the employees here, then we will reverse their payslips, change their CPR numbers, and create an extraordinary payroll with their new CPR numbers.',
  'pay_roll.single.fix_national_id.intro.part_2.one':
    'Fill in the correct CPR number for the employee here, then we will reverse their payslip, change their CPR number, and create an extraordinary payroll with their new CPR number.',
  'pay_roll.single.fix_national_id.intro.part_3.many':
    'We create a net reduction on the employees for the current pay period to ensure they do not get paid twice.',
  'pay_roll.single.fix_national_id.intro.part_3.one':
    'We create a net reduction on the employee for the current pay period to ensure they do not get paid twice.',
  'pay_roll.single.fix_national_id.intro.part_4': 'If a field is left blank, the employee will not be modified.',
  'pay_roll.single.fix_national_id.negate_pay_roll_link': 'Go to the negation payroll',
  'pay_roll.single.fix_national_id.submit.many': 'Change employees',
  'pay_roll.single.fix_national_id.submit.one': 'Change employee',
  'pay_roll.single.fix_national_id.title': 'Fix CPR numbers',
  'pay_roll.single.invoice.download_pdf': 'Download as PDF',
  'pay_roll.single.invoice.header.description': 'Description',
  'pay_roll.single.invoice.header.total': 'Total',
  'pay_roll.single.invoice.header.unit_count': 'Quantity',
  'pay_roll.single.invoice.header.unit_price': 'Unit price',
  'pay_roll.single.invoice.title': 'Invoice',
  'pay_roll.single.invoice.total': 'Price excl. moms: {total}',
  'pay_roll.single.main.awaiting.less_than_250': 'The payroll is being calculated, and will be ready soon.',
  'pay_roll.single.main.awaiting.lower_note': "You can leave this page while it's being calculated.",
  'pay_roll.single.main.awaiting.more_than_250':
    'The payroll is being calculated, it can take upwards of half an hour.',
  'pay_roll.single.main.buttons.approve': 'Approve',
  'pay_roll.single.main.buttons.delete': 'Delete',
  'pay_roll.single.main.buttons.negate': 'Reverse payslips',
  'pay_roll.single.main.buttons.recreate': 'Recreate',
  'pay_roll.single.main.buttons.reject': 'Cancel',
  'pay_roll.single.main.buttons.resend_skat': 'Resend tax report',
  'pay_roll.single.main.buttons.resend_skat.confirm':
    'Are you sure you wish to resend your tax report for this payroll to SKAT?',
  'pay_roll.single.main.buttons.restart': 'Recalculate',
  'pay_roll.single.main.buttons.revert_approval': 'Reopen',
  'pay_roll.single.main.buttons.review': 'Review',
  'pay_roll.single.main.buttons.switch_to_manual': 'Switch to manual transfer',
  'pay_roll.single.main.buttons.switch_to_nets': 'Switch to Payment Service',
  'pay_roll.single.main.header.approved': 'Approved and will be completed',
  'pay_roll.single.main.header.automatic_approval': 'Approves automatically at {time}, {link}',
  'pay_roll.single.main.header.automatic_approval.link': 'see settings',
  'pay_roll.single.main.header.finished': 'Completed',
  'pay_roll.single.main.header.partial_approved.many':
    'The payroll is partially approved, and needs {count} more for approval',
  'pay_roll.single.main.header.partial_approved.one':
    'The payroll is partially approved, and needs one more for approval',
  'pay_roll.single.main.header.reviewed': 'The payroll have been reviewed',
  'pay_roll.single.main.notification.notifications.hidden.many':
    'You have {count} notifications, click here to see them',
  'pay_roll.single.main.notification.notifications.hidden.one': 'You have {count} notification, click here to see them',
  'pay_roll.single.main.notification.notifications.shown.many': 'You have {count} notifications',
  'pay_roll.single.main.notification.notifications.shown.one': 'You have {count} notification',
  'pay_roll.single.main.title': 'Payroll for {name}',
  'pay_roll.single.manual.intro.line_1': 'That means you will have to make the transfers yourself.',
  'pay_roll.single.manual.intro.line_2': 'Write support if you have more questions about manual payment.',
  'pay_roll.single.manual.submit': 'Switch to manual transfers',
  'pay_roll.single.manual.title': 'Switch to manual transfers',
  'pay_roll.single.manual.warning': 'Note: You are about to change from automatic to manual transfers!',
  'pay_roll.single.negate.intro': 'You can select the payslips to reverse.',
  'pay_roll.single.negate.submit': 'Reverse selected payslips',
  'pay_roll.single.negate.title': 'Reverse payslips',
  'pay_roll.single.nets.intro.line_1':
    "This means the money will first be transferred 2 bank days after the payroll's disposition date. So your employees will not receive their pay by tomorrow.",
  'pay_roll.single.nets.intro.line_2': 'Write support if you have questions about manual payment.',
  'pay_roll.single.nets.submit': 'Switch to Payment Service transfers',
  'pay_roll.single.nets.title': 'Switch to Payment Service transfers',
  'pay_roll.single.nets.warning':
    'Note: You are about to switch from manual to automatic transfers after the disposition date!',
  'pay_roll.single.pay_slips.actions.download_payslip': 'Download payslip',
  'pay_roll.single.pay_slips.actions.edit': 'Edit',
  'pay_roll.single.pay_slips.actions.resend': "Resend the payslip to the employee's email",
  'pay_roll.single.pay_slips.download_all_pdf': 'Download all as PDF',
  'pay_roll.single.pay_slips.download_all_zip': 'Download all as zip file',
  'pay_roll.single.pay_slips.error.cannot_resend': 'Could not resend the payslip',
  'pay_roll.single.pay_slips.header.department': 'Department',
  'pay_roll.single.pay_slips.header.gross_pay': 'Gross pay',
  'pay_roll.single.pay_slips.header.name': 'Name',
  'pay_roll.single.pay_slips.header.net_pay': 'Net pay',
  'pay_roll.single.pay_slips.title': 'Payslips',
  'pay_roll.single.pay_slips.total': 'In total: {total}',
  'pay_roll.single.reject.intro':
    "When the pay is cancelled, it is possible to reopen by contacting support before the payroll's deadline.",
  'pay_roll.single.reject.submit': 'Cancel payroll',
  'pay_roll.single.reject.title': 'Do you wish to cancel the payroll?',
  'pay_roll.single.review.approvers.error.at_least_one': 'At least one receipent must be selected.',
  'pay_roll.single.review.approvers.intro':
    'Select which users – who can approve – to receive notification that the payroll has now been reviewed.',
  'pay_roll.single.review.approvers.sms_note': 'The message will be sent as a text message.',
  'pay_roll.single.review.intro':
    'When the payroll is reviewed, it will be locked and can only be reopened by someone who can approve the payroll.',
  'pay_roll.single.review.submit': 'Review payroll',
  'pay_roll.single.review.title': 'Is the payroll ready for approval?',
  'pay_roll.single.tax_report_modal.boolean.false': 'No',
  'pay_roll.single.tax_report_modal.boolean.true': 'Yes',
  'pay_roll.single.tax_report_modal.disposition_date': 'Disposition date',
  'pay_roll.single.tax_report_modal.employee_number': 'Employee number',
  'pay_roll.single.tax_report_modal.income_details': 'Income details',
  'pay_roll.single.tax_report_modal.income_type': 'Income type',
  'pay_roll.single.tax_report_modal.name': 'Name',
  'pay_roll.single.tax_report_modal.period': 'Period',
  'pay_roll.single.tax_report_modal.period.post_paid': 'post paid',
  'pay_roll.single.tax_report_modal.period.pre_paid': 'pre paid',
  'pay_roll.single.tax_report_modal.production_unit': 'Production unit',
  'pay_roll.single.tax_report_modal.termination_date': 'Termination date',
  'pay_roll.single.tax_report_modal.termination_title': 'Termination',
  'pay_roll.single.tax_report_modal.termination_vacation_days': 'Holidays',
  'pay_roll.single.tax_report_modal.termination_vacation_net': 'Holiday allowance',
  'pay_roll.single.tax_report_modal.termination_year': 'Holiday year',
  'pay_roll.single.tax_report_modal.title': 'Report to SKAT',
  'pay_roll.single.tax_report_receipt.code': 'Code',
  'pay_roll.single.tax_report_receipt.information': 'Information',
  'pay_roll.single.tax_report_receipt.text': 'Text',
  'pay_roll.single.tax_report_receipt.title': 'Receipt from SKAT',
  'pay_roll.single.tax_reports.actions.show_details': 'Show details',
  'pay_roll.single.tax_reports.automatic_via': 'Automatic via: {entity}',
  'pay_roll.single.tax_reports.automatic_via.entity': 'SKAT',
  'pay_roll.single.tax_reports.header.name': 'Name',
  'pay_roll.single.tax_reports.header.national_id': 'CPR number',
  'pay_roll.single.tax_reports.title': 'Tax report',
  'pay_roll.single.time_registrations.actions.edit': 'Edit',
  'pay_roll.single.time_registrations.header.name': 'Name',
  'pay_roll.single.time_registrations.header.work_days': 'Days',
  'pay_roll.single.time_registrations.header.work_hours': 'Hours',
  'pay_roll.single.time_registrations.title': 'Time registration',
  'pay_roll.single.time_registrations.total.many': 'In total: {total} hours',
  'pay_roll.single.time_registrations.total.one': 'In total: {total} hour',
  'pay_roll.single.timeline.status.approved': 'Approved at {date}',
  'pay_roll.single.timeline.status.awaiting_execution': 'Will execute at {date}',
  'pay_roll.single.timeline.status.cancelled': 'Cancelled',
  'pay_roll.single.timeline.status.default': 'Will execute at {date}',
  'pay_roll.single.timeline.status.expected': 'Expected at {date}',
  'pay_roll.single.timeline.status.failed': 'Failed',
  'pay_roll.single.timeline.status.partial_failed': 'Partially failed',
  'pay_roll.single.timeline.status.pending': 'Awaiting approval',
  'pay_roll.single.timeline.status.pending_with_deadline': 'Approval approval (deadline {time})',
  'pay_roll.single.timeline.status.rejected': 'Rejected',
  'pay_roll.single.timeline.status.success': 'Completed',
  'pay_roll.single.timeline.status.success_format': '{status} at {date}',
  'pay_roll.single.timeline.status.success.e_indkomst': 'Sent',
  'pay_roll.single.timeline.status.success.e_indkomst_reply': 'Received',
  'pay_roll.single.timeline.title': 'Timeline',
  'pay_roll.single.toggle.hide': 'Hide',
  'pay_roll.single.toggle.show': 'Show',
  'pay_roll.single.transfers_modal.easy_account': 'Easy Account',
  'pay_roll.single.transfers_modal.header.account_details': 'Account number',
  'pay_roll.single.transfers_modal.header.amount': 'Amount',
  'pay_roll.single.transfers_modal.header.payment_date': 'Payment date',
  'pay_roll.single.transfers_modal.header.recipient': 'Receipent',
  'pay_roll.single.transfers_modal.title': 'Transfers',
  'pay_roll.single.transfers.header.amount': 'Amount',
  'pay_roll.single.transfers.header.description': 'Description',
  'pay_roll.single.transfers.header.method': 'Method',
  'pay_roll.single.transfers.header.payment_date': 'Payment date',
  'pay_roll.single.transfers.header.recipient': 'Receipent',
  'pay_roll.single.transfers.nets_note':
    'Transfers are withdrawn the bank day before the payment day (except for A tax and labour market contribution, which are withdrawn on the payment date).',
  'pay_roll.single.transfers.nets_note.has_non_nets':
    'Transfers via MasterCard Payment Service are withdrawn the bank day before the payment day (except for A tax and labour market contribution, which are withdrawn on the payment date).',
  'pay_roll.single.transfers.see_details': 'See account numbers',
  'pay_roll.single.transfers.title': 'Transfers',
  'pay_roll.single.transfers.total': 'In total: {total}',
  'pay_roll.single.warnings.title': 'Notification',
  'pay_roll.single.warnings.total': 'In total: {total}',
  'pay_roll.single.warnings.warning.employee_empty_tax_card':
    "Salary received an empty tax card for {name}, and will thus deduct 55% tax. The employee should modify their preliminary tax assessment, after which we'll receive a new tax card.",
  'pay_roll.single.warnings.warning.employee_has_assets': '{name} has failed to return assets at termination.',
  'pay_roll.single.warnings.warning.employee_has_leave_differences':
    '{name} has spent holiday from a different contract period with a different value.',
  'pay_roll.single.warnings.warning.employee_invalid_national_id.fix_national_id': 'Fix CPR numbers',
  'pay_roll.single.warnings.warning.employee_invalid_national_id.many':
    'The employees {names} have invalid CPR numbers, use the button to solve the problem.',
  'pay_roll.single.warnings.warning.employee_invalid_national_id.one':
    'Employee {names} has an invalid CPR number, use the button to solve the problem.',
  'pay_roll.single.warnings.warning.employee_missing_tax_card':
    "Salary have not received a tax card for {name}, and will thus deduct 55% tax. Check that the CPR number is correct, or attempt to request a new tax card. If you've just created the employee, you will just need to wait 30 minutes.",
  'pay_roll.single.warnings.warning.employees_sharing_transfer_destination':
    'The following employees have the same reg./account number:',
  'pay_roll.single.warnings.warning.employees_sharing_transfer_destination.unknown':
    'More employees have the same reg./account number',
  'pay_roll.single.warnings.warning.excess_vacation_taken.paid_vacation.many':
    '{name} is being deducted {amount} ({count} days) due too much spent paid holiday or unpaid absence',
  'pay_roll.single.warnings.warning.excess_vacation_taken.paid_vacation.one':
    '{name} is being deducted {amount} ({count} day) due too much spent paid holiday or unpaid absence',
  'pay_roll.single.warnings.warning.excess_vacation_taken.unpaid_vacation.many':
    '{name} is being deducted {amount} ({count} days) due to unpaid holiday',
  'pay_roll.single.warnings.warning.excess_vacation_taken.unpaid_vacation.one':
    '{name} is being deducted {amount} ({count} day) due to unpaid holiday',
  'pay_roll.single.warnings.warning.forced_nets_transfer':
    'You have switched the payroll to use Payment Services after the approval deadline. The transfers will first be transferred on the next bank day after approval of the payroll, and will thus first be received the bank day following that.',
  'pay_roll.single.warnings.warning.insufficient_payslip_vouchers': 'Insufficient vouchers for payslips: {missing}',
  'pay_roll.single.warnings.warning.manual_transfers.line_1':
    'This payroll includes transfers, which needs to be transferred manually via your bank.',
  'pay_roll.single.warnings.warning.manual_transfers.line_2':
    'You can check the complete list of transfers further down under Transfers.',
  'pay_roll.single.warnings.warning.manual_transfers.line_3':
    'The following transfers needs to be transferred manually;',
  'pay_roll.single.warnings.warning.manual_transfers.out_of_format': '{type} ({manual} out of {total} transfers)',
  'pay_roll.single.warnings.warning.manual_transfers.switch_to_nets': 'Switch to Payment Services',
  'pay_roll.single.warnings.warning.manual_transfers.switch_to_nets_line':
    'Some of the manual transfers can be switched to Payment Services.',
  'pay_roll.single.warnings.warning.need_credit_card_integration':
    'Click here to add a payment card for the invoice payment. (Tax payments, remuneration etc. can not be paid via payment cards.)',
  'pay_roll.single.warnings.warning.promissory_note':
    'The following employees have a transferred net reduction, money they owe to a later payroll:',
  'pay_roll.single.warnings.warning.terminated_employees_in_period':
    'The following employees are getting their holiday paid out due to being terminated salaried employees:',
  'pay_roll.single.warnings.warning.terminated_employees_in_period.row.many':
    '{name} will receive for {count} days ({paid_out})',
  'pay_roll.single.warnings.warning.terminated_employees_in_period.row.one':
    '{name} will receive for {count} day ({paid_out})',
  'pay_roll.single.warnings.warning.tin_missing.many':
    '{count} employees are missing their TIN, which could mean that SKAT will reject the tax report; verify the following employees: {names}.',
  'pay_roll.single.warnings.warning.tin_missing.one':
    '{count} employee is missing their TIN, which could mean that SKAT will reject the tax report; verify the following employee: {names}.',
  'pay_roll.single.warnings.warning.tin_no_match.many':
    '{count} employees have an invalid TIN, which could mean that SKAT will reject the tax report; verify the following employees: {names}.',
  'pay_roll.single.warnings.warning.tin_no_match.one':
    '{count} employee has an invalid TIN, which could mean that SKAT will reject the tax report; verify the following employee: {names}.',
  'pay_roll.single.warnings.warning.unapproved_car_allowances':
    'The following employees have transport registrations in the period, which have not been approved: {names}',
  'pay_roll.single.warnings.warning.unapproved_leave_registrations':
    'The following employees have absence registrations in the period, which have not been approved: {names}',
  'pay_roll.single.warnings.warning.unapproved_one_time_pays':
    'The following employees/freelancers have payment registrations in the period, which have not been approved: {names}',
  'pay_roll.single.warnings.warning.unapproved_reimbursements':
    'The following employees have outlays in the period, which have not been approved: {names}',
  'pay_roll.single.warnings.warning.unapproved_time_registrations':
    'The following employees have time registrations in the period, which have not been approved: {names}',
  'pay_roll.single.warnings.warning.unknown_employee': 'an employee',
  'pay_roll.single.warnings.warning.unready_employees_on_payroll':
    'There are employees on the payroll not marked ready: {names}',
  'pay_roll.single.warnings.warning.voucher_missing_account':
    'Salary cannot book the payroll, since the following accounts have not been configured for the accounting integration: {accounts}',
  'pay_roll.status.approved': 'Approved',
  'pay_roll.status.automatic_approval': 'To be approved automatically',
  'pay_roll.status.awaiting': 'Awaiting',
  'pay_roll.status.awaiting_approval': 'Awaiting approval',
  'pay_roll.status.cancelled': 'Cancelled',
  'pay_roll.status.failed': 'Failed',
  'pay_roll.status.partial_approved': 'Partially approved',
  'pay_roll.status.partial_failed': 'Partially failed',
  'pay_roll.status.reviewed': 'Reviewed',
  'pay_roll.status.success': 'Completed',
  'pay_roll.status.will_complete': 'To be completed',
  'pay_roll.task.type.accounting': 'Accounting',
  'pay_roll.task.type.e_indkomst': 'Filing with SKAT',
  'pay_roll.task.type.e_indkomst_reply': 'Response from SKAT',
  'pay_roll.task.type.run_pay_roll': 'Payroll run',
  'pay_roll.task.type.transport': 'Payslip delivery',
  'pay_roll.task.type.zero_tax_report': 'Filing of zero tax report',

  'pay_rolls.active.title': 'Payrolls',
  'pay_rolls.add.alert.success': 'Extra payroll created',
  'pay_rolls.add.form.salary_cycle_id': 'Select payroll cycle',
  'pay_rolls.add.form.salary_period_id': 'Select payroll period',
  'pay_rolls.add.form.submit': 'Start extra payroll',
  'pay_rolls.add.form.type': 'Type',
  'pay_rolls.add.form.type.extra': 'Extra payroll',
  'pay_rolls.add.form.type.zero_tax_report': 'Zero tax report',
  'pay_rolls.add.intro': 'Create a payroll for specific employees for a specific period.',
  'pay_rolls.add.title': 'Create extra payroll',
  'pay_rolls.create_manual_pay_roll': 'Create extra payroll',
  'pay_rolls.dormant.button_text': 'Begin running payrolls automatically again',
  'pay_rolls.dormant.description':
    'This company has not been active in a long time, therefore Salary will not automatically start payrolls.',
  'pay_rolls.header.amount': 'Amount',
  'pay_rolls.header.period': 'Period',
  'pay_rolls.header.state': 'State',
  'pay_rolls.header.type': 'Type',
  'pay_rolls.inactive.title': 'Previous payrolls',
  'pay_rolls.not_approved.description': 'Contact Salary support if your company has not been verified within 24 hours',
  'pay_rolls.not_approved.support_link': 'Contact support',
  'pay_rolls.not_approved.title': 'When your company has been verified, the next payroll will appear here',
  'pay_rolls.table.deadline': 'at the latest',
  'pay_rolls.table.run_at': 'at',
  'pay_rolls.type.manual': 'Extra payroll',
  'pay_rolls.type.negation': 'Reversal of payroll',
  'pay_rolls.type.terminated_employments': 'Termination of salaried employees',
  'pay_rolls.type.zero_tax_report': 'Zero tax report',

  'pay_slip_fields.alert.success': 'Changes to {name} have been saved',
  'pay_slip_fields.link.form.pay_slip_questions_link': 'Link on payslip',
  'pay_slip_fields.link.form.pay_slip_questions_link.required': 'Link on payslip is required',
  'pay_slip_fields.link.form.pay_slip_questions_visible': 'Enable help link on the payslip',
  'pay_slip_fields.link.form.submit': 'Save changes to link on payslip',
  'pay_slip_fields.link.title': 'Link on payslip',
  'pay_slip_fields.logo.form.button.create': 'Add logo',
  'pay_slip_fields.logo.form.button.delete': 'Remove logo',
  'pay_slip_fields.logo.form.button.edit': 'Edit logo',
  'pay_slip_fields.logo.form.note':
    "Edit the company logo on your employees' payslips. The image can at most be 60 KB.",
  'pay_slip_fields.logo.title.create': 'Add company logo',
  'pay_slip_fields.logo.title.edit': 'Edit company logo',

  'pay_slips_form.pay_slip_transport_e_boks': 'Send to e-Boks',
  'pay_slips_form.pay_slip_transport_e_boks.lock': 'Upgrade to Basic to send to e-Boks',
  'pay_slips_form.pay_slip_transport_e_boks.price_note': 'Surcharge of {price} per payslip',
  'pay_slips_form.pay_slip_transport_email': 'Send to email',
  'pay_slips_form.pay_slip_transport_email.lock': 'Enter an email address to enable sending to email',
  'pay_slips_form.pay_slip_transport_mit_dk': 'Send to mit.dk',
  'pay_slips_form.pay_slip_transport_mit_dk.lock': 'Upgrade to Basic to send send to mit.dk',
  'pay_slips_form.pay_slip_transport_sms': 'Send as SMS',
  'pay_slips_form.pay_slip_transport_sms.feature_lock': 'Upgrade to Basic to send as text message',
  'pay_slips_form.pay_slip_transport_sms.lock': 'Enter a phone number to enable as text message',

  'pay_slips_tab.header.download_all': 'Download all completed payslips',
  'pay_slips_tab.pdf_password.allow_password_on_payslip': 'Allow custom passwords on payslips',
  'pay_slips_tab.pdf_password.missing': 'Password must be set',
  'pay_slips_tab.pdf_password.password': 'Password',
  'pay_slips_tab.pdf_password.password_header': 'Provide a custom pdf password',
  'pay_slips_tab.pdf_password.password_on_payslip': 'Password on payslip',
  'pay_slips_tab.pdf_password.save': 'Save password',
  'pay_slips_tab.pdf_password.set_password': 'Enter password',
  'pay_slips_tab.resend.error': 'Could not resend payslip',
  'pay_slips_tab.resend.success': "The payslip have been resent to the employee's email",
  'pay_slips_tab.table.actions.download': 'Download',
  'pay_slips_tab.table.actions.payroll': 'Go to payroll',
  'pay_slips_tab.table.actions.resend': "Resend payslip to the employee's email",
  'pay_slips_tab.table.header.disposition_date': 'Disposition date',
  'pay_slips_tab.table.header.gross_pay': 'Gross pay',
  'pay_slips_tab.table.header.period': 'Period',
  'pay_slips_tab.table.header.settled': 'Completed',
  'pay_slips_tab.table.settled.true': 'Yes',
  'pay_slips_tab.title': 'Payslips',

  'payment_method.manual': 'Manual',
  'payment_method.nets': 'Payment Service',
  'payment_method.stripe': 'Payment card',
  'payment_method.zimpler': 'Zimpler',

  'payroll_approve.login.login_again': 'Log in again',
  'payroll_approve.login.login_with_sms_code': 'Log in via text message code',
  'payroll_approve.login.submit': 'Log in',
  'payroll_approve.page.card.approve_deadline': 'Approve before {time}',
  'payroll_approve.page.card.approve.approval_code': 'Approval code',
  'payroll_approve.page.card.approve.approve': 'Approve',
  'payroll_approve.page.card.approve.reject': 'Reject',
  'payroll_approve.page.card.download_payslips': 'Download all payslips as PDF',
  'payroll_approve.page.card.log_out': 'Log out',
  'payroll_approve.page.card.pay_roll_for': 'Payroll for',
  'payroll_approve.page.card.pay_roll_status': 'Payroll status: {status}',
  'payroll_approve.page.error.unknown_company': 'Unknown company',
  'payroll_approve.page.error.unknown_pay_roll': 'Unknown payroll',
  'payroll_approve.pay_slips.table.header.gross_pay': 'Gross pay',
  'payroll_approve.pay_slips.table.header.name': 'Name',
  'payroll_approve.pay_slips.table.header.net_pay': 'Net pay',
  'payroll_approve.reopen.intro': 'You are about to reject the payroll. You can describe why below.',
  'payroll_approve.reopen.message': 'Message',
  'payroll_approve.reopen.reopen': 'Reject',

  'pension_definition.scheme.atp': 'ATP',
  'pension_definition.title.no_pension_company': '{scheme} paid pension (transfer)',
  'pension_definition.title.no_pension_company.net': '53A - {scheme} paid pension (transfer)',
  'pension_definition.title.pension_company': '{scheme} paid pension ({name})',
  'pension_definition.title.pension_company.net': '53A - {scheme} paid pension ({name})',

  'pension.card.edit.form.account': 'Accountno.',
  'pension.card.edit.form.account.invalid': 'Accountno. is invalid',
  'pension.card.edit.form.account.required': 'Accountno. is required',
  'pension.card.edit.form.add_row': 'Add pension row',
  'pension.card.edit.form.company': 'Company',
  'pension.card.edit.form.company.no_company': 'No company',
  'pension.card.edit.form.company.required': 'Pension company is required',
  'pension.card.edit.form.customer_id.required': '{name} is required',
  'pension.card.edit.form.has_net_pension': 'Net pension',
  'pension.card.edit.form.has_pension.false': 'Employee will not receive pension',
  'pension.card.edit.form.has_pension.true': 'Employee will receive pension',
  'pension.card.edit.form.include_vacation_in_pension_basis': 'Include holiday in the pension basis',
  'pension.card.edit.form.scheme': 'Paid by',
  'pension.card.edit.form.scheme.required': 'Paid by is required',
  'pension.card.edit.form.sort_code': 'Reg.no.',
  'pension.card.edit.form.sort_code.invalid': 'Reg.no. is invalid',
  'pension.card.edit.form.sort_code.required': 'Reg.no. is required',
  'pension.card.edit.form.table.company': 'Company',
  'pension.card.edit.form.table.customer_id': 'Number',
  'pension.card.edit.form.table.rate': 'Rate',
  'pension.card.edit.form.table.scheme': 'Paid by',
  'pension.card.edit.form.table.union_agreement_number': 'Union agreement number',
  'pension.card.edit.form.union_agreement_number': 'Number',
  'pension.card.edit.form.union_agreement_number.not_five_characters': 'Union agreement number must be 5 characters',
  'pension.card.edit.form.union_agreement_number.tooltip.line_1': 'Union agreement number is not required.',
  'pension.card.edit.form.union_agreement_number.tooltip.line_2':
    "The field must be 5 characters, you can pad the number with prefixed 0's.",
  'pension.card.edit.form.valid_from.at_date': 'Changes take effect at {date}.',
  'pension.card.edit.form.valid_from.now': 'Changes take effect immediately.',
  'pension.card.edit.form.value.fixed': 'Amount',
  'pension.card.edit.form.value.fixed.short': 'kr.',
  'pension.card.edit.form.value.invalid': '{suffix} is invalid',
  'pension.card.edit.form.value.percentage': 'Procent',
  'pension.card.edit.form.value.percentage.short': '%',
  'pension.card.edit.form.value.required': '{suffix} is required',
  'pension.card.edit.title': 'Edit pension',
  'pension.card.table.header.company': 'Company',
  'pension.card.table.header.number': 'Number',
  'pension.card.table.header.rate': 'Rate',
  'pension.card.table.header.scheme': 'Paid by',
  'pension.card.table.net': '(Net)',
  'pension.card.table.suffix.fixed': 'kr.',
  'pension.card.table.suffix.percentage': '%',
  'pension.card.table.transfer': 'Transfer',
  'pension.card.title': 'Pension',
  'pension.customer_type.account_id': 'Account number',
  'pension.customer_type.agreement_id': 'Agreement number',
  'pension.customer_type.employer_id': 'Company number',
  'pension.customer_type.member_id': 'Member number',
  'pension.customer_type.national_id': 'CPR number',
  'pension.customer_type.number': 'Number',
  'pension.scheme.employee': 'Employee',
  'pension.scheme.employer': 'Employer',

  'person_verification.error.unable_to_find_url': 'Unexpected error, contact Salary.',
  'person_verification.identity_already_verified': 'Your identity has already already verified',
  'person_verification.sign_with_mitid': 'Confirm with MitID',
  'person_verification.signed': 'Thank you for confirming your identity.',
  'person_verification.title': 'Confirm your identity',

  'preview.close': 'Close document',
  'preview.download_pdf': 'Download PDF',

  'pricing_campaign.form_description': 'If you have a campaign code, you can use it here.',
  'pricing_campaign.form_title': 'Use campaign code',
  'pricing_campaign.form.code': 'Campaign code',
  'pricing_campaign.form.submit': 'Use',
  'pricing_campaign.form.validation.code_required': 'Campaign code is required',

  'pricing_package.action.downgrade.button': 'Downgrade',
  'pricing_package.action.downgrade.contact': 'Write support',
  'pricing_package.action.upgrade.button': 'Upgrade',
  'pricing_package.alert.success': 'Changes to {name} have been saved',
  'pricing_package.package.automatic.active.subtitle':
    'If you wish to downgrade to the free package, you will have to write support. Be aware that all included integrations and functions will be removed.',
  'pricing_package.package.automatic.active.title':
    'You are Basic customer, which automates payroll, reducing errors and manual labour',
  'pricing_package.package.automatic.feature_1.line_1': "Option to send payslips to your employee's mit.dk & e-Boks.",
  'pricing_package.package.automatic.feature_1.title': 'mit.dk & e-Boks',
  'pricing_package.package.automatic.feature_2.line_1':
    'Invite your employees to our employee app, so they can register hours, journeys and absence, as well as seeing their payslips.',
  'pricing_package.package.automatic.feature_2.title': 'Employee app',
  'pricing_package.package.automatic.feature_3.line_1':
    'Avoid missing a payroll. With automatic payroll, your payrolls will complete automatically at the end of each period.',
  'pricing_package.package.automatic.feature_3.title': 'Automatic payroll',
  'pricing_package.package.automatic.feature_4.line_1':
    'Let Salary book all your pay transactions for you automatically.',
  'pricing_package.package.automatic.feature_4.title': 'Accounting integration',
  'pricing_package.package.automatic.read_more_url': 'https://salary.dk/priser/',
  'pricing_package.package.automatic.subtitle':
    'For you who wish to automate payroll, reducing errors and manual labour',
  'pricing_package.package.automatic.title': 'Upgrade to Basic for only {price}',
  'pricing_package.package.automatic.title_price': '{price}/payslip',
  'pricing_package.package.premium.active.subtitle':
    'You can downgrade to our Basic price plan at any point, but please note that your Premium functions will be removed by doing so.',
  'pricing_package.package.premium.active.title':
    'You have our Premium price plan, which unlocks all the features and benefits - full digitalisation and streamlining',
  'pricing_package.package.premium.feature_1.line_1':
    'Contact our phone support during our opening hours 9—15, and get help for Salary.',
  'pricing_package.package.premium.feature_1.title': 'Phone support',
  'pricing_package.package.premium.feature_2.line_1':
    "Administrate distribution and receiving of all your company's assets, regardless of whether it's mobile phones, tools or something completely different.  We provide your a full overview.",
  'pricing_package.package.premium.feature_2.title': 'Asset management',
  'pricing_package.package.premium.feature_3.line_1':
    'Create company-wide and unique templates for your employees, so that their working hours automatically get registered correctly, for the benefit of your employees and the company.',
  'pricing_package.package.premium.feature_3.title': 'Working hours templates',
  'pricing_package.package.premium.feature_4.line_1':
    "Your employees' outlays are easily registered via the employee app in Salary, and are automatically added to the payslip, including booking when the outlay is approved and paid.",
  'pricing_package.package.premium.feature_4.title': 'Expense management',
  'pricing_package.package.premium.read_more_url': 'https://salary.dk/priser/',
  'pricing_package.package.premium.subtitle': 'For you who wish to have all the functions and benefits',
  'pricing_package.package.premium.title': 'Upgrade to Premium for {price}',
  'pricing_package.package.premium.title_price': '{price}/payslip',
  'pricing_package.package.reimbursement_voucher.intro.line_1':
    "Your employees' outlays are easily registered via the employee app in Salary, and are automatically added to the payslip, including booking when the outlay is approved and paid.",
  'pricing_package.package.reimbursement_voucher.intro.read_more': 'Read more',
  'pricing_package.package.reimbursement_voucher.intro.read_more_url':
    'https://blog.salary.dk/digitaliser-jeres-udlaeg-og-slip-for-kroellede-kvitteringer/',
  'pricing_package.package.reimbursement_voucher.pricing.per_1': 'month',
  'pricing_package.package.reimbursement_voucher.pricing.per_2': 'company',
  'pricing_package.package.reimbursement_voucher.title': 'Expense management',
  'pricing_package.package.reimbursement_voucher.title_note': '(requires Basic)',
  'pricing_package.package.swipe.intro.line_1':
    'With Swipe your employees can recieve their earned pay whenever they want. It just requires a single swipe on their mobile phone - and the pay is on its way.',
  'pricing_package.package.swipe.intro.line_2': 'You set the rules, and you get happier employees.',
  'pricing_package.package.swipe.link_text': 'Enabled under company - transfers',
  'pricing_package.package.swipe.pricing.per_1': 'employee',
  'pricing_package.package.swipe.pricing.per_2': 'payroll period',
  'pricing_package.package.swipe.title': 'Swipe',
  'pricing_package.package.swipe.title_note': '(requires Basic and Payment Service)',
  'pricing_package.package.work_hours_automatic.intro.line_1':
    'Instead of manual entry for working hours registration, you can have them created automatically with templates.  You only pay for the employees who use templates.',
  'pricing_package.package.work_hours_automatic.intro.read_more': 'Read more',
  'pricing_package.package.work_hours_automatic.intro.read_more_url':
    'https://blog.salary.dk/digitaliser-jeres-udlaeg-og-slip-for-kroellede-kvitteringer/',
  'pricing_package.package.work_hours_automatic.pricing.per_1': 'employee',
  'pricing_package.package.work_hours_automatic.pricing.per_2': 'month',
  'pricing_package.package.work_hours_automatic.title': 'Working hours template',
  'pricing_package.package.work_hours_automatic.title_note': '(requires Basic)',
  'pricing_package.pricing.per': 'per {unit}',

  'production_units.alert.success': 'Changes to {name} have been saved',
  'production_units.form.active': 'Active',
  'production_units.form.add_row': 'Add production unit',
  'production_units.form.name': 'Description',
  'production_units.form.name.required': 'Description is required',
  'production_units.form.number': 'Number',
  'production_units.form.number.invalid': 'Number is invalid',
  'production_units.form.number.required': 'Number is required',
  'production_units.title': 'Production units',

  'projects.edit.form.description': 'Description',
  'projects.edit.form.employees.none': 'No employees selected...',
  'projects.edit.form.employees.select': 'Select employees',
  'projects.edit.form.external_id': 'External reference',
  'projects.edit.form.hourly_rate': 'Hourly rate',
  'projects.edit.form.hourly_rate.invalid': 'Hourly rate is invalid',
  'projects.edit.form.hourly_rate.suffix': 'kr.',
  'projects.edit.form.name': 'Project name',
  'projects.edit.form.name.required': 'Project name is required',
  'projects.edit.form.period_note':
    'Per default, a project is always valid, but you can enable start and end dates, which time limits the project.',
  'projects.edit.form.valid_from': 'Start date',
  'projects.edit.form.valid_from_enabled': 'Enable start date',
  'projects.edit.form.valid_to': 'End date',
  'projects.edit.form.valid_to_enabled': 'Enable end date',
  'projects.edit.title.child.create': 'Create a child project to {name}',
  'projects.edit.title.child.edit': 'Edit child project to {name}',
  'projects.edit.title.standard.create': 'Create project',
  'projects.edit.title.standard.edit': 'Edit project',
  'projects.employee_select.submit': 'Confirm selection of employees',
  'projects.employee_select.title': 'Select employees for the project',
  'projects.header.new_project': 'Create a new main project',
  'projects.header.new_project.lock': 'Upgrade to Premium to manage projects',
  'projects.header.title': 'Projects',
  'projects.table.actions.add_child': 'Add child project',
  'projects.table.actions.delete': 'Delete project',
  'projects.table.actions.edit': 'Edit project',
  'projects.table.empty': 'No projects yet...',
  'projects.table.external_id': 'External ID',
  'projects.table.header.employees': 'Employees',
  'projects.table.header.project': 'Project',
  'projects.table.hourly_rate': 'Hourly rate',

  'register.form.already_user': 'Already have a user? {link}.',
  'register.form.already_user.link': 'Log in here',
  'register.form.email': 'Email',
  'register.form.email.invalid': 'Email is invalid',
  'register.form.email.required': 'Email is required',
  'register.form.name': 'Name',
  'register.form.name.required': 'Name is required',
  'register.form.password': 'Password',
  'register.form.password.at_least_8_characters': 'Password must be at least 8 characters',
  'register.form.password.required': 'Password is required',
  'register.form.submit': 'Sign up for free',
  'register.form.terms': 'I accept {terms}',
  'register.form.terms.required': 'Accepting terms is required',
  'register.form.terms.terms': 'the terms',
  'register.presentation.points.0': 'Automatic reporting and payments for taxes, ATP, holiday account, pension',
  'register.presentation.points.1': 'Automatic booking in leading accounting programs',
  'register.presentation.points.2': 'Thorough intuitive and user friendly experience',
  'register.presentation.points.3': 'Employee app for absence, time and transport registrations',
  'register.presentation.points.4': 'Free zero tax reporting, fully automated',
  'register.presentation.points.5': 'Full absence overview',
  'register.presentation.points.6': 'Lightning quick support',
  'register.presentation.points.7': 'No commitment',
  'register.presentation.title': 'With Salary you get...',
  'register.title': "Denmark's easiest payroll system",

  'registration_form.cost_center.cost_center_id.cost_center': 'Cost centre',
  'registration_form.cost_center.cost_center_id.department': 'Department',

  'registration_method_app_alert.button.car_allowance': 'Switch to detailed',
  'registration_method_app_alert.button.salary_registration': 'Switch to detailed',
  'registration_method_app_alert.button.time_registration': 'Switch to detailed',
  'registration_method_app_alert.message.car_allowance':
    'The employee has coarse transport registration, and is therefore unable to register transport allowances themselves in the app.',
  'registration_method_app_alert.message.salary_registration':
    'The employee has coarse supplement registration, and is therefore unable to register supplements themselves in the app.',
  'registration_method_app_alert.message.time_registration':
    'The employee has coarse time registration, and is therefore unable to register hours themselves in the app.',

  'registrations.general.delete_hidden_registrations': 'Click here to delete them',
  'registrations.general.hidden_coarse_registrations':
    'There are {amount} hidden {descriptor} for a total value of {value}. You cannot edit them, since the employee uses the detailed registration method.',
  'registrations.general.hidden_detailed_registrations':
    'There are {amount} hidden {descriptor} for a total value of {value}. You cannot edit them, since the employee uses the coarse registration method.',
  'registrations.general.switch_to_coarse': 'Switch to coarse',
  'registrations.general.switch_to_detailed': 'Switch to detailed',
  'registrations.layout.awaiting_approval': 'Awaiting approval',
  'registrations.layout.car_allowances': 'Transport allowance',
  'registrations.layout.freelancers': 'Compensation creation',
  'registrations.layout.leave_registrations': 'Absence',
  'registrations.layout.one_time_pays': 'Supplements/Reductions',
  'registrations.layout.reimbursement_vouchers': 'Outlays',
  'registrations.layout.salary_registrations': 'Variable supplements',
  'registrations.layout.swipes': 'Swipe™',
  'registrations.layout.time_registrations': 'Time registrations',
  'registrations.layout.work_hours': 'Working hours',

  'reimbursement_settings.alert.success': 'Changes for expense management settings have been saved',
  'reimbursement_settings.company.form.auto_approval_limit': 'General auto approval limit per outlay',
  'reimbursement_settings.company.form.auto_approval_limit.invalid':
    'General auto approval limit per outlay is invalid',
  'reimbursement_settings.company.form.auto_approval_limit.note.immediate_pay_no_limit':
    'If 0 kr. or blank, then all outlays will be auto approved (except express payments).',
  'reimbursement_settings.company.form.auto_approval_limit.note.immediate_pay_with_limit':
    'If 0 kr. or blank, then all outlays will be auto approved (except express payments that exceeds the express payment limit of {amount}).',
  'reimbursement_settings.company.form.auto_approval_limit.note.no_immediate_pay':
    'If 0 kr. or blank, then all outlays will be auto approved.',
  'reimbursement_settings.company.form.auto_approval_limit.suffix': 'kr.',
  'reimbursement_settings.company.form.auto_approve': 'Auto approval for outlay',
  'reimbursement_settings.employee.form.auto_approval_limit': 'Auto approval limit for outlay',
  'reimbursement_settings.employee.form.auto_approval_limit.company_limit': "Company's general limit ({amount})",
  'reimbursement_settings.employee.form.auto_approval_limit.no_limit': 'No limit',
  'reimbursement_settings.employee.form.auto_approval_limit.remove': 'Remove',
  'reimbursement_settings.employee.form.auto_approval_limit.required': 'Auto approval limit per outlay is required',
  'reimbursement_settings.employee.form.auto_approval_limit.save': 'Save',
  'reimbursement_settings.employee.form.auto_approval_limit.suffix': 'kr.',
  'reimbursement_settings.employee.header.amount': 'Auto approval limit per outlay',
  'reimbursement_settings.employee.header.employee': 'Employee',
  'reimbursement_settings.employee.note.line_1.immediate_pay_no_limit':
    'You can set auto approval limit per outlay per employee, which overrides the general limit for the company, also if the limit on an employee is higher than the one for the company (express payments always needs approval).',
  'reimbursement_settings.employee.note.line_1.immediate_pay_with_limit':
    'You can set auto approval limit per outlay per employee, which overrides the general limit for the company, also if the limit on an employee is higher than the one for the company (the auto approval limit of {amount} for express payments still counts).',
  'reimbursement_settings.employee.note.line_1.no_immediate_pay':
    'You can set auto approval limit per outlay per employee, which overrides the general limit for the company, also if the limit on an employee is higher than the one for the company.',
  'reimbursement_settings.employee.note.line_2':
    'If you set the limit to 0 kr., then all outlays for this employee has to be approved manually. To remove the individual limit, use the remove button.',
  'reimbursement_settings.employee.note.line_3': 'Click on the amounts to change them.',
  'reimbursement_settings.employee.title': 'Auto approval limits per employee',
  'reimbursement_settings.header.back': 'Back',
  'reimbursement_settings.header.immediate_pay': 'Express payment settings',
  'reimbursement_settings.title': 'Express management settings',

  'reimbursement_vouchers.edit.form.amount': 'Amount',
  'reimbursement_vouchers.edit.form.amount.invalid': 'Amount is invalid',
  'reimbursement_vouchers.edit.form.approved': 'Approved',
  'reimbursement_vouchers.edit.form.approved.false': 'No',
  'reimbursement_vouchers.edit.form.approved.true': 'Yes',
  'reimbursement_vouchers.edit.form.card_note': 'Description of company card for accounting',
  'reimbursement_vouchers.edit.form.card_note.placeholder': 'Description',
  'reimbursement_vouchers.edit.form.cost_center_id.cost_center': 'Cost centre',
  'reimbursement_vouchers.edit.form.cost_center_id.cost_center.none': 'No cost centre',
  'reimbursement_vouchers.edit.form.cost_center_id.department': 'Department',
  'reimbursement_vouchers.edit.form.cost_center_id.department.none': 'Own department',
  'reimbursement_vouchers.edit.form.currency': 'Currency code',
  'reimbursement_vouchers.edit.form.currency.invalid': 'Currency code is invalid',
  'reimbursement_vouchers.edit.form.currency.required': 'Currency code is required',
  'reimbursement_vouchers.edit.form.date': 'Date',
  'reimbursement_vouchers.edit.form.date.required': 'Date is required',
  'reimbursement_vouchers.edit.form.disburse_amount': 'Amount in Danish kroner',
  'reimbursement_vouchers.edit.form.disburse_amount.required': 'Amount in Danish kroner is required',
  'reimbursement_vouchers.edit.form.disburse_amount.suffix': 'kr.',
  'reimbursement_vouchers.edit.form.disburseable': 'To be disbursed?',
  'reimbursement_vouchers.edit.form.disburseable.false': 'Company card (only accounting)',
  'reimbursement_vouchers.edit.form.disburseable.true': 'Own outlay (to be disbursed)',
  'reimbursement_vouchers.edit.form.expense_category_id': 'Expense category',
  'reimbursement_vouchers.edit.form.expense_category_id.required': 'Expense category is required',
  'reimbursement_vouchers.edit.form.foreign_amount': 'Foreign amount',
  'reimbursement_vouchers.edit.form.immediate_pay': 'Express payment',
  'reimbursement_vouchers.edit.form.is_foreign_currency': 'Foreign currency',
  'reimbursement_vouchers.edit.form.is_foreign_currency.false': 'No',
  'reimbursement_vouchers.edit.form.is_foreign_currency.true': 'Yes',
  'reimbursement_vouchers.edit.form.note': 'Note',
  'reimbursement_vouchers.edit.form.ready': 'Ready for approval',
  'reimbursement_vouchers.edit.form.ready.false': 'Draft',
  'reimbursement_vouchers.edit.form.ready.true': 'Ready',
  'reimbursement_vouchers.edit.form.voucher.download': 'Download receipt',
  'reimbursement_vouchers.edit.form.voucher.title': 'Receipt',
  'reimbursement_vouchers.edit.form.warning.older_than_12_months': 'Note: The receipt date is more than 12 months old.',
  'reimbursement_vouchers.edit.title.create': 'Create outlay',
  'reimbursement_vouchers.edit.title.edit': 'Edit outlay',
  'reimbursement_vouchers.header.approve_all': 'Approve all',
  'reimbursement_vouchers.header.disable_feature': 'Enable expense management',
  'reimbursement_vouchers.header.enable_feature': 'Disable expense management',
  'reimbursement_vouchers.header.enable_feature.lock':
    'Upgrade to Premium or enable the module for the Basic package to use outlays',
  'reimbursement_vouchers.header.expense_categories': 'Expense categories',
  'reimbursement_vouchers.header.history.back': 'Back',
  'reimbursement_vouchers.header.history.show': 'Show history',
  'reimbursement_vouchers.header.new_voucher': 'Create new outlay',
  'reimbursement_vouchers.header.report': 'Report',
  'reimbursement_vouchers.header.settings': 'Settings',
  'reimbursement_vouchers.new.form.employee.disabled': '{name} (cannot register outlays themselves)',
  'reimbursement_vouchers.new.form.employee.placeholder': 'Employee',
  'reimbursement_vouchers.new.form.employee.terminated': '{name} (terminated)',
  'reimbursement_vouchers.new.form.upload': 'Upload outlay',
  'reimbursement_vouchers.new.intro':
    'Before you create a new outlay, you must select the employee for whom the outlay shall be attached.',
  'reimbursement_vouchers.new.title': 'Create outlay',
  'reimbursement_vouchers.report_modal.report.download': 'Download accounting report',
  'reimbursement_vouchers.report_modal.title': 'Outlay reports',
  'reimbursement_vouchers.report_modal.zip.download': 'Download',
  'reimbursement_vouchers.report_modal.zip.from': 'From date',
  'reimbursement_vouchers.report_modal.zip.intro':
    'The zip file includes all the outlay files as well as their accounting report in a CSV file next to them.',
  'reimbursement_vouchers.report_modal.zip.title': 'Zip file',
  'reimbursement_vouchers.report_modal.zip.to': 'To date',
  'reimbursement_vouchers.state.approved': 'Approved',
  'reimbursement_vouchers.state.booking.booked': 'Booked',
  'reimbursement_vouchers.state.booking.daybooked': 'Transferred to daybook',
  'reimbursement_vouchers.state.booking.failed': 'Booking failed',
  'reimbursement_vouchers.state.booking.ready': 'Awaiting booking',
  'reimbursement_vouchers.state.default': 'Awaiting',
  'reimbursement_vouchers.state.ready': 'Awaiting approval',
  'reimbursement_vouchers.table.actions.approve': 'Approve',
  'reimbursement_vouchers.table.actions.book_again': 'Book again',
  'reimbursement_vouchers.table.actions.delete': 'Delete',
  'reimbursement_vouchers.table.actions.edit': 'Edit',
  'reimbursement_vouchers.table.actions.remove_approval': 'Remove approval',
  'reimbursement_vouchers.table.actions.send_back': 'Send back',
  'reimbursement_vouchers.table.amount.cost_center_note': 'Booked in',
  'reimbursement_vouchers.table.amount.disburseable': 'for disbursing',
  'reimbursement_vouchers.table.amount.immediate_pay': 'For express pay',
  'reimbursement_vouchers.table.amount.not_disburseable': 'only accounting',
  'reimbursement_vouchers.table.book.approve': 'Approve now',
  'reimbursement_vouchers.table.book.book_again': 'Book again',
  'reimbursement_vouchers.table.category.missing': 'Missing category',
  'reimbursement_vouchers.table.date.received': 'Received',
  'reimbursement_vouchers.table.header.amount': 'Amount',
  'reimbursement_vouchers.table.header.date': 'Receipt date',
  'reimbursement_vouchers.table.header.employee': 'Employee',
  'reimbursement_vouchers.table.header.expense_category': 'Expense category',
  'reimbursement_vouchers.table.header.state': 'State',
  'reimbursement_vouchers.title.active': 'Outlays',
  'reimbursement_vouchers.title.history': 'Archived outlays',

  'reimbursements.edit.form.amount': 'outlay',
  'reimbursements.edit.form.disposition_date': 'When shall the outlay be paid?',
  'reimbursements.edit.form.disposition_date.placeholder': 'Payment date',
  'reimbursements.edit.intro': 'Please note that you need to be able to document the expense to SKAT.',
  'reimbursements.edit.title.create': 'Create outlay',
  'reimbursements.edit.title.edit': 'Edit outlay',
  'reimbursements.edit.title.view': 'View outlay',
  'reimbursements.header.approve_all': 'Approve all',
  'reimbursements.header.hide_history': 'Back',
  'reimbursements.header.new_reimbursement': 'Create outlay',
  'reimbursements.header.new_reimbursement_voucher': 'Upload outlay',
  'reimbursements.header.new_reimbursement_voucher.lock':
    'Upgrade to Premium or enable the module for the Basic package to use outlays with attached files',
  'reimbursements.header.new_reimbursement_voucher.lock_disabled':
    'Enable outlays under registrations > outlays to enable outlays with attached files',
  'reimbursements.header.reimbursement_vouchers_note': 'For outlays with attached files, see {link}.',
  'reimbursements.header.reimbursement_vouchers_note.link': 'expense management',
  'reimbursements.header.show_history': 'Show archived',
  'reimbursements.table.actions.delete': 'Delete outlay',
  'reimbursements.table.actions.edit': 'Edit outlay',
  'reimbursements.table.actions.view': 'View outlay',
  'reimbursements.table.amount_format': '{units} of {rate}',
  'reimbursements.table.approved.approve': 'Approve',
  'reimbursements.table.approved.false': 'No',
  'reimbursements.table.approved.remove': 'Remove approval',
  'reimbursements.table.approved.true': 'Yes',
  'reimbursements.table.header.amount': 'Amount',
  'reimbursements.table.header.approved': 'Approved',
  'reimbursements.table.header.date': 'Date',
  'reimbursements.table.header.title': 'Description',
  'reimbursements.table.saving': 'Saving...',
  'reimbursements.title.history': 'Outlay archive',
  'reimbursements.title.standard': 'Outlay',

  'report_button.done': ' (ready)',
  'report_button.download': 'Download {type}',
  'report_button.waiting': 'Report is being built...',

  'report_dst.automatic.form.da_union_code': 'Dansk Arbejdsgiver union code',
  'report_dst.automatic.form.da_union_code.invalid': 'Dansk Arbejdsgiver union code is invalid',
  'report_dst.automatic.form.enable_absence_statistics': 'Absence statistics',
  'report_dst.automatic.form.enable_quarterly_salary_statistics': 'Quarterly statistics',
  'report_dst.automatic.form.enable_yearly_salary_statistics': 'Annual statistics',
  'report_dst.automatic.note.line_1':
    'You can define how often the report is to be sent, including the code for Dansk Arbejdsgiver union, and whether an absence report shall also be sent. If both annual and quarterly reports are disabled, no automatic report will be made.',
  'report_dst.automatic.note.line_2':
    'Only employees with DISCO-08 will be included in the report, you can add DISCO-08 codes under the employment of the employee.',
  'report_dst.automatic.title': 'Automatic report to Danmarks Statistik',
  'report_dst.manual.form.period': 'Period',
  'report_dst.manual.form.period.q1': 'First quarter',
  'report_dst.manual.form.period.q2': 'Second quarter',
  'report_dst.manual.form.period.q3': 'Third quarter',
  'report_dst.manual.form.period.q4': 'Fourth quarter',
  'report_dst.manual.form.period.yearly': 'Whole year',
  'report_dst.manual.form.year': 'Year',
  'report_dst.manual.title': 'Report to Danmarks Statistik',

  'report_name.assets': 'Assets',
  'report_name.car_allowance': 'Transport allowance',
  'report_name.employees': 'Employees',
  'report_name.employments': 'Employments',
  'report_name.flex_registrations': 'Flex registrations',
  'report_name.leave_balances': 'Absence balances',
  'report_name.leave_differences': 'Holiday differences',
  'report_name.leave_registrations': 'Absence registrations',
  'report_name.one_time_pays': 'Pay registrations',
  'report_name.overtime_registrations': 'Overtime registrations',
  'report_name.pay_roll_accounting': 'Accounting report',
  'report_name.pay_roll_employees': 'Payroll report',
  'report_name.pay_roll_transfers': 'Payroll transfers',
  'report_name.period_employees': 'Payroll report, employee level',
  'report_name.period_employees_salary_types': 'Payroll report, employee level, pay types',
  'report_name.period_employees_vacation_transition': 'Holiday report for the transition year',
  'report_name.period_income_tax_report': 'E-income report',
  'report_name.period_payslip_count_report': 'Payslip count for company group',
  'report_name.project_registrations': 'Project registrations',
  'report_name.reimbursement_vouchers_accounting': 'Outlay booking',
  'report_name.reimbursements': 'Outlays',
  'report_name.salary_accounts_monthly_filtered_1': 'Payroll report, company level',
  'report_name.salary_receipts': 'Payroll receipt',
  'report_name.salary_registrations': 'Pay supplement registrations',
  'report_name.start_balances': 'Start balances',
  'report_name.time_registrations': 'Time registrations',
  'report_name.work_hours_registrations': 'Working time',

  'report_tab.form.download_excel': 'Download Excel',
  'report_tab.form.download_pdf': 'Download PDF',
  'report_tab.form.from': 'From',
  'report_tab.form.from.required': 'From date is required',
  'report_tab.form.pay_roll_id': 'Payroll',
  'report_tab.form.report': 'Select report',
  'report_tab.form.to': 'To',
  'report_tab.form.to.required': 'To date is required',
  'report_tab.header.dst_automatic': 'Automatic report for Danmarks Statistik',
  'report_tab.header.dst_manual': 'Report to Danmark Statistik',
  'report_tab.header.tax_cards': 'Active tax cards',
  'report_tab.title': 'Reports',

  'request_deletion.alert.success': 'Deletion request sent',
  'request_deletion.form.comment': 'Write an optional comment to your deletion request',
  'request_deletion.form.confirmed':
    'I understand that I am about to delete my company, and I will not be able to retrieve my data again',
  'request_deletion.form.submit': 'Request deletion',
  'request_deletion.note.line_1':
    'You can request to have your company be completely deleted. When your company is deleted, you will not be able to recreate its data.',
  'request_deletion.note.line_2':
    'The deletion itself is a manual process, and your company will thus not be deleted immediately.',
  'request_deletion.title': 'Request deletion of company',

  'request_import.alert.success': 'Import request sent, you will hear from us',
  'request_import.form.date_request': 'First payroll in Salary',
  'request_import.form.date_request.note':
    'We cannot guarantee the import by the desired month, but we will return as soon as possible to let you know what is possible.',
  'request_import.form.password': 'Password',
  'request_import.form.password.required': 'Password is required',
  'request_import.form.pay_roll_system': 'Current payroll system',
  'request_import.form.pay_roll_system_text': 'Type in the name of the payroll system',
  'request_import.form.pay_roll_system_text.required': 'You must enter a name of your payroll system',
  'request_import.form.pay_roll_system.other': 'Other',
  'request_import.form.pay_roll_system.required': 'Payroll system is required',
  'request_import.form.submit': 'Request import',
  'request_import.form.username': 'Username',
  'request_import.form.username.required': 'Username is required',
  'request_import.title': 'Request import from another payroll system',

  'responsive_note_modal.ok': 'OK',
  'responsive_note_modal.text.line_1':
    'You have now been through the process of Salary that has been optimised for small screens, however the rest of Salary only works optimally on a larger screen.',
  'responsive_note_modal.text.line_2':
    'You are however welcome to continue to use Salary on your mobile phone, that option we will not restrict.',
  'responsive_note_modal.title': 'Salary is not optimised for smaller screens',

  'route.register': 'Create new user',

  'salary_cycle.biweekly.period_a': 'period A',
  'salary_cycle.biweekly.period_a_start': 'period A (next start: {start})',
  'salary_cycle.biweekly.period_b': 'period B',
  'salary_cycle.biweekly.period_b_start': 'period B (next start: {start})',
  'salary_cycle.frequency_per.biweekly': 'Per fortnight (14 days)',
  'salary_cycle.frequency_per.monthly': 'Per month',
  'salary_cycle.frequency_per.monthly_offset':
    'Per month (from {from}{from_ordinal_suffix} to {to}{to_ordinal_suffix})',
  'salary_cycle.frequency_per.weekly': 'Per week',
  'salary_cycle.frequency.biweekly': 'Fortnight (14 days)',
  'salary_cycle.frequency.monthly': 'Month',
  'salary_cycle.frequency.weekly': 'Week',
  'salary_cycle.paid.postpaid': 'Post paid',
  'salary_cycle.paid.prepaid': 'Pre paid',

  'salary_reduction.edit.form.amount': 'gross reduction',
  'salary_reduction.edit.form.disposition_date': 'When shall we reduce the gross pay?',
  'salary_reduction.edit.form.disposition_date.placeholder': 'Reduction date',
  'salary_reduction.edit.form.use_b_income': 'Put the gross reduction on B income',
  'salary_reduction.edit.form.use_b_income_am': 'With labour market contribution',
  'salary_reduction.edit.form.with_pension': 'Adjust pension basis',
  'salary_reduction.edit.form.with_vacation': 'Adjust holiday basis',
  'salary_reduction.edit.title.create': 'Register gross reduction',
  'salary_reduction.edit.title.edit': 'Edit gross reduction',
  'salary_reduction.edit.title.view': 'View gross reduction',
  'salary_reduction.tab.header.add_new': 'Register gross reduction',
  'salary_reduction.tab.header.approve_all': 'Approve all',
  'salary_reduction.tab.header.hide_history': 'Back',
  'salary_reduction.tab.header.show_history': 'Show archive',
  'salary_reduction.tab.table.actions.delete': 'Delete gross reduction',
  'salary_reduction.tab.table.actions.edit': 'Edit gross reduction',
  'salary_reduction.tab.table.actions.view': 'View gross reduction',
  'salary_reduction.tab.table.amount.format': '{units} of {rate}',
  'salary_reduction.tab.table.approved.approve': 'Approve',
  'salary_reduction.tab.table.approved.false': 'No',
  'salary_reduction.tab.table.approved.remove': 'Remove approval',
  'salary_reduction.tab.table.approved.true': 'Yes',
  'salary_reduction.tab.table.header.amount': 'Amount',
  'salary_reduction.tab.table.header.approved': 'Approved',
  'salary_reduction.tab.table.header.date': 'Date',
  'salary_reduction.tab.table.header.title': 'Description',
  'salary_reduction.tab.table.income_type.a': 'A income',
  'salary_reduction.tab.table.income_type.b': 'B income',
  'salary_reduction.tab.table.saving': 'Saving...',
  'salary_reduction.tab.title.history': 'Gross reduction archive',
  'salary_reduction.tab.title.standard': 'Gross reduction',

  'salary_registration.coarse_salary_registration.many': 'coarse variable supplement registrations',
  'salary_registration.coarse_salary_registration.one': 'coarse variable supplement registration',
  'salary_registration.detailed_salary_registration.many': 'detailed variable supplement registrations',
  'salary_registration.detailed_salary_registration.one': 'detailed variable supplement registration',
  'salary_registration.salary_registration.many': 'variable supplement registrations',
  'salary_registration.salary_registration.one': 'variable supplement registration',

  'salary_registrations_tab.coarse.alert.success': "{name}'s supplement registrations have been saved",
  'salary_registrations_tab.coarse.form.description': 'Quantity',
  'salary_registrations_tab.coarse.form.description_rate': 'Rate',
  'salary_registrations_tab.coarse.form.description_rate.title': 'Rate ({title})',
  'salary_registrations_tab.coarse.form.description.title': 'Quantity ({title})',
  'salary_registrations_tab.coarse.form.quantity': 'Quantity',
  'salary_registrations_tab.coarse.form.quantity.invalid': '{description} is invalid',
  'salary_registrations_tab.coarse.form.quantity.required': '{description} is required',
  'salary_registrations_tab.coarse.form.quantity.suffix': 'piece',
  'salary_registrations_tab.coarse.form.rate': 'Rate',
  'salary_registrations_tab.coarse.form.rate.invalid': '{description} is invalid',
  'salary_registrations_tab.coarse.form.rate.required': '{description} is required',
  'salary_registrations_tab.coarse.form.rate.suffix': 'kr.',
  'salary_registrations_tab.coarse.of_rate': 'of {rate}',
  'salary_registrations_tab.coarse.period_range': '{from} - {to}',
  'salary_registrations_tab.coarse.select_period': 'Select period',
  'salary_registrations_tab.detailed.delete_all_confirm':
    'Are you sure you wish to delete all outstanding registrations? They cannot be recreated.',
  'salary_registrations_tab.detailed.edit.form.date': 'Date',
  'salary_registrations_tab.detailed.edit.form.date.not_before_employment': 'Date cannot be before employment date',
  'salary_registrations_tab.detailed.edit.form.date.required': 'Date is required',
  'salary_registrations_tab.detailed.edit.form.note': 'Note',
  'salary_registrations_tab.detailed.edit.form.quantity': 'Quantity',
  'salary_registrations_tab.detailed.edit.form.quantity.invalid': 'Quantity is invalid',
  'salary_registrations_tab.detailed.edit.form.quantity.required': 'Quantity is required',
  'salary_registrations_tab.detailed.edit.form.quantity.suffix': 'piece',
  'salary_registrations_tab.detailed.edit.form.salary_type_id': 'Type',
  'salary_registrations_tab.detailed.edit.form.salary_type_id.required': 'Type is required',
  'salary_registrations_tab.detailed.edit.title.create': 'Create supplement registration',
  'salary_registrations_tab.detailed.edit.title.edit': 'Edit supplement registration',
  'salary_registrations_tab.detailed.header.add_registration': 'Register supplement',
  'salary_registrations_tab.detailed.header.approve_all': 'Approve all',
  'salary_registrations_tab.detailed.header.delete_all': 'Delete all outstanding',
  'salary_registrations_tab.detailed.history.table.header.date': 'Date',
  'salary_registrations_tab.detailed.history.table.header.description': 'Description',
  'salary_registrations_tab.detailed.history.table.header.note': 'Note',
  'salary_registrations_tab.detailed.history.table.header.quantity': 'Quantity',
  'salary_registrations_tab.detailed.table.actions.delete': 'Delete registration',
  'salary_registrations_tab.detailed.table.actions.edit': 'Edit registration',
  'salary_registrations_tab.detailed.table.approve': 'Approve',
  'salary_registrations_tab.detailed.table.approved.false': 'No',
  'salary_registrations_tab.detailed.table.approved.true': 'Yes',
  'salary_registrations_tab.detailed.table.header.approved': 'Approved',
  'salary_registrations_tab.detailed.table.header.date': 'Date',
  'salary_registrations_tab.detailed.table.header.description': 'Description',
  'salary_registrations_tab.detailed.table.header.note': 'Note',
  'salary_registrations_tab.detailed.table.header.quantity': 'Quantity',
  'salary_registrations_tab.detailed.table.remove_approval': 'Remove approval',
  'salary_registrations_tab.detailed.table.saving': 'Saving...',
  'salary_registrations_tab.header.detailed': 'Detailed',
  'salary_registrations_tab.header.hide_history': 'Back',
  'salary_registrations_tab.header.show_history': 'Show archive',
  'salary_registrations_tab.title.history': 'Supplement registration archive',
  'salary_registrations_tab.title.standard': 'Supplement registration',

  'salary_registrations.delete.intro.many':
    "You are about to delete all the detailed supplement registrations on the company, that have not been completed through a payroll. It's a total of {count} registrations. They cannot be recreated.",
  'salary_registrations.delete.intro.one':
    "You are about to delete all the detailed supplement registrations on the company, that have not been completed through a payroll. It's a total of {count} registration. They cannot be recreated.",
  'salary_registrations.delete.submit': 'Delete all',
  'salary_registrations.delete.title': 'Delete all outstanding supplement registrations',
  'salary_registrations.modal_edit.title': 'Supplement registration for {name}',
  'salary_registrations.table.action.edit': 'Edit',
  'salary_registrations.table.action.register': 'Register',
  'salary_registrations.table.all_approved': 'All variable supplements approved',
  'salary_registrations.table.approve_hours': 'Approve all supplements',
  'salary_registrations.table.coarse.title': 'Coarse variable supplements',
  'salary_registrations.table.detailed.delete_all': 'Delete all variable supplements',
  'salary_registrations.table.detailed.title': 'Detailed variable supplements',
  'salary_registrations.table.header.employee': 'Employee',
  'salary_registrations.table.header.quantity': 'Quantity',
  'salary_registrations.table.header.rate': 'Rate',
  'salary_registrations.table.header.salary': 'Remuneration',
  'salary_registrations.table.loading': 'Loading...',
  'salary_registrations.table.saving': 'Saving...',
  'salary_registrations.table.select_period': 'Select period',

  'salary_types.hourly.edit.form.day.holiday': 'Holiday',
  'salary_types.hourly.edit.form.include_in_pension_basis': 'Include in pension basis',
  'salary_types.hourly.edit.form.include_in_sh_fritvalg_basis': 'Include in SH-days/Fritvalg basis',
  'salary_types.hourly.edit.form.supplement.add_row': 'Add row',
  'salary_types.hourly.edit.form.supplement.amount.fixed': 'Amount',
  'salary_types.hourly.edit.form.supplement.amount.fixed.percentage': 'Procent is required',
  'salary_types.hourly.edit.form.supplement.amount.fixed.required': 'Amount is required',
  'salary_types.hourly.edit.form.supplement.amount.percentage': 'Proecent',
  'salary_types.hourly.edit.form.supplement.day': 'Day',
  'salary_types.hourly.edit.form.supplement.day.required': 'Day is required',
  'salary_types.hourly.edit.form.supplement.end_time': 'End time',
  'salary_types.hourly.edit.form.supplement.end_time.required': 'End time is required',
  'salary_types.hourly.edit.form.supplement.header.amount': 'Value',
  'salary_types.hourly.edit.form.supplement.header.day': 'Day',
  'salary_types.hourly.edit.form.supplement.header.end_time': 'End time',
  'salary_types.hourly.edit.form.supplement.header.start_time': 'Start time',
  'salary_types.hourly.edit.form.supplement.header.title': 'Description',
  'salary_types.hourly.edit.form.supplement.start_time': 'Start time',
  'salary_types.hourly.edit.form.supplement.start_time.required': 'Start time is required',
  'salary_types.hourly.edit.form.supplement.title': 'Description',
  'salary_types.hourly.edit.form.supplement.title.required': 'Description is required',
  'salary_types.hourly.edit.form.title': 'Description',
  'salary_types.hourly.edit.form.title.required': 'Description is required',
  'salary_types.hourly.edit.note.line_1':
    'Note: You need only enter the supplements your employees will get in addition to their wage.',
  'salary_types.hourly.edit.note.line_2': 'Their individual wage you set on the employee under employment.',
  'salary_types.hourly.edit.title.create': 'Create wage',
  'salary_types.hourly.edit.title.edit': 'Edit wage',
  'salary_types.supplement_varied.edit.form.include_in_pension_basis': 'Include in pension basis',
  'salary_types.supplement_varied.edit.form.include_in_sh_fritvalg_basis': 'Include in SH-days/Fritvalg basis',
  'salary_types.supplement_varied.edit.form.include_in_vacation_basis': 'Include in holiday basis',
  'salary_types.supplement_varied.edit.form.title': 'Description',
  'salary_types.supplement_varied.edit.form.title.required': 'Description is required',
  'salary_types.supplement_varied.edit.title.create': 'Create variable supplement',
  'salary_types.supplement_varied.edit.title.edit': 'Edit variable supplement',
  'salary_types.tab.header.add_hourly': 'Add wage',
  'salary_types.tab.header.add_supplement_varied': 'Add variable supplement',
  'salary_types.tab.header.show_active': 'Show active',
  'salary_types.tab.header.show_inactive': 'Show inactive',
  'salary_types.tab.table.actions.hourly.edit': 'Edit wage',
  'salary_types.tab.table.actions.hourly.reactivate': 'Reactive wage',
  'salary_types.tab.table.actions.hourly.remove': 'Remove wage',
  'salary_types.tab.table.actions.supplement_varied.edit': 'Edit variable supplement',
  'salary_types.tab.table.actions.supplement_varied.reactivate': 'Reactive variable supplement',
  'salary_types.tab.table.actions.supplement_varied.remove': 'Remove variable supplement',
  'salary_types.tab.table.active.false': 'Not active',
  'salary_types.tab.table.active.true': 'Active',
  'salary_types.tab.table.class.hourly': 'Wage',
  'salary_types.tab.table.class.supplement_varied': 'Variable supplement',
  'salary_types.tab.table.header.class': 'Type',
  'salary_types.tab.table.header.include_in_pension_basis': 'Pension basis?',
  'salary_types.tab.table.header.include_in_sh_fritvalg_basis': 'SH-days/Fritvalg basis?',
  'salary_types.tab.table.header.include_in_vacation_basis': 'Holiday basis?',
  'salary_types.tab.table.header.status': 'Status',
  'salary_types.tab.table.header.title': 'Description',
  'salary_types.tab.table.included.false': 'No',
  'salary_types.tab.table.included.true': 'Yes',
  'salary_types.tab.table.with_supplements.many': '(with {count} supplements)',
  'salary_types.tab.table.with_supplements.one': '(with {count} supplement)',
  'salary_types.tab.title': 'Wages and variable supplements',

  'search.not_found': 'No result',

  'signing_document.description': 'The company {name} ({nationalID}) have asked you to sign a document.',
  'signing_document.document_is_signed': 'The document is signed',
  'signing_document.error.error_during_callback': 'Unable to save your signature, contact Salary.',
  'signing_document.error.missing_callback_parameters': 'Missing fields from MitID, contact Salary.',
  'signing_document.error.unable_to_complete_signing': 'Unable to sign the document, verify your code is correct.',
  'signing_document.error.unable_to_fetch_document': 'Unable to fetch document, contact Salary.',
  'signing_document.error.unable_to_start_signing': 'Unable to start the signing process, contact Salary.',
  'signing_document.error.unknown_error': 'Unknown error from MitID ({error}), contact Salary.',
  'signing_document.error.user_cancelled': 'MitID authorisation cancelled by user.',
  'signing_document.get_document': 'Download document',
  'signing_document.instructions':
    "You sign by entering your phone number, afterwards you'll get a SMS code, that you use to sign the document.",
  'signing_document.request_validation_code': 'Request SMS code',
  'signing_document.restart_flow': 'Click here to try again',
  'signing_document.sign_document': 'Sign document with SMS code',
  'signing_document.sign_with_mitid': 'Sign with MitID',
  'signing_document.signature_type.criipto': 'MitID',
  'signing_document.signature_type.none': 'none',
  'signing_document.signature_type.sms': 'text message',
  'signing_document.title': 'You have a document awaiting your signature',

  'slack_integration.alert.saved': 'Slack configuration saved',
  'slack_integration.error.unknown': 'Configuration could not be saved',
  'slack_integration.form.add_row': 'Add channel',
  'slack_integration.form.channel_id': 'Channel ID',
  'slack_integration.form.channel_id.required': 'Channel ID is required',
  'slack_integration.form.event_types.anniversaries': 'Anniversaries',
  'slack_integration.form.event_types.birthdays': 'Birthdays',
  'slack_integration.form.event_types.leave': 'Leave',
  'slack_integration.form.language_code': 'Language',
  'slack_integration.form.language_code.invalid': 'Language is invalid',
  'slack_integration.form.language_code.required': 'Language is required',
  'slack_integration.form.slack_token': 'Slack token',
  'slack_integration.form.slack_token.required': 'Slack token is required',
  'slack_integration.form.submit': 'Save',
  'slack_integration.new_integration': 'Create integration',
  'slack_integration.new_integration.lock': 'Upgrade to Basic to integrate with Slack.',
  'slack_integration.title': 'Configuration of Slack integration',

  'source_type.api_client': 'API client',
  'source_type.business': 'Normal user',
  'source_type.employee': 'Employee',
  'source_type.external_import': 'Data import',
  'source_type.file_import': 'File import',
  'source_type.support': 'Support',
  'source_type.system': 'System',

  'sso.page.no': 'No',
  'sso.page.title': 'Are you sure you wish to connect to {name}?',
  'sso.page.yes': 'Yes',

  'staged_import.error.company_not_found': 'Unknown company',
  'staged_import.error.employee_not_found':
    'The employee number could not be associated with one in Salary; ensure they are exactly the same, check for instance prefixed zeros',
  'staged_import.error.unknown': 'An unknown error occurred during file loading',
  'staged_import.import_type_file.danloen_salary': 'Danløn file',
  'staged_import.import_type_file.dataloen_salary': 'Dataløn file',
  'staged_import.import_type_file.excel_file': 'Excel file',
  'staged_import.import_type_file.proloen_salary': 'Proløn file',
  'staged_import.import_type_file.workfeed_salary': 'Workfeed file',
  'staged_import.period_form.select_cycle': 'Select cycle for time registrations',
  'staged_import.period_form.select_period': 'Select period for time registrations',
  'staged_import.status.failure': 'Error',
  'staged_import.status.no_change': 'No change',
  'staged_import.status.success': 'Success',
  'staged_import.step.0.continue.template': 'Continue to template',
  'staged_import.step.0.continue.upload': 'Continue to upload',
  'staged_import.step.0.external_format_note':
    '{nb} You are about to chose a non-Salary format. Not all components in these external formats are supported. We recommend only the use of external formats, if you use an external systems that exports in these formats.',
  'staged_import.step.0.external_format_note.nb': 'Note:',
  'staged_import.step.0.external_salary_file_setting.note.danloen':
    "You can alter the texts for Danløn's fields {link}.",
  'staged_import.step.0.external_salary_file_setting.note.link': 'here',
  'staged_import.step.0.external_salary_file_setting.note.proloen':
    "You can alter the texts for Proløn's fields {link}.",
  'staged_import.step.0.import_type_select.type.danloen_salary': 'Danløn format',
  'staged_import.step.0.import_type_select.type.dataloen_salary': 'Dataløn format',
  'staged_import.step.0.import_type_select.type.excel_hours': 'Time registrations (Salary format)',
  'staged_import.step.0.import_type_select.type.freelancer_bonus': 'Compensations for freelancers (Salary format)',
  'staged_import.step.0.import_type_select.type.proloen_salary': 'Proløn format',
  'staged_import.step.0.import_type_select.type.workfeed_salary': 'Workfeed CSV file in special Salary format',
  'staged_import.step.0.intro.line_1': 'Select format to upload hours and/or other pay fields.',
  'staged_import.step.0.intro.line_2': '{title} can be used to register hours for your employees in an Excel sheet.',
  'staged_import.step.0.intro.line_2.title': 'Time registrations',
  'staged_import.step.0.intro.line_3':
    '{title} can be used to give compensation to several freelancers at once in an Excel sheet.',
  'staged_import.step.0.intro.line_3.title': 'Compensations for freelancers',
  'staged_import.step.0.intro.line_4':
    "When you use one of Salary's own formats, you will have to download a template to fill in.",
  'staged_import.step.0.intro.line_5':
    "You can also use {danloen}'s, {dataloen}'s or {proloen}'s formats. Salary supports the most of these formats' fields, and will convert them to appropriate types in Salary.",
  'staged_import.step.0.intro.line_5.danloen': 'Danløn',
  'staged_import.step.0.intro.line_5.dataloen': 'Dataløn',
  'staged_import.step.0.intro.line_5.proloen': 'Proløn',
  'staged_import.step.0.intro.line_6':
    'On the last step, you will be able to see all the information you have uploaded before you actually chose to save.',
  'staged_import.step.0.select_period': 'Select period for time registrations',
  'staged_import.step.0.title': 'Step 1',
  'staged_import.step.0.workfeed_format_note':
    "When you import from Workfeed's CSV file, it's important you have chosen the special Salary version.",
  'staged_import.step.1.button.back': 'Back',
  'staged_import.step.1.button.continue': 'Continue to upload',
  'staged_import.step.1.button.download_template': 'Download Excel file',
  'staged_import.step.1.intro.employees':
    'Download the Excel file with a list over your employees via the button below. If you have already downloaded and filled in the file, you can skip to the next step.',
  'staged_import.step.1.intro.freelancers':
    'Download the Excel file with a list over your freelancers via the button below. If you have already downloaded and filled in the file, you can skip to the next step.',
  'staged_import.step.1.title': 'Step 2',
  'staged_import.step.2.button.back': 'Back',
  'staged_import.step.2.button.upload': 'Upload {type}',
  'staged_import.step.2.check_box.delete_unsettled':
    'Delete outstanding time registrations before the import is saved (recommended)',
  'staged_import.step.2.intro.danloen_salary': 'Upload the Danløn file using the button below.',
  'staged_import.step.2.intro.dataloen_salary': 'Upload the Dataløn file using the button below.',
  'staged_import.step.2.intro.excel_hours':
    'Upload the filled in Excel file with your employees using the button below.',
  'staged_import.step.2.intro.freelancers_bonus':
    'Upload the filled in Excel file with your freelancers using the button below.',
  'staged_import.step.2.intro.proloen_salary': 'Upload the Proløn file using the button below.',
  'staged_import.step.2.intro.workfeed_salary': 'Upload the Workfeed file using the button below.',
  'staged_import.step.2.loading.async':
    'Since the file is very large, it might take a while.  The file is loading, please wait...',
  'staged_import.step.2.loading.not_async': 'The file is loading...',
  'staged_import.step.2.title': 'Step 3',
  'staged_import.step.3.button.approve': 'Approve',
  'staged_import.step.3.button.back': 'Back',
  'staged_import.step.3.intro.line_1':
    'Here you review what we have loaded from the file, including any errors that need fixing.',
  'staged_import.step.3.intro.line_2':
    'If you are satisfied with the changes that will occur, click the approve button.',
  'staged_import.step.3.loading.async':
    'Since there is a lot to be saved, it might take a while.  Data are being saved, please wait...',
  'staged_import.step.3.loading.not_async': 'Data are being saved...',
  'staged_import.step.3.title': 'Step 4a',
  'staged_import.step.4.button.done': 'Done',
  'staged_import.step.4.title': 'Step 4b',
  'staged_import.steps.0.summary': 'Select import type',
  'staged_import.steps.1.summary': 'Download templtae',
  'staged_import.steps.2.summary': 'Upload file',
  'staged_import.steps.3.summary': 'Review and approve',
  'staged_import.steps.4.summary': 'Result',
  'staged_import.steps.intro': 'Import hours, pay fields, etc. over a few steps',
  'staged_import.steps.title': 'Payroll data import',

  'start_balances.alert.success': "{name}'s start balance have been saved",
  'start_balances.form.a_income': 'A income',
  'start_balances.form.a_income.invalid': 'A income is invalid',
  'start_balances.form.a_income.required': 'A income is required',
  'start_balances.form.a_income.suffix': 'kr.',
  'start_balances.form.a_tax': 'A tax',
  'start_balances.form.a_tax.invalid': 'A tax is invalid',
  'start_balances.form.a_tax.required': 'A tax is required',
  'start_balances.form.a_tax.suffix': 'kr.',
  'start_balances.form.accrual_days_carry_over_title': 'Holidays earned 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.accrual_days_earned_title': 'Holidays earned 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.accrual_days_taken_title': 'Holidays spent 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.accrual_days_taken_title.year_end': 'Holidays spent 1/9 {start_year} - 31/12 {end_year}',
  'start_balances.form.am_income': 'Labour market contribution income',
  'start_balances.form.am_income.invalid': 'Labour market contribution income is invalid',
  'start_balances.form.am_income.required': 'Labour market contribution income is required',
  'start_balances.form.am_income.suffix': 'kr.',
  'start_balances.form.am_tax': 'Labour market contribution',
  'start_balances.form.am_tax.invalid': 'Labour market contribution is invalid',
  'start_balances.form.am_tax.required': 'Labour market contribution is required',
  'start_balances.form.am_tax.suffix': 'kr.',
  'start_balances.form.car_allowance': 'Transport allowance this year',
  'start_balances.form.car_allowance.invalid': 'Transport allowance this year is invalid',
  'start_balances.form.car_allowance.required': 'Transport allowance this year is required',
  'start_balances.form.car_allowance.suffix': 'km',
  'start_balances.form.disposition_date': 'Valid per',
  'start_balances.form.disposition_date.required': 'Valid per is required',
  'start_balances.form.employee_atp': 'ATP, employee',
  'start_balances.form.employee_atp.invalid': 'ATP, employee is invalid',
  'start_balances.form.employee_atp.required': 'ATP, employee is required',
  'start_balances.form.employee_atp.suffix': 'kr.',
  'start_balances.form.employee_paid_pension': 'Employee paid pension',
  'start_balances.form.employee_paid_pension.invalid': 'Employee paid pension is invalid',
  'start_balances.form.employee_paid_pension.required': 'Employee paid pension is required',
  'start_balances.form.employee_paid_pension.suffix': 'kr.',
  'start_balances.form.employer_atp': 'ATP, employer',
  'start_balances.form.employer_atp.invalid': 'ATP, employer is invalid',
  'start_balances.form.employer_atp.required': 'ATP, employer is required',
  'start_balances.form.employer_atp.suffix': 'kr.',
  'start_balances.form.employer_paid_pension': 'Employer paid pension',
  'start_balances.form.employer_paid_pension.invalid': 'Employer paid pension is invalid',
  'start_balances.form.employer_paid_pension.required': 'Employer paid pension is required',
  'start_balances.form.employer_paid_pension.suffix': 'kr.',
  'start_balances.form.extra_vacation_accrual_days_earned': 'Extra holidays awarded',
  'start_balances.form.extra_vacation_accrual_days_earned.invalid': 'Extra holidays awarded is invalid',
  'start_balances.form.extra_vacation_accrual_days_earned.required': 'Extra holidays awarded is required',
  'start_balances.form.extra_vacation_accrual_days_earned.suffix': 'days',
  'start_balances.form.extra_vacation_accrual_days_taken': 'Extra holidays spent',
  'start_balances.form.extra_vacation_accrual_days_taken.invalid': 'Extra holidays spent is invalid',
  'start_balances.form.extra_vacation_accrual_days_taken.required': 'Extra holidays spent is required',
  'start_balances.form.extra_vacation_accrual_days_taken.suffix': 'days',
  'start_balances.form.fritvalg': 'Fritvalg',
  'start_balances.form.fritvalg.invalid': 'Fritvalg is invalid',
  'start_balances.form.fritvalg.required': 'Fritvalg is required',
  'start_balances.form.fritvalg.suffix': 'kr.',
  'start_balances.form.fund_days_earned_title': 'Unpaid holidays earned 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.fund_days_taken_title': 'Unpaid holidays spent 1/9 {start_year} - 31/12 {end_year}',
  'start_balances.form.fund_vacation_net_title': 'Holiday allowance earned 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.generic_vacation.invalid': '{title} is invalid',
  'start_balances.form.generic_vacation.required': '{title} is required',
  'start_balances.form.generic_vacation.suffix.currency': 'kr.',
  'start_balances.form.generic_vacation.suffix.days': 'days',
  'start_balances.form.great_prayer_day_earned': 'Great Prayer Day supplement',
  'start_balances.form.great_prayer_day_earned.invalid': 'Great Prayer Day is invalid',
  'start_balances.form.great_prayer_day_earned.required': 'Great Prayer Day is required',
  'start_balances.form.great_prayer_day_earned.suffix': 'kr.',
  'start_balances.form.overtime_balance': 'Overtime balance',
  'start_balances.form.overtime_balance.invalid': 'Overtime balance is invalid',
  'start_balances.form.overtime_balance.required': 'Overtime balance is required',
  'start_balances.form.overtime_balance.suffix': 'hours',
  'start_balances.form.pension_title': 'Pension',
  'start_balances.form.personal_time_accrual_days_earned': 'Personal days awarded',
  'start_balances.form.personal_time_accrual_days_earned.invalid': 'Personal days awarded is invalid',
  'start_balances.form.personal_time_accrual_days_earned.required': 'Personal days awarded is required',
  'start_balances.form.personal_time_accrual_days_earned.suffix': 'days',
  'start_balances.form.personal_time_accrual_days_taken': 'Personal days spent',
  'start_balances.form.personal_time_accrual_days_taken.invalid': 'Personal days spent is invalid',
  'start_balances.form.personal_time_accrual_days_taken.required': 'Personal days spent is required',
  'start_balances.form.personal_time_accrual_days_taken.suffix': 'days',
  'start_balances.form.salary': 'Gross pay',
  'start_balances.form.salary_title': 'Income',
  'start_balances.form.salary.invalid': 'Gross pay is invalid',
  'start_balances.form.salary.required': 'Gross pay is required',
  'start_balances.form.salary.suffix': 'kr.',
  'start_balances.form.sh_dage': 'SH days',
  'start_balances.form.sh_dage.invalid': 'SH days is invalid',
  'start_balances.form.sh_dage.required': 'SH days is required',
  'start_balances.form.sh_dage.suffix': 'kr.',
  'start_balances.form.supplement_earned_title.not_summer':
    'Holiday supplement earned 1/9 {start_year} - 31/5 {end_year}',
  'start_balances.form.supplement_earned_title.summer': 'Holiday supplement earned 1/6 {start_year} - 31/8 {end_year}',
  'start_balances.form.tax_title': 'Tax',
  'start_balances.form.vacation_basis_carry_over_title': 'Holiday entitled pay 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.vacation_basis_title': 'Holiday entitled pay 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.vacation_optional_days_available_this_year': 'Extra holidays awarded this year',
  'start_balances.form.vacation_optional_days_available_this_year.invalid':
    'Extra holidays awarded this year is invalid',
  'start_balances.form.vacation_optional_days_available_this_year.required':
    'Extra holidays awarded this year is required',
  'start_balances.form.vacation_optional_days_available_this_year.suffix': 'days',
  'start_balances.form.vacation_optional_days_taken_this_year': 'Extra holidays spent this holiday year',
  'start_balances.form.vacation_optional_days_taken_this_year.invalid':
    'Extra holidays spent this holiday year is invalid',
  'start_balances.form.vacation_optional_days_taken_this_year.required':
    'Extra holidays spent this holiday year is required',
  'start_balances.form.vacation_optional_days_taken_this_year.suffix': 'days',
  'start_balances.form.vacation_personal_days_days_available_this_year': 'Personal days awarded this year',
  'start_balances.form.vacation_personal_days_days_available_this_year.invalid':
    'Personal days awarded this year is invalid',
  'start_balances.form.vacation_personal_days_days_available_this_year.required':
    'Personal days awarded this year is required',
  'start_balances.form.vacation_personal_days_days_available_this_year.suffix': 'days',
  'start_balances.form.vacation_personal_days_days_taken_this_year': 'Personal days spent this year',
  'start_balances.form.vacation_personal_days_days_taken_this_year.invalid': 'Personal days spent this year is invalid',
  'start_balances.form.vacation_personal_days_days_taken_this_year.required':
    'Personal days spent this year is required',
  'start_balances.form.vacation_personal_days_days_taken_this_year.suffix': 'days',
  'start_balances.form.vacation_title': 'Holiday',
  'start_balances.form.work_days': 'Work days this year',
  'start_balances.form.work_days.invalid': 'Work days this year is invalid',
  'start_balances.form.work_days.required': 'Work days this year is required',
  'start_balances.form.work_days.suffix': 'days',
  'start_balances.form.work_hours': 'Work hours this year',
  'start_balances.form.work_hours.invalid': 'Work hours this year is invalid',
  'start_balances.form.work_hours.required': 'Work hours this year is required',
  'start_balances.form.work_hours.suffix': 'hours',
  'start_balances.intro': 'Enter start balances.',
  'start_balances.title': 'Start balances',

  'stripe.alert.success': 'Payment card agreement has been saved',
  'stripe.edit.fee_explanation.line_1': 'There is a card fee of {fee} + moms per payroll.',
  'stripe.edit.fee_explanation.line_2': 'You can avoid this fee by {link}.',
  'stripe.edit.fee_explanation.line_2_link': 'using MasterCard Payment Service',
  'stripe.edit.form.card_number': 'Card number',
  'stripe.edit.form.cvc': 'CVC',
  'stripe.edit.form.expiry': 'Expiry date',
  'stripe.edit.form.submit': 'Save card',
  'stripe.edit.title.create': 'Create payment card agreement',
  'stripe.edit.title.edit': 'Change payment card agreement',
  'stripe.error_code.call_issuer': 'Contact your card issuer.',
  'stripe.error_code.currency_not_supported': 'The card does not support Danish kroner.',
  'stripe.error_code.expired_card': 'The card has expired.',
  'stripe.error_code.incorrect_number': 'Invalid card number.',
  'stripe.error_code.insufficient_funds': 'There were insufficient funds on the card.',
  'stripe.error_code.pickup_card': 'The card cannot be used for this payment.',
  'stripe.form.expiry.placeholder': 'MM / YY',

  'supplement.type.denmark_fritvalg': 'Fritvalg incl. pension incl. holiday allowance',
  'supplement.type.denmark_fritvalg_amount': 'Fritvalg incl. pension incl. holiday allowance fixed amount',
  'supplement.type.denmark_fritvalg_amount_direct_pay':
    'Fritvalg incl. pension incl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_fritvalg_direct_pay': 'Fritvalg incl. pension incl. holiday allowance recurring payment',
  'supplement.type.denmark_fritvalg_no_pension': 'Fritvalg excl. pension incl. holiday allowance',
  'supplement.type.denmark_fritvalg_no_pension_amount': 'Fritvalg excl. pension incl. holiday allowance fixed amount',
  'supplement.type.denmark_fritvalg_no_pension_amount_direct_pay':
    'Fritvalg excl. pension incl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_fritvalg_no_pension_direct_pay':
    'Fritvalg excl. pension incl. holiday allowance recurring payment',
  'supplement.type.denmark_fritvalg_with_vacation': 'Fritvalg incl. pension excl. holiday allowance',
  'supplement.type.denmark_fritvalg_with_vacation_amount':
    'Fritvalg incl. pension excl. holiday allowance fixed amount',
  'supplement.type.denmark_fritvalg_with_vacation_amount_direct_pay':
    'Fritvalg incl. pension excl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_fritvalg_with_vacation_direct_pay':
    'Fritvalg incl. pension excl. holiday allowance recurring payment',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension': 'Fritvalg excl. pension excl. holiday allowance',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension_amount':
    'Fritvalg excl. pension excl. holiday allowance fixed amount',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension_amount_direct_pay':
    'Fritvalg excl. pension excl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension_direct_pay':
    'Fritvalg excl. pension excl. holiday allowance recurring payment',
  'supplement.type.denmark_great_prayer_day_supplement': 'Great Prayer Day supplement',
  'supplement.type.denmark_sh_dage': 'SH-Dage incl. pension incl. holiday allowance',
  'supplement.type.denmark_sh_dage_amount': 'SH-Dage incl. pension incl. holiday allowance fixed amount',
  'supplement.type.denmark_sh_dage_amount_direct_pay':
    'SH-Dage incl. pension incl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_sh_dage_direct_pay': 'SH-Dage incl. pension incl. holiday allowance recurring payment',
  'supplement.type.denmark_sh_dage_no_pension': 'SH-Dage excl. pension incl. holiday allowance',
  'supplement.type.denmark_sh_dage_no_pension_amount': 'SH-Dage excl. pension incl. holiday allowance fixed amount',
  'supplement.type.denmark_sh_dage_no_pension_amount_direct_pay':
    'SH-Dage excl. pension incl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_sh_dage_no_pension_direct_pay':
    'SH-Dage excl. pension incl. holiday allowance recurring payment',
  'supplement.type.denmark_sh_dage_with_vacation': 'SH-Dage incl. pension excl. holiday allowance',
  'supplement.type.denmark_sh_dage_with_vacation_amount': 'SH-Dage incl. pension excl. holiday allowance fixed amount',
  'supplement.type.denmark_sh_dage_with_vacation_amount_direct_pay':
    'SH-Dage incl. pension excl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_sh_dage_with_vacation_direct_pay':
    'SH-Dage incl. pension excl. holiday allowance recurring payment',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension': 'SH-Dage excl. pension excl. holiday allowance',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension_amount':
    'SH-Dage excl. pension excl. holiday allowance fixed amount',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension_amount_direct_pay':
    'SH-Dage excl. pension excl. holiday allowance fixed amount recurring payment',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension_direct_pay':
    'SH-Dage excl. pension excl. holiday allowance recurring payment',
  'supplement.type.denmark_vacation_supplement': 'Holiday supplement',
  'supplement.type.denmark_vacation_supplement_special': 'Horesta holiday supplement',
  'supplement.type.short_name.denmark_fritvalg': 'Fritvalg',
  'supplement.type.short_name.denmark_sh_dage': 'SH-days',

  'support_tab.access.false': 'No',
  'support_tab.access.provide': 'Give support access to this company',
  'support_tab.access.remove': "Remove support's access to this company",
  'support_tab.access.title': 'Support have access',
  'support_tab.access.until': 'Until {time}',
  'support_tab.alert.success': 'Changes to {name} have been saved',
  'support_tab.employee_import.button': 'Import employees via Excel',
  'support_tab.employee_import.button.lock': 'Upgrade to Basic to import employees via Excel',
  'support_tab.header.data_provider_terms': 'Data Processing Agreement',
  'support_tab.header.terms': 'Terms',
  'support_tab.request_deletion.button': 'Request deletion of company',
  'support_tab.request_import.button': 'Request import from a different payroll system',
  'support_tab.request_import.button.lock': 'Upgrade to Basic to request import from a different payroll system',
  'support_tab.title': 'Support',

  'swipe_employees.header.back': 'Back',
  'swipe_employees.invalid_table.header.employee': 'Name',
  'swipe_employees.invalid_table.header.employee_type': 'Employee type',
  'swipe_employees.invalid_table.header.swipe_count': 'Number of Swipes',
  'swipe_employees.invalid.description':
    'These employees cannot use Swipe, since their employee type prevents them from doing so. {link}.',
  'swipe_employees.invalid.link_text': 'You can select which employee types may use Swipe under the Swipe settings',
  'swipe_employees.invalid.title': 'Other employees',
  'swipe_employees.valid_table.has_swipe.disable': 'Disable',
  'swipe_employees.valid_table.has_swipe.disabled': 'No',
  'swipe_employees.valid_table.has_swipe.enable': 'Enable',
  'swipe_employees.valid_table.has_swipe.enabled': 'Yes',
  'swipe_employees.valid_table.header.employee': 'Name',
  'swipe_employees.valid_table.header.has_swipe': 'Allowed to Swipe?',
  'swipe_employees.valid_table.header.swipe_count': 'Number of Swipes',
  'swipe_employees.valid.description': 'Here you can enable and disable which employees are allowed to use Swipe.',
  'swipe_employees.valid.title': 'Employee overview for Swipe',

  'swipes.header.approve_all': 'Approve all (total {amount})',
  'swipes.header.employees': 'Employees',
  'swipes.header.history.back': 'Back',
  'swipes.header.history.show': 'Show history',
  'swipes.state.approved': 'Approved',
  'swipes.state.awaiting_approval': 'Awaiting approval',
  'swipes.state.paid': 'Paid',
  'swipes.table.actions.approve': 'Approve',
  'swipes.table.actions.delete': 'Delete',
  'swipes.table.approve_now': 'Approve now',
  'swipes.table.header.amount': 'Amount',
  'swipes.table.header.date': 'Date',
  'swipes.table.header.employee': 'Employee',
  'swipes.table.header.state': 'State',
  'swipes.title.active': 'Swipe™ overview',
  'swipes.title.history': 'Archived Swipe™ overview',

  'tax_card.preferred.main_card': 'Primary tax card',
  'tax_card.preferred.secondary_card': 'Secondary tax card',
  'tax_card.request_type.auto_re_request': 'Request created by the system due to missing tax card for current year',
  'tax_card.request_type.changed_employment_details': 'Change of employment information',
  'tax_card.request_type.changed_employment_period': 'Change of employment period',
  'tax_card.request_type.changed_preferred_tax_card_type': 'Change of preferred tax card',
  'tax_card.request_type.new_employee': 'Creation of employee',
  'tax_card.request_type.re_request': 'Manual re-request',
  'tax_card.request_type.unsubscribe': 'Unsubscription from changes to employee’s tax card information',
  'tax_card.tab.alert.success.request':
    'We have ordered a new tax card, it can take upwards of an hour for it to arrive. If you have already approved the payroll, you will need to reopen it to use the new tax card for the employee.',
  'tax_card.tab.alert.success.tax_card': "{name}'s tax card has been saved.",
  'tax_card.tab.alert.success.tax_percentage': "{name}'s tax card has been saved.",
  'tax_card.tab.edit.form.valid_from': 'Valid from',
  'tax_card.tab.edit.form.valid_from.required': 'Valid from is required',
  'tax_card.tab.edit.title': 'Edit tax card',
  'tax_card.tab.header.increase_percentage': 'Increase tax rate',
  'tax_card.tab.header.request_new_tax_card': 'Request a new tax card',
  'tax_card.tab.header.select_tax_card': 'Select card',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage': 'Extra tax rate',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage.invalid': 'Extra tax rate is invalid',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage.required': 'Extra tax rate is required',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage.suffix': '%',
  'tax_card.tab.increase_percentage.intro':
    'Only write what you wish to increase the rate with. For example, if the current tax rate is 40% and you wish for the employee to pay 45%, write 5 in the field.',
  'tax_card.tab.increase_percentage.title': 'Increase tax rate',
  'tax_card.tab.request_table.header.request_type': 'Initiated by',
  'tax_card.tab.request_table.header.sent_time': 'Sent',
  'tax_card.tab.table.active.false': 'No',
  'tax_card.tab.table.active.true': 'Yes',
  'tax_card.tab.table.header.active': 'Active',
  'tax_card.tab.table.header.deduction_monthly': 'Mthly deduct.',
  'tax_card.tab.table.header.income_year': 'Year',
  'tax_card.tab.table.header.max_a_income_free_card': 'Tax free amount',
  'tax_card.tab.table.header.percentage': 'Tax%',
  'tax_card.tab.table.header.tax_card_type': 'Type',
  'tax_card.tab.table.header.valid_from': 'Valid from',
  'tax_card.tab.title.requests': 'Requests',
  'tax_card.tab.title.standard': 'Tax cards',
  'tax_card.type.free': 'Free card',
  'tax_card.type.gross': 'Gross reduction card',
  'tax_card.type.gross_no_retainment': 'Gross reduction card with separate percentage',
  'tax_card.type.none': 'No tax card',
  'tax_card.type.primary': 'Primary tax card',
  'tax_card.type.primary_no_retainment': 'Primary tax card with separate percentage',
  'tax_card.type.secondary': 'Secondary tax card',
  'tax_card.type.secondary_no_retainment': 'Secondary tax card with separate percentage',

  'tax_report_field_number.0011': 'Ej automatisk beregning af befordringsfradrag',
  'tax_report_field_number.0013': 'A-indkomst, hvoraf der skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0014': 'A-indkomst, hvoraf der ikke skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0015': 'Indeholdt A-skat',
  'tax_report_field_number.0016': 'Indeholdt arbejdsmarkedsbidrag (am-bidrag)',
  'tax_report_field_number.0019': 'Værdi af fri bil til rådighed',
  'tax_report_field_number.0020': 'Værdi af fri telefon mm.',
  'tax_report_field_number.0021': 'Værdi af fri kost og logi',
  'tax_report_field_number.0022':
    'Indskud i arbejdsgiveradministreret pensionsordning i svensk pensionsselskab/-institut eller anden godkendt udenlandsk pensionsordning',
  'tax_report_field_number.0023': 'Am-bidrag (8 pct.) af indskud indberettet under feltnr. 22',
  'tax_report_field_number.0024': 'Godkendelsesnummer for anden godkendt udenlandsk pensionsordning ',
  'tax_report_field_number.0025': 'Skattefri del af udbetaling fra anden godkendt udenlandsk pensionsordning ',
  'tax_report_field_number.0026': 'Værdi af arbejdsgiverbetalt sundhedsforsikring og -behandling',
  'tax_report_field_number.0036': 'B-indkomst, hvoraf der skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0037': 'Offentlige tilskud og godtgørelser til virksomhed',
  'tax_report_field_number.0038': 'B-indkomst, hvoraf der ikke skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0039': 'Hædersgaver',
  'tax_report_field_number.0040': 'Naturalieydelser',
  'tax_report_field_number.0041': 'Bruttoindskud i medarbejderinvesteringsselskab',
  'tax_report_field_number.0042': 'Am-bidrag af indskud i medarbejderinvesteringsselskab',
  'tax_report_field_number.0043': 'Cvr-nr. på medarbejderinvesteringsselskab',
  'tax_report_field_number.0045': 'Sats-type for ATP-bidrag',
  'tax_report_field_number.0046': 'ATP-bidrag',
  'tax_report_field_number.0047': 'OP-bidrag',
  'tax_report_field_number.0048': 'Skattefri rejse- og befordringsgodtgørelse',
  'tax_report_field_number.0050': 'Værdi af fri helårsbolig',
  'tax_report_field_number.0051': 'Værdi af fri sommerbolig',
  'tax_report_field_number.0052': 'Værdi af fri lystbåd',
  'tax_report_field_number.0053': 'Værdi af fri medie-/radiolicens',
  'tax_report_field_number.0055': 'Værdi af andre personalegoder, der overstiger bundgrænse. ',
  'tax_report_field_number.0056': 'Værdi af andre personalegoder, uden bundgrænse. ',
  'tax_report_field_number.0057': 'A-indkomst fra aldersopsparing',
  'tax_report_field_number.0058': 'A-skat af aldersopsparing',
  'tax_report_field_number.0059': 'Foreløbig A-skat af aldersopsparing',
  'tax_report_field_number.0063': 'Fri befordring mellem hjem og arbejde mv.',
  'tax_report_field_number.0068': 'Yderligere tekstoplysninger',
  'tax_report_field_number.0069': 'Jubilæumsgratiale og fratrædelsesgodtgørelse',
  'tax_report_field_number.0070': 'Den del af 0069, der er indbetalt til pensionsordning.',
  'tax_report_field_number.0071': 'Den del af 0069, der er tingsgaver',
  'tax_report_field_number.0083': 'Sødage',
  'tax_report_field_number.0088': 'Fritaget for udligningsskat',
  'tax_report_field_number.0089': 'Bidrag til obligatorisk udenlandsk social sikring',
  'tax_report_field_number.0091': 'Gruppelivsforsikring i pensionsindskud',
  'tax_report_field_number.0096': 'Sundhedsforsikring i pensionsindskud',
  'tax_report_field_number.0098': 'Tilbagebetaling af kontanthjælp',
  'tax_report_field_number.0099': 'Pinkode',
  'tax_report_field_number.0113': 'A-indkomst, udbetalt som feriepenge',
  'tax_report_field_number.0114': 'YR-kontonummer - ikke-kommunale ydelser',
  'tax_report_field_number.0115': 'YR-kontonummer - kommunale ydelser',
  'tax_report_field_number.0143': 'RUT-nr. på udenlandsk arbejdsgiver vedr. AFU',
  'tax_report_field_number.0147': 'Lønmodtagers pensionsandel',
  'tax_report_field_number.0148': 'Arbejdsgivers pensionsandel',
  'tax_report_field_number.0157': 'Indbetaling på ikke-grønlandsk pensionsordning',
  'tax_report_field_number.0158': 'Skat af indbetaling på ikke-grønlandsk pensionsordning',
  'tax_report_field_number.0198': 'Bruttoferiepenge - timelønnede',
  'tax_report_field_number.0200': 'Antal løntimer',
  'tax_report_field_number.0201': 'Bruttoferiepenge',
  'tax_report_field_number.0202': 'Nettoferiepenge - timelønnede',
  'tax_report_field_number.0203': 'Optjente feriedage - timelønnede',
  'tax_report_field_number.0209': 'Se-nr. på feriepengeudbetaler',
  'tax_report_field_number.0210': 'Bruttoferiepenge - fortsættende funktionær',
  'tax_report_field_number.0211': 'Feriedage - fortsættende funktionær',
  'tax_report_field_number.0214': 'YR-ydelsesperiode ',
  'tax_report_field_number.0215': 'YR-periode for tilskud og refusion',
  'tax_report_field_number.0216': 'YR-udløsende cpr-nr.',
  'tax_report_field_number.0218': 'Cvr-/se-nr. på udbetalende FerieGarantiOrdning',
  'tax_report_field_number.0220': 'Ingen forhold mellem løn og timer',
  'tax_report_field_number.0248': 'Opsparing til søgne- og helligdags betaling',
  'tax_report_field_number.0249': 'Opsparing til feriefridage',
  'tax_report_field_number.0400': 'Am-bidrag af tilsagnsordning',
  'tax_report_field_number.20004': 'Nettoferiepenge for fratrædende funktionærer (for alle år, der er indberettet)',
  'tax_report_field_number.20005': 'Restferiedage fratrædende funktionærer (for alle år, der er indberettet)',
  'tax_report_field_number.20006': 'Bruttoferiepenge for fratrædende funktionærer (for alle år, der er indberettet)',

  'time_registration_tab.coarse.alert.success': 'Time registration has been savd',
  'time_registration_tab.coarse.form.hours_warning':
    'You have entered more than 200 hours in one of the fields. Ignore this message if the numbers are correct.',
  'time_registration_tab.coarse.form.reuse_registration': 'Reuse hours and days',
  'time_registration_tab.coarse.form.reuse_registration.use_standard_days': 'Reuse hours and standard number of days',
  'time_registration_tab.coarse.form.use_standard_days': 'Use standard number of days',
  'time_registration_tab.coarse.form.work_days': 'Number of days',
  'time_registration_tab.coarse.form.work_days.invalid': 'Number of days is invalid',
  'time_registration_tab.coarse.form.work_days.must_be_integer': 'Number of days must be an integer',
  'time_registration_tab.coarse.form.work_days.required': 'Number of days is required',
  'time_registration_tab.coarse.form.work_days.suffix': 'days',
  'time_registration_tab.coarse.form.work_hours': 'Number of hours',
  'time_registration_tab.coarse.form.work_hours.format': '{title} - {rate}',
  'time_registration_tab.coarse.form.work_hours.info': 'Hundred part hours (e.g. half hour is 0.5 hours).',
  'time_registration_tab.coarse.form.work_hours.invalid': '{description} is invalid',
  'time_registration_tab.coarse.form.work_hours.required': '{description} is required',
  'time_registration_tab.coarse.form.work_hours.suffix': 'hours',
  'time_registration_tab.coarse.form.work_hours.with_title': 'Number of hours ({title})',
  'time_registration_tab.coarse.modal.title': 'Time registration for {name}',
  'time_registration_tab.coarse.prevent_period_change.line_1':
    'Register hours for the whole period {start} to {end} below.',
  'time_registration_tab.coarse.prevent_period_change.line_2':
    'If you wish to register for an earlier period, go to {link} on the employee.',
  'time_registration_tab.coarse.prevent_period_change.line_2.link': 'time registration',
  'time_registration_tab.coarse.select_period': 'Select period',
  'time_registration_tab.coarse.table.actions.edit': 'Edit registration',
  'time_registration_tab.coarse.table.header.days': 'Days',
  'time_registration_tab.coarse.table.header.description': 'Description',
  'time_registration_tab.coarse.table.header.hours': 'Hours',
  'time_registration_tab.coarse.table.header.period': 'Period',
  'time_registration_tab.detailed.add_new.flex_time': 'Register flex',
  'time_registration_tab.detailed.add_new.hours': 'Register time',
  'time_registration_tab.detailed.add_new.overtime': 'Register overtime',
  'time_registration_tab.detailed.add_new.project': 'Register project time',
  'time_registration_tab.detailed.approve_all': 'Approve all',
  'time_registration_tab.detailed.balance_note.flex_time': 'Balance for flex hours awaiting payroll.',
  'time_registration_tab.detailed.balance_note.hours': 'Number of work hours awaiting payment.',
  'time_registration_tab.detailed.balance_note.overtime': 'Balance for overtime awaiting payroll.',
  'time_registration_tab.detailed.balance_note.project': 'Balance for combined project hours awaiting payroll.',
  'time_registration_tab.detailed.confirm.bulk_delete':
    'Are you sure you wish to delete all the registrations? They cannot be recreated.',
  'time_registration_tab.detailed.delete_bulk': 'Delete all',
  'time_registration_tab.detailed.edit.button.approve': 'Approve time registration',
  'time_registration_tab.detailed.edit.button.delete': 'Delete time registration',
  'time_registration_tab.detailed.edit.form.break_minutes': 'Break',
  'time_registration_tab.detailed.edit.form.break_minutes.invalid': 'Break is invalid',
  'time_registration_tab.detailed.edit.form.break_minutes.longer_before_total':
    'Break cannot be longer than the entire registration',
  'time_registration_tab.detailed.edit.form.break_minutes.must_be_integer': 'Break must be an integer',
  'time_registration_tab.detailed.edit.form.break_minutes.suffix': 'minutes',
  'time_registration_tab.detailed.edit.form.created_at': 'Time registration created {createdAt}.',
  'time_registration_tab.detailed.edit.form.date': 'Date',
  'time_registration_tab.detailed.edit.form.date.required': 'Date is required',
  'time_registration_tab.detailed.edit.form.date.within_employment': 'Date cannot be before the employment date',
  'time_registration_tab.detailed.edit.form.end': 'End',
  'time_registration_tab.detailed.edit.form.end.after_start': 'End must be after start',
  'time_registration_tab.detailed.edit.form.end.invalid': 'End is invalid',
  'time_registration_tab.detailed.edit.form.end.required': 'End is required',
  'time_registration_tab.detailed.edit.form.hours': 'Number of hours',
  'time_registration_tab.detailed.edit.form.hours.note': 'Hundred parts hours (e.g. half hour is 0.5 hours).',
  'time_registration_tab.detailed.edit.form.hours.required': 'Number of hours is required',
  'time_registration_tab.detailed.edit.form.hours.suffix': 'hours',
  'time_registration_tab.detailed.edit.form.is_using': 'Spent or earned?',
  'time_registration_tab.detailed.edit.form.is_using.checked': 'Spent',
  'time_registration_tab.detailed.edit.form.is_using.unchecked': 'Earned',
  'time_registration_tab.detailed.edit.form.minutes': 'Minutes',
  'time_registration_tab.detailed.edit.form.minutes.invalid': 'Minutes are invalid',
  'time_registration_tab.detailed.edit.form.minutes.note': 'Only whole minutes',
  'time_registration_tab.detailed.edit.form.minutes.required': 'Minutes are required',
  'time_registration_tab.detailed.edit.form.minutes.suffix': 'minutes',
  'time_registration_tab.detailed.edit.form.note': 'Note',
  'time_registration_tab.detailed.edit.form.project_id': 'Project',
  'time_registration_tab.detailed.edit.form.project_id.none_available': 'No project available for this date',
  'time_registration_tab.detailed.edit.form.project_id.required': 'Project is required',
  'time_registration_tab.detailed.edit.form.salary_type_id': 'Type',
  'time_registration_tab.detailed.edit.form.salary_type_id.required': 'Type is required',
  'time_registration_tab.detailed.edit.form.start': 'Start',
  'time_registration_tab.detailed.edit.form.start.before_end': 'Start must be before end',
  'time_registration_tab.detailed.edit.form.start.format_example': 'Format HH:MM, e.g. 16:15',
  'time_registration_tab.detailed.edit.form.start.invalid': 'Start is invalid',
  'time_registration_tab.detailed.edit.form.start.required': 'Start is required',
  'time_registration_tab.detailed.edit.form.use_minutes': 'Enter in minutes',
  'time_registration_tab.detailed.edit.title.flex_time.create': 'Create flex',
  'time_registration_tab.detailed.edit.title.flex_time.edit': 'Edit flex',
  'time_registration_tab.detailed.edit.title.hours.create': 'Create time registration',
  'time_registration_tab.detailed.edit.title.hours.edit': 'Edit time registration',
  'time_registration_tab.detailed.edit.title.overtime.create': 'Create overtime registration',
  'time_registration_tab.detailed.edit.title.overtime.edit': 'Edit overtime registration',
  'time_registration_tab.detailed.edit.title.project.create': 'Create project hours registration',
  'time_registration_tab.detailed.edit.title.project.edit': 'Edit project hours registration',
  'time_registration_tab.detailed.edit.title.work_hours.create': 'Create working time',
  'time_registration_tab.detailed.edit.title.work_hours.edit': 'Edit working time',
  'time_registration_tab.detailed.history.table.header.date': 'Date',
  'time_registration_tab.detailed.history.table.header.description': 'Description',
  'time_registration_tab.detailed.history.table.header.hours': 'Hours',
  'time_registration_tab.detailed.history.table.header.note': 'Note',
  'time_registration_tab.detailed.history.table.title.earned': '{hours} earned',
  'time_registration_tab.detailed.history.table.title.used': '{hours} spent',
  'time_registration_tab.detailed.table.actions.delete': 'Delete registration',
  'time_registration_tab.detailed.table.actions.edit': 'Edit registration',
  'time_registration_tab.detailed.table.approved': 'Approved',
  'time_registration_tab.detailed.table.approved.approve': 'Approve',
  'time_registration_tab.detailed.table.approved.false': 'No',
  'time_registration_tab.detailed.table.approved.remove': 'Remove approval',
  'time_registration_tab.detailed.table.approved.true': 'Yes',
  'time_registration_tab.detailed.table.date': 'Date',
  'time_registration_tab.detailed.table.description': 'Description',
  'time_registration_tab.detailed.table.hours': 'Hours',
  'time_registration_tab.detailed.table.note': 'Note',
  'time_registration_tab.detailed.table.saving': 'Saving...',
  'time_registration_tab.detailed.table.title.earned': '{hours} earned',
  'time_registration_tab.detailed.table.title.rate_format': '{title} ({rate})',
  'time_registration_tab.detailed.table.title.used': '{hours} spent',
  'time_registration_tab.header.detailed.hours': 'Detailed time registration',
  'time_registration_tab.header.detailed.other': 'Registrations in app',
  'time_registration_tab.header.go_to_calendar.hours': "Go to the company's time schedule calendar",
  'time_registration_tab.header.hide_history': 'Back',
  'time_registration_tab.header.show_history': 'Show archive',
  'time_registration_tab.title.history.flex_time': 'Flex archive',
  'time_registration_tab.title.history.hours': 'Time registrations archive',
  'time_registration_tab.title.history.overtime': 'Overtime archive',
  'time_registration_tab.title.history.project': 'Project hours archive',
  'time_registration_tab.title.standard.flex_time': 'Flex',
  'time_registration_tab.title.standard.hours': 'Time registrations',
  'time_registration_tab.title.standard.overtime': 'Overtime',
  'time_registration_tab.title.standard.project': 'Project hours',

  'time_registration.class.flex': 'Flex hours',
  'time_registration.class.hours': 'Work hours',
  'time_registration.class.leave': 'Absence',
  'time_registration.class.overtime': 'Overtime',
  'time_registration.class.project_hours': 'Project hours',
  'time_registration.class.work_hours': 'Working hours',
  'time_registration.coarse_time_registration.many': 'coarse time registrations',
  'time_registration.coarse_time_registration.one': 'coarse time registration',
  'time_registration.detailed_time_registration.many': 'detailed time registrations',
  'time_registration.detailed_time_registration.one': 'detailed time registration',
  'time_registration.leave_duration.full_day': 'Whole day',
  'time_registration.leave_duration.half_day': 'Half day',
  'time_registration.leave_duration.other': 'Other value',
  'time_registration.leave_duration.quarter_day': 'Quarter day',
  'time_registration.leave_duration.three_quarters_day': 'Three quarter day',
  'time_registration.time_registration.many': 'time registrations',
  'time_registration.time_registration.one': 'time registration',

  'time_registrations.calendar.approve_all_text.many':
    'Are you sure you will approve {count} time registrations for all of {month}?',
  'time_registrations.calendar.approve_all_text.one':
    'Are you sure you will approve {count} time registration for all of {month}?',
  'time_registrations.calendar.context_menu.hours_format.no_note': '{hours}',
  'time_registrations.calendar.context_menu.hours_format.with_note': '{hours} ({note})',
  'time_registrations.calendar.context_menu.time_format.no_note': '{start} - {end}',
  'time_registrations.calendar.context_menu.time_format.with_note': '{start} - {end} ({note})',
  'time_registrations.calendar.instruction_text':
    'Click any available cell to create a new time registration. Click an existing one to approve, edit or delete.',
  'time_registrations.calendar.legend_hours': 'Hours',
  'time_registrations.calendar.new_type': 'new time registration',
  'time_registrations.calendar.title': 'detailed time registration',
  'time_registrations.calendar.tooltip_format_hours': 'Hours for {name} on {date}',
  'time_registrations.calendar.tooltip_format_named': '{salary} for {name} on {date}',
  'time_registrations.header.import_from_file': 'Import from file',
  'time_registrations.header.switch_to_calendar': 'Show as calendar',
  'time_registrations.header.switch_to_table': 'Show as table',
  'time_registrations.tab.delete_modal.intro.many':
    'You are about to delete all detailed time registrations on the company, that have not been through a payroll. This is a total of {count} time registrations. They cannot be recreated.',
  'time_registrations.tab.delete_modal.intro.one':
    'You are about to delete all detailed time registrations on the company, that have not been through a payroll. This is a total of {count} time registration. They cannot be recreated.',
  'time_registrations.tab.delete_modal.submit': 'Delete all',
  'time_registrations.tab.delete_modal.title': 'Delete all outstanding time registrations',
  'time_registrations.table.actions.edit': 'Edit',
  'time_registrations.table.actions.register': 'Register',
  'time_registrations.table.all_approved': 'All hours approved',
  'time_registrations.table.approve_hours': 'Approve hours',
  'time_registrations.table.detailed.delete_all': 'Delete all time registrations',
  'time_registrations.table.detailed.title': 'Detailed time registration',
  'time_registrations.table.header.employee': 'Employee',
  'time_registrations.table.header.hours': 'Hours',
  'time_registrations.table.header.rate': 'Rate',
  'time_registrations.table.header.salary': 'Remuneration',
  'time_registrations.table.loading': 'Loading...',
  'time_registrations.table.saving': 'Saving...',
  'time_registrations.table.select_period': 'Select period',
  'time_registrations.table.simple.title': 'Coarse time registration',

  'transfer_easy_account.alert.success': 'Changes to {name} have been saved',
  'transfer_easy_account.form.enable_easy_account_transfers': 'Enable Easy Account transfers',
  'transfer_easy_account.header.enable_all_employees': 'Enable Easy Account for all employees',
  'transfer_easy_account.intro.line_1':
    'When you have enabled Easy Account, Salary uses their CPR number instead of their account numbers to transfer pay.',
  'transfer_easy_account.intro.line_2':
    'The deadline for approval is 2 hours earlier, since MasterCard Payment Service needs additional time to look up their account number in the Easy Account registry. Additionally, it costs 0.71 kr. per transfer.',
  'transfer_easy_account.intro.line_3': 'Lastly you will need fill in this form at MasterCard Payment Service:',
  'transfer_easy_account.subtitle': 'Easy Account transfers',
  'transfer_easy_account.title': 'Easy Account',

  'transfer_immediate_pay.alert.success': 'Changes to {name} have been savd',
  'transfer_immediate_pay.description.immediate_pay':
    "{immediate} gives the option to transfer an employee's outlay immediately using Payment Service.",
  'transfer_immediate_pay.description.immediate_pay.title': 'Express transfers',
  'transfer_immediate_pay.description.payment_service.with_zimpler':
    "When using Payment Service, a transfer will first occur at next Payment Service run (before noon every bank day), and the money will first be available on the empoyee's account the next bank day.",
  'transfer_immediate_pay.description.payment_service.without_zimpler':
    "Since you are using Payment Service, transfers will first occur at next Payment Service run (before noon every bank day), and the money will first be available on the empoyee's account the next bank day.",
  'transfer_immediate_pay.description.swipe_cost':
    '{cost} per employee using the feature per period, if you are using the Basic package, and is free in the Premium package.',
  'transfer_immediate_pay.description.swipe_cost.cost': 'Swipe is a surcharge of 10 kr.',
  'transfer_immediate_pay.description.swipe.title': 'Swipe',
  'transfer_immediate_pay.description.swipe.with_zimpler':
    'With {title}, your employees can via the employee app request payments of their net pay immediately via Zimpler or Payment Service.',
  'transfer_immediate_pay.description.swipe.without_zimpler':
    'With {title}, your employees can via the employee app request payments of their net pay immediately via Payment Service.',
  'transfer_immediate_pay.description.zimpler':
    'When using Zimpler for the transfers, the money can arrive within five minutes.',
  'transfer_immediate_pay.form.auto_approve_time_registration_hours': 'Enable auto approval of hours',
  'transfer_immediate_pay.form.auto_approve_time_registration_hours.note':
    'The Swipe process is dependant on whether you have auto approval of hours enabled, since your waged employees would otherwise need to wait for a manual approval, if not enabled.',
  'transfer_immediate_pay.form.enable_always_current_pay_roll_run': "Ensure there is always a payroll for today's date",
  'transfer_immediate_pay.form.enable_always_current_pay_roll_run.note.line_1':
    'If you are using offset periods, there will be days where the period is over, but the disposition date is in the future. When there is no active payroll, your employees cannot use Swipe.',
  'transfer_immediate_pay.form.enable_always_current_pay_roll_run.note.line_2':
    'You can enable to ensure there is always an active payroll, as soon as the old one ends. This will however mean, you will end up having two open payrolls at the same time.',
  'transfer_immediate_pay.form.enable_immediate_pay': 'Enable express transfers',
  'transfer_immediate_pay.form.enable_limit': 'Enable auto approval',
  'transfer_immediate_pay.form.enable_limit.note':
    'You can enable auto approval including an upper limit for each employee per period. Without auto approval, all express transfers and Swipes needs to be manually approved.',
  'transfer_immediate_pay.form.enable_swipe': 'Enable Swipe',
  'transfer_immediate_pay.form.enable_swipe_fee': 'Enable employee fees',
  'transfer_immediate_pay.form.enable_swipe_fee.note.with_zimpler_fee':
    'Normally the usage of Swipe is free of charge for the employees, except the fee from Zimpler. You can enable an additional fee, so that the employee must pay to use Swipe. The fee will appear as a net reduction on the payslip.',
  'transfer_immediate_pay.form.enable_swipe_fee.note.without_zimpler':
    'Normally the usage of Swipe is free of charge for the employees. You can enable a fee, so that the employee must pay to use Swipe. The fee will appear as a net reduction on the payslip.',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out':
    'Upper limit for approval per employee per period',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.invalid': 'Upper limit is invalid',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.note.many':
    'With your current settings, {count} employees can automatically get a total of {amount} paid out during a period.',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.note.one':
    'With your current settings, one employee can automatically get a total of {amount} paid out during a period.',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.required': 'Upper limit is required',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.suffix': 'kr.',
  'transfer_immediate_pay.form.swipe_employment_type.note':
    'It is possible to limit the type of employees who can use Swipe, by enabling or disabling the type below. To disable all of them is the same as disabling Swipe altogether. Right now {employees} can use Swipe. You can {link}.',
  'transfer_immediate_pay.form.swipe_employment_type.note.employees.many': '{count} employees',
  'transfer_immediate_pay.form.swipe_employment_type.note.employees.one': '{count} employee',
  'transfer_immediate_pay.form.swipe_employment_type.note.link': 'adjust which individual employees can use Swipe here',
  'transfer_immediate_pay.form.swipe_fee_cost': 'Employee fee',
  'transfer_immediate_pay.form.swipe_fee_cost_cap': 'Upper limit for fee',
  'transfer_immediate_pay.form.swipe_fee_cost_cap.invalid': 'Upper limit for fee is invalid',
  'transfer_immediate_pay.form.swipe_fee_cost_cap.suffix': 'kr.',
  'transfer_immediate_pay.form.swipe_fee_cost_frequency': 'How often to charge the fee?',
  'transfer_immediate_pay.form.swipe_fee_cost_frequency.each': 'Every time',
  'transfer_immediate_pay.form.swipe_fee_cost_frequency.period': 'Once per payroll for the employee',
  'transfer_immediate_pay.form.swipe_fee_cost.fixed': 'kr.',
  'transfer_immediate_pay.form.swipe_fee_cost.invalid': 'Employee fee is invalid',
  'transfer_immediate_pay.form.swipe_fee_cost.note.cap':
    'You can also set an upper limit for the fee, so if the calculated fee is above the limit, the fee will be restricted to the limit.',
  'transfer_immediate_pay.form.swipe_fee_cost.note.frequency':
    'You can chose whether the fee should be charged every time, the employee swipes, or only once per payroll period for the employee.',
  'transfer_immediate_pay.form.swipe_fee_cost.note.type':
    'You can either chose a fixed amount or a percentage as fee. The employee will only see the amount they can Swipe with the fee without going over their available net pay. For eample, if an employee has 1,000 kr. in net pay, 50 kr. in fee, they will only be shown 950 kr. available for a Swipe.',
  'transfer_immediate_pay.form.swipe_fee_cost.percentage': '%',
  'transfer_immediate_pay.form.swipe_fee_cost.required': 'Employee fee is required',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.employee': 'Employee paid',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.employer': 'Employer paid',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.note.line_1':
    "Immediate payments with Zimpler costs 5 k. a piece. Per default it's you as employer who pay the expenses. However, you can chose to place the expenses onto your employees. Alternatively, you can share the expenses (2.5 kr. each) between you.",
  'transfer_immediate_pay.form.swipe_instant_cost_payer.note.line_2':
    'The employee will known of the expenses, and will be able to chose whether to use immediate or express payments (via Payment Service), which is expense free.',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.shared': 'Shared between employer and employee',
  'transfer_immediate_pay.form.swipe_notification_at': 'Time for Swipe notification',
  'transfer_immediate_pay.form.swipe_notification_at.between_0_and_23':
    'Time for Swipe notification must between 00 and 23, both inclusive',
  'transfer_immediate_pay.form.swipe_notification_at.note.line_1':
    'For notifications regarding Swipe requests that are not approved, can you chose when during the day (which hour), users that receives this notification will be informed.',
  'transfer_immediate_pay.form.swipe_notification_at.note.line_2':
    'Under company - payroll - notifications can you chose whether you want these notifications.',
  'transfer_immediate_pay.form.swipe_notification_at.required': 'Time for Swipe is required',
  'transfer_immediate_pay.header.reimbursement_settings': 'Expense management settings',
  'transfer_immediate_pay.header.transfers': 'Back',
  'transfer_immediate_pay.title.immediate': 'Express transfers',
  'transfer_immediate_pay.title.immediate_and_swipe': 'Express transfers & Swipe',
  'transfer_immediate_pay.title.swipe': 'Swipe',

  'transfer_report.from_date': 'From',
  'transfer_report.title': 'Transfer report',
  'transfer_report.to_date': 'To',

  'transfer_settings.alert.success': 'Changes to {name} have been saved',
  'transfer_settings.form.transfer_manual': 'Manual transfer',
  'transfer_settings.form.transfer_via': 'Transfered via {integration}',
  'transfer_settings.header.back': 'Back',
  'transfer_settings.title': 'Transfer settings',
  'transfer_settings.transfer_bank_account.change': 'Change payment integration',
  'transfer_settings.transfer_bank_account.create': 'Create Payment Service agreement',
  'transfer_settings.transfer_from.title': 'Transferred from account:',
  'transfer_settings.transfers_handled.automatic': 'Automatically',
  'transfer_settings.transfers_handled.manual': 'Manual',
  'transfer_settings.transfers_handled.title': 'The transfers happen:',
  'transfer_settings.transfers_via.payment_service': 'Payment Service',
  'transfer_settings.transfers_via.title': 'Transfered via:',

  'transfer.destination_type.dk_account': 'Danish bank account',
  'transfer.destination_type.dk_nemkonto': 'Easy Account',
  'transfer.destination_type.dk_nemkonto_cvr': 'Easy Account (CVR)',
  'transfer.destination_type.none': 'Foreign bank account',
  'transfer.setting_integration.manual': 'Manual',
  'transfer.setting_integration.nets': 'Payment Service',
  'transfer.setting_integration.stripe': 'Payment card',
  'transfer.setting_integration.zimpler': 'Zimpler',
  'transfer.type.dk_a_tax': 'A-tax',
  'transfer.type.dk_am_contribution': 'AM contribution',
  'transfer.type.dk_atp': 'ATP',
  'transfer.type.dk_flf': 'Family leave',
  'transfer.type.dk_flf_company_zero': 'Family leave zero report',
  'transfer.type.dk_flf_da_barsel': 'DA Barsel',
  'transfer.type.dk_forenede_gruppeliv': 'Forenede Gruppeliv',
  'transfer.type.dk_vacation_fund': 'Holiday allowance',
  'transfer.type.dk_vacation_fund_custom': 'Custom holiday allowance',
  'transfer.type.dk_vacation_fund_special_supplement': 'Custom holiday allowance (special supplement)',
  'transfer.type.freelance_fee': 'Freelancer compensation',
  'transfer.type.immediate_pay': 'Express payment',
  'transfer.type.invoice': 'Invoice',
  'transfer.type.pension': 'Pension',
  'transfer.type.pension_dk_account': 'Pension (account transfer)',
  'transfer.type.salary': 'Remuneration',

  'transfers_tab.header.early_payments': 'Transfer of taxes, etc.',
  'transfers_tab.header.immediate_pay': 'Swipe',
  'transfers_tab.header.nemkonto': 'Easy Account',
  'transfers_tab.header.transfer_report': 'Download Excel',
  'transfers_tab.header.transfer_settings': 'Change transfer settings',
  'transfers_tab.saving': 'Saving...',
  'transfers_tab.table.action.complete': 'Mark as manually transferred',
  'transfers_tab.table.action.edit_date': 'edit date',
  'transfers_tab.table.action.remove': 'Remove',
  'transfers_tab.table.action.switch_to_manual': 'Change to manual',
  'transfers_tab.table.action.switch_to_nets': 'Change to Payment Service',
  'transfers_tab.table.action.switch_to_stripe.full': 'Change to payment card',
  'transfers_tab.table.action.switch_to_stripe.simple': 'payment card',
  'transfers_tab.table.action.switch_wrapper.both': '{nets_link} or {stripe_link}',
  'transfers_tab.table.action.switch_wrapper.nets': '{nets_link}',
  'transfers_tab.table.action.switch_wrapper.stripe': '{stripe_link}',
  'transfers_tab.table.action.try_again': 'Run transfer again',
  'transfers_tab.table.completed.failed': 'Failed',
  'transfers_tab.table.completed.false': 'No',
  'transfers_tab.table.completed.true': 'Yes',
  'transfers_tab.table.header.amount': 'Amount',
  'transfers_tab.table.header.completed': 'Completed?',
  'transfers_tab.table.header.payment_date': 'Payment date',
  'transfers_tab.table.header.status': 'Status',
  'transfers_tab.table.header.type': 'Type',
  'transfers_tab.table.status.approved': 'Approved',
  'transfers_tab.table.status.completed': 'Completed',
  'transfers_tab.table.status.failed': 'Failed',
  'transfers_tab.table.status.pending': 'Awaiting',
  'transfers_tab.title': 'Transfers',

  'transfers.date.form.date': 'Payment date',
  'transfers.date.form.date.required': 'Payment date is required',
  'transfers.date.title': 'Edit payment date',

  'travel_allowance.edit.form.amount': 'travel allowance',
  'travel_allowance.edit.form.disposition_date': 'When to pay out the travel allowance?',
  'travel_allowance.edit.form.disposition_date.placeholder': 'Payment date',
  'travel_allowance.edit.intro': 'Please note you need to be able to document the receipts to SKAT.',
  'travel_allowance.edit.title.create': 'Create travel allowance',
  'travel_allowance.edit.title.edit': 'Edit travel allowance',
  'travel_allowance.header.add_travel_allowance': 'Create travel allowance',
  'travel_allowance.header.approve_all': 'Approve all',
  'travel_allowance.header.hide_history': 'Back',
  'travel_allowance.header.show_history': 'Show archive',
  'travel_allowance.header.title.history': 'Travel allowance archive',
  'travel_allowance.header.title.standard': 'Travel allowance',
  'travel_allowance.table.actions.delete': 'Delete travel allowance',
  'travel_allowance.table.actions.edit': 'Edit travel allowance',
  'travel_allowance.table.actions.view': 'View travel allowance',
  'travel_allowance.table.amount.format': '{units} of {rate}',
  'travel_allowance.table.approved.approve': 'Approve',
  'travel_allowance.table.approved.false': 'No',
  'travel_allowance.table.approved.remove': 'Remove approval',
  'travel_allowance.table.approved.true': 'Yes',
  'travel_allowance.table.header.amount': 'Amount',
  'travel_allowance.table.header.approved': 'Approved',
  'travel_allowance.table.header.date': 'Date',
  'travel_allowance.table.header.title': 'Description',
  'travel_allowance.table.saving': 'Saving...',

  'user_notifications.alert.success': 'Your notification settings for {name} have been saved',
  'user_notifications.form.notification_automatic_zero_tax_report_done':
    'Notification when a zero tax report has been completed.',
  'user_notifications.form.notification_employee_updated_banking':
    'Notification when an employee has changed their bank information via the employee app.',
  'user_notifications.form.notification_pay_roll_approval_upcoming':
    'Notification via text message the day before the payroll with Payment Service transfers needs approval.',
  'user_notifications.form.notification_pay_roll_automatically_started':
    'Notification when a new automatic payroll opens.',
  'user_notifications.form.notification_pay_roll_deadline_upcoming':
    'Notification days before a payroll needs approval.',
  'user_notifications.form.notification_pay_roll_done': 'Notification when a payroll is completed.',
  'user_notifications.form.notification_swipe_awaiting_approval': 'Notification when Swipe requests require approval.',
  'user_notifications.form.notification_transfer_info':
    'Notification for Payment Service errors (e.g. wrong account number) or general information.',
  'user_notifications.form.section.title': 'Your company notifications',
  'user_notifications.header.back': 'Back',
  'user_notifications.title': 'Your company notifications',

  'user_survey.step_0.help.least': 'Not likely',
  'user_survey.step_0.help.most': 'Very likely',
  'user_survey.step_0.intro.line_1': 'Hi {name}',
  'user_survey.step_0.intro.line_1.user_default': 'user',
  'user_survey.step_0.intro.line_2': 'How likely are you to recommend Salary to a friend or colleague?',
  'user_survey.step_0.next': 'Next',
  'user_survey.step_0.reject': 'No thanks',
  'user_survey.step_1.intro': 'What can we do to make Salary even better for you?',
  'user_survey.step_1.next': 'Submit',
  'user_survey.step_1.previous': 'Back',
  'user_survey.step_2.intro': 'Thank you for your time!  Your feedback is very valuable to us.',
  'user_survey.title': 'Survey',

  'user.company_type.accountant': 'Accountant',
  'user.company_type.approve_only': 'Pay approving only',
  'user.company_type.book_keeper': 'Bookkeeper',
  'user.company_type.business': 'Employee',
  'user.company_type.department_manager': 'Department manager',
  'user.company_type.external_salary_administrator': 'External pay administrator',
  'user.company_type.internal_salary_administrator': 'Internal pay administrator',
  'user.company_type.read_only': 'Read access only',
  'user.department_permission.approve_objects': 'Approve items',
  'user.department_permission.edit_objects': 'Edit items',
  'user.department_permission.hire_fire': 'Edit employments',
  'user.department_permission.see_salary_rates': 'See pay rates',
  'user.permission.admin': 'Administrator',
  'user.permission.approve_only': 'Pay approving only',
  'user.permission.approve_pay_roll': 'Approve pay',
  'user.permission.manager': 'User',
  'user.permission.review_pay_roll': 'Review pay',

  'vacation_calendar.alert.success': 'Changes to {name} have been saved',
  'vacation_calendar.date_format': '{day}/{month}',
  'vacation_calendar.form.active.checked': 'Holiday',
  'vacation_calendar.form.active.unchecked': 'Work day',
  'vacation_calendar.form.add_day.action': 'Add holiday',
  'vacation_calendar.form.add_day.intro':
    'You can add a holiday to the calendar which is not already included. The year in the calendar picker is ignored, and only the date is saved.',
  'vacation_calendar.form.add_day.note':
    'The added holiday will first be saved when you\'ve clicked "save changes" below.',
  'vacation_calendar.form.add_day.placeholder': 'Day',
  'vacation_calendar.form.add_day.title': 'Add holiday to calendar',
  'vacation_calendar.header.back': 'Back',
  'vacation_calendar.intro.line_1':
    'The holiday calendar determines which days are counted as holidays, except Saturday and Sunday, which normally are never work days.',
  'vacation_calendar.intro.line_2':
    'The normal holiday calendar in Salary follows the Danish holidays. You can adjust the holiday calendar here, including add more days as holidays.',
  'vacation_calendar.named_day_format': '{name} ({date})',
  'vacation_calendar.named_days.christmas_eve': "Christmas Eve's Day",
  'vacation_calendar.named_days.constitution_day': 'Constitution Day',
  'vacation_calendar.named_days.first_day_of_christmas': 'Christmas Day',
  'vacation_calendar.named_days.mayday': 'May day',
  'vacation_calendar.named_days.new_years_day': "New Year's Day",
  'vacation_calendar.named_days.new_years_eve': "New Year's Eve",
  'vacation_calendar.named_days.second_day_of_christmas': 'Boxing Day',
  'vacation_calendar.title': 'Back',

  'vacation_form.extra_vacation_accrual': 'Earned',
  'vacation_form.extra_vacation_accrual.monthly': 'Monthly',
  'vacation_form.extra_vacation_accrual.yearly': 'Annual',
  'vacation_form.extra_vacation_days': 'Extra holidays',
  'vacation_form.extra_vacation_days.invalid': 'Extra holidays are invalid',
  'vacation_form.extra_vacation_days.required': 'Extra holidays are required',
  'vacation_form.extra_vacation_days.suffix': 'days per year',
  'vacation_form.funds.10250218': 'MFD Feriekasse',
  'vacation_form.funds.19003981': 'Danske Slagtermestre',
  'vacation_form.funds.31378028': '3F Feriekasse',
  'vacation_form.funds.34498458': 'Feriefonden f Hotel- & Rest.erhv.',
  'vacation_form.funds.81831718': 'Bager- og Konditormestre i Danmark',
  'vacation_form.funds.own': 'Own CVR number',
  'vacation_form.great_prayer_supplement': 'Great Prayer Day Supplement',
  'vacation_form.has_vacation_fund': 'Other holiday fund',
  'vacation_form.has_vacation_fund.lock': 'Enable other holiday fund under company - advanced to edit this field',
  'vacation_form.horesta_vacation_supplement': 'Special holiday supplement',
  'vacation_form.horesta_vacation_supplement_amount': 'Supplement',
  'vacation_form.horesta_vacation_supplement_amount.invalid': 'Special holiday supplement is invalid',
  'vacation_form.horesta_vacation_supplement_amount.required': 'Special holiday supplement is required',
  'vacation_form.horesta_vacation_supplement_amount.suffix': '%',
  'vacation_form.manual_vacation_fund': 'Manual holiday allowance payment',
  'vacation_form.overtime_rate': 'Overtime rate',
  'vacation_form.overtime_rate.invalid': 'Overtime rate is invalid',
  'vacation_form.overtime_rate.required': 'Overtime rate is required',
  'vacation_form.overtime_rate.suffix': 'kr./hour',
  'vacation_form.personal_days': 'Personal day',
  'vacation_form.personal_days_accrual': 'Earned',
  'vacation_form.personal_days_accrual.monthly': 'Monthly',
  'vacation_form.personal_days_accrual.yearly': 'Annually',
  'vacation_form.personal_days.invalid': 'Personal day are invalid',
  'vacation_form.personal_days.required': 'Personal day are required',
  'vacation_form.personal_days.suffix': 'days per year',
  'vacation_form.time_bank': 'Time bank',
  'vacation_form.time_bank.flex': 'Flex',
  'vacation_form.time_bank.none': 'No time bank',
  'vacation_form.time_bank.overtime': 'Overtime',
  'vacation_form.use_atp': 'ATP',
  'vacation_form.use_day_laborer': 'Use other holiday calculation',
  'vacation_form.use_day_laborer.days': 'Use holiday calculation per day',
  'vacation_form.use_day_laborer.hours': 'Use holiday calculation per hour',
  'vacation_form.use_day_laborer.lock':
    'Enable holiday calculation per day/hour under company - advanced to edit this field',
  'vacation_form.use_day_laborer.none': 'Use normal holiday calculation',
  'vacation_form.vacation_days': 'Holidays',
  'vacation_form.vacation_days.invalid': 'Holidays are invalid',
  'vacation_form.vacation_days.must_be_between': 'Holidays must be between {min} and {max}',
  'vacation_form.vacation_days.required': 'Holidays are required',
  'vacation_form.vacation_days.suffix': 'days per year',
  'vacation_form.vacation_fund_cvr': 'CVR number for reporter',
  'vacation_form.vacation_fund_cvr.required': 'CVR number is required',
  'vacation_form.vacation_money': 'Holiday allowance',
  'vacation_form.vacation_money.invalid': 'Holiday allowance is invalid',
  'vacation_form.vacation_money.must_be_12_5': 'Holiday allowance can only be 12.5%',
  'vacation_form.vacation_money.required': 'Holiday allowance is required',
  'vacation_form.vacation_money.restricted_rate':
    'Holiday allowance can only be 12%, 12.5% or a value higher than 12.5%',
  'vacation_form.vacation_money.suffix': '%',
  'vacation_form.vacation_supplement': 'Holiday supplement',
  'vacation_form.vacation_supplement_in_pension_basis': 'Include vacation supplement in pension basis',
  'vacation_form.vacation_supplement.invalid': 'Holiday supplement is invalid',
  'vacation_form.vacation_supplement.required': 'Holiday supplement is required',
  'vacation_form.vacation_supplement.suffix': '%',
  'vacation_form.vacation_type.none': 'Executive pay',
  'vacation_form.vacation_type.none.tooltip': 'No legally required holiday',
  'vacation_form.vacation_type.paid': 'Paid holiday',
  'vacation_form.vacation_type.paid.tooltip': 'At least 25 holidays and 1% holiday supplement',
  'vacation_form.vacation_type.unpaid': 'Unpaid holiday',
  'vacation_form.vacation_type.unpaid.tooltip': 'At least 25 holidays and 12.5% holiday allowance',

  'vacation_settings.alert.success': 'Changes to {name} have been saved',
  'vacation_settings.form.automatic_optional_vacation_transfer_limit':
    'Number of automatically transferred monthly accrued personal days/extra holidays to next holiday year',
  'vacation_settings.form.automatic_optional_vacation_transfer_limit.invalid':
    'Number of automatically transferred monthly accrued personal days/extra holidays to next holiday year is invalid',
  'vacation_settings.form.automatic_vacation_transfer_limit':
    'Number of automatically transferred paid holidays to next holiday year',
  'vacation_settings.form.automatic_vacation_transfer_limit.invalid':
    'Number of automatically transferred paid holidays to next holiday year is invalid',
  'vacation_settings.form.leave_types_cycles_title': 'Holiday years for annual absence types',
  'vacation_settings.form.limit_i_calendar_to_departments': 'Access to absence calendar for employees',
  'vacation_settings.form.limit_i_calendar_to_departments.all': 'All employees in company',
  'vacation_settings.form.limit_i_calendar_to_departments.department': 'All employees in same department',
  'vacation_settings.form.limit_i_calendar_to_departments.employee': 'Personal absence calendar',
  'vacation_settings.form.limit_i_calendar_to_departments.required':
    'Access to absence calendar for employees is required',
  'vacation_settings.form.vacation_excess_limit': 'Number of holidays in advance',
  'vacation_settings.form.vacation_excess_limit.invalid': 'Number of holidays employees may borrow is invalid',
  'vacation_settings.header.back': 'Back',
  'vacation_settings.header.vacation_calendar': 'Holiday calendar',
  'vacation_settings.title': 'Holiday settings',

  'validate_national_id.cpr.in_use': 'CPR number is in use',
  'validate_national_id.cpr.invalid': 'CPR number is invalid',
  'validate_national_id.cpr.required': 'CPR number is required',
  'validate_national_id.cvr.in_use': 'CVR number is in use',
  'validate_national_id.cvr.invalid': 'CVR number is invalid',
  'validate_national_id.cvr.required': 'CVR number is required',

  'voucher_date_modal.form.date': 'Bookkeeping date',
  'voucher_date_modal.intro': 'You can edit the date Salary has chosen for your bookkeeping.',
  'voucher_date_modal.title': 'Edit bookkeeping date',

  'vouchers.header.back': 'Back',
  'vouchers.header.filter.only_failed': 'Show only failed',
  'vouchers.header.filter.payroll': 'Payrolls',
  'vouchers.header.filter.reimbursement_vouchers': 'Outlays',
  'vouchers.header.filter.transfer': 'Transfers',
  'vouchers.header.title': 'Bookkeeping',
  'vouchers.modal.header.account_number': 'Account',
  'vouchers.modal.header.credit': 'Credit',
  'vouchers.modal.header.debit': 'Debit',
  'vouchers.modal.header.description': 'Description',
  'vouchers.modal.header.vat': 'Moms',
  'vouchers.modal.title': 'Booking transactions',
  'vouchers.modal.unknown_account': 'Unknown account number',
  'vouchers.table.actions.csv': 'CSV',
  'vouchers.table.actions.details': 'Booking transactions',
  'vouchers.table.actions.details.tooltip': 'Show booking transactions',
  'vouchers.table.actions.pdf': 'PDF',
  'vouchers.table.actions.pdf.tooltip': 'Download as PDF',
  'vouchers.table.change_date': 'Edit date',
  'vouchers.table.header.date': 'Date',
  'vouchers.table.header.status': 'Status',
  'vouchers.table.header.type': 'Type',
  'vouchers.table.rebook': 'Book again',
  'vouchers.table.status.booked.automated': 'Automatically booked',
  'vouchers.table.status.booked.manual': 'Booked manually',
  'vouchers.table.status.booking_failed': 'Booking failed',
  'vouchers.table.status.daybooked': 'Transferred to journal',
  'vouchers.table.status.manual': 'Booked manually',
  'vouchers.table.status.pending.at_date': 'Will be booked at {date}',
  'vouchers.table.status.pending.unapproved': 'Draft',
  'vouchers.table.status.pending.within_15_minutes': 'Booked within 15 minutes',
  'vouchers.table.type.pay_roll': 'Payroll',
  'vouchers.table.type.reimbursement': 'Outlay',
  'vouchers.table.type.transfer': 'Transfer',

  'work_hours_registration_tab.alert.success': 'Working hours have been saved',
  'work_hours_registration_tab.alert.template_deleted': 'Template deleted',
  'work_hours_registration_tab.alert.template_none_saved': 'Employee now has no template',
  'work_hours_registration_tab.alert.template_saved': 'Template saved',
  'work_hours_registration_tab.detailed.table.actions.delete': 'Delete registration',
  'work_hours_registration_tab.detailed.table.actions.edit': 'Edit registration',
  'work_hours_registration_tab.detailed.table.date': 'Date',
  'work_hours_registration_tab.detailed.table.hours': 'Hours',
  'work_hours_registration_tab.detailed.table.note': 'Note',
  'work_hours_registration_tab.header.help.work_hours.line_1':
    'Working time is registration of hours for salaried employees.  They are exclusively for documentation of how many hours salaried employees have actually worked.',
  'work_hours_registration_tab.header.help.work_hours.line_2':
    'Working time has no impact on payrolls, and only appear in a report.',
  'work_hours_registration_tab.simple_form.company_template_intro':
    'Here you can fill in a company template, that will affect all employees in the overview, unless they have their own template.  The company template can always be replaced on the individual employee, if they need a different template week or no template at all.',
  'work_hours_registration_tab.simple_form.delete': 'Delete template',
  'work_hours_registration_tab.simple_form.delete_with_company': 'Use company level template',
  'work_hours_registration_tab.simple_form.intro': 'Enter the number of hours worked per day.',
  'work_hours_registration_tab.simple_form.leave_on_date': 'Absence on date.',
  'work_hours_registration_tab.simple_form.leave_on_date.item': '{amount} {name}',
  'work_hours_registration_tab.simple_form.leave_on_date.note.has_templates':
    'Templates will not save working hours on this date, since the following absence has been registered:',
  'work_hours_registration_tab.simple_form.leave_on_date.note.no_templates':
    'The following absence has been registered:',
  'work_hours_registration_tab.simple_form.none': 'No template for this employee',
  'work_hours_registration_tab.simple_form.submit': 'Save week',
  'work_hours_registration_tab.simple_form.submit.template': 'Save template',
  'work_hours_registration_tab.simple_form.time_based.break': 'Break',
  'work_hours_registration_tab.simple_form.time_based.break.invalid': 'Break is invalid',
  'work_hours_registration_tab.simple_form.time_based.break.longer_before_total':
    'Break cannot be longer than the entire registration',
  'work_hours_registration_tab.simple_form.time_based.break.must_be_integer': 'Break must be an integer',
  'work_hours_registration_tab.simple_form.time_based.break.suffix': 'minutes',
  'work_hours_registration_tab.simple_form.time_based.end': 'End',
  'work_hours_registration_tab.simple_form.time_based.header.break': 'Break',
  'work_hours_registration_tab.simple_form.time_based.header.day': 'Day',
  'work_hours_registration_tab.simple_form.time_based.header.end': 'End',
  'work_hours_registration_tab.simple_form.time_based.header.start': 'Start',
  'work_hours_registration_tab.simple_form.time_based.start': 'Start',
  'work_hours_registration_tab.simple_form.unlock': 'Create template for employee',
  'work_hours_registration_tab.template.lock': 'Upgrade to Premium to create working hours templates',
  'work_hours_registration_tab.title': 'Working time',
  'work_hours_registration_tab.violation.average_rule_violation':
    'Over four months, the average has been more than 48 hours per week.',
  'work_hours_registration_tab.violation.day_off_violation': 'During a week, there was not the required rest day.',
  'work_hours_registration_tab.violation.go_to_week': 'Switch to week',
  'work_hours_registration_tab.violation.rest_hours_violation':
    'A period of 24 hours did not meet the required at least 11 hours of rest.',
  'work_hours_registration_tab.week.detailed_toggle': 'Detailed',
  'work_hours_registration_tab.week.new_registration': 'New registration',
  'work_hours_registration_tab.week.next': 'Next week',
  'work_hours_registration_tab.week.previous': 'Previous week',
  'work_hours_registration_tab.week.summary': 'Weekly hours: {summary}',
  'work_hours_registration_tab.week.summary.count.many': '{number} hours',
  'work_hours_registration_tab.week.summary.count.one': '{number} hour',
  'work_hours_registration_tab.week.this_week': 'This week',
  'work_hours_registration_tab.week.title.template': 'Template week',

  'work_hours.delete_all.delete_all': 'Delete also in the past',
  'work_hours.delete_all.intro.line_1':
    'You are about to delete all working hours registrations from the current week and onwards (i.e. from and inclusive {from}).  You can also chose to delete all working hours registration, including in the past.',
  'work_hours.delete_all.intro.line_2': 'Be aware that once deleted, they cannot be restored!',
  'work_hours.delete_all.submit.include_past': 'Delete all working hours registrations',
  'work_hours.delete_all.submit.normal': 'Delete all working hours registrations from this week and onwards',
  'work_hours.delete_all.title': 'Delete all working hours registrations?',
  'work_hours.header.delete_all': 'Delete all',
  'work_hours.header.enable_work_hours': 'Enable working hours',
  'work_hours.header.enable_work_hours_auto': 'Enable templates',
  'work_hours.header.enable_work_hours_auto.lock': 'Upgrade to Premium to create working hours templates',
  'work_hours.header.enable_work_hours_start_end': 'Register using from/to fields',
  'work_hours.header.enable_work_hours_warnings': 'Enable warnings',
  'work_hours.header.enable_work_hours_warnings.lock':
    'Upgrade to Premium to enable warnings about working hours irregularities',
  'work_hours.header.templates': 'Templates',
  'work_hours.table.header.employee': 'Employee',
  'work_hours.table.header.registrations_count': 'Number of registrations',
  'work_hours.table.header.total_month_hours': 'Monthly working hours',
  'work_hours.table.header.total_week_hours': 'Weekly working hours',
  'work_hours.table.title': 'Working hours',
  'work_hours.table.violations.average_rule_violation': 'Over 4 months, the average is more than 48 hours per week.',
  'work_hours.table.violations.day_off_violation': 'There is a missing weekly rest day.',
  'work_hours.table.violations.rest_hours_violation': 'There is less than 11 hours rest over a 24 hour period.',
  'work_hours.table.week.next': 'Next week',
  'work_hours.table.week.previous': 'Previous week',
  'work_hours.table.week.this_week': 'This week',
  'work_hours.table.week.title': 'Week {week_number} ({week_from} - {week_to})',
  'work_hours.table.work_hours_not_enabled':
    'The working hours feature has not been enabled.  You can enable it at the top of this page.',
  'payslip_import.loading.timeout.text': 'This could take several minutes. Please wait and stay on this page.',
  'payslip_import.title': 'IMPORT EMPLOYEE PAYSLIP',
  'payslip_import.step.1.intro.line_1': 'Choose format to upload payslips with.',
  'payslip_import.step.1.intro.line_2.title': 'payslip format',
  'payslip_import.step.1.intro.line_2': 'When you have chosen the correct {title} you can click on proceed.',
  'payslip_import.step.1.intro.line_3':
    'On the last step you can see all the information from the file you have uploaded, before you actually chose to save it.',
  'payslip_import.step.1.import_type_select.type.dataloen': 'Dataløn format',
  'payslip_import.step.1.import_type_select.type.danloen': 'Danløn format',
  'payslip_import.step.1.import_type_select.type.zenegy': 'Zenegy format',
  'payslip_import.step.1.import_type_select.type.prolon': 'Proløn format',
  'payslip_import.step.1.import_type_select.type.visma': 'Visma format',
  'payslip_import.step.1.import_type_select.type.letlon': 'Letløn format',
  'payslip_import.step.1.import_type_select.type.lessor': 'Lessor format',
  'payslip_import.step.1.import_type_select.type.intect': 'Intect format',
  'payslip_import.step.1.import_type_select.type.ekspreslon': 'EkspresLøn format',
  'payslip_import.step.1.continue.upload': 'Continue to upload',
  'payslip_import.step.2.title': 'Upload PDF file',
  'payslip_import.step.2.intro.line_2.title': 'one PDF file at a time',
  'payslip_import.step.2.intro.line_1':
    'Upload the PDF file containing the payslips here on this page and then you can proceed to see the data afterwards',
  'payslip_import.step.2.intro.line_2': 'You can only upload {title}, so gather the payslips in one file.',
  'payslip_import.step.2.button.proceed': 'Proceed to approve',
  'payslip_import.step.2.button.process': 'Process file',
  'payslip_import.step.2.button.upload': 'Upload file',
  'payslip_import.step.2.button.upload.another': 'Upload a different file',
  'payslip_import.step.2.button.upload.success': 'The file was uploaded succesfully: ',
  'payslip_import.step.2.button.upload.error':
    'Error in upload, try again, check the payslip type in the previous step: ',
  'payslip_import.step.2.button.staging.error': 'Processing error, go back and check the payslip type',
  'payslip_import.step.2.button.staging.success': 'Processing of file completed: ',
  'payslip_import.step.3.title': 'Approve & apply',
  'payslip_import.step.3.intro.line_1':
    'Below here you can see all the information which was lifted from the file that was uploaded',
  'payslip_import.step.3.intro.line_2':
    "If you are satisfied with the way it looks, you can click the 'Create' button and then the employees will be created",
  'payslips_import.step.3.button.done': 'Create',
  'payslips_import.step.3.button.cancel': 'Cancel',
  'payslips_import.step.3.button.finish': 'Finish',
  'payslip_import.step.3.intro.line_3': 'ATT: Not approved employees will be removed from the creation.',
  'payslip_import.step.3.tooltip.approved': 'Have you approved all employees that should be created?',
  'payslip_import.step.3.tooltip.not.approved': 'Approve employees first',
  'payslip_import.step.3.all.creations.failed':
    'All creations failed. Contact customer service for help. Possible reasons: ',
  'payslip_import.step.3.all.creations.failed.reason.nemkonto': "NemKonto transfers without 'Overførselsservice'",
  'payslip_import.step.3.all.creations.failed.reason.exists': 'Employees already created',
  'payslip_import.step.3.some.creations.failed': 'Some creations failed. Contact customer service for help.',
  'payslip_import.step.3.creations.async':
    'It is taking longer than expected. Wait a couple of minutes and then you can see the created employees under the "Employees" tab. If they do not show up, then please contact customer service for further help.',

  'payslip_import.steps.title': 'Payslip import',
  'payslip_import.steps.intro': 'Import start balances, salary data, personal data, etc.',
  'payslip_import.steps.1.summary': 'Chose import type',
  'payslip_import.steps.2.summary': 'Upload file',
  'payslip_import.steps.3.summary': 'Approve & apply import',
  'payslip_import.steps.4.summary': 'Chose import type',

  'payslip_import.employees.table.header.name': 'Name',
  'payslip_import.employees.table.header.name.error': `Payslip {indexNumber} could not be imported`,
  'payslip_import.employees.table.header.national.id': 'CPR',
  'payslip_import.employees.table.header.show.info': 'Show information',
  'payslip_import.employees.table.header.remove.employee': 'Remove from import',
  'payslip_import.employees.table.header.approved': 'Approved',
  'payslip_import.employees.table.title': 'Import overview',
  'payslip_import.employees.table.row.checkbox.label': 'Confirm approval',
  'payslip_import.employees.table.row.button.show': 'Show',
  'payslip_import.employees.table.row.button.hide': 'Hide',
  'payslip_import.employees.table.row.button.remove': 'Remove',
  'payslip_import.employees.table.row.info.title.leave': 'Leave',
  'payslip_import.employees.table.row.info.title.benefits': 'Benefits',
  'payslip_import.employees.table.row.info.title.pension': 'Pension',
  'payslip_import.employees.table.row.info.title.supplements': 'Supplements',
  'payslip_import.employees.table.row.info.title.start.balances': 'Start balances',
  'payslip_import.employees.table.row.info.title.personal.info': 'Personal information',
  'payslip_import.employees.table.row.info.title.salary': 'Salary',
  'payslip_import.employees.table.row.info.subtitle.address': 'Address',
  'payslip_import.employees.table.row.info.subtitle.zip.code': 'Zip code',
  'payslip_import.employees.table.row.info.subtitle.city': 'City',
  'payslip_import.employees.table.row.info.subtitle.salary.type': 'Salary type',
  'payslip_import.employees.table.row.info.subtitle.salary.amount': 'Salary amount',
  'payslip_import.employees.table.row.info.subtitle.leave.type': 'Leave type',
  'payslip_import.employees.table.row.info.subtitle.leave.max': 'Max days earned per year',
  'payslip_import.employees.table.row.info.subtitle.benefits.amount': 'Benefits amount',
  'payslip_import.employees.table.row.info.subtitle.supplements.amount': 'Supplements amount',
  'payslip_import.employees.table.row.info.subtitle.pension.lines': 'Has pension',
  'payslip_import.employees.table.row.info.subtitle.pension.companies': 'Pension companies',
  'payslip_import.employees.table.row.info.subtitle.start.balances.aincome': 'A-income',
  'payslip_import.employees.table.row.info.subtitle.start.balances.atax': 'A-tax',
  'payslip_import.employees.table.row.info.subtitle.start.balances.amincome': 'AM-income',
  'payslip_import.employees.table.row.info.subtitle.start.balances.amcontribution': 'AM-contribution',
  'payslip_import.employees.table.row.info.subtitle.start.balances.atp.employee': 'ATP employee',
  'payslip_import.employees.table.row.info.subtitle.start.balances.atp.employer': 'ATP employer',
  'payslip_import.employees.table.row.info.subtitle.start.balances.hours.worked': 'Hours worked',
  'payslip_import.employees.table.row.info.subtitle.start.balances.great.prayer': 'Great prayer day contribution',

  'payslip_import.employees.table.row.info.field.empty': 'Empty value',
  'payslip_import.employees.table.row.info.field.true': 'Yes',
  'payslip_import.employees.table.row.info.field.false': 'No',
  'payslip_import.employees.table.row.info.field.denmark.vacation.accrual': 'Paid vacation',
  'payslip_import.employees.table.row.info.field.denmark.vacation.fund': 'Unpaid vacation',
  'payslip_import.employees.table.row.info.field.normal.hours': 'Hourly wage',
  'payslip_import.employees.table.row.info.field.monthly.salary': 'Monthly',
  'payslip_import.employees.table.row.info.field.ceo.salary': 'Non holiday entitled salary',

  'global_search.advanced_settings': 'Settings',
  'global_search.sites': 'Sites',

  'accounting_tab.description': 'Here you can see and change how your accounting program is connected to Salary',
  'accounting_tab.dimensions.departments.description':
    'Normally, accounting lines are collected for the entire company, so that, for example, only one large payroll entry is posted. But you can also have the postings divided by department instead',
  'accounting_integration.integration_type.description':
    'Connect your accounting program. Everything accounting-wise will be automatically transferred to your accounting program.',
  'accounting_tab.settings.description':
    'Here you can turn advanced accounting settings on and off, depending on your needs.',
  'vouchers.header.description': 'Here you can see the status and type of your accounting entries and download them',
  'integration_layout.menu.contract_book.description':
    'Here you can integrate, change and view contracts in contractbook.',
  'integration_layout.menu.data_provider.description':
    'Employees, time registrations and/or absence registrations can be imported from a data provider, such as a time registration system or a personnel management system.',
  'integration_layout.menu.codan.description':
    'Salary collaborates with Codan on the mandatory occupational injury insurance.',
  'integration_layout.menu.min_refusion.description':
    'Connect MinRefusion to Salary and receive refunds automatically.',
  'integration_layout.menu.rmindr.description': 'Avoid fines, get an overview of your deadlines',
  'integration_layout.menu.slack.description': 'Setting up Slack integration',
  'integration_layout.menu.advisor.description': 'Find your accountant or bookkeeper with Ageras',
  'company.menu.company.description':
    'Here you can see an overview of your company as well as change master data and enable/disable auto approvals',
  'vacation_settings.description': 'Here you can set the number of vacation days employees can transfer and borrow',
  'vacation_settings.header.vacation_calendar.description':
    'The holiday calendar determines which days count as a holiday, in addition to Saturday and Sunday, which by default are never working days.',
  'company.edit.header.production_units.description': 'Here you can add and remove production units.',
  'company.edit.header.users.description': 'Overview of users with access to this company profile.',
  'company.menu.pay.description':
    'Set payroll to automatic approval as well as set up zero reporting and other payroll related functions.',
  'nets_tab.description': 'View messages from the transfer service.',
  'deviation.header.description':
    'Here you can choose which types of deviations you want to be notified about, as well as extra settings for them.',
  'salary_types.tab.description': 'Set hourly rates and variable supplements for employees.',
  'user_notifications.description': 'Decide when you receive company notifications.',
  'company.menu.invoices.description': 'Overview of invoices paid through Salary.',
  'company.menu.transfers.description': 'Get an overview of all transfers made through Salary.',
  'transfer_immediate_pay.title.immediate_and_swipe.description':
    'Express transfers allow you to transfer an employees expenses immediately via the Transfer Service and swipe allows your employees to get paid immediately',
  'reimbursement_settings.description': 'Determine which types of transfers should be made manually.',
  'transfer_easy_account.description':
    'When you have NemKonto enabled, Salary uses your employees CPR numbers instead of their account numbers to transfer salary.',
  'transfer_settings.description': 'Here you can view and change the companys transfer settings.',
  'transfer_settings.transfer_bank_account.change.description': 'Here you can get help to create a transfer agreement',
  'company.menu.departments.description': 'Create departments for your company.',
  'company.menu.api_keys.description': 'Create and remove API keys for Salary',
  'company.menu.pricing.description': 'See your current price plan and see how to change it.',
  'company.menu.support.description':
    'Give support access to your company, order moving service from another payroll system, request company deletion.',
  'company.menu.reports.description': 'Choose from a list of different reports',
  'report_tab.header.tax_cards.description':
    'Get an overview of which active tax cards Salary has over the companyss employees.',
  'administration_layout.menu.documents.description': 'Here you can upload, share, and delete documents.',
  'administration_layout.menu.assets.description':
    'Keep track of the companys units, who has what and which units must be returned for terminated employees',
  'administration_layout.menu.projects.description': 'Create and customize projects and which employees are associated',
  'documents.title.history.description': 'Get an overview of the companys documents.',
  'asset_category.header.description': 'Here you can see the different unit categories your company has.',
  'approve_tab.time_registrations.description': 'Here you can see your employees simple or detailed time registration.',
  'registrations.layout.awaiting_approval.description': 'Here you can see registrations that are awaiting approval.',
  'registrations.layout.leave_registrations.description': 'Here you can see your employees absence registration.',
  'registrations.layout.car_allowances.description':
    'Here you can see simple or detailed mileage accounts for your employees.',
  'registrations.layout.work_hours.description': 'Here you can see the working hours for the companys employees.',
  'registrations.layout.one_time_pays.description':
    'Here you can see and add payroll supplements/deductions for the companys employees',
  'registrations.layout.reimbursement_vouchers.description': 'Here you can see an expense overview of the company.',
  'reimbursement_vouchers.header.expense_categories.description': 'Here you can see the companys expense categories.',
  'transfer_immediate_pay.header.reimbursement_settings.description':
    'Here you can change or view the companys expense settings.',
  'freelancers_overview.description': 'Here you can view and edit compensation registration for freelancers.',
  'freelancers_overview_add.description': 'Here you can view, edit and add to the companys freelancers.',
  'freelancers.header.invite_freelancer.description': 'Here you can invite freelancers to the company.',
  'freelancers.header.add_freelancer.description': 'Here you can add freelancers.',
  'header.employees.description': 'Here you can see an overview of the companys employees',
  'employee.add.steps.description': 'Here you can add employees to the company.',
  'employee.add.invite.description': 'Here you can invite employees to the company',
  'employee_batch.wizard.steps.description': 'Here you can perform a batch action for the companys employees.',
  'header.pay.description': 'Here you can see an overview of payrolls.',
  'pay_rolls.add.description': 'Here you can create extraordinary pay.',
  'account.profile.description': 'Here you can view your Salary profile and change name, phone and language settings.',
  'account.email.description': 'Here you can view and change the email you have associated with your Salary profile.',
  'account.add_new_company.description': 'Here you can add a new company to your Salary account.',
  'account.password.description': 'Here you can change your password for Salary',
  'account.mfa.description': 'Here you can enable two-factor authentication.',
  'account.mitid.description': 'Here you can verify your identity with MitID.',
  'account.terminate.description': 'Here you can request to terminate your Salary account.',
}
